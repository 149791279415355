import React, {useEffect, useState} from "react";
import {Row, Col, CardBody, Card, Container} from "reactstrap";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {getUserStores} from "slices/thunks";
import processArrow from "assets/images/process-arrow-img.png";
import {useDispatch, useSelector} from "react-redux";
import {useProfile} from "Components/Hooks/UserHooks";
import {createSelector} from "@reduxjs/toolkit";
import {RootState} from "slices";
import {getUserSubscriptionPackage} from "slices/account/thunk";

declare global {
  interface Window {
    dataLayer: any[];
  }
}

const GettingStarted = () => {
  const {t} = useTranslation();
  const {hasPermission} = useProfile();
  const dispatch: any = useDispatch();
  const [steps, setSteps] = useState<Array<{step: number; completed: boolean; title: string; description: string; icon: string; href?: string; hrefName?: string}>>([]);

  const userData = createSelector(
    (state: RootState) => state,
    (state) => ({
      storeList: state.Stores.list,
      userSubscription: state.Account.userSubscription,
      subscription: state.Account.subscription,
    }),
  );
  const {storeList, userSubscription, subscription} = useSelector(userData);

  document.title = t("PageTitles.TrialStartedWelcome");

  const initialSteps = [
    {
      step: 1,
      completed: true,
      title: "GettingStarted.Steps.Step1.Title",
      description: "GettingStarted.Steps.Step1.Description",
      icon: " ri-user-add-line",
    },
    {
      step: 2,
      completed: true,
      title: "GettingStarted.Steps.Step2.Title",
      description: "GettingStarted.Steps.Step2.Description",
      icon: "mdi mdi-calendar-month-outline",
    },
    {
      step: 3,
      completed: false,
      title: "GettingStarted.Steps.Step3.Title",
      description: "GettingStarted.Steps.Step3.Description",
      href: "/stores/connect",
      hrefName: "ConnectYourStore",
      icon: "mdi mdi-connection",
    },
    {
      step: 4,
      completed: false,
      title: "GettingStarted.Steps.Step4.Title",
      description: "GettingStarted.Steps.Step4.Description",
      href: "/searches/new",
      hrefName: "SearchProducts",
      icon: "bx bx-search-alt",
    },
  ];

  useEffect(() => {
    if (hasPermission("account.subscription") && hasPermission("stores")) {
      dispatch(getUserSubscriptionPackage());
      dispatch(getUserStores());
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    const updatedSteps = initialSteps.map((step) => {
      if (step.step === 3) {
        return {...step, completed: storeList.length > 0};
      }
      return step;
    });
    setSteps(updatedSteps);
  }, [storeList]); // eslint-disable-line

  const subscriptionValue =
    userSubscription && subscription
      ? userSubscription.billingPeriodMonth === 1
        ? subscription.priceMonth
        : userSubscription.billingPeriodMonth === 6
        ? subscription.priceSixMonth
        : subscription.priceYear
      : 0;

  useEffect(() => {
    if (subscriptionValue > 0) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "subscriptionSuccess",
        subscriptionValue: subscriptionValue.toFixed(2),
      });
    }
  }, [subscriptionValue]);

  return (
    <React.Fragment>
      <div className="page-content">
        <section className="section">
          <Container>
            <Card className="rounded-4 bg-overlay-pattern shadow-sm">
              <CardBody>
                <Row className="justify-content-center mb-3">
                  <Col lg={8}>
                    <div className="my-5">
                      <div className="avatar-lg mx-auto">
                        <div className="avatar-title bg-light text-success display-3 rounded-circle">
                          <i className="ri-checkbox-circle-fill"></i>
                        </div>
                      </div>
                      <h1
                        className="mt-4 mb-5 fw-semibold lh-base text-center"
                        dangerouslySetInnerHTML={{
                          __html: t("GettingStarted.Title"),
                        }}
                      ></h1>
                      <p className="fs-17 mb-4">{t("GettingStarted.MainMessage")}</p>
                      <p className="fs-17 mb-4">{t("GettingStarted.SubMessage")}</p>
                      <p className="text-muted">{t("GettingStarted.NeedHelp")}</p>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Row className="text-center mb-4">
              {steps.map((item, key) => (
                <Col lg={3} md={6} key={key} className="same-height">
                  <Card className={`rounded-4 ${item.completed === true ? "shadow-lg card-item-checked-lg" : "shadow-sm"}`}>
                    <CardBody>
                      <div className="process-card mt-4">
                        {key !== steps.length - 1 ? (
                          <div className="process-arrow-img d-none d-lg-block">
                            <img src={processArrow} alt="" className="img-fluid" />
                          </div>
                        ) : null}
                        <div className="avatar-sm icon-effect mx-auto mb-4">
                          <div className="avatar-title bg-transparent text-success rounded-circle h1">
                            <i className={item.icon}></i>
                          </div>
                        </div>

                        <h5>{t(item.title)}</h5>
                        <p className="text-muted fs-15">{t(item.description)}</p>
                        {item.href && !item.completed && (
                          <div className="mt-3">
                            <Link to={item.href} className="fs-13 fw-medium link-secondary">
                              {t(item.hrefName ?? "")} <i className="ri-arrow-right-s-line align-bottom"></i>
                            </Link>
                          </div>
                        )}
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              ))}
            </Row>
            <Row>
              <div className="mt-0 text-center">
                <Link to={"/dashboard"} className="btn btn-info rounded-pill">
                  {t("GettingStarted.GoToDashboard")} <i className="ri-arrow-right-line align-middle ms-1"></i>
                </Link>
              </div>
              <input type="hidden" id="subscriptionValue" value={subscriptionValue || 0} />
            </Row>
          </Container>
        </section>
      </div>
    </React.Fragment>
  );
};

export default GettingStarted;
