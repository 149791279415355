import {formatCurrency} from "helpers/utilities";
import {UserSearchProduct} from "models/user_search_product";
import {Button, Card, CardBody, Table, UncontrolledPopover} from "reactstrap";
import {SellerTypeBadge} from "./Components/SellerTypeBadge";
import {useTranslation} from "react-i18next";
import DefaultUncontrolledTooltip from "Components/Common/DefaultUncontrolledTooltip";
import DisplayPrice from "Components/Common/DisplayPrice";
import {useEffect, useState} from "react";
import AmazonMarketplaceInfos, {AmazonMarketplaceInfosType} from "Components/Common/AmazonMarketplaceInfos";
import RenderNumber from "Components/Common/RenderNumber";

interface ProfitAnalysisProps {
  item: UserSearchProduct;
}
const ProfitAnalysis = ({item}: ProfitAnalysisProps) => {
  const {t} = useTranslation();
  const [amazonMarketplaceInfos] = useState(AmazonMarketplaceInfos());
  const [sourceMarketplace, setSourceMarketplace] = useState<AmazonMarketplaceInfosType>();
  const [destinationMarketplace, setDestinationMarketplace] = useState<AmazonMarketplaceInfosType>();

  let priceSign = "";

  if (item.currencyCodeTarget === "CAD") {
    priceSign = "$";
  }

  useEffect(() => {
    let source = amazonMarketplaceInfos.find((amazonMarketplaceInfo) => amazonMarketplaceInfo.marketplace === item.marketplaceSource);
    let destination = amazonMarketplaceInfos.find((amazonMarketplaceInfo: any) => amazonMarketplaceInfo.marketplace === item.marketplaceTarget);
    setSourceMarketplace(source);
    setDestinationMarketplace(destination);
  }, [amazonMarketplaceInfos, item.marketplaceSource, item.marketplaceTarget]);

  return (
    <Card className="border card-animate">
      <CardBody>
        <h5>{t("SearchResults.Analyzes.ProfitAnalysis.Title")}</h5>
        <ul className="list-group d-flex flex-group gap-1">
          <li className="d-flex justify-content-between fw-bold mt-1">
            <span>{t("SearchResults.Analyzes.ProfitAnalysis.CostsTitle")}</span>
            <span className="d-flex">
              <DisplayPrice className="me-1" source={sourceMarketplace?.currency} decimals={2} value={item.profitAnalysis.totalCostSource} prefix="(" suffix=")" />
              <DisplayPrice source={destinationMarketplace?.currency} decimals={2} value={item.profitAnalysis.totalCost} />
            </span>
          </li>
          <li className="d-flex justify-content-between">
            <span className="ms-3">
              {t("SearchResults.Analyzes.ProfitAnalysis.PurchasePrice")}
              {item.competitorAnalysis?.offerCountSource === 0 && (
                <>
                  <i id={`PopoverNoSellerInTheSourceMarketplace-${item.userSearchProductId}`} className="ms-1 mdi mdi-alert align-middle text-warning"></i>
                  <DefaultUncontrolledTooltip target={`PopoverNoSellerInTheSourceMarketplace-${item.userSearchProductId}`}> {t("NoSellerInTheSourceMarketplace")}</DefaultUncontrolledTooltip>
                </>
              )}
            </span>
            <span className="d-flex">
              <DisplayPrice className="me-1" source={sourceMarketplace?.currency} decimals={2} value={item.profitAnalysis.purchasePriceSource} prefix="(" suffix=")" />
              <DisplayPrice source={destinationMarketplace?.currency} decimals={2} value={item.profitAnalysis.purchasePrice} />
            </span>
          </li>
          <li className="d-flex justify-content-between">
            <span className="ms-3">{t("SearchResults.Analyzes.ProfitAnalysis.Tax")}</span>
            <span className="d-flex">
              <DisplayPrice className="me-1" source={sourceMarketplace?.currency} decimals={2} value={item.profitAnalysis.taxSource} prefix="(" suffix=")" />
              <DisplayPrice source={destinationMarketplace?.currency} decimals={2} value={item.profitAnalysis.tax} />
            </span>
          </li>
          <li className="d-flex justify-content-between">
            <div className="d-flex align-items-center">
              <span className="ms-3">{t("SearchResults.Analyzes.ProfitAnalysis.Prep&Ship")}</span>
              <i id={`PopoverShippingPrice-${item.userSearchProductId}`} className="ms-1 bx bxs-info-circle text-secondary"></i>
              <DefaultUncontrolledTooltip target={`PopoverShippingPrice-${item.userSearchProductId}`}>{t("Definitions.ShippingPrice")}</DefaultUncontrolledTooltip>
            </div>
            <span className="d-flex">
              <DisplayPrice className="me-1" source={sourceMarketplace?.currency} decimals={2} value={item.profitAnalysis.shippingPriceSource} prefix="(" suffix=")" />
              <DisplayPrice source={destinationMarketplace?.currency} decimals={2} value={item.profitAnalysis.shippingPrice} />
            </span>
          </li>
        </ul>

        <li className="d-flex justify-content-between fw-bold">
          <span>{t("SearchResults.Analyzes.ProfitAnalysis.FeesTitle")}</span>
          <span>
            <DisplayPrice source={destinationMarketplace?.currency} decimals={2} value={item.profitAnalysis.totalFees} />
          </span>
        </li>
        <ul className="list-group d-flex flex-group gap-1">
          <li className="d-flex justify-content-between">
            <span className="ms-3">{t("SearchResults.Analyzes.ProfitAnalysis.FBAFee")}</span>
            <DisplayPrice source={destinationMarketplace?.currency} decimals={2} value={item.profitAnalysis.fbaFee} />
          </li>
          <li className="d-flex justify-content-between">
            <span className="ms-3">{t("SearchResults.Analyzes.ProfitAnalysis.ReferralFee")}</span>
            <DisplayPrice source={destinationMarketplace?.currency} decimals={2} value={item.profitAnalysis.referralFee} />
          </li>
          <li className="d-flex justify-content-between">
            <div className="d-flex align-items-center">
              <Button id={`OtherFees-${item.profitAnalysis.userSearchProductProfitAnalysisId}`} style={{height: 19.5}} className="d-flex align-items-center btn btn-ghost-dark" role="button">
                <span>{t("SearchResults.Analyzes.ProfitAnalysis.OtherFees")}</span>
                <i className="ri-arrow-down-s-line fs-16 ms-1 text-info"></i>
              </Button>
              <UncontrolledPopover trigger="legacy" placement="right" popperClassName="search-results-other-fees" target={`OtherFees-${item.profitAnalysis.userSearchProductProfitAnalysisId}`}>
                <Table className="align-middle table table-hover mb-0">
                  <thead className="table-light">
                    <tr>
                      <th>{t("SearchResults.Analyzes.ProfitAnalysis.TableColumn.Type")}</th>
                      <th>{t("SearchResults.Analyzes.ProfitAnalysis.TableColumn.Fee")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{t("SearchResults.Analyzes.ProfitAnalysis.BankTransferCommission")}</td>
                      <td>
                        {item.profitAnalysis?.bankTransferCommission
                          ? `${formatCurrency(priceSign, item.profitAnalysis.bankTransferCommission, 2, item.currencyCodeTarget ?? "")} (%${item.profitAnalysis.bankTransferCommissionRate})`
                          : t("NA")}
                      </td>
                    </tr>
                    <tr>
                      <td>{t("SearchResults.Analyzes.ProfitAnalysis.EstimatedRefund")}</td>
                      <td>
                        {item.profitAnalysis?.estimatedRefund
                          ? `${formatCurrency(priceSign, item.profitAnalysis.estimatedRefund, 2, item.currencyCodeTarget ?? "")} (%${item.profitAnalysis.estimatedRefundRate})`
                          : t("NA")}
                      </td>
                    </tr>
                    <tr>
                      <td>{t("SearchResults.Analyzes.ProfitAnalysis.CurrencyExchangeLoss")}</td>
                      <td>
                        <RenderNumber value={item.profitAnalysis?.currencyExchangeLoss} decimals={0} prefix="%"/>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </UncontrolledPopover>
            </div>
            <span>{item.profitAnalysis?.otherFees ? `${formatCurrency(priceSign, item.profitAnalysis.otherFees, 2, item.currencyCodeTarget ?? "")}` : t("NA")}</span>
          </li>
        </ul>
        <hr />
        <ul className="list-group d-flex flex-group gap-1">
          <li className="d-flex justify-content-between">
            <span>{t("SearchResults.Analyzes.ProfitAnalysis.OverallCost")}</span>
            <span>{item.profitAnalysis?.overallCost ? `${formatCurrency(priceSign, item.profitAnalysis.overallCost, 2, item.currencyCodeTarget ?? "")}` : t("NA")}</span>
          </li>
          <li className="d-flex justify-content-between mt-3">
            <span>
              {t("SearchResults.Analyzes.ProfitAnalysis.SellPrice")}
              {item.competitorAnalysis?.offerCount === 0 && (
                <>
                  <i id={`PopoverNoSellerInTheTargetMarketplace-${item.userSearchProductId}`} className="ms-1 mdi mdi-alert align-middle text-warning"></i>
                  <DefaultUncontrolledTooltip target={`PopoverNoSellerInTheTargetMarketplace-${item.userSearchProductId}`}> {t("NoSellerInTheTargetMarketplace")}</DefaultUncontrolledTooltip>
                </>
              )}
            </span>
            <span>
              <SellerTypeBadge type={item.competitorAnalysis?.buyBoxType ?? null} hideIfNA={true} />
              <span className="ms-1">{item.profitAnalysis?.sellPrice ? `${formatCurrency(priceSign, item.profitAnalysis.sellPrice, 2, item.currencyCodeTarget ?? "")}` : t("NA")}</span>
            </span>
          </li>
          {(item.profitAnalysis?.currentShippingPrice ?? 0) > 0 && (item.profitAnalysis?.currentPrice ?? 0) > 0 && (
            <li className="d-flex justify-content-between mb-2 mt-n1">
              <span></span>
              <small className="ms-1 text-muted">
                ({formatCurrency(priceSign, item.profitAnalysis.currentPrice ?? 0, 2, "")} +{" "}
                {formatCurrency(priceSign, item.profitAnalysis.currentShippingPrice ?? 0, 2, item.currencyCodeTarget ?? "")} {t("Shipping")})
              </small>
            </li>
          )}

          {item.profitAnalysis?.marketplaceTaxExceptionRate && item.profitAnalysis?.marketplaceTaxExceptionRate > 0 ? (
            <li className="d-flex justify-content-between">
              <span>
                <div>{t("SearchResults.Analyzes.ProfitAnalysis.MarketplaceTaxException")}</div>
              </span>
              <span>{item.profitAnalysis?.marketplaceTaxException ? `${formatCurrency(priceSign, item.profitAnalysis.marketplaceTaxException, 2, item.currencyCodeTarget ?? "")}` : t("NA")}</span>
            </li>
          ) : (
            ""
          )}
          <li className="d-flex justify-content-between fw-bold">
            <span>{t("SearchResults.Analyzes.ProfitAnalysis.Profit")}</span>
            {item.profitAnalysis?.profit ? (
              <span className={item.profitAnalysis.profit < 0 ? "text-danger" : ""}>{`${formatCurrency(priceSign, item.profitAnalysis.profit, 2, item.currencyCodeTarget ?? "")}`}</span>
            ) : (
              <span>{t("NA")}</span>
            )}
          </li>
          <li className="d-flex justify-content-between fw-bold">
            <span>{t("SearchResults.Analyzes.ProfitAnalysis.Profit")} (USD)</span>
            {item.profitAnalysis?.profitSource ? (
              <span className={item.profitAnalysis.profitSource < 0 ? "text-danger" : ""}>{`${formatCurrency("$", item.profitAnalysis.profitSource, 2, "USD")}`}</span>
            ) : (
              <span>{t("NA")}</span>
            )}
          </li>
          <li className="d-flex justify-content-between fw-bold">
            <span>{t("SearchResults.Analyzes.ProfitAnalysis.Margin")}</span>
            {item.profitAnalysis?.margin ? <span className={item.profitAnalysis.margin < 0 ? "text-danger" : ""}>{item.profitAnalysis.margin}%</span> : <span>{t("NA")}</span>}
          </li>
          <li className="d-flex justify-content-between fw-bold">
            <div>
              <span>{t("SearchResults.Analyzes.ProfitAnalysis.ROI")}</span>
            </div>
            {item.profitAnalysis?.roi ? <span className={item.profitAnalysis.roi < 0 ? "text-danger" : ""}>{item.profitAnalysis.roi}%</span> : <span>{t("NA")}</span>}
          </li>
        </ul>

        {item.profitAnalysis?.exchangeRateTarget ? (
          <div className="d-flex justify-content-between mt-2 fs-11">
            <span></span>
            <span className="text-muted">
              <em>{t("SearchResults.Analyzes.ProfitAnalysis.ExchangeRate") + ": " + item.profitAnalysis.exchangeRateTarget}</em>
            </span>
          </div>
        ) : (
          ""
        )}
      </CardBody>
    </Card>
  );
};

export default ProfitAnalysis;
