import {Modal, ModalHeader, ModalBody, ModalFooter, Button, Col, Row, Spinner, Form, Label} from "reactstrap";
import {useFormik} from "formik";
import * as Yup from "yup";
import {RefObject, useEffect, useState} from "react";

// Import React FilePond
import {useTranslation} from "react-i18next";
import SelectOptions, {SelectOptionsType} from "Components/Hooks/SelectOptions";
import {sendAnalysis} from "slices/scan-and-save/thunk";
import {useDispatch, useSelector} from "react-redux";
import {ScanAndSave} from "models/scan_and_save";
import {UserStore} from "models/user_stores";
import {createSelector} from "reselect";
import {RootState} from "slices";
import AmazonMarketplaceInfos from "Components/Common/AmazonMarketplaceInfos";
import ValidatedInput from "Components/Common/ValidatedInput";
import {loadUserStores} from "slices/common/thunk";
import ValidatedSelect from "Components/Common/ValidatedSelect";
import { SubmitForAnalysisCommand } from "api/command";
import { ScanAndSaveListRef } from "..";

interface SendAnalysisProps {
  isOpen: boolean;
  selectedScan: ScanAndSave;
  toggle: () => void;
  listRef: RefObject<ScanAndSaveListRef>;
}
const SendAnalysis = (props: SendAnalysisProps) => {
  const {t} = useTranslation();
  const dispatch: any = useDispatch();
  const {marketplaceSelectOptions} = SelectOptions();
  const [marketplaceSelectOptionsWithStores, setMarketplaceSelectOptionsWithStores] = useState(marketplaceSelectOptions);
  const [maxSendingAsinCount, setMaxSendingAsinCount] = useState<number>(0);
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      searchName: props.selectedScan?.searchName || "",
      sendingAsinCount: maxSendingAsinCount,
      marketplace: "",
      asinFetcherTaskId: props.selectedScan?.id || "",
    } as SubmitForAnalysisCommand,
    validationSchema: Yup.object<SubmitForAnalysisCommand>({
      searchName: Yup.string().required(t("ScanAndSave.Dialog.SendingAnalysisForm.Validation.AnalysisName")),
      sendingAsinCount: Yup.number()
        .required(t("ScanAndSave.Dialog.SendingAnalysisForm.Validation.SendingAsinCount"))
        .max(maxSendingAsinCount, t("ScanAndSave.Dialog.SendingAnalysisForm.Validation.SendingAsinCountMax", {value: maxSendingAsinCount})),
      marketplace: Yup.string().required(t("ScanAndSave.Dialog.SendingAnalysisForm.Validation.Marketplace")),
    }),
    onSubmit: (values: SubmitForAnalysisCommand) => {
      values.asinFetcherTaskId = props.selectedScan.id;
      let payload = JSON.parse(JSON.stringify(values));
      const sendPromise = sendAnalysis(payload)(dispatch);

      sendPromise.then((isSuccess) => {
        if (isSuccess) {
          props.listRef.current?.reload();
          validation.resetForm();
        }
      });
    },
  });

  //#region New Structure
  const [amazonMarketplaceInfos] = useState(AmazonMarketplaceInfos());

  const commonData = createSelector(
    (state: RootState) => state,
    (state) => ({
      loading: state.ScanAndSave.loading,
      list: state.ScanAndSave.list,
      userStores: state.Common.userStores,
    }),
  );
  const {loading, list, userStores} = useSelector(commonData);

  useEffect(() => {
    const userStoreOptions: SelectOptionsType[] = marketplaceSelectOptions;
    userStores.forEach((store: UserStore) => {
      const name = amazonMarketplaceInfos.find((amazonMarketplaceInfo: any) => amazonMarketplaceInfo.marketplace === store.marketplace)?.countryName + " - " + store.name;
      userStoreOptions.unshift({value: store.userStoreId, label: name});
    });
    setMarketplaceSelectOptionsWithStores(userStoreOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userStores]);

  useEffect(() => {
    if(list && list.items) {
      const updatedScan = list.items.find((item: ScanAndSave) => item.id === props.selectedScan?.id);
      if(updatedScan)
        setMaxSendingAsinCount(updatedScan?.total - updatedScan?.sent);
    }
  }, [list]) //eslint-disable-line
  
  useEffect(() => {
    if (props.selectedScan) {
      const sendingAsinCount = props.selectedScan.total - props.selectedScan.sent;
      if (sendingAsinCount < 5000) {
        setMaxSendingAsinCount(sendingAsinCount);
      } else {
        setMaxSendingAsinCount(5000);
      }
    }
  }, [props.selectedScan, props.isOpen]);

  useEffect(() => {
    loadUserStores()(dispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal id="showSendModal" className="modal-lg" isOpen={props.isOpen} toggle={props.toggle} centered>
      <ModalHeader className="bg-light p-3" toggle={props.toggle}>
        {t("ScanAndSave.Dialog.SendingAnalysisForm.Title")}
      </ModalHeader>
      <ModalBody>
        <Form onSubmit={validation.handleSubmit}>
          <Row>
            <Col>
              <Label htmlFor="searchName">{t("ScanAndSave.Dialog.SendingAnalysisForm.AnalysisName")}</Label>
              <ValidatedInput validation={validation} field="searchName" maxLength={100} placeholder={t("ScanAndSave.Dialog.SendingAnalysisForm.AnalysisName")} disableValidationUI />
            </Col>

            <Col>
              <Label htmlFor="sendingAsinCount">{t("ScanAndSave.Dialog.SendingAnalysisForm.SendingAsinCount")}</Label>
              <ValidatedInput type="number" validation={validation} field="sendingAsinCount" placeholder={t("ScanAndSave.Dialog.SendingAnalysisForm.SendingAsinCount")} disableValidationUI />
            </Col>

            <Col>
              <Label htmlFor="marketplace">{t("ScanAndSave.Dialog.SendingAnalysisForm.Marketplace")}</Label>
              <ValidatedSelect
                className={`new-search filter-input ${validation.errors.marketplace && validation.touched.marketplace && "form-control is-invalid form-select-invalid"}`}
                options={marketplaceSelectOptionsWithStores}
                validation={validation}
                field="marketplace"
                value={validation.values.marketplace}
                errorStyle="container"
              />
            </Col>
          </Row>
        </Form>
      </ModalBody>
      <ModalFooter>
        <div className="hstack gap-2 justify-content-end">
          <Button type="button" className="btn btn-light" disabled={loading.send} onClick={props.toggle}>
            {t("ScanAndSave.Dialog.SendingAnalysisForm.Button.Close")}
          </Button>

          <Button
            type="submit"
            className="btn btn-success"
            disabled={loading.send}
            onClick={() => {
              validation.setFieldTouched("searchName");
              validation.setFieldTouched("sendingAsinCount");
              validation.setFieldTouched("marketplace");

              if (validation.isValid) {
                validation.handleSubmit();
              }
            }}
          >
            {loading.send && <Spinner size="sm" className="me-2"></Spinner>}
            {t("ScanAndSave.Dialog.SendingAnalysisForm.Button.Save")}
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default SendAnalysis;
