import {Modal, ModalHeader, ModalBody, ModalFooter, Button, Col, Row, Spinner, Form, Label} from "reactstrap";
import {useFormik} from "formik";
import {RefObject, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {SubmitForAnalysisCommand} from "api/command";
import {sendAnalysis} from "slices/scan-and-save/thunk";
import {useDispatch, useSelector} from "react-redux";
import {ScanAndSave} from "models/scan_and_save";
import {createSelector} from "reselect";
import {RootState} from "slices";
import {loadUserStores} from "slices/common/thunk";
import Checkbox from "Components/Common/Checkbox";
import SelectOptions from "Components/Hooks/SelectOptions";
import ValidatedInput from "Components/Common/ValidatedInput";
import ValidatedSelect from "Components/Common/ValidatedSelect";
import ValidationWrapper from "Components/Common/ValidationWrapper";
import * as Yup from "yup";
import { AdminScanAndSaveListRef } from "..";

interface SendAnalysisProps {
  isOpen: boolean;
  selectedScan: ScanAndSave;
  toggle: () => void;
  listRef: RefObject<AdminScanAndSaveListRef>;
}
const SendAnalysis = (props: SendAnalysisProps) => {
  const {t} = useTranslation();
  const dispatch: any = useDispatch();
  const {marketplaceSelectOptions} = SelectOptions();
  const [maxSendingAsinCount, setMaxSendingAsinCount] = useState<number>(0);
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      searchName: props.selectedScan?.searchName || "",
      sendingAsinCount: maxSendingAsinCount,
      marketplace: "",
      asinFetcherTaskId: props.selectedScan?.id || "",
      specificUserId: props.selectedScan?.user?.userId,
      preventReduceLimits: true,
    } as SubmitForAnalysisCommand,
    validationSchema: Yup.object<SubmitForAnalysisCommand>({
      searchName: Yup.string().required(t("Admin.ScanAndSave.Dialog.SendingAnalysisForm.Validation.AnalysisName")),
      sendingAsinCount: Yup.number()
        .required(t("Admin.ScanAndSave.Dialog.SendingAnalysisForm.Validation.SendingAsinCount"))
        .max(maxSendingAsinCount, t("Admin.ScanAndSave.Dialog.SendingAnalysisForm.Validation.SendingAsinCountMax", {value: maxSendingAsinCount})),
      marketplace: Yup.string().required(t("Admin.ScanAndSave.Dialog.SendingAnalysisForm.Validation.Marketplace")),
      asinFetcherTaskId: Yup.string(),
      specificUserId: Yup.string().notRequired(),
      preventReduceLimits: Yup.boolean().notRequired(),
    }),
    onSubmit: (values: SubmitForAnalysisCommand) => {
      values.asinFetcherTaskId = props.selectedScan.id;
      let payload = JSON.parse(JSON.stringify(values));
      const sendPromise = sendAnalysis(payload)(dispatch);

      sendPromise.then((isSuccess) => {
        if (isSuccess) {
          props.listRef.current?.reload();
          props.toggle();
          validation.resetForm();
        }
      });
    },
  });

  const commonData = createSelector(
    (state: RootState) => state,
    (state) => ({
      loading: state.ScanAndSave.loading,
    }),
  );
  const {loading} = useSelector(commonData);

  useEffect(() => {
    if (props.selectedScan) {
      const sendingAsinCount = props.selectedScan.total - props.selectedScan.sent;
      if (sendingAsinCount < 5000) {
        setMaxSendingAsinCount(sendingAsinCount);
      } else {
        setMaxSendingAsinCount(5000);
      }
    }
  }, [props.selectedScan]);

  useEffect(() => {
    loadUserStores()(dispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal id="showSendModal" className="modal-lg" isOpen={props.isOpen} toggle={props.toggle} centered>
      <ModalHeader className="bg-light p-3" toggle={props.toggle}>
        {t("Admin.ScanAndSave.Dialog.SendingAnalysisForm.Title")}
      </ModalHeader>
      <ModalBody>
        <Form onSubmit={validation.handleSubmit}>
          <Row>
            <Col>
              <Label htmlFor="searchName">{t("Admin.ScanAndSave.Dialog.SendingAnalysisForm.AnalysisName")}</Label>
              <ValidatedInput validation={validation} field="searchName" maxLength={100} placeholder={t("Admin.ScanAndSave.Dialog.SendingAnalysisForm.AnalysisName")} disableValidationUI />
            </Col>

            <Col>
              <Label htmlFor="sendingAsinCount">{t("Admin.ScanAndSave.Dialog.SendingAnalysisForm.SendingAsinCount")}</Label>
              <ValidatedInput type="number" validation={validation} field="sendingAsinCount" placeholder={t("Admin.ScanAndSave.Dialog.SendingAnalysisForm.SendingAsinCount")} disableValidationUI />
            </Col>

            <Col>
              <Label htmlFor="marketplace">{t("Admin.ScanAndSave.Dialog.SendingAnalysisForm.Marketplace")}</Label>
              <ValidatedSelect
                className={`new-search filter-input ${validation.errors.marketplace && validation.touched.marketplace && "form-control is-invalid form-select-invalid"}`}
                options={marketplaceSelectOptions}
                validation={validation}
                field="marketplace"
                value={validation.values.marketplace}
                errorStyle="container"
              />
            </Col>
          </Row>
        </Form>
      </ModalBody>
      <ModalFooter>
        <div className="hstack gap-2 justify-content-end">
          <ValidationWrapper validation={validation} field="preventReduceLimits">
            <Checkbox label={t("Admin.ScanAndSave.Dialog.SendingAnalysisForm.Button.PreventReduceLimits")} />
          </ValidationWrapper>

          <Button type="button" className="btn btn-light" disabled={loading.send} onClick={props.toggle}>
            {t("Admin.ScanAndSave.Dialog.SendingAnalysisForm.Button.Close")}
          </Button>

          <Button
            type="submit"
            className="btn btn-success"
            disabled={loading.send}
            onClick={() => {
              validation.setFieldTouched("searchName");
              validation.setFieldTouched("sendingAsinCount");
              validation.setFieldTouched("marketplace");

              if (validation.isValid) {
                validation.handleSubmit();
              }
            }}
          >
            {loading.send && <Spinner size="sm" className="me-2"></Spinner>}
            {t("Admin.ScanAndSave.Dialog.SendingAnalysisForm.Button.Save")}
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default SendAnalysis;
