import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ApiError, LoadingStates} from "helpers/types";
import { Currencies } from "models/currencies";
import {UserShipment} from "models/user_shipment";
import {UserStore} from "models/user_stores";

export type CommonLoadingState = LoadingStates<"userStore" | "userShipment" | "loggedUser" | "save" | "update" |"delete">;

export type ShipmentInfo = {
  userStores: UserStore[];
  userShipments: UserShipment[];
  includesASIN: boolean;
  asinList: string[];
};

export type CommonState = {
  loading: CommonLoadingState;
  userStores: UserStore[];
  shipmentInfo: ShipmentInfo;
  currencies: Currencies[];
  error: ApiError;
};

const initialState: CommonState = {
  loading: {
    userStore: true,
    userShipment: false,
    loggedUser: false,
    save: false,
    update: false,
    delete: false,
  },
  userStores: [] as UserStore[],
  shipmentInfo: {} as ShipmentInfo,
  currencies: [] as Currencies[],
  error: {} as ApiError,
};

const CommonSlice = createSlice({
  name: "Common",
  initialState,
  reducers: {
    setUserStores(state, action: PayloadAction<UserStore[]>) {
      state.userStores = action.payload;
    },
    setShipmentInfo(state, action: PayloadAction<ShipmentInfo>) {
      state.shipmentInfo = action.payload;
    },
    setCurrencies(state, action: PayloadAction<Currencies[]>) {
      state.currencies = action.payload;
    },
    loading(state, action: PayloadAction<[keyof CommonLoadingState, boolean]>) {
      const [operation, loadingState] = action.payload;
      state.loading[operation] = loadingState;
    },
    apiError(state, action: PayloadAction<ApiError>) {
      state.error = action.payload;
    },
    reset(state) {
      state.loading = initialState.loading;
      state.userStores = initialState.userStores;
      state.shipmentInfo = initialState.shipmentInfo;
      state.error = initialState.error;
    },
  },
});

export const {setUserStores, setShipmentInfo, setCurrencies, loading, apiError, reset} = CommonSlice.actions;

export default CommonSlice.reducer;
