import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Card, CardBody} from "reactstrap";
import {createSelector} from "reselect";
import {RootState} from "slices";
import {Link} from "react-router-dom";
import CountUp from "react-countup";
import PlaceholderGlow from "Components/Common/PlaceholderGlow";
import DefaultUncontrolledTooltip from "Components/Common/DefaultUncontrolledTooltip";
import Dialog, {DialogRef} from "Components/Common/Dialog";
import {useRef, useState} from "react";
import {rebalanceOrchestrationService} from "slices/admin/systemMonitoring/thunk";

const OrchServiceDistribution = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const rebalanceOrchestrationServiceDialogRef = useRef<DialogRef>(null);
  const [dialogTitle, setDialogTitle] = useState<string>("");
  const [dialogDescription, setDialogDescription] = useState<string>("");
  const [isUnassigned, setIsUnassigned] = useState<boolean>(false);
  const systemMonitoringData = createSelector(
    (state: RootState) => state,
    (state) => ({
      systemMonitoring: state.AdminSystemMonitoring.systemMonitoring,
      loading: state.AdminSystemMonitoring.loading,
    }),
  );

  const {systemMonitoring, loading} = useSelector(systemMonitoringData);
  return (
    <PlaceholderGlow busy={loading.list}>
      <Card className="card-animate mb-0">
        <div className="align-items-center d-flex card-header">
          <h4 className="card-title mb-0 flex-grow-1">{t("Admin.SystemMonitoring.Section.Title.OrchestrationServiceDistribution")}</h4>
          <div className="flex-shrink-0">
            <Link id="buttonOrchestrationRebalance" to="" className="btn btn-soft-secondary btn-sm px-1 py-0 fs-16" onClick={()=> {
              setIsUnassigned(false);
              setDialogTitle(t("Admin.SystemMonitoring.Dialog.RebalanceOrchestrationService.Title"));
              setDialogDescription(t("Admin.SystemMonitoring.Dialog.RebalanceOrchestrationService.Description"));
              rebalanceOrchestrationServiceDialogRef?.current?.show();
            }}>
              <i className="mdi mdi-autorenew"></i>
            </Link>
            <DefaultUncontrolledTooltip target="buttonOrchestrationRebalance">{t("Admin.SystemMonitoring.Button.Rebalance")}</DefaultUncontrolledTooltip>
          </div>
        </div>
        <CardBody>
          <div className="table-responsive table-card">
            <table className="table-hover table table-centered table-borderless border-0 align-middle mb-0 border-top-0">
              <thead className="text-muted table-light">
                <tr className="border">
                  <th scope="col">{t("Server")}</th>
                  <th scope="col">{t("Total")}</th>
                </tr>
              </thead>
              <tbody>
                {systemMonitoring?.orchestrationServicesDistribution?.map((item, key) => (
                  <tr key={key}>
                    <td>
                      <div className="d-flex align-items-center">
                        {item.server}
                        {item.server === "Unassigned" && (
                          <button
                            type="button"
                            className="btn btn-soft-secondary btn-sm ms-2"
                            onClick={() => {
                              setIsUnassigned(true);
                              setDialogTitle(t("Admin.SystemMonitoring.Dialog.RebalanceUnassignedOrchestrationService.Title"));
                              setDialogDescription(t("Admin.SystemMonitoring.Dialog.RebalanceUnassignedOrchestrationService.Description"));
                              rebalanceOrchestrationServiceDialogRef?.current?.show();
                            }}
                          >
                            {t("Admin.SystemMonitoring.Button.Rebalance")}
                          </button>
                        )}
                      </div>
                    </td>
                    <td>
                      <span className="counter-value">
                        <CountUp end={item.total} duration={2} />
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </CardBody>
      </Card>
      <Dialog
        ref={rebalanceOrchestrationServiceDialogRef}
        color="secondary"
        buttons={["yes", "no"]}
        busy={loading.rebalance}
        iconClass="mdi mdi-autorenew"
        title={dialogTitle}
        message={dialogDescription}
        onButtonClick={async (button, hide) => {
          if (button === "yes") {
            await rebalanceOrchestrationService({isUnassigned})(dispatch);
          }
          rebalanceOrchestrationServiceDialogRef.current?.hide();
        }}
      />
    </PlaceholderGlow>
  );
};

export default OrchServiceDistribution;
