import {DashboardFilter, MenuDisplayMode, SearchResultLocalData, SubscriptionLocalData, SubscriptionPeriodType} from "models/local_storage";
import {ApplicationUser} from "models/application_user";
import _ from "lodash";
import {DemoMode} from "slices/auth/login/reducer";

export const LOCAL_STORAGE = {
  DASHBOARD: "DASHBOARD",
  MENU_DISPLAY_MODE: "MENU_DISPLAY_MODE",
  SEARCH_RESULT_DATA: "SEARCH_RESULT_DATA",
  SUBSCRIPTION: "subscription",
  LOGGED_USER: "loggedUser",
  I18N_LANGUAGE: "I18N_LANGUAGE",
  THEME: "THEME",
  DEMO_MODE: "demoMode",
};

const getMenuModeLocalData = (): MenuDisplayMode | undefined => {
  const mode = localStorage.getItem(LOCAL_STORAGE.MENU_DISPLAY_MODE);
  if (_.isNil(mode)) {
    return undefined;
  } else {
    return mode as MenuDisplayMode;
  }
};
const updateMenuModeLocalData = (menuMode: MenuDisplayMode) => {
  localStorage.setItem(LOCAL_STORAGE.MENU_DISPLAY_MODE, menuMode);
};

const getDashboardFilter = (): DashboardFilter | undefined => {
  const filter = localStorage.getItem(LOCAL_STORAGE.DASHBOARD);
  if (_.isNil(filter)) {
    return undefined;
  } else {
    return JSON.parse(filter);
  }
};

const updateDashboardFilter = ({userId, dateRange: filterDay, currency, stores}: DashboardFilter) => {
  let dashboardFilter: DashboardFilter = localStorage.getItem(LOCAL_STORAGE.DASHBOARD) ? JSON.parse(localStorage.getItem(LOCAL_STORAGE.DASHBOARD)!) : {};
  if (userId) dashboardFilter.userId = userId;
  if (filterDay) dashboardFilter.dateRange = filterDay;
  if (currency) dashboardFilter.currency = currency;
  if (stores) dashboardFilter.stores = stores;
  localStorage.setItem(LOCAL_STORAGE.DASHBOARD, JSON.stringify(dashboardFilter));
};

const getSearchResultLocalData = (): SearchResultLocalData | undefined => {
  const searchResultData = localStorage.getItem(LOCAL_STORAGE.SEARCH_RESULT_DATA);
  if (_.isNil(searchResultData)) {
    return undefined;
  } else {
    return JSON.parse(searchResultData);
  }
};

const updateSearchResultLocalData = ({showDetails}: SearchResultLocalData) => {
  let localData: SearchResultLocalData = localStorage.getItem(LOCAL_STORAGE.SEARCH_RESULT_DATA) ? JSON.parse(localStorage.getItem(LOCAL_STORAGE.SEARCH_RESULT_DATA)!) : {};
  localData.showDetails = showDetails;
  localStorage.setItem(LOCAL_STORAGE.SEARCH_RESULT_DATA, JSON.stringify(localData));
};

const getLoggedUser = (): ApplicationUser | undefined => {
  const user: any = localStorage.getItem(LOCAL_STORAGE.LOGGED_USER);
  if (_.isNil(user)) {
    return undefined;
  } else {
    return JSON.parse(user) as ApplicationUser;
  }
};

const removeLoggedUserLocalData = () => {
  localStorage.removeItem(LOCAL_STORAGE.LOGGED_USER);
};

const getDemoModeInfo = (): DemoMode | undefined => {
  const demoMode: any = localStorage.getItem(LOCAL_STORAGE.DEMO_MODE);
  if (_.isNil(demoMode)) {
    return undefined;
  } else {
    return JSON.parse(demoMode) as DemoMode;
  }
};

const updateDemoModeInfoLocalData = (isActive: boolean, date: Date) => {
  const item: DemoMode = {
    active: isActive,
    date: date,
  };
  localStorage.setItem(LOCAL_STORAGE.DEMO_MODE, JSON.stringify(item));
};

const getSubscriptionLocalData = (): SubscriptionLocalData | undefined => {
  const subscriptionLocalData = localStorage.getItem(LOCAL_STORAGE.SUBSCRIPTION);
  if (_.isNil(subscriptionLocalData)) {
    return undefined;
  } else {
    return JSON.parse(subscriptionLocalData);
  }
};

const updateSubscriptionLocalData = (tier: string, frequency: SubscriptionPeriodType) => {
  const item: SubscriptionLocalData = {
    frequency: frequency,
    tier: tier,
  };
  localStorage.setItem(LOCAL_STORAGE.SUBSCRIPTION, JSON.stringify(item));
};

const removeSubscriptionLocalData = () => {
  localStorage.removeItem(LOCAL_STORAGE.SUBSCRIPTION);
};

export {
  updateDashboardFilter,
  getLoggedUser,
  removeLoggedUserLocalData,
  getDemoModeInfo,
  updateDemoModeInfoLocalData,
  getDashboardFilter,
  updateSubscriptionLocalData,
  removeSubscriptionLocalData,
  getSubscriptionLocalData,
  getMenuModeLocalData,
  updateMenuModeLocalData,
  getSearchResultLocalData,
  updateSearchResultLocalData
};
