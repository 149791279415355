import {useEffect, useImperativeHandle, useMemo, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Card, CardBody, Container} from "reactstrap";
import {createSelector} from "reselect";
import {RootState} from "slices";
import {getUserOrders} from "slices/orders/thunk";
import {OrderStatus, UserOrder, UserOrderItem} from "models/user_order";
import {Link, useNavigate} from "react-router-dom";
import {formatDate, getOrderStatusText} from "helpers/utilities";
import {useImmer} from "use-immer";
import {ColumnDef} from "@tanstack/react-table";
import {useDebounce} from "Components/Hooks/HelperHooks";
import {DataTableRef} from "Components/Common/DataTable";
import {useProfile} from "Components/Hooks/UserHooks";
import {ConstantPage} from "helpers/permission_helper";
import {FilterOrderQuery} from "api/query";
import BreadCrumb from "Components/Common/BreadCrumb";
import Filters from "./Filters";
import Stats from "./Stats";
import DomainToFlag from "Components/Common/DomainToFlag";
import i18n from "i18n";
import Loader from "Components/Common/Loader";
import DataTable from "Components/Common/DataTable";
import Moment from "react-moment";
import Restricted from "Components/Common/Restricted";
import Unauthorized from "pages/Errors/_Unauthorized";
import errorImage from "assets/images/svg/product.svg";
import CopyAsinWidget from "Components/Common/CopyAsinWidget";
import DisplayPrice from "Components/Common/DisplayPrice";
import AmazonMarketplaceInfos from "Components/Common/AmazonMarketplaceInfos";
import RenderNumber from "Components/Common/RenderNumber";
import NoResult from "Components/Common/NoResult";
import DefaultUncontrolledTooltip from "Components/Common/DefaultUncontrolledTooltip";
import {UserShipment} from "models/user_shipment";

export type OrderListRef = {
  reload: VoidFunction;
};

const PAGE_IDENTIFIER: ConstantPage = "orders";
const Orders = () => {
  const {t} = useTranslation();
  const {userProfile, hasPermission} = useProfile();
  const dispatch: any = useDispatch();
  const tableRef = useRef<DataTableRef>(null);
  const listRef = useRef<OrderListRef>(null);
  const navigate = useNavigate();
  const [amazonMarketplaceInfos] = useState(AmazonMarketplaceInfos());

  const [query, updateQuery] = useImmer<FilterOrderQuery>({
    filtering: true,
    startDate: undefined,
    endDate: undefined,
    page: 1,
    pageSize: 10,
  });

  const debouncedLoadList = useDebounce(() => {
    if (hasPermission(PAGE_IDENTIFIER)) {
      getUserOrders(query)(dispatch).then(() => {
        tableRef.current?.resetSelection();
      });
    }
  }, 200);

  useEffect(() => {
    if (query.endDate !== undefined && query.startDate !== undefined) {
      navigate(`/orders?start=${formatDate(query.startDate)}&end=${formatDate(query.endDate, true)}`, {replace: true});
      debouncedLoadList();
    }
  }, [debouncedLoadList, query]); // eslint-disable-line

  useImperativeHandle(
    listRef,
    () => {
      return {
        reload: () => {
          debouncedLoadList();
        },
      };
    },
    [debouncedLoadList],
  );

  const ordersData = createSelector(
    (state: RootState) => state,
    (state) => ({
      loading: state.Orders.loading,
      userOrders: state.Orders.userOrders,
    }),
  );
  const {loading, userOrders} = useSelector(ordersData);

  const columns = useMemo<ColumnDef<UserOrder, any>[]>(
    () => [
      {
        header: t("Orders.TableColumn.OrderId"),
        size: 50,
        accessorKey: "amazonOrderId",
        sortingFn: "alphanumeric",
        cell: (cellProps) => {
          const row = cellProps.row.original as UserOrder;
          return (
            <>
              <div className="mb-1 d-flex">
                <Link className="link-secondary link-offset-2 text-decoration-underline me-1 nowrap" target="_blank" to={`/orders/${row.amazonOrderId}`}>
                  {row.amazonOrderId}
                  <i className="ri-arrow-right-up-line"></i>
                </Link>
              </div>
              <div className="d-flex align-items-center text-nowrap text-muted">
                <DomainToFlag marketplace={row.userStore.marketplace} />
                <span>{`${row.userStore.marketplace} - ${row.userStore.name}`}</span>
              </div>
            </>
          );
        },
      },
      {
        header: t("Orders.TableColumn.Products"),
        size: 50,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserOrder;
          return (
            <>
              {row.userOrderItems && row.userOrderItems.length <= 1 ? (
                <div className="d-flex align-items-center">
                  <div className="d-flex align-items-center avatar-sm rounded flex-shrink-0 overflow-hidden">
                    <img
                      className="rounded w-100"
                      src={`https://m.media-amazon.com/images/I/${row.userOrderItems[0].userInventory?.imageSmall}`}
                      onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                        e.currentTarget.onerror = null;
                        e.currentTarget.src = errorImage;
                      }}
                      alt=""
                    />
                  </div>
                  <div className="flex-grow-1 ms-2">
                    <div className="d-flex align-items-center">
                      <CopyAsinWidget Asin={row.userOrderItems[0].asin} index={row.userOrderItems[0].asin} asLink={true} marketplace={row.userStore.marketplace} hideCopyIcon={true} />
                    </div>
                    <div className="d-flex align-items-center">{row.userOrderItems[0].sellerSKU}</div>
                  </div>
                </div>
              ) : (
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0">
                    <div className="avatar-group">
                      {row.userOrderItems &&
                        row.userOrderItems.map((item: UserOrderItem, itemIndex: number) => {
                          const itemImage = `https://m.media-amazon.com/images/I/${item.userInventory.imageSmall}`;
                          return (
                            <div key={itemIndex}>
                              <Link to={`/inventory?asin=${item.asin}`} target="_blank" className="avatar-group-item">
                                <img
                                  src={itemImage}
                                  id={`${row.userOrderId}-${item.asin}`}
                                  alt=""
                                  className="rounded-circle avatar-xxs"
                                  onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                                    e.currentTarget.onerror = null;
                                    e.currentTarget.src = errorImage;
                                  }}
                                />
                              </Link>
                              <DefaultUncontrolledTooltip target={`${row.userOrderId}-${item.asin}`}>
                                {item.asin} x {item.quantityOrdered}
                              </DefaultUncontrolledTooltip>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                  <div className="flex-grow-1 ms-2">
                    <Link className="link-body-emphasis link-offset-1 text-decoration-underline link-underline-opacity-75-hover " target="_blank" to={`/orders/${row.userOrderId}`}>
                      {t("Dashboard.LatestOrders.Link.ClickForDetails")}
                      <i className="ri-arrow-right-up-line"></i>
                    </Link>
                  </div>
                </div>
              )}
            </>
          );
        },
      },
      {
        header: t("Orders.TableColumn.Shipments"),
        size: 100,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserOrder;
          return (
            <>
              {row.userShipments && row.userShipments.length > 0
                ? row.userShipments?.map((shipment: UserShipment, index: number) => {
                    return (
                      <>
                        <Link
                          key={`${row.amazonOrderId}-${index}`}
                          to={`/shipments/shipment-details/${shipment.userShipmentId}`}
                          target="_blank"
                          className="link-secondary link-offset-2 text-decoration-underline me-1 nowrap"
                        >
                          {shipment.name}
                        </Link>
                        <br />
                      </>
                    );
                  })
                : "-"}
            </>
          );
        },
      },
      {
        header: t("Orders.TableColumn.Status"),
        size: 100,
        accessorFn: (item) => item.orderStatus,
        sortingFn: "alphanumericCaseSensitive",
        cell: (cellProps) => {
          const row = cellProps.row.original as UserOrder;
          const cssClass =
            row.orderStatus === OrderStatus.CANCELED ? "bg-danger-subtle text-danger" : row.orderStatus === OrderStatus.PENDING ? "bg-warning-subtle text-warning" : "bg-success-subtle text-success";

          const statuText = getOrderStatusText(row.orderStatus);
          return (
            <>
              <span className={`badge ${cssClass} fs-12`}>{statuText}</span>
            </>
          );
        },
      },
      {
        header: t("Orders.TableColumn.Quantity"),
        size: 100,
        accessorFn: (item) => item.quantityOrdered,
        sortingFn: "alphanumeric",
        cell: (cellProps) => {
          const row = cellProps.row.original as UserOrder;
          return (
            <>
              <h5 className="fs-14 my-1 align-items-center d-flex">
                {row.quantityOrdered} <i className="ri-bar-chart-fill text-success fs-16 ms-1"></i>
              </h5>
              <span className="text-muted text-nowrap"></span>
            </>
          );
        },
      },
      {
        header: t("Orders.TableColumn.TotalAndProfit"),
        size: 100,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserOrder;
          const rowCurrency = amazonMarketplaceInfos.find((amazonMarketplaceInfo) => amazonMarketplaceInfo.marketplace === row.userStore.marketplace)?.currency;
          return (
            <>
              {row.orderStatus === OrderStatus.SHIPPED ? (
                <>
                  <span className="text-muted">
                    <DisplayPrice source={rowCurrency} value={row.totalSellPrice} decimals={2} />
                  </span>
                  <h5 className="fs-14 my-1 d-flex align-items-center">
                    <DisplayPrice source={rowCurrency} value={row.profit!} decimals={2} />
                    {row.costIsPredicted && (
                      <>
                        <i className="ri-question-fill text-warning fs-14 ms-1" id={`CostIsPredictedTooltip-${row.amazonOrderId}`} />
                        <DefaultUncontrolledTooltip target={`CostIsPredictedTooltip-${row.amazonOrderId}`}>{t("CostIsPredicted")}</DefaultUncontrolledTooltip>
                      </>
                    )}
                  </h5>
                </>
              ) : (
                <span className="text-muted">{t("NA")}</span>
              )}
            </>
          );
        },
      },
      {
        header: t("Orders.TableColumn.RoiMargin"),
        size: 100,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserOrder;
          return (
            <>
              {row.orderStatus === OrderStatus.SHIPPED ? (
                <>
                  <span className="text-muted">
                    <RenderNumber value={row.roi} decimals={0} prefix={"%"} suffix={""} />
                  </span>
                  <h5 className="fs-14 my-1">
                    <RenderNumber value={row.margin} decimals={0} prefix={"%"} suffix={""} />
                  </h5>
                </>
              ) : (
                <span className="text-muted">{t("NA")}</span>
              )}
            </>
          );
        },
      },

      {
        header: t("Orders.TableColumn.FeesAndTaxes"),
        size: 100,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserOrder;
          const rowCurrency = amazonMarketplaceInfos.find((amazonMarketplaceInfo) => amazonMarketplaceInfo.marketplace === row.userStore.marketplace)?.currency;
          return (
            <>
              {row.orderStatus === OrderStatus.SHIPPED ? (
                <>
                  <span className="text-muted text-nowrap">
                    <DisplayPrice source={rowCurrency} value={row.totalFees!} decimals={2} />
                  </span>
                  <h5 className="fs-14 my-1 text-nowrap">
                    <DisplayPrice source={rowCurrency} value={row.totalTax!} decimals={2} />
                  </h5>
                </>
              ) : (
                <span className="text-muted ">{t("NA")}</span>
              )}
            </>
          );
        },
      },
      {
        header: t("Orders.TableColumn.OrderDate"),
        size: 100,
        accessorFn: (item) => item.purchaseDate,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserOrder;
          return (
            <>
              <h5 className="fs-14 my-1 text-nowrap">
                <Moment format="D MMM YYYY" locale={i18n.language} tz={userProfile?.timezone}>
                  {row.purchaseDate}
                </Moment>
              </h5>
              <span className="text-muted text-nowrap">
                <Moment format="HH:mm" locale={i18n.language} tz={userProfile?.timezone}>
                  {row.purchaseDate}
                </Moment>
              </span>
            </>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, userOrders],
  );

  document.title = t("PageTitles.Orders");

  return (
    <Restricted require={PAGE_IDENTIFIER} read fallback={() => <Unauthorized />}>
      <>
        <div className="page-content">
          <Container fluid>
            <BreadCrumb title={t("Orders.Title")} menus={[{label: t("Orders.Title")}]} />
            <>
              <Filters busy={loading.filter} page={query.page} pageSize={query.pageSize} handleFilter={(f) => updateQuery(f)} />
            </>
            {loading.filter ? <Loader /> : userOrders.items && userOrders.items.length > 0 ? <Stats busy={loading.list} /> : ""}
            <Card>
              <CardBody>
                {loading.filter ? (
                  <Loader />
                ) : userOrders.items && userOrders.items.length > 0 ? (
                  <>
                    <DataTable
                        
                      ref={tableRef}
                      busy={loading.list || loading.updateShipment}
                      columns={columns}
                      data={userOrders.items || []}
                      totalDataLength={userOrders.totalCount}
                      pagination={{
                        pageIndex: query.page - 1,
                        pageSize: query.pageSize,
                      }}
                      onPaginationChanged={(pagination) =>
                        updateQuery((q) => {
                          q.page = pagination.pageIndex + 1;
                          q.pageSize = pagination.pageSize;
                          q.filtering = false;
                        })
                      }
                      hovered
                    />
                  </>
                ) : (
                  <NoResult title={t("Orders.NoResult.Title")} description={t("Orders.NoResult.Description")} />
                )}
              </CardBody>
            </Card>
          </Container>
        </div>
      </>
    </Restricted>
  );
};

export default Orders;
