import useSelectOptions, {SelectOptionsType} from "Components/Hooks/SelectOptions";
import {Button, Card, CardBody, Col, Form, Label, Row, Spinner} from "reactstrap";
import {useFormik} from "formik";
import {RefObject, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {UserStore} from "models/user_stores";
import {ShipmentListRef} from "..";
import {FilterShipmentsQuery} from "models/user_shipment";
import {DateRange} from "helpers/types";
import {useProfile} from "Components/Hooks/UserHooks";
import {getToday} from "helpers/utilities";
import AmazonMarketplaceInfos from "Components/Common/AmazonMarketplaceInfos";
import PlaceholderGlow from "Components/Common/PlaceholderGlow";
import NewShipment from "../Modal/NewShipment";
import Restricted from "Components/Common/Restricted";
import moment from "moment";
import ValidatedDateRangeInput from "Components/Common/ValidatedDateRangeInput";
import ValidatedMultiSelect from "Components/Common/ValidatedMultiSelect";
import * as Yup from "yup";

interface FiltersProps {
  listRef: RefObject<ShipmentListRef>;
  busy: boolean;
  page: number;
  pageSize: number;
  handleFilter: (filter: FilterShipmentsQuery) => void;
  userStores: UserStore[];
}
const Filters = (props: FiltersProps) => {
  const {t} = useTranslation();
  const {shipmentStatusSelectOptions, multiSelectTranslations} = useSelectOptions();
  const [userStoreOptions, setUserStoreOptions] = useState<SelectOptionsType[]>([]);
  const [amazonMarketplaceInfos] = useState(AmazonMarketplaceInfos());
  const {userProfile} = useProfile();

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const createDateStart = queryParams.get("createDateStart");
    const createDateEnd = queryParams.get("createDateEnd");
    const shipDateStart = queryParams.get("shipDateStart");
    const shipDateEnd = queryParams.get("shipDateEnd");
    const status = queryParams.get("status");

    var payload: FilterShipmentsQuery = {
      page: 1,
      pageSize: props.pageSize,
      filtering: true,
    };

    if (status) {
      validation.setFieldValue("statusFilter", status);
      payload = {
        ...payload,
        statuses: [status],
      };
    }
    if (createDateStart) {
      validation.setFieldValue("createDateRange.start", new Date(createDateStart));
      payload = {
        ...payload,
        createDateRange: {
          start: moment
            .tz(createDateStart, userProfile?.timezone!)
            .startOf("day")
            .tz("Etc/GMT")
            .toDate(),
        },
      };
    }

    if (createDateEnd) {
      validation.setFieldValue("createDateRange.end", new Date(createDateEnd));
      payload = {
        ...payload,
        createDateRange: {
          end: moment
            .tz(createDateEnd, userProfile?.timezone!)
            .endOf("day")
            .tz("Etc/GMT")
            .toDate(),
        },
      };
    }

    if (shipDateStart) {
      validation.setFieldValue("shipDateRange.start", new Date(shipDateStart));
      payload = {
        ...payload,
        shipDateRange: {
          start: moment
            .tz(shipDateStart, userProfile?.timezone!)
            .startOf("day")
            .tz("Etc/GMT")
            .toDate(),
        },
      };
    }

    if (shipDateEnd) {
      validation.setFieldValue("shipDateRange.end", new Date(shipDateEnd));
      payload = {
        ...payload,
        shipDateRange: {
          end: moment
            .tz(shipDateEnd, userProfile?.timezone!)
            .endOf("day")
            .tz("Etc/GMT")
            .toDate(),
        },
      };
    }

    props.handleFilter(payload);
  }, []); // eslint-disable-line

  useEffect(() => {
    const options: SelectOptionsType[] = [];
    props.userStores.forEach((store: UserStore) => {
      const country = amazonMarketplaceInfos.find((marketplaceInfo) => marketplaceInfo.marketplace === store.marketplace);
      if (country && store.status) {
        options.push({value: store.userStoreId, label: country.countryName + " - " + store.name});
      }
    });
    setUserStoreOptions(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.userStores]);

  // validation
  const validation = useFormik({
    initialValues: {
      createDateRange: {
        start: undefined,
        end: undefined,
      },
      shipDateRange: {
        start: undefined,
        end: undefined,
      },
      userStoreIds: [] as string[],
      statuses: [] as string[],
    } as FilterShipmentsQuery,
    validationSchema: Yup.object<FilterShipmentsQuery>({
      startDate: Yup.date()
        .min(new Date("2023-01-01"), t("Shipments.Filters.Validation.StartDateMin"))
        .max(Yup.ref("endDate"), t("Shipments.Filters.Validation.StartDateMax"))
        .typeError(t("Shipments.Filters.Validation.InvalidDateType")),
      endDate: Yup.date()
        .min(Yup.ref("startDate"), t("Shipments.Filters.Validation.EndDateMin"))
        .max(getToday().toDate(), t("Shipments.Filters.Validation.EndDateMax"))
        .typeError(t("Shipments.Filters.Validation.InvalidDateType")),
      shipDateRange: Yup.object<DateRange>({
        startDate: Yup.date()
          .min(new Date("2023-01-01"), t("Shipments.Filters.Validation.StartDateMin"))
          .max(Yup.ref("endDate"), t("Shipments.Filters.Validation.StartDateMax"))
          .typeError(t("Shipments.Filters.Validation.InvalidDateType")),
        endDate: Yup.date()
          .min(Yup.ref("startDate"), t("Shipments.Filters.Validation.EndDateMin"))
          .max(getToday().toDate(), t("Shipments.Filters.Validation.EndDateMax"))
          .typeError(t("Shipments.Filters.Validation.InvalidDateType")),
      }),
      marketplaceFilter: Yup.string(),
      statusFilter: Yup.string(),
    }),
    onSubmit: (values) => {
      const payload: FilterShipmentsQuery = {
        page: 1,
        pageSize: props.pageSize,
        createDateRange: {
          start: values.createDateRange?.start
            ? moment
                .tz(values.createDateRange?.start, userProfile?.timezone!)
                .startOf("day")
                .tz("Etc/GMT")
                .toDate()
            : undefined,
          end: values.createDateRange?.end
            ? moment
                .tz(values.createDateRange?.end, userProfile?.timezone!)
                .tz(userProfile?.timezone!)
                .endOf("day")
                .toDate()
            : undefined,
        },
        shipDateRange: {
          start: values.shipDateRange?.start
            ? moment
                .tz(values.shipDateRange?.start, userProfile?.timezone!)
                .startOf("day")
                .tz("Etc/GMT")
                .toDate()
            : undefined,
          end: values.shipDateRange?.end
            ? moment
                .tz(values.shipDateRange?.end, userProfile?.timezone!)
                .tz(userProfile?.timezone!)
                .endOf("day")
                .toDate()
            : undefined,
        },
        userStoreIds: values.userStoreIds || undefined,
        statuses: values.statuses || undefined,
        filtering: true,
      };
      props.handleFilter(payload);
    },
  });

  return (
    <PlaceholderGlow busy={props.busy}>
      <Card style={{zIndex: 4}}>
        <CardBody>
          <div className="live-preview">
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              <Row className="align-items-center">
                <Col>
                  <div className="flex-shrink-0">
                    <Label className="form-label text-muted">{t("Shipments.Filters.Title")}</Label>
                  </div>
                </Col>
                <Col xs="auto" align="right">
                  <Restricted require="shipments" create>
                    <NewShipment busy={props.busy} userStoresSelectOptions={userStoreOptions} listRef={props.listRef} />
                  </Restricted>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col sm={3} className="mb-3 shipments filters-col">
                  <Label htmlFor="endDate">{t("Shipments.Filters.Label.CreateDate")}</Label>
                  <ValidatedDateRangeInput validation={validation} field="createDateRange" />
                </Col>
                <Col sm={2} className="mb-3 shipments filters-col">
                  <Label htmlFor="endDate">{t("Shipments.Filters.Label.ShipDate")}</Label>
                  <ValidatedDateRangeInput validation={validation} field="shipDateRange" />
                </Col>
                <Col sm={2} className="mb-3 shipments filters-col">
                  <Label htmlFor="userStoreIds">{t("Shipments.Filters.Label.Store")}</Label>
                  <ValidatedMultiSelect
                    className="common-css filter-input"
                    options={userStoreOptions}
                    validation={validation}
                    field="userStoreIds"
                    value={validation.values.userStoreIds}
                    translations={{
                      ...multiSelectTranslations,
                      selectSomeItems: t("General.Select.SelectStore"),
                      allItemsAreSelected: t("General.Select.AllStoresSelected"),
                    }}
                  />
                </Col>
                <Col sm={2} className="mb-3 searches filters-col">
                  <Label htmlFor="statuses">{t("Shipments.Filters.Label.Status")}</Label>
                  <ValidatedMultiSelect
                    className="common-css filter-input"
                    options={shipmentStatusSelectOptions}
                    validation={validation}
                    field="statuses"
                    value={validation.values.statuses}
                  />
                </Col>
                <Col sm={2}>
                  <div className="mt-2">
                    <Button type="submit" className="btn bg-primary" disabled={props.busy}>
                      {props.busy ? <Spinner size="sm" className="me-2"></Spinner> : <i className="ri-equalizer-fill me-1 align-bottom"></i>}
                      {t("Shipments.Filters.Button.Apply")}
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
          </div>
        </CardBody>
      </Card>
    </PlaceholderGlow>
  );
};

export default Filters;
