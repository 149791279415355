import React, {useEffect, useMemo, useRef, useState} from "react";
import Dialog, {DialogRef} from "Components/Common/Dialog";
import BreadCrumb, {BreadcrumbMenuItem} from "Components/Common/BreadCrumb";
import DataTable, {DataTableRef} from "Components/Common/DataTable";
import {useTranslation} from "react-i18next";
import {Link, useNavigate} from "react-router-dom";
import {Button, Card, CardBody, CardHeader, CardTitle, Col, Container, Row} from "reactstrap";
import {deleteAnalyzeOptionSettings, getAnalyzeOptions, setAsDefaultAnalyzeOptions} from "slices/settings/thunk";
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "reselect";
import {RootState} from "slices";
import {UserSettingAnalyzeOptions} from "models/user_setting_analyze_options";
import {setCurrentAnalyzeOption} from "slices/settings/reducer";
import {ColumnDef} from "@tanstack/react-table";
import {useProfile} from "Components/Hooks/UserHooks";
import {ConstantPage} from "helpers/permission_helper";
import CreateAnalyzeOption from "./Modals/CreateAnalyzeOption";
import AmazonMarketplaceInfos from "Components/Common/AmazonMarketplaceInfos";
import SettingsLayout from "Components/Common/Layout/SettingsLayout";
import Moment from "react-moment";
import i18n from "i18n";
import DefaultUncontrolledTooltip from "Components/Common/DefaultUncontrolledTooltip";

const PAGE_IDENTIFIER: ConstantPage = "settings.analyzeOptions";
const AnalyzeOptionsList = () => {
  const {t} = useTranslation();
  const {userProfile, hasPermission} = useProfile();
  const [amazonMarketplaceInfos] = useState(AmazonMarketplaceInfos());
  const setAsDefaultDialogRef = useRef<DialogRef>(null);
  const deleteDialogRef = useRef<DialogRef>(null);
  const navigate = useNavigate();
  const dispatch: any = useDispatch();
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const tableRef = useRef<DataTableRef>(null);

  useEffect(() => {
    if (hasPermission(PAGE_IDENTIFIER)) {
      dispatch(getAnalyzeOptions());
    }
  }, [dispatch, navigate]); //eslint-disable-line

  const settingsPageData = createSelector(
    (state: RootState) => state,
    (state) => ({
      loading: state.Settings.loading,
      analyzeOptionsList: state.Settings.analyzeList,
      currentAnalyzeOption: state.Settings.currentAnalyzeOption,
    }),
  );
  const {loading, analyzeOptionsList, currentAnalyzeOption} = useSelector(settingsPageData);

  const columns = useMemo<ColumnDef<UserSettingAnalyzeOptions, any>[]>(
    () => [
      {
        header: t("Actions"),
        size: 100,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserSettingAnalyzeOptions;
          return (
            <>
              <Link
                id={`EditAnalyze-${row.userSettingAnalyzeOptionId}`}
                to={`/account/settings/analyze-options/edit/${row.userSettingAnalyzeOptionId}`}
                className="btn btn-ghost-secondary px-1 py-0 fs-16"
              >
                <i className="ri-pencil-fill"></i>
              </Link>
              <DefaultUncontrolledTooltip target={`EditAnalyze-${row.userSettingAnalyzeOptionId}`}>{t("Settings.AnalyzeOptions.Tooltip.Edit")}</DefaultUncontrolledTooltip>

              <Link
                id={`CopyAnalyzeOption-${row.userSettingAnalyzeOptionId}`}
                to=""
                className="btn btn-ghost-secondary px-1 py-0 fs-16"
                onClick={() => {
                  dispatch(setCurrentAnalyzeOption(row));
                  setIsCreateModalOpen(true);
                }}
              >
                <i className="ri-file-copy-fill"></i>
              </Link>
              <DefaultUncontrolledTooltip target={`CopyAnalyzeOption-${row.userSettingAnalyzeOptionId}`}>{t("Settings.AnalyzeOptions.Tooltip.Duplicate")}</DefaultUncontrolledTooltip>

              <Link
                id={`DeleteAnalyze-${row.userSettingAnalyzeOptionId}`}
                to=""
                className="btn btn-ghost-danger px-1 py-0 fs-16"
                onClick={() => {
                  dispatch(setCurrentAnalyzeOption(row));
                  deleteDialogRef.current?.show();
                }}
              >
                <i className="ri-delete-bin-fill"></i>
              </Link>
              <DefaultUncontrolledTooltip target={`DeleteAnalyze-${row.userSettingAnalyzeOptionId}`}>{t("Settings.AnalyzeOptions.Tooltip.Delete")}</DefaultUncontrolledTooltip>

              {!row.isDefault && (
                <>
                  <Link
                    id={`SetAsDefaultAnalyze-${row.userSettingAnalyzeOptionId}`}
                    to=""
                    className="btn btn-ghost-secondary px-1 py-0 fs-16"
                    onClick={() => {
                      dispatch(setCurrentAnalyzeOption(row));
                      setAsDefaultDialogRef.current?.show();
                    }}
                  >
                    <i className="ri-checkbox-circle-fill"></i>
                  </Link>
                  <DefaultUncontrolledTooltip target={`SetAsDefaultAnalyze-${row.userSettingAnalyzeOptionId}`}>{t("Settings.AnalyzeOptions.Tooltip.SetAsDefault")}</DefaultUncontrolledTooltip>
                </>
              )}
            </>
          );
        },
      },
      {
        header: t("Settings.AnalyzeOptions.TableColumn.Name"),
        size: 200,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserSettingAnalyzeOptions;
          return (
            <span className="link-secondary link-offset-2 text-decoration-underline" onClick={() => navigate(`/account/settings/analyze-options/edit/${row.userSettingAnalyzeOptionId}`)} role="button">
              {row.name}
            </span>
          );
        },
      },
      {
        header: t("Settings.AnalyzeOptions.TableColumn.IsDefault"),
        size: 200,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserSettingAnalyzeOptions;
          return <>{row.isDefault && <span className="fs-12 badge bg-success-subtle text-success">{t("Default")}</span>}</>;
        },
      },
      {
        header: t("Settings.AnalyzeOptions.TableColumn.CreateDate"),
        size: 150,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserSettingAnalyzeOptions;
          return (
            <>
              <Moment locale={i18n.language} format="D MMM YYYY HH:mm" tz={userProfile?.timezone}>
                {row.createDate}
              </Moment>
            </>
          );
        },
      },
    ],
    // TODO remove
    // eslint-disable-next-line
    [t, amazonMarketplaceInfos, navigate],
  );

  useEffect(() => {
    if (analyzeOptionsList.length === 1) {
      navigate(`/account/settings/analyze-options/edit/${analyzeOptionsList[0].userSettingAnalyzeOptionId}`);
    }
  }, [analyzeOptionsList, navigate]);

  const breadcrumbMenus: BreadcrumbMenuItem[] = [
    {
      label: t("Settings.Title"),
      url: "#",
    },
    {
      label: t("Settings.AnalyzeOptions.Title"),
      url: "",
    },
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t("Settings.Title")} menus={breadcrumbMenus} />
          <SettingsLayout hasAnyChanges={false} navigateTo="">
            <>
              <Card>
                <CardHeader>
                  <Row>
                    <Col>
                      <CardTitle className="fw-medium mb-0 align-items-center d-flex">
                        <i className="mdi mdi-chart-timeline-variant-shimmer fs-18 me-1"></i>
                        {t("Settings.AnalyzeOptions.Title")}
                      </CardTitle>
                    </Col>
                    <Col xs="auto">
                      <Button type="button" color="primary" onClick={() => setIsCreateModalOpen(true)}>
                        {t("Settings.AnalyzeOptions.Button.Create")}
                      </Button>
                      <CreateAnalyzeOption isOpen={isCreateModalOpen} toggle={() => setIsCreateModalOpen(!setIsCreateModalOpen)} />
                    </Col>
                  </Row>
                </CardHeader>
              </Card>
              <Card>
                <CardBody>
                  <DataTable
                    ref={tableRef}
                    columns={columns}
                    data={analyzeOptionsList || []}
                    totalDataLength={analyzeOptionsList.length}
                     
                    busy={loading.list}
                    hovered
                  />
                </CardBody>
              </Card>
            </>
          </SettingsLayout>
        </Container>
      </div>
      <Dialog
        ref={setAsDefaultDialogRef}
        color="success"
        buttons={["yes", "no"]}
        busy={loading.update}
        iconClass="ri-checkbox-circle-fill"
        message={t("Settings.AnalyzeOptions.Dialog.SetAsDefault.Description")}
        title={t("Settings.AnalyzeOptions.Dialog.SetAsDefault.Title")}
        onButtonClick={async (button) => {
          if (button === "yes") {
            const current = currentAnalyzeOption;
            await dispatch(setAsDefaultAnalyzeOptions(current.userSettingAnalyzeOptionId));
          }
          dispatch(setCurrentAnalyzeOption({} as UserSettingAnalyzeOptions));
          setAsDefaultDialogRef.current?.hide();
        }}
        onToggle={() => {
          dispatch(setCurrentAnalyzeOption({} as UserSettingAnalyzeOptions));
        }}
      />
      <Dialog
        ref={deleteDialogRef}
        color="danger"
        buttons={["yes", "no"]}
        busy={loading.delete}
        iconClass="ri-delete-bin-line"
        message={t("Settings.AnalyzeOptions.Dialog.Delete.Description")}
        title={t("Settings.AnalyzeOptions.Dialog.Delete.Title")}
        onButtonClick={async (button) => {
          if (button === "yes") {
            const current = currentAnalyzeOption;
            await dispatch(deleteAnalyzeOptionSettings(current.userSettingAnalyzeOptionId));
          }
          dispatch(setCurrentAnalyzeOption({} as UserSettingAnalyzeOptions));
          deleteDialogRef.current?.hide();
        }}
        onToggle={() => {
          dispatch(setCurrentAnalyzeOption({} as UserSettingAnalyzeOptions));
        }}
      />
    </React.Fragment>
  );
};

export default AnalyzeOptionsList;
