import useSelectOptions, {SelectOptionsType} from "Components/Hooks/SelectOptions";
import {useFormik} from "formik";
import {SearchResultFilter, SearchResultQuery} from "models/search_result";
import {SearchStatus} from "models/user_search";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Link, useNavigate, useParams} from "react-router-dom";
import {Button, Card, CardBody, Col, Form, Input, Label, Row, Spinner} from "reactstrap";
import {createSelector} from "reselect";
import {RootState} from "slices";
import {useCallback, useEffect, useState} from "react";
import {setSearchResultFilters, setSearchResultsShowDetails} from "slices/search-result2/thunk";
import {preventScrollUp} from "helpers/utilities";
import Masonry from "react-masonry-css";
import Restricted from "Components/Common/Restricted";
import ValidatedInput from "Components/Common/ValidatedInput";
import ValidatedMultiSelect from "Components/Common/ValidatedMultiSelect";
import ValidatedSelect from "Components/Common/ValidatedSelect";
import InitialFilter from "./_InitialFilter";
import PlaceholderGlow from "Components/Common/PlaceholderGlow";
import AmazonMarketplaceInfos from "Components/Common/AmazonMarketplaceInfos";
import Select from "react-select";
import * as Yup from "yup";
import {UserSearchResultsFilter} from "models/user_search_result_filter";

interface FiltersProps {
  page: number;
  pageSize: number;
  handleFilter: (filter: SearchResultQuery) => void;
}
const Filters = (props: FiltersProps) => {
  const {t} = useTranslation();
  const {searchId, favoriteId} = useParams();
  const {decisionSelectOptions, dateRangeSelectOptions, commonProductSelectOptions, priceAnalysisResultSelectOptions, competitionSelectOptions, amazonCatalogStatusSelectOptions} = useSelectOptions();
  const [definedFilterOptions, setDefinedFilterOptions] = useState<SelectOptionsType[]>([]);
  const [selectedFilter, setSelectedFilter] = useState<UserSearchResultsFilter>();
  const [selectedFilterOption, setSelectedFilterOption] = useState<SelectOptionsType>();
  const [currencyTarget, setCurrencyTarget] = useState<string>("");
  const sortByParameter = new URLSearchParams(window.location.search).get("sortBy");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const searchResultsData = createSelector(
    (state: RootState) => state,
    (state) => ({
      loading: state.SearchResult.loading,
      search: state.SearchResult.result.search,
      favorite: state.SearchResult.result.favorite,
      categories: state.SearchResult.result.categories,
      definedFilters: state.SearchResult.definedFilters,
      showDetails: state.SearchResult.showDetails,
      pageUrl: state.SearchResult.pageUrl,
    }),
  );
  const {loading, search, favorite, categories, definedFilters, pageUrl, showDetails} = useSelector(searchResultsData);

  const categoriesSelectOptions: SelectOptionsType[] = categories?.map((category) => ({
    value: category,
    label: category,
  })) ?? [];

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: InitialFilter(),
    validationSchema: Yup.object<SearchResultFilter>({
      decisionFilter: Yup.array<String>().nullable(),
      amazonCatalogStatusFilter: Yup.array<String>().nullable(),
      keyword: Yup.string().nullable(),
      profitMarginMin: Yup.number().nullable(),
      profitMarginMax: Yup.number().nullable(),
      fbaSellerCountMin: Yup.number().nullable(),
      fbaSellerCountMax: Yup.number().nullable(),
      saleCountMin: Yup.number().nullable(),
      saleCountMax: Yup.number().nullable(),
      priceChangeMin: Yup.number().nullable(),
      priceChangeMax: Yup.number().nullable(),
      roiMin: Yup.number().nullable(),
      roiMax: Yup.number().nullable(),
      fbmSellerCountMin: Yup.number().nullable(),
      fbmSellerCountMax: Yup.number().nullable(),
      bsrMin: Yup.number().nullable(),
      bsrMax: Yup.number().nullable(),
      weightMin: Yup.number().nullable(),
      weightMax: Yup.number().nullable(),
      profitMin: Yup.number().nullable(),
      profitMax: Yup.number().nullable(),
      remoteFbaSellerCountMin: Yup.number().nullable(),
      remoteFbaSellerCountMax: Yup.number().nullable(),
      variationCountMin: Yup.number().nullable(),
      variationCountMax: Yup.number().nullable(),
      sellPriceMin: Yup.number().nullable(),
      sellPriceMax: Yup.number().nullable(),
      purchasePriceMin: Yup.number().nullable(),
      purchasePriceMax: Yup.number().nullable(),
      amazonNotListedSince: Yup.string().nullable(),
      competition: Yup.array<String>().nullable(),
      priceAnalysisResult: Yup.array<String>().nullable(),
      productAge: Yup.string(),
      potentialBrandOwner: Yup.string(),
      isAmazonExist: Yup.string(),
      sameProduct: Yup.string(),
      customsRestriction: Yup.string(),
      restrictionCheck: Yup.string(),
      sizeIsEligible: Yup.string(),
      isOldProduct: Yup.string(),
      eligibleToSell: Yup.string(),
      categories: Yup.array<String>().nullable(),
      sortBy: Yup.string().nullable(),
    }),
    onSubmit: () => {
      const query = {
        filtering: true,
        page: 1,
        pageSize: props.pageSize,
        favoriteId: favoriteId || undefined,
        searchId: searchId || undefined,
        filter: getFilterValidationValues(),
      } as SearchResultQuery;
      props.handleFilter(query);
    },
  });

  const getFilterValidationValues = (): SearchResultFilter => {
    const x = {
      decisionFilter: validation.values.decisionFilter || undefined,
      amazonCatalogStatusFilter: validation.values.amazonCatalogStatusFilter || undefined,
      keyword: validation.values.keyword || undefined,
      profitMarginMin: validation.values.profitMarginMin || undefined,
      profitMarginMax: validation.values.profitMarginMax || undefined,
      fbaSellerCountMin: validation.values.fbaSellerCountMin || undefined,
      fbaSellerCountMax: validation.values.fbaSellerCountMax || undefined,
      saleCountMin: validation.values.saleCountMin || undefined,
      saleCountMax: validation.values.saleCountMax || undefined,
      priceChangeMin: validation.values.priceChangeMin || undefined,
      priceChangeMax: validation.values.priceChangeMax || undefined,
      roiMin: validation.values.roiMin || undefined,
      roiMax: validation.values.roiMax || undefined,
      fbmSellerCountMin: validation.values.fbmSellerCountMin || undefined,
      fbmSellerCountMax: validation.values.fbmSellerCountMax || undefined,
      bsrMin: validation.values.bsrMin || undefined,
      bsrMax: validation.values.bsrMax || undefined,
      weightMin: validation.values.weightMin || undefined,
      weightMax: validation.values.weightMax || undefined,
      profitMin: validation.values.profitMin || undefined,
      profitMax: validation.values.profitMax || undefined,
      remoteFbaSellerCountMin: validation.values.remoteFbaSellerCountMin || undefined,
      remoteFbaSellerCountMax: validation.values.remoteFbaSellerCountMax || undefined,
      variationCountMin: validation.values.variationCountMin || undefined,
      variationCountMax: validation.values.variationCountMax || undefined,
      sellPriceMin: validation.values.sellPriceMin || undefined,
      sellPriceMax: validation.values.sellPriceMax || undefined,
      purchasePriceMin: validation.values.purchasePriceMin || undefined,
      purchasePriceMax: validation.values.purchasePriceMax || undefined,
      amazonNotListedSince: validation.values.amazonNotListedSince || undefined,
      competition: validation.values.competition || undefined,
      priceAnalysisResult: validation.values.priceAnalysisResult || undefined,
      productAge: validation.values.productAge || undefined,
      potentialBrandOwner: validation.values.potentialBrandOwner || undefined,
      isAmazonExist: validation.values.isAmazonExist || undefined,
      sameProduct: validation.values.sameProduct || undefined,
      customsRestriction: validation.values.customsRestriction || undefined,
      restrictionCheck: validation.values.restrictionCheck || undefined,
      sizeIsEligible: validation.values.sizeIsEligible || undefined,
      isOldProduct: validation.values.isOldProduct || undefined,
      eligibleToSell: validation.values.eligibleToSell || undefined,
      categories: validation.values.categories || undefined,
      sortBy: validation.values.sortBy || undefined,
    };
    return x;
  };

  const handleOnSelectedFilterChange = useCallback(() => {
    if (selectedFilter) {
      const filter = definedFilters?.find((filter) => filter.filterName === selectedFilter?.filterName)?.filter;
      if (filter) {
        const filterObject: SearchResultFilter = JSON.parse(filter as any);
        validation.setValues(filterObject).then(() => {
          const query = {
            filtering: true,
            page: 1,
            pageSize: props.pageSize,
            favoriteId: favoriteId || undefined,
            searchId: searchId || undefined,
            filter: {
              decisionFilter: filterObject.decisionFilter || undefined,
              amazonCatalogStatusFilter: filterObject.amazonCatalogStatusFilter || undefined,
              keyword: filterObject.keyword || undefined,
              profitMarginMin: filterObject.profitMarginMin || undefined,
              profitMarginMax: filterObject.profitMarginMax || undefined,
              fbaSellerCountMin: filterObject.fbaSellerCountMin || undefined,
              fbaSellerCountMax: filterObject.fbaSellerCountMax || undefined,
              saleCountMin: filterObject.saleCountMin || undefined,
              saleCountMax: filterObject.saleCountMax || undefined,
              priceChangeMin: filterObject.priceChangeMin || undefined,
              priceChangeMax: filterObject.priceChangeMax || undefined,
              roiMin: filterObject.roiMin || undefined,
              roiMax: filterObject.roiMax || undefined,
              fbmSellerCountMin: filterObject.fbmSellerCountMin || undefined,
              fbmSellerCountMax: filterObject.fbmSellerCountMax || undefined,
              bsrMin: filterObject.bsrMin || undefined,
              bsrMax: filterObject.bsrMax || undefined,
              weightMin: filterObject.weightMin || undefined,
              weightMax: filterObject.weightMax || undefined,
              profitMin: filterObject.profitMin || undefined,
              profitMax: filterObject.profitMax || undefined,
              remoteFbaSellerCountMin: filterObject.remoteFbaSellerCountMin || undefined,
              remoteFbaSellerCountMax: filterObject.remoteFbaSellerCountMax || undefined,
              variationCountMin: filterObject.variationCountMin || undefined,
              variationCountMax: filterObject.variationCountMax || undefined,
              sellPriceMin: filterObject.sellPriceMin || undefined,
              sellPriceMax: filterObject.sellPriceMax || undefined,
              purchasePriceMin: filterObject.purchasePriceMin || undefined,
              purchasePriceMax: filterObject.purchasePriceMax || undefined,
              amazonNotListedSince: filterObject.amazonNotListedSince || undefined,
              competition: filterObject.competition || undefined,
              priceAnalysisResult: filterObject.priceAnalysisResult || undefined,
              productAge: filterObject.productAge || undefined,
              potentialBrandOwner: filterObject.potentialBrandOwner || undefined,
              isAmazonExist: filterObject.isAmazonExist || undefined,
              sameProduct: filterObject.sameProduct || undefined,
              customsRestriction: filterObject.customsRestriction || undefined,
              restrictionCheck: filterObject.restrictionCheck || undefined,
              sizeIsEligible: filterObject.sizeIsEligible || undefined,
              isOldProduct: filterObject.isOldProduct || undefined,
              eligibleToSell: filterObject.eligibleToSell || undefined,
              categories: filterObject.categories || undefined,
              sortBy: filterObject.sortBy || undefined,
            },
          } as SearchResultQuery;
          props.handleFilter(query);
        });
      }
    }
  }, [selectedFilter]); // eslint-disable-line

  const handleFilterValuesChange = useCallback(async () => {
    if (validation.values) {
      setSearchResultFilters(validation.values)(dispatch);
    }
  }, [validation.values]); // eslint-disable-line

  const updateDefinedFilterOptions = useCallback(() => {
    const array: SelectOptionsType[] = definedFilters.map((filter) => ({
      value: filter.filterName,
      label: filter.filterName,
    }));
    setDefinedFilterOptions(array);
  }, [definedFilters]);

  const updateCurrencyTarget = useCallback(() => {
    let mp;
    if (search) {
      mp = AmazonMarketplaceInfos().find((mp) => mp.marketplace === search.marketplaceTarget);
    } else if (favorite) {
      mp = AmazonMarketplaceInfos().find((mp) => mp.marketplace === favorite.marketplace);
    }
    setCurrencyTarget(mp?.currency || "");
  }, [search, favorite]);

  const handleSortByFilterChange = useCallback(() => {
    if (sortByParameter) {
      const query = {
        filtering: true,
        page: props.page,
        pageSize: props.pageSize,
        favoriteId: favoriteId || undefined,
        searchId: searchId || undefined,
        filter: {
          ...validation.values,
          sortBy: sortByParameter,
        },
      } as SearchResultQuery;
      props.handleFilter(query);
    }
  }, [sortByParameter]); // eslint-disable-line

  useEffect(() => {
    updateDefinedFilterOptions();
  }, [updateDefinedFilterOptions]);

  useEffect(() => {
    updateCurrencyTarget();
  }, [updateCurrencyTarget]);

  useEffect(() => {
    handleFilterValuesChange();
  }, [handleFilterValuesChange]);

  useEffect(() => {
    handleOnSelectedFilterChange();
  }, [handleOnSelectedFilterChange]);

  useEffect(() => {
    handleSortByFilterChange();
  }, [handleSortByFilterChange]);

  return (
    <PlaceholderGlow busy={loading.filter}>
      <Card>
        <CardBody>
          <div className="live-preview search-results-filters">
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              <Row className="align-items-center mb-4">
                <Col xs>
                  <div className="flex-shrink-0">
                    <Label className="form-label text-muted">{t("SearchResults.Filters.Title")}</Label>
                  </div>
                </Col>

                <Col className="col-auto ps-0" align="right">
                  <div className="flex-shrink-0 w-xl">
                    <div className="form-check form-switch form-switch-right form-switch-md form-switch-success">
                      <Label className="form-label" for="showDetails">
                        {t("SearchResults.Filters.Button.ShowDetails")}
                      </Label>
                      <Input
                        className="form-check-input code-switcher"
                        type="checkbox"
                        name="showDetails"
                        id="showDetails"
                        onChange={(e) => setSearchResultsShowDetails(e.target.checked)(dispatch)}
                        checked={showDetails}
                      />
                    </div>
                  </div>
                </Col>
                {search && (
                  <Col className="col-auto ps-2" align="right">
                    <div className="flex-shrink-0">
                      <Link
                        to={`${pageUrl}/re-analyze`}
                        className="btn btn-soft-primary btn-sm"
                        type="button"
                        style={search.status === SearchStatus.IN_PROGRESS ? {pointerEvents: "none", color: "#858585"} : {}}
                      >
                        <i className="mdi mdi-chart-timeline-variant-shimmer me-2"></i>
                        {t("SearchResults.Filters.Button.ReAnalyze")}
                      </Link>
                    </div>
                  </Col>
                )}
              </Row>
              <Row className="d-flex align-items-center">
                <Masonry
                  breakpointCols={{
                    default: 4,
                    1200: 4, // md
                    992: 2, // sm
                    768: 1, // xs
                  }}
                  className="my-masonry-grid"
                  columnClassName="my-masonry-grid_column"
                >
                  <Col className="mb-2">
                    <Label className="col-form-label-sm" htmlFor="definedFilter">
                      {t("SearchResults.Filters.DefinedFilter")}
                    </Label>
                    <Select
                      placeholder={t("SearchResults.Filters.DefinedFilterPlaceholder")}
                      options={definedFilterOptions}
                      onChange={(e: any) => {
                        if (e) {
                          const f = definedFilters?.find((f) => f.filterName === e.value);
                          setSelectedFilter(f);
                          setSelectedFilterOption({label: e.value, value: e.value});
                        } else {
                          validation.resetForm();
                          setSelectedFilter(undefined);
                          setSelectedFilterOption(undefined);
                        }
                      }}
                      value={selectedFilterOption}
                      isClearable
                    />
                  </Col>
                  <Col className="mb-2">
                    <Label className="col-form-label-sm" htmlFor="decisionFilter">
                      {t("SearchResults.Filters.DecisionFilter")}
                    </Label>
                    <ValidatedMultiSelect options={decisionSelectOptions} validation={validation} field="decisionFilter" value={validation.values.decisionFilter} isClearable />
                  </Col>
                  <Col className="mb-2">
                    <Label className="col-form-label-sm" htmlFor="amazonCatalogStatusFilter">
                      {t("SearchResults.Filters.AmazonCatalogStatusFilter")}
                    </Label>
                    <ValidatedMultiSelect
                      options={amazonCatalogStatusSelectOptions}
                      validation={validation}
                      field="amazonCatalogStatusFilter"
                      value={validation.values.amazonCatalogStatusFilter}
                      isClearable
                    />
                  </Col>
                  <Col className="mb-2">
                    <Label className="col-form-label-sm" htmlFor="keyword">
                      {t("SearchResults.Filters.Search")}
                    </Label>
                    <ValidatedInput type="text" placeholder={t("SearchResults.Filters.SearchPlaceholder")} validation={validation} field="keyword" maxLength={100} disableValidationUI />
                  </Col>
                </Masonry>
              </Row>
              <Row className="my-1">
                <hr />
              </Row>

              <Row className="d-flex align-items-center">
                <Masonry
                  breakpointCols={{
                    default: 4,
                    1900: 4,
                    1400: 3,
                    1200: 2,
                    820: 1,
                  }}
                  className="my-masonry-grid"
                  columnClassName="my-masonry-grid_column"
                >
                  <Col className="mb-2">
                    <Row className="d-flex align-items-center">
                      <Col xs={5}>
                        <Label size="sm">{t("SearchResults.Filters.ProfitMargin")}</Label>
                      </Col>
                      <Col xs={3}>
                        <ValidatedInput type="number" placeholder={t("Min")} validation={validation} field="profitMarginMin" size="sm" disableValidationUI />
                      </Col>
                      <Col xs={1} className="text-center text-nowrap p-0 text-muted">
                        {t("and")}
                      </Col>
                      <Col xs={3}>
                        <ValidatedInput type="number" placeholder={t("Max")} validation={validation} field="profitMarginMax" size="sm" disableValidationUI />
                      </Col>
                    </Row>
                  </Col>

                  <Col className="mb-2">
                    <Row className="d-flex align-items-center">
                      <Col xs={5}>
                        <Label size="sm">{t("SearchResults.Filters.FBASellerCount")}</Label>
                      </Col>
                      <Col xs={3}>
                        <ValidatedInput type="number" placeholder={t("Min")} validation={validation} field="fbaSellerCountMin" size="sm" disableValidationUI />
                      </Col>
                      <Col xs={1} className="text-center text-nowrap p-0 text-muted">
                        {t("and")}
                      </Col>
                      <Col xs={3}>
                        <ValidatedInput type="number" placeholder={t("Max")} validation={validation} field="fbaSellerCountMax" size="sm" disableValidationUI />
                      </Col>
                    </Row>
                  </Col>

                  <Col className="mb-2">
                    <Row className="d-flex align-items-center">
                      <Col xs={5}>
                        <Label size="sm">{t("SearchResults.Filters.SaleCount")}</Label>
                      </Col>
                      <Col xs={3}>
                        <ValidatedInput type="number" placeholder={t("Min")} validation={validation} field="saleCountMin" size="sm" disableValidationUI />
                      </Col>
                      <Col xs={1} className="text-center text-nowrap p-0 text-muted">
                        {t("and")}
                      </Col>
                      <Col xs={3}>
                        <ValidatedInput type="number" placeholder={t("Max")} validation={validation} field="saleCountMax" size="sm" disableValidationUI />
                      </Col>
                    </Row>
                  </Col>

                  <Col className="mb-2">
                    <Row className="d-flex align-items-center">
                      <Col xs={5}>
                        <Label size="sm">{t("SearchResults.Filters.PriceChange")}</Label>
                      </Col>
                      <Col xs={3}>
                        <ValidatedInput type="number" placeholder={t("Min")} validation={validation} field="priceChangeMin" size="sm" disableValidationUI />
                      </Col>
                      <Col xs={1} className="text-center text-nowrap p-0 text-muted">
                        {t("and")}
                      </Col>
                      <Col xs={3}>
                        <ValidatedInput type="number" placeholder={t("Max")} validation={validation} field="priceChangeMax" size="sm" disableValidationUI />
                      </Col>
                    </Row>
                  </Col>

                  <Col className="mb-2">
                    <Row className="d-flex align-items-center">
                      <Col xs={5}>
                        <Label size="sm">{t("SearchResults.Filters.ROI")}</Label>
                      </Col>
                      <Col xs={3}>
                        <ValidatedInput type="number" placeholder={t("Min")} validation={validation} field="roiMin" size="sm" disableValidationUI />
                      </Col>
                      <Col xs={1} className="text-center text-nowrap p-0 text-muted">
                        {t("and")}
                      </Col>
                      <Col xs={3}>
                        <ValidatedInput type="number" placeholder={t("Max")} validation={validation} field="roiMax" size="sm" disableValidationUI />
                      </Col>
                    </Row>
                  </Col>

                  <Col className="mb-2">
                    <Row className="d-flex align-items-center">
                      <Col xs={5}>
                        <Label size="sm">{t("SearchResults.Filters.FBM")}</Label>
                      </Col>
                      <Col xs={3}>
                        <ValidatedInput type="number" placeholder={t("Min")} validation={validation} field="fbmSellerCountMin" size="sm" disableValidationUI />
                      </Col>
                      <Col xs={1} className="text-center text-nowrap p-0 text-muted">
                        {t("and")}
                      </Col>
                      <Col xs={3}>
                        <ValidatedInput type="number" placeholder={t("Max")} validation={validation} field="fbmSellerCountMax" size="sm" disableValidationUI />
                      </Col>
                    </Row>
                  </Col>

                  <Col className="mb-2">
                    <Row className="d-flex align-items-center">
                      <Col xs={5}>
                        <Label size="sm">{t("SearchResults.Filters.BSR")}</Label>
                      </Col>
                      <Col xs={3}>
                        <ValidatedInput type="number" placeholder={t("Min")} validation={validation} field="bsrMin" size="sm" disableValidationUI />
                      </Col>
                      <Col xs={1} className="text-center text-nowrap p-0 text-muted">
                        {t("and")}
                      </Col>
                      <Col xs={3}>
                        <ValidatedInput type="number" placeholder={t("Max")} validation={validation} field="bsrMax" size="sm" disableValidationUI />
                      </Col>
                    </Row>
                  </Col>

                  <Col className="mb-2">
                    <Row className="d-flex align-items-center">
                      <Col xs={5}>
                        <Label size="sm">{t("SearchResults.Filters.Weight")}</Label>
                      </Col>
                      <Col xs={3}>
                        <ValidatedInput type="number" placeholder={t("Min")} validation={validation} field="weightMin" size="sm" disableValidationUI />
                      </Col>
                      <Col xs={1} className="text-center text-nowrap p-0 text-muted">
                        {t("and")}
                      </Col>
                      <Col xs={3}>
                        <ValidatedInput type="number" placeholder={t("Max")} validation={validation} field="weightMax" size="sm" disableValidationUI />
                      </Col>
                    </Row>
                  </Col>

                  <Col className="mb-2">
                    <Row className="d-flex align-items-center">
                      <Col xs={5}>
                        <Label size="sm">{t("SearchResults.Filters.Profit", {marketplace: currencyTarget})}</Label>
                      </Col>
                      <Col xs={3}>
                        <ValidatedInput type="number" placeholder={t("Min")} validation={validation} field="profitMin" size="sm" disableValidationUI />
                      </Col>
                      <Col xs={1} className="text-center text-nowrap p-0 text-muted">
                        {t("and")}
                      </Col>
                      <Col xs={3}>
                        <ValidatedInput type="number" placeholder={t("Max")} validation={validation} field="profitMax" size="sm" disableValidationUI />
                      </Col>
                    </Row>
                  </Col>

                  <Col className="mb-2">
                    <Row className="d-flex align-items-center">
                      <Col xs={5}>
                        <Label size="sm">{t("SearchResults.Filters.RemoteFbaSeller")}</Label>
                      </Col>
                      <Col xs={3}>
                        <ValidatedInput type="number" placeholder={t("Min")} validation={validation} field="remoteFbaSellerCountMin" size="sm" disableValidationUI />
                      </Col>
                      <Col xs={1} className="text-center text-nowrap p-0 text-muted">
                        {t("and")}
                      </Col>
                      <Col xs={3}>
                        <ValidatedInput type="number" placeholder={t("Max")} validation={validation} field="remoteFbaSellerCountMax" size="sm" disableValidationUI />
                      </Col>
                    </Row>
                  </Col>

                  <Col className="mb-2">
                    <Row className="d-flex align-items-center">
                      <Col xs={5}>
                        <Label size="sm">{t("SearchResults.Filters.VariationCount")}</Label>
                      </Col>
                      <Col xs={3}>
                        <ValidatedInput type="number" placeholder={t("Min")} validation={validation} field="variationCountMin" size="sm" disableValidationUI />
                      </Col>
                      <Col xs={1} className="text-center text-nowrap p-0 text-muted">
                        {t("and")}
                      </Col>
                      <Col xs={3}>
                        <ValidatedInput type="number" placeholder={t("Max")} validation={validation} field="variationCountMax" size="sm" disableValidationUI />
                      </Col>
                    </Row>
                  </Col>
                  <Col></Col>
                  <Col className="mb-2">
                    <Row className="d-flex align-items-center">
                      <Col xs={5}>
                        <Label size="sm">{t("SearchResults.Filters.SellPrice")}</Label>
                      </Col>
                      <Col xs={3}>
                        <ValidatedInput type="number" placeholder={t("Min")} validation={validation} field="sellPriceMin" size="sm" disableValidationUI />
                      </Col>
                      <Col xs={1} className="text-center text-nowrap p-0 text-muted">
                        {t("and")}
                      </Col>
                      <Col xs={3}>
                        <ValidatedInput type="number" placeholder={t("Max")} validation={validation} field="sellPriceMax" size="sm" disableValidationUI />
                      </Col>
                    </Row>
                  </Col>

                  <Col className="mb-2">
                    <Row className="d-flex align-items-center">
                      <Col xs={5}>
                        <Label size="sm">{t("SearchResults.Filters.PurchasePrice")}</Label>
                      </Col>
                      <Col xs={3}>
                        <ValidatedInput type="number" placeholder={t("Min")} validation={validation} field="purchasePriceMin" size="sm" disableValidationUI />
                      </Col>
                      <Col xs={1} className="text-center text-nowrap p-0 text-muted">
                        {t("and")}
                      </Col>
                      <Col xs={3}>
                        <ValidatedInput type="number" placeholder={t("Max")} validation={validation} field="purchasePriceMax" size="sm" disableValidationUI />
                      </Col>
                    </Row>
                  </Col>
                </Masonry>
              </Row>
              <Row className="align-items-center mt-4">
                <Masonry
                  breakpointCols={{
                    default: 7,
                    1200: 6, // md
                    992: 2, // sm
                    768: 1, // xs
                  }}
                  className="my-masonry-grid"
                  columnClassName="my-masonry-grid_column"
                >
                  <Col className="mb-2">
                    <Label className="col-form-label-sm" htmlFor="amazonNotListedSince">
                      {t("SearchResults.Filters.AmazonNotListedSince")}
                    </Label>
                    <ValidatedSelect
                      className="col-form-label-sm"
                      options={dateRangeSelectOptions}
                      validation={validation}
                      field="amazonNotListedSince"
                      value={validation.values.amazonNotListedSince}
                    />
                  </Col>

                  <Col className="mb-2">
                    <Label className="col-form-label-sm" htmlFor="competition">
                      {t("SearchResults.Filters.Competition")}
                    </Label>
                    <ValidatedMultiSelect className="col-form-label-sm" options={competitionSelectOptions} validation={validation} field="competition" value={validation.values.competition} />
                  </Col>

                  <Col className="mb-2">
                    <Label className="col-form-label-sm" htmlFor="priceAnalysisResult">
                      {t("SearchResults.Filters.PriceAnalysisResult")}
                    </Label>
                    <ValidatedMultiSelect
                      className="col-form-label-sm"
                      options={priceAnalysisResultSelectOptions}
                      validation={validation}
                      field="priceAnalysisResult"
                      value={validation.values.priceAnalysisResult}
                    />
                  </Col>

                  <Col className="mb-2">
                    <Label className="col-form-label-sm" htmlFor="productAge">
                      {t("SearchResults.Filters.ProductAge")}
                    </Label>
                    <ValidatedSelect className="col-form-label-sm" options={dateRangeSelectOptions} validation={validation} field="productAge" value={validation.values.productAge} />
                  </Col>

                  <Col className="mb-2">
                    <Label className="col-form-label-sm" htmlFor="potentialBrandOwner">
                      {t("SearchResults.Filters.PotentialBrandOwner")}
                    </Label>
                    <ValidatedSelect
                      className="col-form-label-sm"
                      options={commonProductSelectOptions}
                      validation={validation}
                      field="potentialBrandOwner"
                      value={validation.values.potentialBrandOwner}
                    />
                  </Col>

                  <Col className="mb-2">
                    <Label className="col-form-label-sm" htmlFor="isAmazonExist">
                      {t("SearchResults.Filters.IsAmazonExist")}
                    </Label>
                    <ValidatedSelect className="col-form-label-sm" options={commonProductSelectOptions} validation={validation} field="isAmazonExist" value={validation.values.isAmazonExist} />
                  </Col>

                  <Col className="mb-2">
                    <Label className="col-form-label-sm" htmlFor="sameProduct">
                      {t("SearchResults.Filters.SameProduct")}
                    </Label>
                    <ValidatedSelect className="col-form-label-sm" options={commonProductSelectOptions} validation={validation} field="sameProduct" value={validation.values.sameProduct} />
                  </Col>
                  <Col className="mb-2">
                    <Label className="col-form-label-sm" htmlFor="customsRestriction">
                      {t("SearchResults.Filters.CustomsRestriction")}
                    </Label>
                    <ValidatedSelect
                      className="col-form-label-sm"
                      options={commonProductSelectOptions}
                      validation={validation}
                      field="customsRestriction"
                      value={validation.values.customsRestriction}
                    />
                  </Col>

                  <Col className="mb-2">
                    <Label className="col-form-label-sm" htmlFor="restrictionCheck">
                      {t("SearchResults.Filters.RestrictionCheck")}
                    </Label>
                    <ValidatedSelect className="col-form-label-sm" options={commonProductSelectOptions} validation={validation} field="restrictionCheck" value={validation.values.restrictionCheck} />
                  </Col>

                  <Col className="mb-2">
                    <Label className="col-form-label-sm" htmlFor="sizeIsEligible">
                      {t("SearchResults.Filters.SizeIsEligible")}
                    </Label>
                    <ValidatedSelect className="col-form-label-sm" options={commonProductSelectOptions} validation={validation} field="sizeIsEligible" value={validation.values.sizeIsEligible} />
                  </Col>
                  <Col className="mb-2">
                    <Label className="col-form-label-sm" htmlFor="isOldProduct">
                      {t("SearchResults.Filters.IsOldProduct")}
                    </Label>
                    <ValidatedSelect className="col-form-label-sm" options={commonProductSelectOptions} validation={validation} field="isOldProduct" value={validation.values.isOldProduct} />
                  </Col>

                  <Col className="mb-2">
                    <Label className="col-form-label-sm" htmlFor="eligibleToSell">
                      {t("SearchResults.Filters.EligibleToSell")}
                    </Label>
                    <ValidatedSelect className="col-form-label-sm" options={commonProductSelectOptions} validation={validation} field="eligibleToSell" value={validation.values.eligibleToSell} />
                  </Col>

                  <Col className="mb-2">
                    <Label className="col-form-label-sm" htmlFor="categories">
                      {t("SearchResults.Filters.Categories")}
                    </Label>
                    <ValidatedMultiSelect className="col-form-label-sm" options={categoriesSelectOptions} validation={validation} field="categories" value={validation.values.categories} />
                  </Col>
                </Masonry>

                <Row className="pe-0">
                  <Col></Col>
                  <Col xs="auto">
                    <Restricted require="searchResult" create>
                      <>
                        <Button
                          className="btn btn-info add-btn"
                          data-bs-toggle="modal"
                          data-bs-target="#showModal"
                          disabled={!validation.dirty}
                          onClick={() => {
                            const filter = definedFilters.find((filter) => filter.filterName === selectedFilter?.filterName);
                            if (selectedFilter?.filterName && filter) navigate(`${pageUrl}/defined-filter?filterName=${encodeURIComponent(filter.filterName)}`);
                            else navigate(`${pageUrl}/defined-filter`);
                            preventScrollUp();
                          }}
                        >
                          {selectedFilter ? (
                            <>
                              <i className="ri-pencil-line align-bottom me-1"></i> {t("SearchResults.Filters.Button.Update")}
                            </>
                          ) : (
                            <>
                              <i className="ri-add-line align-bottom me-1"></i> {t("SearchResults.Filters.Button.Save")}
                            </>
                          )}
                        </Button>
                      </>
                    </Restricted>
                  </Col>
                  <Col xs="auto" className="px-0">
                    <Button type="submit" className="btn bg-primary " disabled={loading.list}>
                      {loading.list ? <Spinner size="sm" className="me-2"></Spinner> : <i className="ri-equalizer-fill me-1 align-bottom"></i>}
                      {t("SearchResults.Filters.Button.Apply")}
                    </Button>
                  </Col>
                </Row>
              </Row>
            </Form>
          </div>
        </CardBody>
      </Card>
    </PlaceholderGlow>
  );
};

export default Filters;
