import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Card, CardBody, Col} from "reactstrap";
import {BestSellingCategory} from "models/dashboard";
import {createSelector} from "reselect";
import {RootState} from "slices";
import {useSelector} from "react-redux";
import DisplayPrice from "Components/Common/DisplayPrice";
import DomainToFlag from "Components/Common/DomainToFlag";
import CountUp from "react-countup";
import SimpleBar from "simplebar-react";
import NoData from "Components/Common/NoData";

const BestSellingCategories = () => {
  const {t} = useTranslation();

  const [bestSellingCategories, setBestSellingCategories] = useState<BestSellingCategory[]>([]);
  const dashboardData = createSelector(
    (state: RootState) => state,
    (state) => ({
      statistics: state.Dashboard.statistics,
      filter: state.Dashboard.filter,
    }),
  );

  const {filter, statistics} = useSelector(dashboardData);

  useEffect(() => {
    if (statistics) {
      setBestSellingCategories(statistics.bestSellingCategories);
    }
  }, [statistics]);

  return (
    <React.Fragment>
      <Col xl={5}>
        <Card className="card-height-100 card-animate">
          <div className="align-items-center d-flex card-header">
            <h4 className="card-title mb-0 flex-grow-1">{t("Dashboard.BestSellingCategories.Title")}</h4>
          </div>
          <CardBody>
            {bestSellingCategories && bestSellingCategories.length > 0 ? (
              <div className="table-responsive table-card">
                <SimpleBar style={{height: "425px"}}>
                  <table className="table-hover table table-centered table-borderless border-0 align-middle mb-0 border-top-0">
                    <thead className="text-muted table-light sticky-top">
                      <tr className="border">
                        <th scope="col">{t("Dashboard.BestSellingCategories.TableColumn.MarketplaceAndCategory")}</th>
                        <th scope="col">{t("Dashboard.BestSellingCategories.TableColumn.Revenue")}</th>
                        <th scope="col">{t("Dashboard.BestSellingCategories.TableColumn.Orders")}</th>
                        <th scope="col">{t("Dashboard.BestSellingCategories.TableColumn.Units")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {bestSellingCategories &&
                        bestSellingCategories.map((item: BestSellingCategory, index: number) => {
                          return (
                            <tr key={index}>
                              <td>
                                <div className="d-flex align-items-center">
                                  <DomainToFlag marketplace={item.marketplace} />
                                  <span className="ellipsis-single-line fw-medium" title={item.category}>
                                    {item.category}
                                  </span>
                                </div>
                              </td>
                              <td>
                                <span className="badge bg-success-subtle text-success fs-12">
                                  <DisplayPrice source={filter?.currency} value={item.revenue ?? 0} decimals={2} />
                                </span>
                              </td>
                              <td>
                                <span className="badge bg-secondary-subtle text-secondary fs-12">
                                  <CountUp start={0} end={item.orderCount} duration={2} />
                                </span>
                              </td>
                              <td>
                                <span className="badge bg-secondary-subtle text-secondary fs-12">
                                  <CountUp start={0} end={item.unitCount} duration={2} />
                                </span>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </SimpleBar>
              </div>
            ) : (
              <NoData icon="mdi mdi-note-search-outline" />
            )}
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default BestSellingCategories;
