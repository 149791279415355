import {GetUsersQuery, User} from "models/user";
import {apiError, loading, setLoginToken, setSubscriptionList, setUserLogHistoryList, setUserPaymentHistoryList, setUsersList, updateUserStatusAsActive, updateUserStatusAsBlock} from "./reducer";
import {ResponseModel} from "models/response_model";
import {postGetUsers, postBlockUser, postUnblockUser, postResetUserPassword, postGetUserPaymentHistory, postGetUserLogHistory, postGenerateUserLoginToken} from "services/admin_service";
import {generateError, renderSuccessToast} from "helpers/utilities";
import {PagedList} from "helpers/types";
import {postDownloadInvoice, postGetSubscriptions} from "services/payment_service";
import {GetUserLogHistoryQuery, GetUserPaymentHistoryQuery} from "api/query";
import {UserPayment} from "models/user_payment";
import {LogEntry} from "models/log_entry";
import i18n from "i18n";

export const getUsers = (payload: GetUsersQuery) => async (dispatch: any) => {
  const loadingType = payload.filtering ? "filter" : "list";
  try {
    dispatch(loading([loadingType, true]));
    const response: ResponseModel = await postGetUsers(payload);
    const list: PagedList<User> = response.data.pagedList;
    dispatch(setUsersList(list));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading([loadingType, false]));
  }
};

export const getAllSubscriptionData = () => async (dispatch: any) => {
  try {
    dispatch(loading(["listSubscriptions", true]));
    const result: ResponseModel = await postGetSubscriptions();
    dispatch(setSubscriptionList(result.data));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["listSubscriptions", false]));
  }
};

export const blockUser = (user: User) => async (dispatch: any) => {
  try {
    dispatch(loading(["block", true]));
    await postBlockUser({userId: user.userId});
    dispatch(updateUserStatusAsBlock(user));
    renderSuccessToast(i18n.t("Admin.Users.Toast.BlockSuccess"));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["block", false]));
  }
};

export const unblockUser = (user: User) => async (dispatch: any) => {
  try {
    dispatch(loading(["unblock", true]));
    await postUnblockUser({userId: user.userId});
    dispatch(updateUserStatusAsActive(user));
    renderSuccessToast(i18n.t("Admin.Users.Toast.UnblockSuccess"));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["unblock", false]));
  }
};

export const generateUserLoginToken = (user: User) => async (dispatch: any) => {
  try {
    dispatch(loading(["loginAs", true]));
    const result: ResponseModel = await postGenerateUserLoginToken({userId: user.userId});
    dispatch(setLoginToken(result.data));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["loginAs", false]));
  }
};

export const resetUserPassword = (user: User) => async (dispatch: any) => {
  try {
    dispatch(loading(["resetPassword", true]));
    await postResetUserPassword({userId: user.userId});
    dispatch(updateUserStatusAsActive(user));
    renderSuccessToast(i18n.t("Admin.Users.Toast.PasswordResetSuccess"));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["resetPassword", false]));
  }
};

export const getUserPaymentHistory = (query: GetUserPaymentHistoryQuery) => async (dispatch: any) => {
  try {
    dispatch(loading(["listPaymentHistory", true]));
    const response: ResponseModel = await postGetUserPaymentHistory(query);
    const pagedResult: PagedList<UserPayment> = response.data;
    dispatch(setUserPaymentHistoryList(pagedResult));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["listPaymentHistory", false]));
  }
};

export const downloadAdminUserPayment = (userPaymentId: string) => async (dispatch: any) => {
  try {
    dispatch(loading(["downloadPayment", true]));
    const result = await postDownloadInvoice({userPaymentId});
    window.open(result.message, "_blank", "noreferrer noopener");
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["downloadPayment", false]));
  }
};

export const getUserLogHistory = (payload: GetUserLogHistoryQuery) => async (dispatch: any) => {
  try {
    dispatch(loading(["listLogHistory", true]));
    const response: ResponseModel = await postGetUserLogHistory(payload);
    const pagedResult: PagedList<LogEntry> = response.data;
    dispatch(setUserLogHistoryList(pagedResult));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["listLogHistory", false]));
  }
};
