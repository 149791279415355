import {useState, useMemo, useCallback} from "react";
import {formatCurrency} from "helpers/utilities";
import AmazonMarketplaceInfos from "./AmazonMarketplaceInfos";
import CountUp from "react-countup";

interface DisplayPriceProps {
  source?: string;
  value?: number;
  decimals: number;
  countUp?: boolean;
  prefix?: string;
  suffix?: string;
  className?:string;
}

const DisplayPrice = (props: DisplayPriceProps) => {
  const [amazonMarketplaceInfos] = useState(AmazonMarketplaceInfos());

  const getCurrencySymbol = useMemo((): string => {
    return amazonMarketplaceInfos.find((amazonMarketplaceInfo: any) => amazonMarketplaceInfo.currency === props.source)?.priceSign || "";
  }, [props.source, amazonMarketplaceInfos]);

  const formattingFn = useCallback((value: number) => formatCurrency(getCurrencySymbol, value, props.decimals, props.source), [getCurrencySymbol, props.decimals, props.source]);

  return (
    <div className={`text-nowrap ${props.className}`}>
      {props.source &&
        (props.countUp ? (
          <CountUp
            start={0}
            prefix={`${props.prefix || ""}${getCurrencySymbol}`}
            suffix={` ${props.source}${props.suffix || ""}`}
            decimals={props.decimals}
            duration={2}
            end={props.value || 0}
            formattingFn={formattingFn}
          />
        ) : (
          `${props.prefix || ""}${formatCurrency(getCurrencySymbol, props.value || 0, props.decimals, props.source)}${props.suffix || ""}`
        ))}
    </div>
  );
};

export default DisplayPrice;
