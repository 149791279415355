import {UserSearchProduct} from "models/user_search_product";
import {useTranslation} from "react-i18next";
import {Card, CardBody} from "reactstrap";

interface VariationAnalysisProps {
  item: UserSearchProduct;
}
const VariationAnalysis = ({item}: VariationAnalysisProps) => {
  const {t} = useTranslation();

  return (
    <Card className="border card-animate">
      <CardBody>
        <h5>{t("SearchResults.Analyzes.VariationAnalysis.Title")}</h5>
        <ul className="list-group d-flex flex-group gap-1">
          <li className="d-flex justify-content-between">
            <div>
              <span>{t("SearchResults.Analyzes.VariationAnalysis.Count")}</span>
            </div>
            {item.variationAnalysis?.variationCount ? (
              <span
                className={
                  item.variationAnalysis.variationCount < 2
                    ? "badge bg-success rounded-pill h-25 fs-10"
                    : item.variationAnalysis.variationCount < 5
                    ? "badge bg-warning rounded-pill h-25 fs-10"
                    : "badge bg-danger rounded-pill h-25 fs-10"
                }
              >
                {item.variationAnalysis.variationCount}
              </span>
            ) : (
              <span>{t("NA")}</span>
            )}
          </li>
          <li className="d-flex justify-content-between">
            <span>{t("SearchResults.Analyzes.VariationAnalysis.PriceDiffOtherVariations")}</span>
            <span>{item.variationAnalysis?.priceDiffRate ? "%" + item.variationAnalysis.priceDiffRate : t("NA")}</span>
          </li>
          <li className="d-flex justify-content-between">
            <span>{t("SearchResults.Analyzes.VariationAnalysis.FBASellerExist")}</span>
            {item.variationAnalysis?.isHasFBASeller ? (
              <span className={item.variationAnalysis.isHasFBASeller ? "text-danger" : "text-success"}>{item.variationAnalysis.isHasFBASeller ? t("Yes") : t("No")}</span>
            ) : (
              <span>{t("NA")}</span>
            )}
          </li>
          <li className="d-flex justify-content-between">
            <span>{t("SearchResults.Analyzes.VariationAnalysis.FBASellersOtherVariations")}</span>
            {item.variationAnalysis?.othersHasFBASeller ? (
              <span className={item.variationAnalysis.othersHasFBASeller ? "text-danger" : "text-success"}>{item.variationAnalysis.othersHasFBASeller ? t("Yes") : t("No")}</span>
            ) : (
              <span>{t("NA")}</span>
            )}
          </li>
        </ul>
      </CardBody>
    </Card>
  );
};
export default VariationAnalysis;
