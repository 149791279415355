import {UserInventory} from "./user_inventory";
import {UserShipment} from "./user_shipment";
import {UserStore} from "./user_stores";
import {UserOrderItemFinance} from "./user_order_item_finance";

export interface UserOrder {
  userOrderId: string;
  userId: string;
  userStoreId: string;
  amazonOrderId: string;
  purchaseDate: Date; // This field defined as string in database
  lastUpdateDate: string; // This field defined as string in database
  orderStatus: OrderStatus;
  fulfillmentChannel: FulfillmentChannel;
  orderTotal: number;
  numberOfItemsShipped: number;
  numberOfItemsUnshipped: number;
  shipmentServiceLevelCategory: string;
  orderType: OrderType;
  latestShipDate: string; // This field defined as string in database
  latestDeliveryDate: string; // This field defined as string in database
  isBusinessOrder: boolean;
  isPrime?: boolean;
  quantityOrdered?: number;
  totalCost?: number;
  totalCostTarget?: number;
  costIsPredicted?: boolean;
  marketplaceFacilitatorTax?: number;
  promotions?: number;
  totalFBAFee?: number;
  totalReferralFee?: number;
  totalOtherFees?: number;
  totalFees?: number;
  totalTax?: number;
  marketplaceTaxExceptionRate?: number;
  marketplaceTaxException?: number;
  totalSellPrice?: number;
  totalSalesProceeds?: number;
  profit?: number;
  margin?: number;
  roi?: number;
  currencyCodeSource?: string;
  currencyCodeTarget?: string;
  exchangeRateSource?: number;
  exchangeRateTarget?: number;
  stateSalesTaxRate?: number;
  includedShipmentIds: string;
  excludedShipmentIds: string;
  shipmentCompleted: boolean;
  createDate: Date;
  updateDate: Date;
  userStore: UserStore;
  userOrderItems?: UserOrderItem[];
  userShipments?: UserShipment[]; // additional
}

export interface UserOrderItem {
  userOrderItemId: string;
  userOrderId: string;
  userInventoryId: string;
  asin: string;
  sellerSKU: string; // Here defined as string in database
  orderItemId: string;
  title: string;
  quantityOrdered: FulfillmentChannel;
  quantityShipped: number;
  itemPrice: number;
  shippingPrice: number;
  itemTax: number;
  shippingTax: number;
  shippingDiscount: number;
  shippingDiscountTax: number;
  promotionDiscount: number;
  promotionDiscountTax: number;
  orderTotal: number;
  orderPerUnit: number;
  totalOtherFees: number;
  totalCost: number;
  totalFees: number;
  totalTax: number;
  marketplaceTaxException: number;
  totalSellPrice: number;
  sellPricePerUnit: number;
  totalSalesProceeds: number;
  salesProceedsPerUnit: number;
  profit: number;
  margin: number;
  roi: number;
  assignedShipmentItemCount: number;
  createDate: Date;
  updateDate: Date;
  userOrderItemFinance: UserOrderItemFinance;
  userInventory: UserInventory;
}

export enum OrderStatus {
  PENDING = 1,
  UNSHIPPED = 2,
  PARTIALLY_SHIPPED = 3,
  SHIPPED = 4,
  CANCELED = 5,
  UNFULFILLABLE = 6,
  INVOICE_UNCONFIRMED = 7,
  PENDING_AVAILABILITY = 8,
}

enum FulfillmentChannel {
  MFN = 1,
  AFN = 2,
}

enum OrderType {
  STANDARD_ORDER = 1,
  LONG_LEAD_TIME_ORDER = 2,
  PRE_ORDER = 3,
  BACK_ORDER = 4,
  SOURCING_ON_DEMAND_ORDER = 5,
}

export type OrderShipments = {
  userShipmentItemId: string;
  shipment: string;
  asin: string;
  quantity: number;
  status: string;
  availableCount: number;
};
