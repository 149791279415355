import {APIClient} from "helpers/api_helper";
import * as url from "../helpers/url_helper";
import {
  IGetUserShipmentsFromSearchResultsDto,
  ISaveNewUserShipmentDto,
  IEditUserShipmentDto,
  IDeleteUserShipmentDto,
  IGetUserShipmentDetailsDto,
  ISetUserShipmentDetailsDto,
  IAddUserShipmentItemDto,
  IDeleteUserShipmentItemDto,
  IRefreshUserShipmentItemDto,
  FilterShipmentsQuery,
} from "models/user_shipment";

const api = new APIClient();

export const postGetUserShipments = (data: FilterShipmentsQuery) => api.create(url.POST_GET_USER_SHIPMENTS, data);

export const postGetUserShipmentsFromSearchResults = (data: IGetUserShipmentsFromSearchResultsDto) => api.create(url.POST_GET_USER_SHIPMENTS_FROM_SEARCH_RESULTS, data);

export const postGetUserDraftShipments = (data: IGetUserShipmentsFromSearchResultsDto) => api.create(url.POST_GET_USER_DRAFT_SHIPMENTS, data);

export const postSaveNewUserShipment = (data: ISaveNewUserShipmentDto) => api.create(url.POST_SAVE_NEW_USER_SHIPMENT, data);

export const postEditUserShipment = (data: IEditUserShipmentDto) => api.create(url.POST_EDIT_USER_SHIPMENT, data);

export const postDeleteUserShipment = (data: IDeleteUserShipmentDto) => api.create(url.POST_DELETE_USER_SHIPMENT, data);

export const postGetUserShipmentDetails = (data: IGetUserShipmentDetailsDto) => api.create(url.POST_GET_USER_SHIPMENT_DETAILS, data);

export const postSetUserShipmentDetails = (data: ISetUserShipmentDetailsDto) => api.create(url.POST_SET_USER_SHIPMENT_DETAILS, data);

export const postAddUserShipmentItem = (data: IAddUserShipmentItemDto) => api.create(url.POST_ADD_USER_SHIPMENT_ITEM, data);

export const postDeleteUserShipmentItem = (data: IDeleteUserShipmentItemDto) => api.create(url.POST_DELETE_USER_SHIPMENT_ITEM, data);

export const postRefreshUserShipmentItem = (data: IRefreshUserShipmentItemDto) => api.create(url.POST_REFRESH_USER_SHIPMENT_ITEM, data);
