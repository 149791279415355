import {Badge, Card, CardBody} from "reactstrap";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {UserSearchProduct} from "models/user_search_product";
import {createSelector} from "reselect";
import {RootState} from "slices";
import {useSelector} from "react-redux";
import {preventScrollUp} from "helpers/utilities";
import DefaultUncontrolledTooltip from "Components/Common/DefaultUncontrolledTooltip";
import {SellerTypeBadge} from "./Components/SellerTypeBadge";

interface CompetitorAnalysisProps {
  item: UserSearchProduct;
}
const CompetitorAnalysis = ({item}: CompetitorAnalysisProps) => {
  const {t} = useTranslation();

  const searchResultsData = createSelector(
    (state: RootState) => state,
    (state) => ({
      pageUrl: state.SearchResult.pageUrl,
    }),
  );
  const {pageUrl} = useSelector(searchResultsData);

  return (
    <>
      <Card className="border card-animate">
        <CardBody>
          <h5>{t("SearchResults.Analyzes.CompetitorAnalysis.Title")}</h5>
          <ul className="list-group d-flex flex-group gap-1">
            <li className="d-flex justify-content-between">
              <span>{t("SearchResults.Analyzes.CompetitorAnalysis.Competition")}</span>
              {item.competitorAnalysis?.competitionLevel ? (
                <Badge color={item.competitorAnalysis.competitionLevel === "Weak" ? "success" : item.competitorAnalysis.competitionLevel === "Medium" ? "warning" : "danger"} className="h-25 fs-10">
                  {t(`${item.competitorAnalysis.competitionLevel}`)}
                  {item.competitorAnalysis.competitionLevel === "Weak" && <i className="mdi mdi-check ms-1"></i>}
                </Badge>
              ) : (
                <span>{t("NA")}</span>
              )}
            </li>
            <li className="d-flex justify-content-between">
              <div className="d-flex align-items-center">{t("SearchResults.Analyzes.CompetitorAnalysis.CurrentSellerType")}</div>
              <span>
                <SellerTypeBadge type={item.competitorAnalysis?.buyBoxType ?? null} hideIfNA={false} />
              </span>
            </li>
            <li className="d-flex justify-content-between">
              <div className="d-flex align-items-center">
                {item.competitorAnalysis?.fbaSellerCount ? (
                  <>
                    <Link className="link-secondary link-offset-2 text-decoration-underline" to={`${pageUrl}/fba-sellers?asin=${item.asin}`} onClick={() => preventScrollUp()}>
                      <span>{t("SearchResults.Analyzes.CompetitorAnalysis.FBASeller")}</span>
                    </Link>
                    <i id={`PopoverFBASellers-${item.userSearchProductId}`} className="ms-1 bx bxs-info-circle text-secondary"></i>
                    <DefaultUncontrolledTooltip target={`PopoverFBASellers-${item.userSearchProductId}`}>{t("SearchResults.Analyzes.CompetitorAnalysis.ClickForDetails")}</DefaultUncontrolledTooltip>
                  </>
                ) : (
                  <span>{t("SearchResults.Analyzes.CompetitorAnalysis.FBASeller")}</span>
                )}
              </div>
              <span>{item.competitorAnalysis?.fbaSellerCount ?? t("NA")}</span>
            </li>
            <li className="d-flex justify-content-between">
              <div className="d-flex align-items-center">
                <span>{t("Remote FBA Seller")}</span> <i id={`PopoverFBASellerCountRemote-${item.userSearchProductId}`} className="ms-1 bx bxs-info-circle text-secondary"></i>
                <DefaultUncontrolledTooltip target={`PopoverFBASellerCountRemote-${item.userSearchProductId}`}>{t("Definitions.RemoteFBA")}</DefaultUncontrolledTooltip>
              </div>
              <span>{item.competitorAnalysis?.fbaSellerCountRemote ?? t("NA")}</span>
            </li>
            <li className="d-flex justify-content-between">
              <span>{t("SearchResults.Analyzes.CompetitorAnalysis.FBMSeller")}</span>
              <span>{item.competitorAnalysis?.fbmSellerCount ?? t("NA")}</span>
            </li>
            <li className="d-flex justify-content-between">
              <span>{t("SearchResults.Analyzes.CompetitorAnalysis.IsAmazonExist")}</span>
              {item.competitorAnalysis?.isAmazonSell ? (
                <span className={item.competitorAnalysis.isAmazonSell ? "text-danger" : "text-success"}>{item.competitorAnalysis.isAmazonSell ? t("Yes") : t("No")}</span>
              ) : (
                <span>{t("NA")}</span>
              )}
            </li>
            <li className="d-flex justify-content-between">
              <div>
                <span>{t("SearchResults.Analyzes.CompetitorAnalysis.AmazonLastSeen")}</span>
              </div>
              <span>
                {item.competitorAnalysis?.lastAmazonSeenText
                  ? item.competitorAnalysis.lastAmazonSeenText
                      .replace("years", t("years"))
                      .replace("year", t("year"))
                      .replace("months", t("months"))
                      .replace("month", t("month"))
                      .replace("days", t("days"))
                      .replace("day", t("day"))
                      .replace("and", t("and"))
                      .replace("ago", t("ago"))
                  : t("NA")}
              </span>
            </li>
          </ul>
        </CardBody>
      </Card>
    </>
  );
};
export default CompetitorAnalysis;
