import {useProfile} from "Components/Hooks/UserHooks";
import { getMenuModeLocalData } from "helpers/local_storage";
import {getToday} from "helpers/utilities";
import {Navigate, Outlet, useLocation} from "react-router-dom";

const ProtectedRoutes = () => {
    const { userProfile } = useProfile();
    const location = useLocation();
    const hasSubscription = userProfile?.hasSubscription;
    const isTokenValid = userProfile && new Date(userProfile.tokenInfo.expiresAt) > getToday().toDate();
    const isAdmin = userProfile?.roles.includes("Admin");  
    const menuMode = getMenuModeLocalData();
    // If token is invalid
    if (!isTokenValid) {
      if(location.pathname !== "/") {
        return <Navigate to={`/account/login?return_url=${location.pathname}`} />
      }else {
        return <Navigate to="/account/login" />;
      }
    }
  
    if (!hasSubscription && location.pathname !== "/account/subscription/new") {
      return <Navigate to="/account/subscription/new" />;
    }

  
    // If user has subscription and is trying to access "/account/subscription/new"
    if (hasSubscription && location.pathname === "/account/subscription/new") {
      return <Navigate to="/" />;
    }
  
    if (isAdmin) {
      if (location.pathname === "/" || location.pathname === "/account/login") {
        return menuMode === "Admin" ? <Navigate to="/admin/system-monitoring" /> : <Navigate to="/dashboard" />;
      }
    } else {
      if (location.pathname === "/" || location.pathname === "/account/login") {
        return <Navigate to="/dashboard" />;
      }
    }
  
    // Redirect to target page
    return <Outlet />;
};

export default ProtectedRoutes;
