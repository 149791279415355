import SelectOptions, {SelectOptionsType} from "Components/Hooks/SelectOptions";
import {Button, Card, CardBody, Col, Form, Label, Row, Spinner} from "reactstrap";
import {useFormik} from "formik";
import {RefObject, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {UserSearchesQuery} from "models/user_search";
import {SearchesListRef} from "..";
import {getRelativeDateByTimezone, getToday} from "helpers/utilities";
import {useProfile} from "Components/Hooks/UserHooks";
import ValidateDate from "Components/Common/ValidatedDate";
import PlaceholderGlow from "Components/Common/PlaceholderGlow";
import moment from "moment";
import ValidatedMultiSelect from "Components/Common/ValidatedMultiSelect";
import * as Yup from "yup";

interface FiltersProps {
  listRef: RefObject<SearchesListRef>;
  busy: boolean;
  page: number;
  pageSize: number;
  handleFilter: (filter: UserSearchesQuery) => void;
  marketplaceSelectOptionsWithStores: SelectOptionsType[];
}
const Filters = (props: FiltersProps) => {
  const {t} = useTranslation();
  const {userProfile} = useProfile();
  const navigate = useNavigate();
  const {searchStatusSelectOptions, multiSelectTranslations} = SelectOptions();
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const start = queryParams.get("start");
    const end = queryParams.get("end");

    var payload: UserSearchesQuery = {
      page: 1,
      pageSize: props.pageSize,
      filtering: true,
    };
    if (start && end) {
      setStartDate(new Date(start));
      setEndDate(new Date(end));
      validation.setFieldValue("startDate", new Date(start));
      validation.setFieldValue("endDate", new Date(end));
      payload = {
        ...payload,
        startDate: moment
          .tz(start, userProfile?.timezone!)
          .startOf("day")
          .tz("Etc/GMT")
          .toDate(),
        endDate: moment
          .tz(end, userProfile?.timezone!)
          .endOf("day")
          .tz("Etc/GMT")
          .toDate(),
      };
    } else {
      setStartDate(getRelativeDateByTimezone(30, userProfile?.timezone));
      setEndDate(getToday().toDate());
      validation.setFieldValue("startDate", getRelativeDateByTimezone(30, userProfile?.timezone));
      validation.setFieldValue("endDate", getToday().toDate());
      payload = {
        ...payload,
        startDate: getRelativeDateByTimezone(30, userProfile?.timezone),
        endDate: getToday()
          .tz(userProfile?.timezone!)
          .endOf("day")
          .toDate(),
      };
    }

    props.handleFilter(payload);
  }, []); // eslint-disable-line

  // validation
  const validation = useFormik({
    initialValues: {
      startDate: startDate,
      endDate: endDate,
      marketplace: [] as string[],
      status: [] as string[],
    } as UserSearchesQuery,
    validationSchema: Yup.object<UserSearchesQuery>({
      startDate: Yup.date()
        .min(new Date("2023-01-01"), t("Searches.Filters.Validation.StartDateMin"))
        .max(Yup.ref("endDate"), t("Searches.Filters.Validation.StartDateMax"))
        .typeError(t("Searches.Filters.Validation.InvalidDateType")),
      endDate: Yup.date()
        .min(Yup.ref("startDate"), t("Searches.Filters.Validation.EndDateMin"))
        .max(getToday().toDate(), t("Searches.Filters.Validation.EndDateMax"))
        .typeError(t("Searches.Filters.Validation.InvalidDateType")),
      marketplace: Yup.array<String>().notRequired(),
      statusFilter: Yup.string().notRequired(),
    }),
    onSubmit: (values: UserSearchesQuery) => {
      const payload: UserSearchesQuery = {
        page: 1,
        pageSize: props.pageSize,
        startDate: moment
          .tz(values.startDate, userProfile?.timezone!)
          .startOf("day")
          .tz("Etc/GMT")
          .toDate(),
        endDate: moment
          .tz(values.endDate, userProfile?.timezone!)
          .tz(userProfile?.timezone!)
          .endOf("day")
          .toDate(),
        marketplace: values.marketplace || undefined,
        status: values.status || undefined,
        filtering: true,
      };
      props.handleFilter(payload);
    },
  });

  return (
    <PlaceholderGlow busy={props.busy}>
      <Card style={{zIndex: 4}}>
        <CardBody>
          <div className="live-preview">
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              <Row className="align-items-center">
                <Col>
                  <div className="flex-shrink-0">
                    <Label className="form-label text-muted">{t("Searches.Filters.Title")}</Label>
                  </div>
                </Col>
                <Col xs="auto" align="right">
                  <Button color="warning" className="me-2" onClick={() => navigate("/favorites")}>
                    <i className="ri-star-line align-bottom me-1"></i>
                    {t("Searches.Filters.Button.Favorites")}
                  </Button>
                  <Button type="button" color="info" disabled={props.busy} onClick={() => navigate("/searches/new")}>
                    <i className="ri-add-line align-bottom me-1"></i>
                    {t("Searches.Filters.Button.NewSearch")}
                  </Button>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col sm={2} className="mb-3 searches filters-col">
                  <Label htmlFor="startDate">{t("Searches.Filters.StartDate")}</Label>
                  <ValidateDate field="startDate" validation={validation} value={validation.values.startDate}></ValidateDate>
                </Col>
                <Col sm={2} className="mb-3 searches filters-col">
                  <Label htmlFor="endDate">{t("Searches.Filters.EndDate")}</Label>
                  <ValidateDate field="endDate" validation={validation} value={validation.values.endDate}></ValidateDate>
                </Col>
                <Col sm={2} className="mb-3 searches filters-col">
                  <Label htmlFor="marketplace">{t("Searches.Filters.Marketplace")}</Label>
                  <ValidatedMultiSelect
                    className="common-css filter-input"
                    options={props.marketplaceSelectOptionsWithStores}
                    validation={validation}
                    field="marketplace"
                    value={validation.values.marketplace}
                    translations={{
                      ...multiSelectTranslations,
                      selectSomeItems: t("General.Select.SelectStore")
                    }}
                  />
                </Col>
                <Col sm={2} className="mb-3 searches filters-col">
                  <Label htmlFor="status">{t("Searches.Filters.Status")}</Label>
                  <ValidatedMultiSelect
                    className="common-css filter-input"
                    options={searchStatusSelectOptions}
                    validation={validation}
                    field="status"
                    value={validation.values.status}
                  />
                </Col>
                <Col sm={2}>
                  <div className="mt-2">
                    <Button type="submit" className="btn bg-primary" disabled={props.busy}>
                      {props.busy ? <Spinner size="sm" className="me-2"></Spinner> : <i className="ri-equalizer-fill me-1 align-bottom"></i>}
                      {t("Searches.Filters.Button.Apply")}
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
          </div>
        </CardBody>
      </Card>
    </PlaceholderGlow>
  );
};

export default Filters;
