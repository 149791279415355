import {APIClient} from "helpers/api_helper";
import {IDeleteUserStoreDto, IDisconnectUserStoreDto, ISetUserStoreNameDto} from "models/user_stores";
import {FilterInventoryQuery, FilterOrderQuery, GetStatisticsQuery, GetUserOrderDetailsQuery} from "api/query";
import {ArchiveUserInventoryCommand, DownloadInventoryCommand, ReassignUserOrderShipmentCommand, UpdateInventoryCommand, UpdateUserInventoryCostCommand, UpdateUserOrderCostCommand} from "api/command";
import * as url from "../helpers/url_helper";

const api = new APIClient();

export const postGetUserStores = () => api.create(url.POST_GET_USER_STORES, []);

export const postSetUserStoreName = (data: ISetUserStoreNameDto) => api.create(url.POST_SET_USER_STORE_NAME, data);

export const postDisconnectUserStore = (data: IDisconnectUserStoreDto) => api.create(url.POST_DISCONNECT_USER_STORE, data);

export const postDeleteUserStore = (data: IDeleteUserStoreDto) => api.create(url.POST_DELETE_USER_STORE, data);

export const postGetUserInventories = (data: FilterInventoryQuery) => api.create(url.POST_GET_USER_INVENTORIES, data);

export const postArchiveInventories = (data: ArchiveUserInventoryCommand) => api.create(url.POST_ARCHIVE_INVENTORIES, data);

export const postUnarchiveInventories = (data: ArchiveUserInventoryCommand) => api.create(url.POST_UNARCHIVE_INVENTORIES, data);

export const postUpdateInventoryCost = (data: UpdateUserInventoryCostCommand) => api.create(url.POST_UPDATE_INVENTORY_COST, data);

export const postUpdateInventory = (data: UpdateInventoryCommand) => api.create(url.POST_UPDATE_INVENTORY, data);

export const postDownloadInventory = (data: DownloadInventoryCommand) => api.create(url.POST_DOWNLOAD_INVENTORY, data);

export const postGetUserOrders = (data: FilterOrderQuery) => api.create(url.POST_GET_USER_ORDERS, data);

export const postGetUserOrderDetails = (data: GetUserOrderDetailsQuery) => api.create(url.POST_GET_USER_ORDER_DETAILS, data);

export const postUpdateUserOrderCost = (data: UpdateUserOrderCostCommand) => api.create(url.POST_UPDATE_USER_ORDER_COST, data);

export const postReassignUserOrderShipment = (data: ReassignUserOrderShipmentCommand) => api.create(url.POST_REASSIGN_USER_ORDER_SHIPMENT, data);

export const postGetDashboardStatistics = (data: GetStatisticsQuery) => api.create(url.POST_GET_DASHBOARD_STATISTICS, data);
