import React, {useEffect, useState} from "react";
import AmazonMarketplaceInfos, {AmazonMarketplaceInfosType} from "Components/Common/AmazonMarketplaceInfos";
import {useTranslation} from "react-i18next";
import {Card, CardBody, CardHeader, Col, FormFeedback, Input, InputGroup, InputGroupText, Row} from "reactstrap";
import {createSelector} from "reselect";
import {ISetUserShipmentDetailsDto} from "models/user_shipment";
import {useDispatch, useSelector} from "react-redux";
import {setUserShipmentDetails} from "slices/thunks";
import {UserShipmentFinance} from "models/user_shipment_finance";
import {RootState} from "slices";
import {useFormik} from "formik";
import ChangeShipmentStatus from "./Modals/ChangeShipmentStatus";
import ShipmentItems from "./_ShipmentItems";
import languages from "../../../../common/language";
import * as Yup from "yup";
import _, {get} from "lodash";
import DisplayPrice from "Components/Common/DisplayPrice";

const ShipmentFinance = () => {
  const {t} = useTranslation();
  const dispatch: any = useDispatch();
  const currentLanguage: any = localStorage.getItem("I18N_LANGUAGE");

  let currentLocale = get(languages, `${currentLanguage}.locale`);

  const [amazonMarketplaceInfos] = useState(AmazonMarketplaceInfos());
  const [sourceMarketplace, setSourceMarketplace] = useState<AmazonMarketplaceInfosType>();
  const [destinationMarketplace, setDestinationMarketplace] = useState<AmazonMarketplaceInfosType>();

  const shipmentsData = createSelector(
    (state: RootState) => state,
    (state) => ({
      currentUserShipment: state.Shipment.currentUserShipment,
    }),
  );

  const {currentUserShipment} = useSelector(shipmentsData);
  useEffect(() => {
    if (currentUserShipment) {
      let source = amazonMarketplaceInfos.find((amazonMarketplaceInfo: any) => amazonMarketplaceInfo.marketplace === "US");
      let destination = amazonMarketplaceInfos.find((amazonMarketplaceInfo: any) => amazonMarketplaceInfo.marketplace === currentUserShipment.userStore.marketplace);
      setSourceMarketplace(source);
      setDestinationMarketplace(destination);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUserShipment]);

  const validation: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      actualPurchasePrice: currentUserShipment.userShipmentFinance.actualPurchasePrice.toFixed(2) || "",
      actualShippingPrice: currentUserShipment.userShipmentFinance.actualShippingPrice.toFixed(2) || "",
      actualTax: currentUserShipment.userShipmentFinance.actualTax.toFixed(2) || "",
      actualDiscount: currentUserShipment.userShipmentFinance.actualDiscount.toFixed(2) || "",
    },
    validationSchema: Yup.object().shape({
      actualPurchasePrice: Yup.number()
        .required(t("FieldRequired"))
        .min(0, t("FieldNonNegativeError"))
        .typeError(t("Must be number")),
      actualShippingPrice: Yup.number()
        .required(t("FieldRequired"))
        .min(0, t("FieldNonNegativeError"))
        .typeError(t("Must be number")),
      actualTax: Yup.number()
        .required(t("FieldRequired"))
        .min(0, t("FieldNonNegativeError"))
        .typeError(t("Must be number")),
      actualDiscount: Yup.number()
        .required(t("FieldRequired"))
        .min(0, t("FieldNonNegativeError"))
        .typeError(t("Must be number")),
    }),
    onSubmit: (values: any) => {},
  });

  useEffect(() => {
    validation.resetForm();

    validation.initialValues = {
      actualPurchasePrice: currentUserShipment.userShipmentFinance.actualPurchasePrice.toFixed(2),
      actualShippingPrice: currentUserShipment.userShipmentFinance.actualShippingPrice.toFixed(2),
      actualTax: currentUserShipment.userShipmentFinance.actualTax.toFixed(2),
      actualDiscount: currentUserShipment.userShipmentFinance.actualDiscount.toFixed(2),
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUserShipment]);

  const handleInputBlur = (input: any) => {
    validation.handleBlur(input);
    if (validation.isValid) {
      let inputVal = parseFloat(input.target.value);
      const name: keyof UserShipmentFinance = input.target.name;
      let existVal = parseFloat(currentUserShipment.userShipmentFinance[name] as string);
      if (!isNaN(inputVal) && !isNaN(existVal) && inputVal !== existVal) {
        let newShipmentDetails: ISetUserShipmentDetailsDto = {
          userShipmentId: currentUserShipment.userShipmentId,
          userInventoryId: "",
          userShipmentItemId: "",
          field: _.upperFirst(input.target.name),
          newValue: inputVal,
          shipDate: "",
          amazonShipmentId: "",
          from: "Shipments",
        };

        setUserShipmentDetails(newShipmentDetails, "update")(dispatch);
      }
    }
  };

  return (
    sourceMarketplace &&
    destinationMarketplace && (
      <React.Fragment>
        <Row>
          <Col xxl={3} sm={6} className="same-height">
            <div className="p-2 border card card-animate rounded">
              <div className="d-flex align-items-center">
                <div className="avatar-sm me-2 mb-3">
                  <div className="avatar-title rounded bg-transparent text-secondary fs-24">
                    <i className="ri-numbers-fill"></i>
                  </div>
                </div>
                <div className="flex-grow-1 me-3 my-1">
                  <p className="text-muted mb-2 fs-18">{t("Shipments.ShipmentDetails.ItemsCount")}</p>
                  <h2 className="mb-0">{currentUserShipment.totalItems.toLocaleString()}</h2>
                </div>
              </div>
            </div>
          </Col>
          <Col xxl={3} sm={6} className="same-height">
            <div className="p-2 border card card-animate rounded">
              <div className="d-flex align-items-center">
                <div className="avatar-sm me-2 mb-3">
                  <div className="avatar-title rounded bg-transparent text-danger fs-24">
                    <i className="ri-shopping-cart-2-fill"></i>
                  </div>
                </div>
                <div className="flex-grow-1 me-3 my-1">
                  <p className="text-muted mb-2 fs-18">{t("Shipments.ShipmentDetails.SoldItems")}</p>
                  <h2 className="mb-0">{currentUserShipment.soldItems.toLocaleString()}</h2>
                </div>
              </div>
            </div>
          </Col>
          <Col xxl={3} sm={6} className="same-height">
            <div className="p-2 border card card-animate rounded">
              <div className="d-flex align-items-center">
                <div className="avatar-sm me-2 mb-3">
                  <div className="avatar-title rounded bg-transparent text-info fs-24">
                    <i className="ri-pie-chart-fill"></i>
                  </div>
                </div>
                <div className="flex-grow-1 me-3 my-1">
                  <p className="text-muted mb-2 fs-18">{t("Shipments.ShipmentDetails.ItemsLeft")}</p>
                  <h2 className="mb-0">{(currentUserShipment.totalItems - currentUserShipment.soldItems).toLocaleString()}</h2>
                </div>
              </div>
            </div>
          </Col>
          <Col xxl={3} sm={6} className="same-height">
            <div className="p-2 border card card-animate rounded">
              <div className="d-flex align-items-center">
                <div className="avatar-sm me-2 mb-3">
                  <div className="avatar-title rounded bg-transparent text-success fs-24">
                    <i className="ri-price-tag-3-fill"></i>
                  </div>
                </div>
                <div className="flex-grow-1 me-3 my-1">
                  <p className="text-muted mb-2 fs-18">{t("Shipments.ShipmentDetails.SKUCount")}</p>
                  <h2 className="mb-0">{currentUserShipment.totalSKU.toLocaleString()}</h2>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xxl={3} sm={6} className="same-height">
            <Card className="card-animate border">
              <CardHeader>
                <div className="d-flex justify-content-between align-items-center">
                  <h6 className="card-title mb-0">{t("Shipments.ShipmentDetails.ExpectedPurchaseCost")}</h6>
                  <div className="avatar-xs flex-shrink-0 common-css">
                    <span className={"avatar-title rounded-circle fs-4 bg-secondary-subtle text-secondary"}>
                      <i className="ri-funds-line"></i>
                    </span>
                  </div>
                </div>
              </CardHeader>
              <CardBody className="d-flex flex-column">
                <ul className="list-group">
                  <li className="d-flex justify-content-between mb-1">
                    <span>{t("Shipments.ShipmentDetails.BuyPrice")}</span>
                    <span>
                      <DisplayPrice source={sourceMarketplace?.currency} value={currentUserShipment.userShipmentFinance.expectedPurchasePrice} decimals={2} />
                    </span>
                  </li>
                  <li className="d-flex justify-content-between mb-1">
                    <span>{t("Shipments.ShipmentDetails.Tax")}</span>
                    <span>
                      <DisplayPrice source={sourceMarketplace?.currency} value={currentUserShipment.userShipmentFinance.expectedTax} decimals={2} />
                    </span>
                  </li>
                  <li className="d-flex justify-content-between">
                    <span>{t("Shipments.ShipmentDetails.Prep&Ship")}</span>
                    <span>
                      <DisplayPrice source={sourceMarketplace?.currency} value={currentUserShipment.userShipmentFinance.expectedShippingPrice} decimals={2} />
                    </span>
                  </li>
                </ul>
                <ul className="list-group mt-auto">
                  <hr></hr>
                  <li className="d-flex justify-content-between mb-1">
                    <span>{t("Total")}</span>
                    <span>
                      <DisplayPrice source={sourceMarketplace?.currency} value={currentUserShipment.userShipmentFinance.expectedTotalCost} decimals={2} />
                    </span>
                  </li>
                  <li className="d-flex justify-content-between mb-1">
                    <span></span>
                    <span>
                      <DisplayPrice source={destinationMarketplace?.currency} value={currentUserShipment.userShipmentFinance.expectedTotalCostTarget} decimals={2} />
                    </span>
                  </li>
                  <li className="d-flex justify-content-between mt-3 fs-11">
                    <span></span>
                    <span className="text-muted">
                      <em>{t("Exchange Rate") + ": " + currentUserShipment.userShipmentFinance.exchangeRateTarget}</em>
                    </span>
                  </li>
                </ul>
              </CardBody>
            </Card>
          </Col>
          <Col xxl={3} sm={6} className="same-height">
            <Card className="card-animate border shipment-finance-items">
              <CardHeader>
                <div className="d-flex justify-content-between align-items-center">
                  <h6 className="card-title mb-0">{t("Shipments.ShipmentDetails.ActualPurchaseCost")}</h6>
                  <div className="avatar-xs flex-shrink-0 common-css">
                    <span className={"avatar-title rounded-circle fs-4 bg-danger-subtle text-danger"}>
                      <i className="ri-exchange-funds-line"></i>
                    </span>
                  </div>
                </div>
              </CardHeader>
              <CardBody className="d-flex flex-column">
                <ul className="list-group">
                  <li className="d-flex justify-content-between mb-1">
                    <span>{t("Shipments.ShipmentDetails.BuyPrice")}</span>
                    <span>
                      {currentUserShipment.userShipmentItems.length !== 0 ? (
                        <>
                          <InputGroup className="shipment-details input-group-size" size="sm">
                            <Input
                              type="text"
                              className="form-control form-control-sm border-input-group"
                              size={20}
                              name="actualPurchasePrice"
                              id="actualPurchasePrice"
                              onChange={validation.handleChange}
                              onBlur={handleInputBlur}
                              value={validation.values.actualPurchasePrice}
                              invalid={validation.touched.actualPurchasePrice && validation.errors.actualPurchasePrice ? true : false}
                            />
                            <InputGroupText style={{background: "var(--vz-input-bg-custom)"}}>{sourceMarketplace?.currency}</InputGroupText>
                          </InputGroup>
                          {validation.touched.actualPurchasePrice && validation.errors.actualPurchasePrice ? (
                            <div className="text-end">
                              <FormFeedback type="invalid" className="shipment-details validation">
                                {validation.errors.actualPurchasePrice}
                              </FormFeedback>
                            </div>
                          ) : null}
                        </>
                      ) : (
                        currentUserShipment.userShipmentFinance.actualPurchasePrice.toLocaleString(currentLocale, {
                          style: "currency",
                          currency: sourceMarketplace?.currency,
                        })
                      )}
                    </span>
                  </li>
                  <li className="d-flex justify-content-between mb-1">
                    <span>{t("Shipments.ShipmentDetails.Tax")}</span>
                    <span>
                      {currentUserShipment.userShipmentItems.length !== 0 ? (
                        <>
                          <InputGroup className="shipment-details input-group-size" size="sm">
                            <Input
                              type="text"
                              className="form-control form-control-sm border-input-group"
                              name="actualTax"
                              id="actualTax"
                              onChange={validation.handleChange}
                              onBlur={handleInputBlur}
                              value={validation.values.actualTax}
                              invalid={validation.touched.actualTax && validation.errors.actualTax ? true : false}
                            />
                            <InputGroupText style={{background: "var(--vz-input-bg-custom)"}}>{sourceMarketplace?.currency}</InputGroupText>
                          </InputGroup>
                          {validation.touched.actualTax && validation.errors.actualTax ? (
                            <div className="text-end">
                              <FormFeedback type="invalid" className="shipment-details validation">
                                {validation.errors.actualTax}
                              </FormFeedback>
                            </div>
                          ) : null}
                        </>
                      ) : (
                        currentUserShipment.userShipmentFinance.actualTax.toLocaleString(currentLocale, {
                          style: "currency",
                          currency: sourceMarketplace?.currency,
                        })
                      )}
                    </span>
                  </li>
                  <li className="d-flex justify-content-between mb-1">
                    <span>{t("Shipments.ShipmentDetails.Prep&Ship")}</span>
                    <span>
                      {currentUserShipment.userShipmentItems.length !== 0 ? (
                        <>
                          <InputGroup className="shipment-details input-group-size" size="sm">
                            <Input
                              type="text"
                              className="form-control form-control-sm border-input-group"
                              name="actualShippingPrice"
                              id="actualShippingPrice"
                              onChange={validation.handleChange}
                              onBlur={handleInputBlur}
                              value={validation.values.actualShippingPrice}
                              invalid={validation.touched.actualShippingPrice && validation.errors.actualShippingPrice ? true : false}
                            />
                            <InputGroupText style={{background: "var(--vz-input-bg-custom)"}}>{sourceMarketplace?.currency}</InputGroupText>
                          </InputGroup>
                          {validation.touched.actualShippingPrice && validation.errors.actualShippingPrice ? (
                            <div className="text-end">
                              <FormFeedback type="invalid" className="shipment-details validation">
                                {validation.errors.actualShippingPrice}
                              </FormFeedback>
                            </div>
                          ) : null}
                        </>
                      ) : (
                        currentUserShipment.userShipmentFinance.actualShippingPrice.toLocaleString(currentLocale, {
                          style: "currency",
                          currency: sourceMarketplace?.currency,
                        })
                      )}
                    </span>
                  </li>
                  <li className="d-flex justify-content-between">
                    <span>{t("Shipments.ShipmentDetails.Discount")}</span>
                    <span>
                      {currentUserShipment.userShipmentItems.length !== 0 ? (
                        <>
                          <InputGroup className="shipment-details input-group-size" size="sm">
                            <Input
                              type="text"
                              className="form-control form-control-sm border-input-group"
                              name="actualDiscount"
                              id="actualDiscount"
                              onChange={validation.handleChange}
                              onBlur={handleInputBlur}
                              value={validation.values.actualDiscount}
                              invalid={validation.touched.actualDiscount && validation.errors.actualDiscount ? true : false}
                            />
                            <InputGroupText style={{background: "var(--vz-input-bg-custom)"}}>{sourceMarketplace?.currency}</InputGroupText>
                          </InputGroup>
                          {validation.touched.actualDiscount && validation.errors.actualDiscount ? (
                            <div className="text-end">
                              <FormFeedback type="invalid" className="shipment-details validation">
                                {validation.errors.actualDiscount}
                              </FormFeedback>
                            </div>
                          ) : null}
                        </>
                      ) : (
                        currentUserShipment.userShipmentFinance.actualDiscount.toLocaleString(currentLocale, {
                          style: "currency",
                          currency: sourceMarketplace?.currency,
                        })
                      )}
                    </span>
                  </li>
                </ul>
                <ul className="list-group mt-auto">
                  <hr></hr>
                  <li className="d-flex justify-content-between">
                    <span>{t("Total")}</span>
                    <span>
                      <DisplayPrice source={sourceMarketplace?.currency} value={currentUserShipment.userShipmentFinance.actualTotalCost} decimals={2} />
                    </span>
                  </li>
                  <li className="d-flex justify-content-between">
                    <span></span>
                    <span>
                      <DisplayPrice source={destinationMarketplace?.currency} value={currentUserShipment.userShipmentFinance.actualTotalCostTarget} decimals={2} />
                    </span>
                  </li>
                  <li className="d-flex justify-content-between mt-3 fs-11">
                    <span></span>
                    <span className="text-muted">
                      <em>{t("Exchange Rate") + ": " + currentUserShipment.userShipmentFinance.exchangeRateTarget}</em>
                    </span>
                  </li>
                </ul>
              </CardBody>
            </Card>
          </Col>
          <Col xxl={3} sm={6} className="same-height">
            <Card className="card-animate border">
              <CardHeader>
                <div className="d-flex justify-content-between align-items-center">
                  <h6 className="card-title mb-0">{t("Fees")}</h6>
                  <div className="avatar-xs flex-shrink-0 common-css">
                    <span className={"avatar-title rounded-circle fs-4 bg-info-subtle text-info"}>
                      <i className="ri-bank-line"></i>
                    </span>
                  </div>
                </div>
              </CardHeader>
              <CardBody className="d-flex flex-column">
                <ul className="list-group">
                  <li className="d-flex justify-content-between mb-1">
                    <span>{t("Shipments.ShipmentDetails.FBAFees")}</span>
                    <span>
                      <DisplayPrice source={destinationMarketplace?.currency} value={currentUserShipment.userShipmentFinance.fbaFees} decimals={2} />
                    </span>
                  </li>
                  <li className="d-flex justify-content-between mb-1">
                    <span>{t("Shipments.ShipmentDetails.ReferralFees")}</span>
                    <span>
                      <DisplayPrice source={destinationMarketplace?.currency} value={currentUserShipment.userShipmentFinance.referralFees} decimals={2} />
                    </span>
                  </li>
                  <li className="d-flex justify-content-between mb-1">
                    <span>{t("Shipments.ShipmentDetails.OtherFees")}</span>
                    <span>
                      <DisplayPrice source={destinationMarketplace?.currency} value={currentUserShipment.userShipmentFinance.otherFees} decimals={2} />
                    </span>
                  </li>
                </ul>
                <ul className="list-group mt-auto">
                  <hr></hr>
                  <li className="d-flex justify-content-between">
                    <span>{t("Total")}</span>
                    <span>
                      <DisplayPrice source={destinationMarketplace?.currency} value={currentUserShipment.userShipmentFinance.totalFees} decimals={2} />
                    </span>
                  </li>
                </ul>
              </CardBody>
            </Card>
          </Col>
          <Col xxl={3} sm={6} className="same-height">
            <Card className="card-animate border">
              <CardHeader>
                <div className="d-flex justify-content-between align-items-center">
                  <h6 className="card-title mb-0">{t("Shipments.ShipmentDetails.ShipmentProfit")}</h6>
                  <div className="avatar-xs flex-shrink-0 common-css">
                    <span className={"avatar-title rounded-circle fs-4 bg-success-subtle text-success"}>
                      <i className="ri-exchange-dollar-line"></i>
                    </span>
                  </div>
                </div>
              </CardHeader>
              <CardBody className="d-flex flex-column">
                <ul className="list-group">
                  <li className="d-flex justify-content-between mb-1">
                    <span>{t("Shipments.ShipmentDetails.TotalRevenue")}</span>
                    <span>
                      <DisplayPrice source={destinationMarketplace?.currency} value={currentUserShipment.userShipmentFinance.totalRevenue} decimals={2} />
                    </span>
                  </li>
                  <li className="d-flex justify-content-between mb-1">
                    <span>{t("Shipments.ShipmentDetails.TotalPurchaseCost")}</span>
                    <span>
                      <DisplayPrice source={destinationMarketplace?.currency} value={currentUserShipment.userShipmentFinance.actualTotalCostTarget} decimals={2} />
                    </span>
                  </li>
                  <li className="d-flex justify-content-between">
                    <span>{t("Shipments.ShipmentDetails.TotalFees")}</span>
                    <span>
                      <DisplayPrice source={destinationMarketplace?.currency} value={currentUserShipment.userShipmentFinance.totalFees} decimals={2} />
                    </span>
                  </li>
                </ul>
                <ul className="list-group mt-auto">
                  <hr></hr>
                  <li className="d-flex justify-content-between mb-1">
                    <span>{t("Shipments.ShipmentDetails.ExpectedProfit")}</span>
                    <span>
                      <DisplayPrice source={destinationMarketplace?.currency} value={currentUserShipment.userShipmentFinance.expectedProfit} decimals={2} />
                    </span>
                  </li>
                  <li className="d-flex justify-content-between mb-1">
                    <span>{t("Shipments.ShipmentDetails.ExpectedProfitPerItem")}</span>
                    <span>
                      <DisplayPrice source={destinationMarketplace?.currency} value={currentUserShipment.userShipmentFinance.expectedProfitPerItem} decimals={2} />
                    </span>
                  </li>
                  <li className="d-flex justify-content-between mb-1">
                    <span>{t("Shipments.ShipmentDetails.ExpectedMargin")}</span>
                    <span>%{currentUserShipment.userShipmentFinance.expectedMargin}</span>
                  </li>
                  <li className="d-flex justify-content-between">
                    <span>{t("Shipments.ShipmentDetails.ExpectedROI")}</span>
                    <span>%{currentUserShipment.userShipmentFinance.expectedROI}</span>
                  </li>
                </ul>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <ShipmentItems />

        {currentUserShipment.userShipmentItems.length !== 0 ? (
          <div className="hstack gap-2 justify-content-end">
            <ChangeShipmentStatus userShipment={currentUserShipment} />
          </div>
        ) : (
          ""
        )}
      </React.Fragment>
    )
  );
};

export default ShipmentFinance;
