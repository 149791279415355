import React from "react";
import getChartColorsArray from "../ChartsDynamicColor";
import ReactEcharts from "echarts-for-react";

const PriceHistoryChart = ({dataColors}: any) => {
  var chartStepLineColors = getChartColorsArray(dataColors);
  var option = {
    tooltip: {
      trigger: "axis",
    },
    legend: {
      data: [""],
      textStyle: {
        //The style of the legend text
        color: "#858d98",
      },
    },
    grid: {
      left: "0%",
      right: "0%",
      bottom: "0%",
      containLabel: true,
    },
    toolbox: {
      feature: {
        saveAsImage: {},
      },
    },
    xAxis: {
      type: "category",
      data: ["Aug 22", "Aug 23", "Aug 24", "Aug 26", "Aug 27", "Aug 28", "Aug 29"],
      axisLine: {
        lineStyle: {
          color: "#858d98",
        },
      },
    },
    yAxis: {
      type: "value",
      axisLine: {
        lineStyle: {
          color: "#858d98",
        },
      },
      splitLine: {
        lineStyle: {
          color: "rgba(133, 141, 152, 0.1)",
        },
      },
    },
    textStyle: {
      fontFamily: "Poppins, sans-serif",
    },
    color: chartStepLineColors,
    series: [
      {
        name: "Price",
        type: "line",
        step: "start",
        data: [120, 132, 101, 134, 90, 230, 210],
      },
    ],
  };

  return (
    <React.Fragment>
      <ReactEcharts style={{height: "250px"}} option={option} />
    </React.Fragment>
  );
};

const SellerHistoryChart = ({dataColors}: any) => {
  var chartStepLineColors = getChartColorsArray(dataColors);
  var option = {
    tooltip: {
      trigger: "axis",
    },
    legend: {
      data: ["FBA", "FBM", "Amazon"],
      textStyle: {
        //The style of the legend text
        color: "#858d98",
      },
    },
    grid: {
      left: "0%",
      right: "0%",
      bottom: "0%",
      containLabel: true,
    },
    toolbox: {
      feature: {
        saveAsImage: {},
      },
    },
    xAxis: {
      type: "category",
      data: ["Aug 22", "Aug 23", "Aug 24", "Aug 26", "Aug 27", "Aug 28", "Aug 29"],
      axisLine: {
        lineStyle: {
          color: "#858d98",
        },
      },
    },
    yAxis: {
      type: "value",
      axisLine: {
        lineStyle: {
          color: "#858d98",
        },
      },
      splitLine: {
        lineStyle: {
          color: "rgba(133, 141, 152, 0.1)",
        },
      },
    },
    textStyle: {
      fontFamily: "Poppins, sans-serif",
    },
    color: chartStepLineColors,
    series: [
      {
        name: "FBA",
        type: "line",
        step: "start",
        data: [120, 132, 101, 134, 90, 230, 210],
      },
      {
        name: "FBM",
        type: "line",
        step: "middle",
        data: [220, 282, 201, 234, 290, 430, 410],
      },
      {
        name: "Amazon",
        type: "line",
        step: "end",
        data: [450, 432, "", 454, 590, 530, 510],
      },
    ],
  };

  return (
    <React.Fragment>
      <ReactEcharts className="h-100" option={option} />
    </React.Fragment>
  );
};

const BSRHistoryChart = ({dataColors}: any) => {
  var chartStepLineColors = getChartColorsArray(dataColors);
  var option = {
    tooltip: {
      trigger: "axis",
    },
    legend: {
      data: [""],
      textStyle: {
        //The style of the legend text
        color: "#858d98",
      },
    },
    grid: {
      left: "0%",
      right: "0%",
      bottom: "0%",
      containLabel: true,
    },
    toolbox: {
      feature: {
        saveAsImage: {},
      },
    },
    xAxis: {
      type: "category",
      data: ["Aug 22", "Aug 23", "Aug 24", "Aug 26", "Aug 27", "Aug 28", "Aug 29"],
      axisLine: {
        lineStyle: {
          color: "#858d98",
        },
      },
    },
    yAxis: {
      type: "value",
      axisLine: {
        lineStyle: {
          color: "#858d98",
        },
      },
      splitLine: {
        lineStyle: {
          color: "rgba(133, 141, 152, 0.1)",
        },
      },
    },
    textStyle: {
      fontFamily: "Poppins, sans-serif",
    },
    color: chartStepLineColors,
    series: [
      {
        name: "Price",
        type: "line",
        step: "start",
        data: [120, 132, 101, 134, 90, 230, 210],
      },
    ],
  };

  return (
    <React.Fragment>
      <ReactEcharts style={{height: "350px"}} option={option} />
    </React.Fragment>
  );
};

export {PriceHistoryChart, SellerHistoryChart, BSRHistoryChart};
