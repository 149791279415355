import React, {useCallback, useEffect, useState} from "react";
import {Modal, ModalHeader, ModalBody, Button, ModalFooter, Col, Input, Row} from "reactstrap";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "reselect";
import {RootState} from "slices";
import {useNavigate} from "react-router-dom";
import {UserSearchProduct} from "models/user_search_product";
import {preventScrollUp} from "helpers/utilities";
import {setUserShowCheckListOptionChoice} from "slices/search-result2/thunk";
import CheckListItem from "./Components/CheckListItem";

interface ShowChecklistProps {
  isOpen: boolean;
}

const CheckList = ({isOpen}: ShowChecklistProps) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [product, setProduct] = useState<UserSearchProduct>();
  const searchResultsData = createSelector(
    (state: RootState) => state,
    (state) => ({
      loading: state.SearchResult.loading,
      result: state.SearchResult.result,
      pageUrl: state.SearchResult.pageUrl,
    }),
  );
  const {loading, result, pageUrl} = useSelector(searchResultsData);

  const initModal = useCallback(() => {
    if (isOpen && pageUrl) {
      const queryParams = new URLSearchParams(window.location.search);
      const asin = queryParams.get("asin");

      const product = result.products.items?.find((x) => x.asin === asin);
      if (product) {
        setProduct(product);
      } else {
        navigate(pageUrl);
      }
    }
  }, [isOpen, pageUrl]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    initModal();
  }, [initModal]);

  const updateShowCheckListOption = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserShowCheckListOptionChoice(!event.target.checked as boolean)(dispatch);
  };

  const toggle = () => {
    navigate(pageUrl);
    preventScrollUp();
  };

  return (
    <Modal backdrop="static" isOpen={isOpen} toggle={toggle} fade={true} centered={true}>
      <ModalHeader className="bg-light p-3" toggle={toggle}>
        {t("SearchResults.Dialog.ShowCheckList.Title")}
      </ModalHeader>
      <ModalBody>
        <ul className="list-unstyled vstack gap-1 mb-0">
          <h5>{t("SearchResults.Dialog.ShowCheckList.Description")}</h5>
          {product?.checkList?.sameProductCheckResult !== undefined && (
            <CheckListItem labelName={t("SearchResults.Analyzes.CheckList.SameProduct")} status={product?.checkList?.sameProductCheckResult === 1 ? undefined : null}></CheckListItem>
          )}
          {(product?.checkList?.customsCheckCategoryCheckResult !== undefined || product?.checkList?.customsCheckKeywordCheckResult !== undefined) && (
            <CheckListItem
              labelName={t("SearchResults.Analyzes.CheckList.CustomsCheck")}
              status={product?.checkList?.customsCheckCategoryCheckResult === 1 || product?.checkList?.customsCheckKeywordCheckResult === 1 ? undefined : null}
            ></CheckListItem>
          )}
          {!product?.checkList?.productSizeEligibleToFBA && (
            <CheckListItem labelName={t("SearchResults.Analyzes.CheckList.ProductSizeEligibleToFba")} status={product?.checkList?.productSizeEligibleToFBA ?? null}></CheckListItem>
          )}
          {!product?.checkList?.isOldProduct && (
            <CheckListItem
              labelName={
                t("SearchResults.Analyzes.CheckList.IsOldProduct") +
                (product?.checkList?.productExistSince
                  ? "(" +
                    product?.checkList.productExistSince
                      .replace("years", t("years"))
                      .replace("year", t("year"))
                      .replace("months", t("months"))
                      .replace("month", t("month"))
                      .replace("days", t("days"))
                      .replace("day", t("day"))
                      .replace("and", t("and"))
                      .replace("ago", t("ago")) +
                    ")"
                  : "")
              }
              status={product?.checkList?.isOldProduct ?? null}
            ></CheckListItem>
          )}
          {!product?.checkList?.isTheRatingAverageGood && (
            <CheckListItem labelName={t("SearchResults.Analyzes.CheckList.IsTheRatingAverageGood")} status={product?.checkList?.isTheRatingAverageGood ?? null}></CheckListItem>
          )}
        </ul>
        <br></br>
      </ModalBody>
      <ModalFooter>
        <div className="vstack justify-content-start">
          <Row className="d-flex align-items-center">
            <Col xs="auto">
              <span>{t("SearchResults.Dialog.ShowCheckList.SwitchShowCheckListTitle")}</span>
            </Col>
            <Col xs="auto">
              <div className="form-check form-switch">
                <Input id="showCheckList" className="form-check-input form-switch-md " disabled={loading.save} type="checkbox" role="switch" onChange={updateShowCheckListOption} />
              </div>
            </Col>
          </Row>
        </div>
        <div className="hstack gap-2 justify-content-end">
          <Button type="button" className="btn btn-light" onClick={toggle}>
            {t("Close")}
          </Button>
          <Button
            type="submit"
            className="btn w-sm btn-primary"
            onClick={() => {
              //props.toggleSellThis();
              preventScrollUp();
              navigate(`${pageUrl}/sell-this?asin=${product?.asin}&marketplace=${product?.marketplaceTarget}`);
            }}
          >
            {t("SearchResults.Dialog.ShowCheckList.SubmitButton")}
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default CheckList;
