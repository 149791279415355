import Dialog, {DialogRef} from "Components/Common/Dialog";
import BreadCrumb, {BreadcrumbMenuItem} from "Components/Common/BreadCrumb";
import DataTable, {DataTableRef} from "Components/Common/DataTable";
import {useEffect, useMemo, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {
  cancelUserSubscription,
  deleteUserPaymentMethod,
  getUserPaymentMethods,
  getUserSubscriptionPackage,
  resumeUserSubscription,
  retrySubscriptionPayment,
  setAsDefaultPaymentMethod,
} from "slices/account/thunk";
import {Alert, Button, Card, CardBody, CardHeader, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown} from "reactstrap";
import {Link, useNavigate} from "react-router-dom";
import {UserSubscriptionStatus} from "models/user_subscription";
import {UserPaymentMethod} from "models/user_payment_methods";
import {RootState} from "slices";
import {createSelector} from "reselect";
import {ColumnDef} from "@tanstack/react-table";
import {useProfile} from "Components/Hooks/UserHooks";
import {convertTimeByTimeZone, getToday} from "helpers/utilities";
import CreateUserPaymentMethod from "./Modal/CreateUserPaymentMethod";
import UpdateUserPaymentMethod from "./Modal/UpdateUserPaymentMethod";
import Loader from "Components/Common/Loader";
import visaLogo from "assets/images/brands/visa.png";
import mastercardLogo from "assets/images/brands/mastercard.png";
import americanExpressLogo from "assets/images/brands/american_express.png";
import discoverLogo from "assets/images/brands/discover.png";
import jcbLogo from "assets/images/brands/jcb.png";
import dinersClubLogo from "assets/images/brands/diners_club.png";
import unionPayLogo from "assets/images/brands/union_pay.png";
import moment from "moment";
import ProfileLayout from "Components/Common/Layout/ProfileLayout";
import Restricted from "Components/Common/Restricted";
import Unauthorized from "pages/Errors/_Unauthorized";
import _ from "lodash";

export type UserPaymentMethodListRef = {
  reload: VoidFunction;
};
const PAGE_IDENTIFIER = "account.subscription";
const Subscription = () => {
  const {t} = useTranslation();
  const {userProfile, hasPermission} = useProfile();
  const [trialRemainingDay, setTrialRemainingDay] = useState<number>(0);
  const [paymentMethod, setPaymentMethod] = useState<UserPaymentMethod>();
  const [subscriptionEndDate, setSubscriptionEndDate] = useState<string>("");
  const [isEditing, setIsEditing] = useState(false);
  const [cancelAtPeriodEnd, setCancelAtPeriodEnd] = useState<boolean>(true);
  const dispatch: any = useDispatch();
  const navigate = useNavigate();
  const tableRef = useRef<DataTableRef>(null);
  const setAsDefaultDialogRef = useRef<DialogRef>(null);
  const deleteDialogRef = useRef<DialogRef>(null);
  const cancelCurrentSubscriptionDialogRef = useRef<DialogRef>(null);
  const resumeSubscriptionDialogRef = useRef<DialogRef>(null);
  const retrySubscriptionPaymentDialogRef = useRef<DialogRef>(null);

  const accountData = createSelector(
    (state: RootState) => state,
    (state) => ({
      loading: state.Account.loading,
      paymentMethods: state.Account.paymentMethods,
      userSubscription: state.Account.userSubscription,
      subscription: state.Account.subscription,
      userBalance: state.Account.userBalance,
    }),
  );

  const {loading, paymentMethods, userSubscription, subscription, userBalance} = useSelector(accountData);

  useEffect(() => {
    if (hasPermission(PAGE_IDENTIFIER)) {
      dispatch(getUserSubscriptionPackage());
      dispatch(getUserPaymentMethods());
    }
  }, [dispatch, navigate]); //eslint-disable-line

  useEffect(() => {
    if (userProfile && userSubscription) {
      const date = moment(convertTimeByTimeZone(userSubscription.endDate, userProfile?.timezone)).format("D MMM YYYY HH:mm");
      setSubscriptionEndDate(date);
    }
  }, [userProfile, userSubscription]);

  useEffect(() => {
    if (subscription && userSubscription) {
      if (userSubscription.status === UserSubscriptionStatus.Trial) {
        const startMoment = moment(getToday().toDate());
        const endMoment = moment(userSubscription.endDate);
        const differenceInDays = endMoment.diff(startMoment, "days");
        setTrialRemainingDay(differenceInDays);
      }
    }
  }, [subscription, userSubscription]);

  const columns = useMemo<ColumnDef<UserPaymentMethod, any>[]>(
    () => [
      {
        header: t("Account.Subscription.TableHeader.Card"),
        size: 100,
        accessorKey: "brand",
        sortingFn: "alphanumeric",
        cell: (cellProps) => {
          const card = cellProps.row.original as UserPaymentMethod;
          let logoSrc = "";

          if (card.brand === "visa") {
            logoSrc = visaLogo;
          } else if (card.brand === "mastercard") {
            logoSrc = mastercardLogo;
          } else if (card.brand === "amex") {
            logoSrc = americanExpressLogo;
          } else if (card.brand === "discover") {
            logoSrc = discoverLogo;
          } else if (card.brand === "jcb") {
            logoSrc = jcbLogo;
          } else if (card.brand === "diners") {
            logoSrc = dinersClubLogo;
          } else if (card.brand === "unionpay") {
            logoSrc = unionPayLogo;
          } else {
            logoSrc = "";
          }

          return (
            <>
              <div className="d-flex align-items-center">
                <div className="flex-shrink-0 me-3">
                  <div className="avatar-sm bg-light rounded p-1">
                    <img src={logoSrc} alt="card-img" className="img-fluid d-block" />
                  </div>
                </div>
                <div className="flex-grow-1">
                  <h5 className="fs-14 mb-1">
                    <span className="text-body">
                      {_.toUpper(card.brand)} ending in {card.last4}
                    </span>
                  </h5>
                  <p className="text-muted mb-0">
                    {t("Account.Subscription.ExpireDate")}
                    <span className="fw-medium">
                      {" "}
                      {card.expireMonth} / {card.expireYear}
                    </span>
                  </p>
                </div>
              </div>
            </>
          );
        },
      },
      {
        header: "  ",
        size: 100,
        accessorKey: "isDefault",
        sortingFn: "alphanumeric",
        cell: (cellProps) => {
          const card = cellProps.row.original as UserPaymentMethod;
          return <>{card.isDefault && <span className="fs-12 badge bg-success-subtle text-success">{t("Account.Subscription.Badge.DefaultPaymentMethod")}</span>}</>;
        },
      },
      {
        header: " ",
        size: 100,
        cell: (cellProps) => {
          return (
            <Restricted require="account.subscription" update>
              <UncontrolledDropdown>
                <DropdownToggle href="#" className="btn btn-soft-secondary btn-sm float-end" tag="button">
                  <i className="ri-more-fill" />
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                  <DropdownItem
                    onClick={() => {
                      setPaymentMethod(cellProps.row.original);
                      setAsDefaultDialogRef.current?.show();
                    }}
                    disabled={cellProps.row.original.isDefault}
                  >
                    <i className="ri-markup-fill align-bottom me-2 text-muted"></i> {t("Account.Subscription.ContextMenu.SetAsDefault")}
                  </DropdownItem>

                  <DropdownItem
                    onClick={() => {
                      setPaymentMethod(cellProps.row.original);
                      setIsEditing(true);
                    }}
                  >
                    <i className="ri-pencil-fill align-bottom me-2 text-muted"></i> {t("Account.Subscription.ContextMenu.Edit")}
                  </DropdownItem>

                  <DropdownItem divider />
                  <DropdownItem
                    disabled={cellProps.row.original.isDefault}
                    onClick={() => {
                      const rowData = cellProps.row.original;
                      setPaymentMethod(rowData);

                      if (paymentMethods.length > 1) {
                        setPaymentMethod(rowData);
                        deleteDialogRef.current?.show();
                      }
                    }}
                  >
                    <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i> {t("Delete")}
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Restricted>
          );
        },
      },
    ],
    [paymentMethods, t],
  );

  document.title = t("PageTitles.Subscription");

  const breadcrumbMenus: BreadcrumbMenuItem[] = [
    {
      label: t("Account.Title"),
      url: "/account/subscription",
    },
    {
      label: t("Account.Subscription.Title"),
      url: "",
    },
  ];
  return (
    <Restricted require={PAGE_IDENTIFIER} read fallback={() => <Unauthorized />}>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t("Account.Title")} menus={breadcrumbMenus} />
          <ProfileLayout hasAnyChanges={false} navigateTo="">
            <>
              <Row>
                <Col xs={12}>
                  <Card className="ribbon-box overflow-hidden">
                    <CardHeader>
                      <h4 className="card-title mb-0 align-items-center d-flex">
                        <i className="mdi mdi-credit-card-outline fs-18 me-1"></i>
                        {t("Account.Subscription.Title")}
                      </h4>
                    </CardHeader>
                    <CardBody className="text-center">
                      {loading.userSubscription ? (
                        <Loader />
                      ) : (
                        userSubscription && (
                          <>
                            {userSubscription.status === UserSubscriptionStatus.Trial && trialRemainingDay > 0 && (
                              <CardHeader className="p-0 mb-3">
                                <Alert color="warning" className="alert-solid alert-label-icon border-0 rounded-0 m-0 d-flex align-items-center" role="alert">
                                  <i className="ri-error-warning-line label-icon"></i>
                                  <div className="flex-grow-1 text-truncate">{t("Account.Subscription.TrialDay", {day: trialRemainingDay})}</div>
                                </Alert>
                              </CardHeader>
                            )}

                            {(userSubscription.status === UserSubscriptionStatus.Active ||
                              userSubscription.status === UserSubscriptionStatus.Trial ||
                              userSubscription.status === UserSubscriptionStatus.Canceled) && (
                              <>
                                <h5 className="">
                                  <Link to="" className="link-primary">
                                    {t("Account.Subscription.PreferredPlan")}
                                  </Link>
                                </h5>
                                <Row className="mt-4">
                                  <Col lg={6} className="border-end-dashed border-end">
                                    <h5>{subscription.name}</h5>
                                    <span className="text-muted">
                                      {userSubscription.billingPeriodMonth === 1
                                        ? t("Account.Subscription.Monthly")
                                        : userSubscription.billingPeriodMonth === 6
                                        ? t("Account.Subscription.6-Month")
                                        : t("Account.Subscription.12-Month")}
                                    </span>
                                  </Col>
                                  <Col lg={6}>
                                    <h5>
                                      {userSubscription.billingPeriodMonth === 1
                                        ? `$${subscription.priceMonth}`
                                        : userSubscription.billingPeriodMonth === 6
                                        ? `$${subscription.priceSixMonth}`
                                        : `$${subscription.priceYear}`}
                                      {" / "}
                                      {userSubscription.billingPeriodMonth === 1
                                        ? t("Account.Subscription.Monthly")
                                        : userSubscription.billingPeriodMonth === 6
                                        ? t("Account.Subscription.6-Month")
                                        : t("Account.Subscription.12-Month")}
                                    </h5>
                                  </Col>
                                </Row>
                                <Row className="mt-3">
                                  <Col>
                                    <span className="fw-bold">
                                      {t("Account.Subscription.YourBalance")} : ${userBalance}
                                    </span>
                                  </Col>
                                </Row>
                              </>
                            )}

                            {(userSubscription.status === UserSubscriptionStatus.Terminated || _.isEmpty(userSubscription)) && (
                              <>
                                <Alert color="primary">{t("Account.Subscription.Error.NoActiveSubscription")}</Alert>
                                <Link to="/account/subscription/change" className="btn btn-primary w-100 mb-3">
                                  {t("Account.Subscription.CreateSubscription")}
                                </Link>
                                <span className="fw-bold">
                                  {t("Account.Subscription.YourBalance")} : ${userBalance}
                                </span>
                              </>
                            )}

                            {userSubscription.status === UserSubscriptionStatus.PaymentFailed && (
                              <>
                                <Alert color="danger">{t("Account.Subscription.Error.PaymentFailed")}</Alert>
                                <Row>
                                  <Col>
                                    <Button color="primary" className="w-100" onClick={() => retrySubscriptionPaymentDialogRef?.current?.show()}>
                                      {t("Account.Subscription.TryPaymentAgain")}
                                    </Button>
                                  </Col>
                                  <Col>
                                    <Button
                                      color="info"
                                      className="w-100"
                                      onClick={() => {
                                        setCancelAtPeriodEnd(false);
                                        cancelCurrentSubscriptionDialogRef?.current?.show();
                                      }}
                                    >
                                      {t("Account.Subscription.Button.CancelSubscription")}
                                    </Button>
                                  </Col>
                                </Row>
                              </>
                            )}

                            {(userSubscription.status === UserSubscriptionStatus.Trial || userSubscription.status === UserSubscriptionStatus.Active) && (
                              <>
                                <div className="mt-4"></div>
                                <Row className="mt-4">
                                  <Col>
                                    {userSubscription.status === UserSubscriptionStatus.Trial && (
                                      <Alert color="primary">
                                        {t("Account.Subscription.TrialPeriodWillEndOn", {
                                          date: subscriptionEndDate,
                                        })}
                                      </Alert>
                                    )}

                                    {userSubscription.status === UserSubscriptionStatus.Active && (
                                      <Alert color="success">
                                        {t("Account.Subscription.SubscriptionWillRenewOn", {
                                          date: subscriptionEndDate,
                                        })}
                                      </Alert>
                                    )}
                                  </Col>
                                </Row>
                              </>
                            )}

                            {(userSubscription.status === UserSubscriptionStatus.Active || userSubscription.status === UserSubscriptionStatus.Trial) && (
                              <Restricted require="account.subscription" update>
                                <Row>
                                  <Col className="same-height">
                                    <Button
                                      color="danger"
                                      className="w-100 h-100 d-flex justify-content-center align-items-center"
                                      onClick={() => {
                                        setCancelAtPeriodEnd(true);
                                        cancelCurrentSubscriptionDialogRef?.current?.show();
                                      }}
                                    >
                                      {t("Account.Subscription.Button.CancelSubscription")}
                                    </Button>
                                  </Col>
                                  <Col className="same-height">
                                    <Link to="/account/subscription/change" className="btn btn-primary w-100 h-100 d-flex justify-content-center align-items-center">
                                      {t("Account.Subscription.Button.SwitchPlan")}
                                    </Link>
                                  </Col>
                                </Row>
                              </Restricted>
                            )}

                            {userSubscription.status === UserSubscriptionStatus.Canceled && (
                              <Row className="px-4 mt-2">
                                <Alert color="danger">
                                  {t("Account.Subscription.SubscriptionCancelled", {
                                    date: subscriptionEndDate,
                                  })}
                                </Alert>
                                <Button color="primary" onClick={() => resumeSubscriptionDialogRef?.current?.show()}>
                                  <i className="bx bx-history align-bottom me-1 fs-18"></i>
                                  {t("Account.Subscription.Button.ReactivateSubscription")}
                                </Button>
                              </Row>
                            )}
                          </>
                        )
                      )}
                    </CardBody>
                  </Card>
                </Col>
                <Col xs={12}>
                  <Card>
                    <CardHeader className="align-items-center d-flex">
                      <h4 className="card-title mb-0 flex-grow-1">{t("Account.Subscription.PaymentMethods")}</h4>
                      <CreateUserPaymentMethod reload={() => dispatch(getUserPaymentMethods())} />
                      <UpdateUserPaymentMethod reload={() => dispatch(getUserPaymentMethods())} paymentMethod={paymentMethod} isOpen={isEditing} toggle={() => setIsEditing(false)} />
                    </CardHeader>
                    <CardBody>
                      {loading.paymentMethodList ? (
                        <Loader />
                      ) : (
                        <DataTable
                          ref={tableRef}
                          columns={columns}
                          data={paymentMethods}
                          tableClass="mb-0 align-middle table-borderless"
                          divClass="overflow-x-visible"
                          totalDataLength={paymentMethods?.length || 0}
                          busy={loading.paymentMethodList || loading.update}
                          hovered
                        />
                      )}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </>
          </ProfileLayout>
        </Container>
      </div>
      <Dialog
        ref={setAsDefaultDialogRef}
        color="success"
        buttons={["yes", "no"]}
        busy={loading.update}
        iconClass="ri-checkbox-circle-fill"
        message={t("Account.Subscription.Dialog.SetAsDefaultPaymentMethod.Description")}
        title={t("Account.Subscription.Dialog.SetAsDefaultPaymentMethod.Title")}
        onButtonClick={async (button, hide) => {
          if (button === "yes") {
            if (paymentMethod) {
              await setAsDefaultPaymentMethod(paymentMethod.userPaymentMethodId)(dispatch);
              setAsDefaultDialogRef.current?.hide();
              dispatch(getUserPaymentMethods());
            }
          }
          setAsDefaultDialogRef.current?.hide();
        }}
      />
      <Dialog
        ref={deleteDialogRef}
        color="danger"
        buttons={["yes", "no"]}
        busy={loading.delete}
        iconClass="ri-delete-bin-line"
        message={t("Account.Subscription.Dialog.DeletePaymentMethod.Description")}
        title={t("Account.Subscription.Dialog.DeletePaymentMethod.Title")}
        onButtonClick={async (button, hide) => {
          if (button === "yes") {
            if (paymentMethod) {
              await deleteUserPaymentMethod(paymentMethod.userPaymentMethodId)(dispatch);
              deleteDialogRef.current?.hide();
              dispatch(getUserPaymentMethods());
            }
          }
          deleteDialogRef.current?.hide();
        }}
      />
      <Dialog
        ref={cancelCurrentSubscriptionDialogRef}
        color="danger"
        buttons={["yes", "no"]}
        busy={loading.delete}
        iconClass="ri-delete-bin-line"
        message={t("Account.Subscription.Dialog.CancelSubscription.Description")}
        title={t("Account.Subscription.Dialog.CancelSubscription.Title")}
        onButtonClick={async (button, hide) => {
          if (button === "yes") {
            await cancelUserSubscription(userSubscription.userSubscriptionId, cancelAtPeriodEnd)(dispatch);
            cancelCurrentSubscriptionDialogRef.current?.hide();
            await getUserSubscriptionPackage()(dispatch);
          }
          cancelCurrentSubscriptionDialogRef.current?.hide();
        }}
      />

      <Dialog
        ref={resumeSubscriptionDialogRef}
        color="success"
        buttons={["yes", "no"]}
        busy={loading.resumeSubscription}
        iconClass="ri-play-circle-fill"
        message={t("Account.Subscription.Dialog.ResumeSubscription.Description")}
        title={t("Account.Subscription.Dialog.ResumeSubscription.Title")}
        onButtonClick={async (button, hide) => {
          if (button === "yes") {
            await resumeUserSubscription(userSubscription.userSubscriptionId)(dispatch);
            resumeSubscriptionDialogRef.current?.hide();
            dispatch(getUserSubscriptionPackage());
          }
          resumeSubscriptionDialogRef.current?.hide();
        }}
      />

      <Dialog
        ref={retrySubscriptionPaymentDialogRef}
        color="success"
        buttons={["yes", "no"]}
        busy={loading.retryPayment}
        iconClass="ri-play-circle-fill"
        message={t("Account.Subscription.Dialog.RetryPayment.Description")}
        title={t("Account.Subscription.Dialog.RetryPayment.Title")}
        onButtonClick={async (button, hide) => {
          if (button === "yes") {
            await retrySubscriptionPayment(userSubscription.userSubscriptionId)(dispatch);
            retrySubscriptionPaymentDialogRef.current?.hide();
          }
          retrySubscriptionPaymentDialogRef.current?.hide();
        }}
      />
    </Restricted>
  );
};

export default Subscription;
