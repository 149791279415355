import {SearchResultFilter, SearchResultQuery, SearchResults} from "models/search_result";
import {
  addFavItem,
  apiError,
  loading,
  removeFavItem,
  reset,
  setCurrentFavoriteList,
  setDefinedFilters,
  setFavoriteList,
  setFilter,
  setInUserShipment,
  setPageUrl,
  setResult,
  setShowCheckList,
  setShowDetails,
} from "./reducer";
import {generateError, renderSuccessToast} from "helpers/utilities";
import {ResponseModel} from "models/response_model";
import {
  postAddToUserFavoriteList,
  postDeleteFilter,
  postDeleteUserFavoriteList,
  postGetFilters,
  postGetSearchResults,
  postGetUserFavorites,
  postRemoveFromUserFavoriteList,
  postRenameUserFavoriteList,
  postSaveFilter,
  postSaveUserFavorite,
  postSetUserShowCheckListOptionChoice,
} from "services/search_service";
import {SendReportErrorRequest} from "models/user";
import {postSendReportError} from "services/identity_service";
import {UserSearchResultsFilter} from "models/user_search_result_filter";
import i18n from "i18n";
import {SaveOrUpdateSearchResultsFilterCommand} from "api/command";
import {GetUserFavoriteListQuery} from "api/query";
import {ISaveUserFavoriteListDto, UserFavorite} from "models/user_favorites";
import {PagedList} from "helpers/types";
import {renderFavoriteToast} from "Components/Common/ToastChangeFavoriteList";
import { NavigateFunction } from "react-router-dom";
import { updateSearchResultLocalData } from "helpers/local_storage";

export const getUserSearchResults = (payload: SearchResultQuery) => async (dispatch: any) => {
  const loadingType = payload.filtering ? "filter" : "list";
  try {
    dispatch(loading([loadingType, true]));
    const response: ResponseModel = await postGetSearchResults(payload);
    const result: SearchResults = response.data;
    dispatch(setResult(result));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading([loadingType, false]));
  }
};

export const getUserSearchResultsFilters = () => async (dispatch: any) => {
  try {
    dispatch(loading(["definedFilters", true]));
    const response: ResponseModel = await postGetFilters();
    const result: UserSearchResultsFilter[] = response.data;
    dispatch(setDefinedFilters(result));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["definedFilters", false]));
  }
};

export const getUserFavoriteList = (data: GetUserFavoriteListQuery) => async (dispatch: any) => {
  try {
    dispatch(loading(["listFavorite", true]));
    const response: ResponseModel = await postGetUserFavorites(data);
    const list: Partial<PagedList<UserFavorite>> = response.data;
    dispatch(setFavoriteList(list));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["listFavorite", false]));
  }
};

export const setUserShowCheckListOptionChoice = (choice: boolean) => async (dispatch: any) => {
  try {
    dispatch(loading(["save", true]));
    const result: ResponseModel = await postSetUserShowCheckListOptionChoice({showCheckList: choice});
    dispatch(setShowCheckList(choice));
    renderSuccessToast(i18n.t(result.message));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["save", false]));
  }
};

export const deleteFilter = (filterName: string) => async (dispatch: any) => {
  try {
    dispatch(loading(["delete", true]));
    const result: ResponseModel = await postDeleteFilter({filterName});
    dispatch(getUserSearchResultsFilters());
    renderSuccessToast(i18n.t(result.message));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["delete", false]));
  }
};

export const saveFilter = (command: SaveOrUpdateSearchResultsFilterCommand) => async (dispatch: any) => {
  try {
    dispatch(loading(["save", true]));
    const result: ResponseModel = await postSaveFilter(command);
    dispatch(getUserSearchResultsFilters());
    renderSuccessToast(i18n.t(result.message));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["save", false]));
  }
};

export const setSearchResultsShowDetails = (showDetails: boolean) => (dispatch: any) => {
  updateSearchResultLocalData({showDetails});
  dispatch(setShowDetails(showDetails));
};

export const setSearchResultFilters = (filter: SearchResultFilter) => (dispatch: any) => {
  dispatch(setFilter({...filter}));
};

export const setSearchResultPageUrl = (pageUrl: string) => (dispatch: any) => {
  dispatch(setPageUrl(pageUrl));
};

export const changeCurrentFavoriteList = (userFavorite: UserFavorite) => (dispatch: any) => {
  try {
    dispatch(loading(["update", true]));
    dispatch(setCurrentFavoriteList(userFavorite));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return true;
  } finally {
    dispatch(loading(["update", false]));
    return true;
  }
};

export const createUserFavoriteList = (data: ISaveUserFavoriteListDto) => async (dispatch: any) => {
  try {
    dispatch(loading(["save", true]));
    const response: ResponseModel = await postSaveUserFavorite(data);
    const list: Partial<PagedList<UserFavorite>> = response.data;
    dispatch(setFavoriteList(list));
    renderSuccessToast(i18n.t("Favorites.Toast.FavoriteListCreateSuccess"));
    return true;
  } catch (error:any) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["save", false]));
  }
};

export const deleteUserFavoriteList = (userFavorite: UserFavorite) => async (dispatch: any) => {
  try {
    dispatch(loading(["delete", true]));
    const response: ResponseModel = await postDeleteUserFavoriteList({userFavoriteId: userFavorite.userFavoriteId});
    const list: Partial<PagedList<UserFavorite>> = response.data;
    dispatch(setFavoriteList(list));
    renderSuccessToast(i18n.t("Favorites.Toast.FavoriteListDeleteSuccess"));
    return true;
  } catch (error:any) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["delete", false]));
  }
};

export const addFavoriteItem = (userFavoriteId: string, asin: string, marketplace: string, showToast: boolean, navigate: NavigateFunction) => async (dispatch: any) => {
  try {
    dispatch(loading(["update", true]));
    await postAddToUserFavoriteList({asin, userFavoriteId, marketplace});
    if (showToast) renderFavoriteToast("add", asin, navigate);
    dispatch(addFavItem({userFavoriteId, asin}));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["update", false]));
  }
};

export const removeFavoriteItem = (userFavoriteId: string, asin: string, marketplace: string, showToast: boolean, navigate: NavigateFunction) => async (dispatch: any) => {
  try {
    dispatch(loading(["delete", true]));
    await postRemoveFromUserFavoriteList({userFavoriteId, asin, marketplace});
    if (showToast) renderFavoriteToast("remove", asin, navigate);
    dispatch(removeFavItem({userFavoriteId, asin, marketplace}));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["delete", false]));
    return false;
  }
};




export const renameFavoriteList = (userFavoriteId: string, name: string) => async (dispatch: any) => {
  try {
    dispatch(loading(["renameFavoriteList", true]));
    const response: ResponseModel = await postRenameUserFavoriteList({userFavoriteId, name});
    const list: Partial<PagedList<UserFavorite>> = response.data;
    dispatch(setFavoriteList(list));
    renderSuccessToast(i18n.t("SearchResults.Dialog.AddToFavorites.Toast.FavoriteListRenameSuccess"));
    return true;
  } catch (error:any) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["renameFavoriteList", false]));
  }
};

export const changeInUserShipmentStatusForSearchResult = (asin: string, inUserShipment: boolean) => async (dispatch: any) => {
  try {
    dispatch(setInUserShipment({asin, inUserShipment}));
    return true;
  } catch (error) {
    const errorObject = generateError(error, false);
    dispatch(apiError(errorObject));
    return false;
  }
};

export const sendReportError = (sendReportErrorRequest: SendReportErrorRequest) => async (dispatch: any) => {
  try {
    dispatch(loading(["save", true]));
    await postSendReportError(sendReportErrorRequest);
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["save", false]));
  }
};

export const resetSearchResultState = () => async (dispatch: any) => {
  dispatch(reset());
};
