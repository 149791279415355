import AmazonMarketplaceInfos from "Components/Common/AmazonMarketplaceInfos";
import {UserLimitType} from "models/user_limit_usage_history";
import {useTranslation} from "react-i18next";

const GetMarketplaceSelectOptions = () => {
  let marketplaces: {value: any; label: any}[] = [];

  AmazonMarketplaceInfos().map((amazonInfo: any) =>
    amazonInfo.marketplace !== "US" && amazonInfo.active
      ? marketplaces.push({
          value: amazonInfo.marketplace,
          label: amazonInfo.countryName,
        })
      : null,
  );

  return marketplaces;
};

export type SelectOptionsType = {
  value: string;
  label: string;
  image?: string;
};

export type MultiSelectTranslations = {
  allItemsAreSelected: string;
  clearSearch: string;
  clearSelected: string;
  noOptions: string;
  search: string;
  selectAll: string;
  selectAllFiltered: string;
  selectSomeItems: string;
  create: string;
};

const useSelectOptions = () => {
  const {t} = useTranslation();

  const marketplaceSelectOptions = GetMarketplaceSelectOptions();

  const searchStatusSelectOptions = [
    {value: "Completed", label: t("Completed")},
    {value: "InProgress", label: t("In Progress")},
    {value: "Initial", label: t("Initial")},
    {value: "Error", label: t("Error")},
  ];

  const scanAndSaveStatusSelectOptions = [
    {value: "Undefined", label: t("ScanAndSave.Status.Undefined")},
    {value: "Waiting", label: t("ScanAndSave.Status.Waiting")},
    {value: "Planned", label: t("ScanAndSave.Status.Planned")},
    {value: "Working", label: t("ScanAndSave.Status.Working")},
    {value: "Done", label: t("ScanAndSave.Status.Done")},
    {value: "Canceled", label: t("ScanAndSave.Status.Canceled")},
  ];

  const decisionSelectOptions = [
    {value: "Sellable", label: t("Sellable")},
    {value: "Recheck", label: t("Recheck")},
    {value: "Not Sellable", label: t("Not Sellable")},
  ];

  const amazonCatalogStatusSelectOptions = [
    {
      value: "product-not-exist-in-amazon-com",
      label: t("Product not exist in Amazon.com"),
    },
    {
      value: "product-not-exist-in-target-marketplace",
      label: t("Product not exist in target marketplace"),
    },
    {
      value: "product-not-available-in-amazon-com",
      label: t("Product not available in Amazon.com"),
    },
    {
      value: "product-not-available-in-target-marketplace",
      label: t("Product not available in target marketplace"),
    },
  ];

  const commonProductSelectOptions = [
    {value: "all", label: t("All")},
    {value: "yes", label: t("Yes")},
    {value: "no", label: t("No")},
  ];

  const shipmentStatusSelectOptions = [
    {value: "Draft", label: t("Draft")},
    {value: "Shipped", label: t("Shipped")},
    {value: "Completed", label: t("Completed")},
  ];

  const dateRangeSelectOptions = [
    {value: "all", label: t("SearchResults.Filters.All")},
    {value: "1-month", label: t("SearchResults.Filters.1-Month")},
    {value: "3-months", label: t("SearchResults.Filters.3-Months")},
    {value: "6-months", label: t("SearchResults.Filters.6-Months")},
    {value: "1-year", label: t("SearchResults.Filters.1-Year")},
    {value: "more-than-1-year", label: t("SearchResults.Filters.MoreThanOneYear")},
  ];

  const competitionSelectOptions = [
    {value: "Weak", label: t("Weak")},
    {value: "Medium", label: t("Medium")},
    {value: "Strong", label: t("Strong")},
  ];

  const priceAnalysisResultSelectOptions = [
    {value: "Stable", label: t("SearchResults.Filters.Stable")},
    {value: "Not Stable", label: t("SearchResults.Filters.NotStable")},
    {value: "Recheck", label: t("SearchResults.Filters.Recheck")},
  ];

  const supportIssueSelectOptions = [
    {value: "ScanAndSave", label: t("Account.Support.Issue.ScanAndSave")},
    {value: "ProductAnalysis", label: t("Account.Support.Issue.ProductAnalysis")},
    {value: "Orders", label: t("Account.Support.Issue.Orders")},
    {value: "Inventory", label: t("Account.Support.Issue.Inventory")},
    {value: "Shipments", label: t("Account.Support.Issue.Shipments")},
    {value: "Reports", label: t("Account.Support.Issue.Reports")},
    {value: "Stores", label: t("Account.Support.Issue.Stores")},
    {value: "Account", label: t("Account.Support.Issue.Account")},
    {value: "Limits", label: t("Account.Support.Issue.Limits")},
    {value: "Subscriptions", label: t("Account.Support.Issue.Subscriptions")},
    {value: "Payments", label: t("Account.Support.Issue.Payments")},
    {value: "NotListed", label: t("Account.Support.Issue.NotListed")},
  ];

  const supportIssuePrioritySelectOptions = [
    {value: "Low", label: t("Account.Support.Priority.Low")},
    {value: "Medium", label: t("Account.Support.Priority.Medium")},
    {value: "High", label: t("Account.Support.Priority.High")},
  ];

  const serviceSelectOptions = [
    {value: "spapi_search_source", label: "SPAPI Search Source"},
    {value: "spapi_search_target", label: "SPAPI Search Target"},
    {value: "product_offers_source", label: "Product Offers Source"},
    {value: "product_offers_target", label: "Product Offers Target"},
    {value: "fees_estimate", label: "Fees Estimate"},
    {value: "variations", label: "Variations"},
    {value: "category", label: "Category"},
    {value: "connect_api", label: "Connect Api"},
    {value: "sale_count", label: "Sale Count"},
    {value: "fba_eligibility", label: "FBA Eligibility"},
    {value: "restriction_check", label: "Restriction Check"},
    {value: "fast_analyze", label: "Fast Analyze"},
    {value: "decision", label: "Decision"},
  ];

  const microserviceSelectOptions = [
    {value: "SellThis.Services.Identity", label: "SellThis.Services.Identity"},
    {value: "SellThis.Services.Admin", label: "SellThis.Services.Admin"},
    {value: "SellThis.Services.Amazon", label: "SellThis.Services.Amazon"},
    {value: "SellThis.Services.Payment", label: "SellThis.Services.Payment"},
    {value: "SellThis.Services.Notification", label: "SellThis.Services.Notification"},
    {value: "SellThis.Services.Search", label: "SellThis.Services.Search"},
    {value: "SellThis.Services.Crawler", label: "SellThis.Services.Crawler"},
  ];

  const logOperationSelectOptions = [
    {value: "/GetUserWebNotifications", label: "/GetUserWebNotifications"},
    {value: "/GetUserRemainingLimit", label: "/GetUserRemainingLimit"},
    {value: "/GetUserSearches", label: "/GetUserSearches"},
    {value: "/GetUserSearchResults", label: "/GetUserSearchResults"},
    {value: "/GetUserShipments", label: "/GetUserShipments"},
    {value: "/GetScanAndSave", label: "/GetScanAndSave"},
    {value: "/Login", label: "/Login"},
    {value: "/GetUserInventories", label: "/GetUserInventories"},
    {value: "/GetUserLimitUsageHistory", label: "/GetUserLimitUsageHistory"},
    {value: "/GetUserOrders", label: "/GetUserOrders"},
    {value: "/GetSearches", label: "/GetSearches"},
    {value: "/GetUserSubscription", label: "/GetUserSubscription"},
    {value: "/UpdateUIPreferences", label: "/UpdateUIPreferences"},
    {value: "/GetUserPaymentMethods", label: "/GetUserPaymentMethods"},
    {value: "/GetUserShipmentDetails", label: "/GetUserShipmentDetails"},
    {value: "/GetUsers", label: "/GetUsers"},
    {value: "/SaveUserNewSearch", label: "/SaveUserNewSearch"},
    {value: "/GetUserPayments", label: "/GetUserPayments"},
    {value: "/RegisterUser", label: "/RegisterUser"},
    {value: "/GetUserOrderDetails", label: "/GetUserOrderDetails"},
    {value: "/SaveNewScan", label: "/SaveNewScan"},
    {value: "/SubmitForAnalysis", label: "/SubmitForAnalysis"},
    {value: "/AddUserShipmentItemAsync", label: "/AddUserShipmentItemAsync"},
    {value: "/UpdateNotificationOptions", label: "/UpdateNotificationOptions"},
    {value: "/SaveSubscription", label: "/SaveSubscription"},
    {value: "/ForgotPassword", label: "/ForgotPassword"},
    {value: "/ReadUserWebNotification", label: "/ReadUserWebNotification"},
    {value: "/SaveUserPaymentMethod", label: "/SaveUserPaymentMethod"},
    {value: "/DeleteSubUser", label: "/DeleteSubUser"},
    {value: "/DeleteUserStoreAsync", label: "/DeleteUserStoreAsync"},
    {value: "/SaveSubUserInvitation", label: "/SaveSubUserInvitation"},
    {value: "/UpdateUserInventoryCost", label: "/UpdateUserInventoryCost"},
    {value: "/DeleteUserPaymentMethod", label: "/DeleteUserPaymentMethod"},
    {value: "/UpdateCostAndFeeItem", label: "/UpdateCostAndFeeItem"},
    {value: "/ExportAsin", label: "/ExportAsin"},
    {value: "/RegisterSubUser", label: "/RegisterSubUser"},
    {value: "/UpdateGeneralInformation", label: "/UpdateGeneralInformation"},
    {value: "/DeleteUserShipmentItemAsync", label: "/DeleteUserShipmentItemAsync"},
    {value: "/ChangePassword", label: "/ChangePassword"},
    {value: "/MarkAllAsRead", label: "/MarkAllAsRead"},
    {value: "/ResetPassword", label: "/ResetPassword"},
    {value: "/CancelSearch", label: "/CancelSearch"},
    {value: "/ReassignUserOrderShipment", label: "/ReassignUserOrderShipment"},
    {value: "/SaveUserFavoriteList", label: "/SaveUserFavoriteList"},
    {value: "/SetAsDefaultPaymentMethod", label: "/SetAsDefaultPaymentMethod"},
    {value: "/UpdateAnalyzeItem", label: "/UpdateAnalyzeItem"},
    {value: "/UpdateUserOrderCost", label: "/UpdateUserOrderCost"},
    {value: "/AddToUserFavoriteList", label: "/AddToUserFavoriteList"},
    {value: "/SaveAnalyzeItem", label: "/SaveAnalyzeItem"},
    {value: "/UpdateSubUserInformation", label: "/UpdateSubUserInformation"},
    {value: "/ResetDefaultAnalyzeItem", label: "/ResetDefaultAnalyzeItem"},
    {value: "/ArchiveUserInventories", label: "/ArchiveUserInventories"},
    {value: "/CancelScan", label: "/CancelScan"},
    {value: "/DeleteUserFavoriteList", label: "/DeleteUserFavoriteList"},
    {value: "/DisconnectUserStoreAsync", label: "/DisconnectUserStoreAsync"},
    {value: "/ResetDefaultCostAndFeeItem", label: "/ResetDefaultCostAndFeeItem"},
    {value: "/RestartScan", label: "/RestartScan"},
    {value: "/SetAsDefaultAnalyzeItem", label: "/SetAsDefaultAnalyzeItem"},
    {value: "/SupportRequest", label: "/SupportRequest"},
    {value: "/UnarchiveUserInventories", label: "/UnarchiveUserInventories"},
    {value: "/UpdateAvatar", label: "/UpdateAvatar"},
    {value: "/UpdatePaymentMethod", label: "/UpdatePaymentMethod"},
  ];

  const limitUsageHistoryOperationSelectOptions = [
    {value: UserLimitType.SEARCH.toString(), label: t("Search")},
    {value: UserLimitType.CRAWLER_SCAN.toString(), label: "Scan"},
  ];

  const multiSelectTranslations: MultiSelectTranslations = {
    allItemsAreSelected: t("General.Select.AllItemsAreSelected"),
    clearSearch: t("General.Select.ClearSearch"),
    clearSelected: t("General.Select.ClearSelected"),
    noOptions: t("General.Select.NoOptions"),
    search: t("General.Select.Search"),
    selectAll: t("General.Select.SelectAll"),
    selectAllFiltered: t("General.Select.SelectAllFiltered"),
    selectSomeItems: t("General.Select.SelectSomeItems"),
    create: t("General.Select.Create"),
  };

  return {
    marketplaceSelectOptions,
    searchStatusSelectOptions,
    decisionSelectOptions,
    amazonCatalogStatusSelectOptions,
    commonProductSelectOptions,
    shipmentStatusSelectOptions,
    scanAndSaveStatusSelectOptions,
    dateRangeSelectOptions,
    competitionSelectOptions,
    priceAnalysisResultSelectOptions,
    supportIssueSelectOptions,
    supportIssuePrioritySelectOptions,
    serviceSelectOptions,
    microserviceSelectOptions,
    logOperationSelectOptions,
    limitUsageHistoryOperationSelectOptions,
    multiSelectTranslations,
  };
};

export default useSelectOptions;
