import DataTable, {DataTableRef} from "Components/Common/DataTable";
import Dialog, {DialogRef} from "Components/Common/Dialog";
import {Modal, ModalHeader, ModalBody, Col, Row, Input, Button, ModalFooter, Form, Spinner, Label} from "reactstrap";
import {ColumnDef} from "@tanstack/react-table";
import {UserStore} from "models/user_stores";
import {
  addUserShipmentItemFromSearchResult,
  deleteUserShipmentFromSearchResult,
  deleteUserShipmentItemFromSearchResult,
  getUserDraftShipments,
  setUserShipmentDetailsFromSearchResult,
} from "slices/common/thunk";
import {useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState} from "react";
import {IAddUserShipmentItemDto, IDeleteUserShipmentItemDto, ISetUserShipmentDetailsDto, UserShipment} from "models/user_shipment";
import {SelectOptionsType} from "Components/Hooks/SelectOptions";
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "reselect";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {RootState} from "slices";
import {useFormik} from "formik";
import {useTranslation} from "react-i18next";
import {useProfile} from "Components/Hooks/UserHooks";
import {preventScrollUp} from "helpers/utilities";
import {useDebounce} from "Components/Hooks/HelperHooks";
import AmazonMarketplaceInfos from "Components/Common/AmazonMarketplaceInfos";
import PlaceholderGlow from "Components/Common/PlaceholderGlow";
import Moment from "react-moment";
import EditShipment from "pages/Shipments/Shipments/Modal/EditShipment";
import ValidatedInput from "Components/Common/ValidatedInput";
import ValidatedSelect from "./ValidatedSelect";
import RenderNumber from "./RenderNumber";
import DefaultUncontrolledTooltip from "./DefaultUncontrolledTooltip";
import i18n from "i18n";
import * as Yup from "yup";

interface SellThisProps {
  isOpen: boolean;
}
export type ListRef = {
  reload: VoidFunction;
};

const SellThis = ({isOpen}: SellThisProps) => {
  const {t} = useTranslation();
  const {userProfile} = useProfile();
  const [showNewShipmentStage, setShowNewShipmentStage] = useState<boolean>(false);
  const [selectedShipment, setSelectedShipment] = useState<UserShipment>();
  const [isEditShipmentOpen, setIsEditShipmentOpen] = useState<boolean>(false);
  const [storeSelectOptions, setStoreSelectOptions] = useState<SelectOptionsType[]>([]);
  const dispatch = useDispatch();
  const amazonMarketplaceInfos = AmazonMarketplaceInfos();
  const tableRef = useRef<DataTableRef>(null);
  const listRef = useRef<ListRef>(null);
  const deleteDialogRef = useRef<DialogRef>(null);
  const location = useLocation();
  const navigate = useNavigate();
  const [asin, setAsin] = useState<string>("");
  const [marketplace, setMarketplace] = useState<string>("");
  const commonData = createSelector(
    (state: RootState) => state,
    (state) => ({
      loading: state.Common.loading,
      shipmentInfo: state.Common.shipmentInfo,
      commonUserStore: state.Common.userStores,
    }),
  );
  const {loading, shipmentInfo, commonUserStore} = useSelector(commonData);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      shipmentName: "",
      userStore: "",
    } as AddUserShipmentForm,
    validationSchema: Yup.object<AddUserShipmentForm>({
      shipmentName: Yup.string()
        .required(t("Shipments.NameRequired"))
        .max(100, t("Shipments.NameMaxLength")),
      userStore: Yup.string().required(t("Shipments.StoreRequired")),
    }),
    onSubmit: (values: AddUserShipmentForm) => {
      let productInfos: IAddUserShipmentItemDto = {
        userStoreId: values.userStore,
        userShipmentId: "",
        shipmentName: values.shipmentName,
        asin: asin,
        purchasePrice: 0,
        tax: 0,
        shippingPrice: 0,
        fBAFee: 0,
        referralFee: 0,
        otherFees: 0,
        expectedSellPrice: 0,
        totalItems: 1,
        weight: 0,
        from: "SearchResults",
      };

      const savePromise = addUserShipmentItemFromSearchResult(productInfos)(dispatch);
      savePromise.then((isSuccess) => {
        if (isSuccess) {
          setShowNewShipmentStage(false);
          validation.resetForm();
          listRef.current?.reload();
        }
      });
    },
  });

  const handleCheckBoxChange = (event: any, userStoreId: string, userShipmentId: string, shipmentName: string) => {
    setShowNewShipmentStage(false);

    if (event.target.checked) {
      let parameters: IAddUserShipmentItemDto = {
        userStoreId: userStoreId,
        userShipmentId: userShipmentId,
        shipmentName: shipmentName,
        asin: asin,
        purchasePrice: 0,
        tax: 0,
        shippingPrice: 0,
        fBAFee: 0,
        referralFee: 0,
        otherFees: 0,
        expectedSellPrice: 0,
        totalItems: 1,
        weight: 0,
        from: "SearchResults",
      };
      addUserShipmentItemFromSearchResult(parameters)(dispatch);
    } else {
      let parameters: IDeleteUserShipmentItemDto = {
        userShipmentId: userShipmentId,
        userShipmentItemId: "",
        asin: asin,
        from: "SearchResults",
      };
      deleteUserShipmentItemFromSearchResult(parameters)(dispatch);
    }
  };

  const debouncedLoadList = useDebounce(() => {
    if (isOpen) {
      getUserDraftShipments(
        asin,
        marketplace,
      )(dispatch).then(() => {
        tableRef.current?.resetSelection();
      });
    }
  }, 200);

  useEffect(() => {
    debouncedLoadList();
  }, [debouncedLoadList]);

  useImperativeHandle(
    listRef,
    () => {
      return {
        reload: () => {
          debouncedLoadList();
        },
      };
    },
    [debouncedLoadList],
  );

  const initModal = useCallback(() => {
    if (isOpen) {
      const queryParams = new URLSearchParams(window.location.search);
      const asin = queryParams.get("asin");
      const marketplace = queryParams.get("marketplace");
      if (asin && marketplace) {
        setAsin(asin);
        setMarketplace(marketplace);
        getUserDraftShipments(
          asin,
          marketplace,
        )(dispatch).then(() => {
          tableRef.current?.resetSelection();
        });
        // continue
      } else {
        const baseUrl = window.location.origin + window.location.pathname;
        navigate(baseUrl);
      }
    }
  }, [isOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    initModal();
  }, [initModal]);

  useEffect(() => {
    prepareOptions();
  }, [commonUserStore]); //eslint-disable-line

  const prepareOptions = async () => {
    const stores = commonUserStore.filter((store) => store.marketplace !== "US" && store.status);
    const storeOptions = stores.map((store: UserStore) => {
      return {
        value: store.userStoreId,
        label: amazonMarketplaceInfos.find((amazonMarketplaceInfo: any) => amazonMarketplaceInfo.marketplace === store.marketplace)?.countryName + " - " + store.name,
      };
    });
    setStoreSelectOptions(storeOptions);
  };

  const columns = useMemo<ColumnDef<UserShipment, any>[]>(
    () => [
      {
        header: t("Select"),
        size: 50,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserShipment;
          const dynamic = row as any;
          return (
            <Row className="align-items-center w-100">
              <div className="align-middle">
                <Input
                  className="form-check-input cursor-pointer"
                  type="checkbox"
                  checked={dynamic.userShipmentItem}
                  onChange={(event) => handleCheckBoxChange(event, row.userStoreId, row.userShipmentId, "")}
                />
              </div>
            </Row>
          );
        },
      },
      {
        header: t("Shipments.ShipmentItems.Quantity"),
        size: 10,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserShipment;
          const dynamic = row as any;

          const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            const newValue = parseInt(e.target.value);

            if (newValue !== (dynamic.userShipmentItem?.totalItems || 0)) {
              let newShipmentDetails: ISetUserShipmentDetailsDto = {
                userShipmentId: row.userShipmentId,
                userInventoryId: dynamic.userShipmentItem?.userInventoryId,
                userShipmentItemId: dynamic.userShipmentItem?.userShipmentItemId,
                field: "TotalItems",
                newValue: newValue,
                shipDate: "",
                amazonShipmentId: "",
                from: "SearchResults",
              };

              setUserShipmentDetailsFromSearchResult(newShipmentDetails)(dispatch);
            }
          };

          return (
            <>
              <Input
                id={"quantity-" + row.userShipmentId}
                type="number"
                style={{width: 60}}
                className="form-control form-control-sm border-input-group"
                defaultValue={dynamic.userShipmentItem?.totalItems || 0}
                onBlur={handleValueChange}
                disabled={(dynamic.userShipmentItem?.totalItems || 0) === 0}
              />
            </>
          );
        },
      },
      {
        header: t("Action"),
        size: 50,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserShipment;
          return (
            <div className="d-flex align-items-center">
              <Link
                to=""
                id={`Edit-${row.userShipmentId}`}
                className="btn btn-ghost-secondary px-1 py-0 fs-16"
                onClick={() => {
                  setSelectedShipment(row);
                  setIsEditShipmentOpen(true);
                }}
              >
                <i className="ri-pencil-fill"></i>
              </Link>
              <DefaultUncontrolledTooltip target={`Edit-${row.userShipmentId}`}>{t("Edit")}</DefaultUncontrolledTooltip>
              <Link
                to=""
                id={`Delete-${row.userShipmentId}`}
                className="btn btn-ghost-danger px-1 py-0 fs-16"
                onClick={() => {
                  setSelectedShipment(row);
                  deleteDialogRef.current?.show();
                  // handleDeleteActionBtn(shipmentData);
                }}
              >
                <i className="ri-delete-bin-fill"></i>
              </Link>
              <DefaultUncontrolledTooltip target={`Delete-${row.userShipmentId}`}>{t("Delete")}</DefaultUncontrolledTooltip>
            </div>
          );
        },
      },
      {
        header: t("Shipments.Name"),
        size: 100,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserShipment;
          return (
            <Link to={`/shipments/shipment-details/${row.userShipmentId}`} className="link-secondary link-offset-2 text-decoration-underline" target="_blank">
              {row.name}
              <i className="ri-arrow-right-up-line"></i>
            </Link>
          );
        },
      },
      {
        header: t("Store"),
        size: 200,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserShipment;
          const marketplaceObject = amazonMarketplaceInfos.find((amazonMarketplaceInfo) => amazonMarketplaceInfo.marketplace === marketplace);
          return (
            <span className="hstack gap-2">
              <div className="avatar-xs img-thumbnail rounded-circle flex-shrink-0">
                <img src={marketplaceObject?.flag} alt="" className=" rounded-circle" />
              </div>
              {marketplaceObject?.countryName} {`(${marketplace}) - ${row.userStore?.name}`}
            </span>
          );
        },
      },
      {
        header: t("Shipments.SKUCount"),
        size: 50,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserShipment;
          return <>{<RenderNumber value={row.totalSKU} />}</>;
        },
      },
      {
        header: t("Shipments.ItemsCount"),
        size: 50,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserShipment;
          return <>{<RenderNumber value={row.totalItems} />}</>;
        },
      },
      {
        header: t("CreateDate"),
        size: 100,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserShipment;
          return (
            <Moment locale={i18n.language} format="D MMM YYYY HH:mm" tz={userProfile?.timezone}>
              {row.createDate}
            </Moment>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, commonUserStore, asin, marketplace],
  );

  const toggle = () => {
    const baseUrl = location.pathname.split("sell-this")[0];
    preventScrollUp();
    navigate(baseUrl);
  };
  return (
    <Modal backdrop="static" id="showSellThisModal" isOpen={isOpen} toggle={toggle} fade={true} centered={true} size="xl">
      <PlaceholderGlow busy={loading.userShipment || loading.save || loading.update || loading.delete}>
        <ModalHeader className="bg-light p-3" toggle={toggle}>
          {t("Shipments.CurrentShipments")}
          <span className="ms-1 fs-13 text-muted">
            -{" "}
            <span
              dangerouslySetInnerHTML={{
                __html: t("Shipments.PleaseSelectShipmentForASIN", {asin: asin}),
              }}
            ></span>
          </span>
        </ModalHeader>
        <ModalBody>
          {shipmentInfo?.userStores && shipmentInfo.userStores.length === 0 ? (
            <>
              <div>
                <i className="ms-1 bx bxs-info-circle fs-20 text-danger"></i> {t("Shipments.StoreConnectInfoMessage")}
              </div>
              <div className="mt-4">
                <Button
                  type="submit"
                  className="btn w-sm btn-success"
                  onClick={() => {
                    window.location.href = "/stores?connect=true";
                  }}
                >
                  {t("Shipments.GoToStores")}
                </Button>
              </div>
            </>
          ) : (
            <>
              <Row>
                <Col>
                  {shipmentInfo?.userShipments && shipmentInfo?.userShipments.length > 0 ? (
                    <DataTable
                      ref={tableRef}
                      columns={columns}
                      data={shipmentInfo.userShipments}
                      totalDataLength={shipmentInfo?.userShipments.length || 0}
                       
                      thClass="text-black"
                      busy={loading.userShipment || loading.update}
                    />
                  ) : (
                    t("Shipments.NotHaveCurrentShipments")
                  )}
                </Col>
              </Row>
              <Row className="mt-4">
                <Col>
                  <Button type="button" className="btn btn-secondary add-btn" onClick={() => setShowNewShipmentStage(true)}>
                    <i className="ri-add-line align-bottom me-1"></i>
                    {t("Shipments.NewShipment")}
                  </Button>
                </Col>
              </Row>
              {showNewShipmentStage && (
                <Row className={"mt-4"}>
                  <Col xs={12} lg={12} className="mb-2">
                    <Form
                      onSubmit={(e) => {
                        e.preventDefault();

                        validation.setFieldTouched("shipmentName", true);
                        validation.setFieldTouched("userStore", true);

                        if (validation.isValid) {
                          validation.handleSubmit();
                        } else {
                          validation.setFieldError("shipmentName", t("Shipments.NameRequired"));
                          validation.setFieldError("userStore", t("Shipments.UserStoreRequired"));
                        }
                        return false;
                      }}
                    >
                      <Row className="mt-3">
                        <Col xs={12} md={4} lg={3} className="mb-3 common-css input-col">
                          <Label htmlFor="search">{t("Shipments.Name")}</Label>
                          <ValidatedInput validation={validation} type="text" field="shipmentName" maxLength={100} placeholder={t("Shipments.Name")} disableValidationUI />
                        </Col>
                        <Col xs={12} md={4} lg={3} className="mb-3 common-css input-col">
                          <Label htmlFor="search">{t("Inventory.Filters.StoreFilter")}</Label>
                          <ValidatedSelect options={storeSelectOptions} validation={validation} field="userStore" value={validation.values.userStore} errorStyle="container" />
                        </Col>
                        <Col xs={12} md={4} lg={3} className="common-css top-5 mt-4">
                          <Button type="submit" className="btn btn-success " disabled={loading.save}>
                            {loading.save && <Spinner size="sm" className="me-2"></Spinner>}
                            {t("Create")}
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                </Row>
              )}
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <div className="hstack gap-2 justify-content-end">
            <Button type="button" className="btn btn-light" onClick={toggle}>
              {t("Close")}
            </Button>
          </div>
        </ModalFooter>
      </PlaceholderGlow>
      <Dialog
        ref={deleteDialogRef}
        color="danger"
        buttons={["yes", "no"]}
        busy={loading.delete}
        iconClass="ri-delete-bin-line"
        message={t("Shipments.Dialog.Delete.Description", {shipmentName: selectedShipment?.name})}
        title={t("Shipments.Dialog.Delete.Title")}
        onButtonClick={async (button, hide) => {
          if (button === "yes") {
            const deletePromise = deleteUserShipmentFromSearchResult({
              asin: asin,
              from: "SearchResults",
              userShipmentId: selectedShipment!.userShipmentId,
            })(dispatch);

            deletePromise.then(() => {
              deleteDialogRef.current?.hide();
              listRef.current?.reload();
            });
          } else {
            deleteDialogRef.current?.hide();
          }
        }}
      />
      <EditShipment
        isOpen={isEditShipmentOpen}
        onReload={() => listRef.current?.reload()}
        toggle={() => setIsEditShipmentOpen(!isEditShipmentOpen)}
        from="SearchResults"
        shipment={selectedShipment!}
      />
    </Modal>
  );
};

export default SellThis;

type AddUserShipmentForm = {
  shipmentName: string;
  userStore: string;
};
