import DataTable, {DataTableRef} from "Components/Common/DataTable";
import Dialog, {DialogRef} from "Components/Common/Dialog";
import {Link} from "react-router-dom";
import {useEffect, useImperativeHandle, useMemo, useRef, useState} from "react";
import {Badge, Card, CardBody, Col, Container, Row} from "reactstrap";
import {useTranslation} from "react-i18next";
import {ColumnDef} from "@tanstack/react-table";
import {GetUsersQuery, User, UserStatus} from "models/user";
import {createSelector} from "reselect";
import {RootState} from "slices";
import {useDispatch, useSelector} from "react-redux";
import {useProfile} from "Components/Hooks/UserHooks";
import {useImmer} from "use-immer";
import {useDebounce} from "Components/Hooks/HelperHooks";
import {ConstantPage} from "helpers/permission_helper";
import {getUsers, blockUser, unblockUser, resetUserPassword} from "slices/admin/users/thunk";
import {UserLimitType} from "models/user_limit_usage_history";
import BreadCrumb from "Components/Common/BreadCrumb";
import Restricted from "Components/Common/Restricted";
import Unauthorized from "pages/Errors/_Unauthorized";
import Loader from "Components/Common/Loader";
import Filters from "./Filters";
import Moment from "react-moment";
import NoResult from "Components/Common/NoResult";
import PaymentHistoryModal from "./Modals/PaymentHistory";
import defaultAvatar from "assets/images/user-dummy-img.jpg";
import i18n from "i18n";
import LogHistoryModal from "./Modals/LogHistory";
import LoginAsModal from "./Modals/LoginAs";
import RenderNumber from "Components/Common/RenderNumber";
import DefaultUncontrolledTooltip from "Components/Common/DefaultUncontrolledTooltip";

export type UserListRef = {
  reload: VoidFunction;
};

const PAGE_IDENTIFIER: ConstantPage = "adminUsers";
const Users = () => {
  const {t} = useTranslation();
  const {userProfile, hasPermission} = useProfile();
  const [selectedUser, setSelectedUser] = useState<User>({} as User);
  const [isLoginAsModalOpen, setIsLoginAsModalOpen] = useState<boolean>(false);
  const [isPaymentHistoryModalOpen, setIsPaymentHistoryModalOpen] = useState<boolean>(false);
  const [isLogHistoryModalOpen, setIsLogHistoryModalOpen] = useState<boolean>(false);
  const tableRef = useRef<DataTableRef>(null);
  const listRef = useRef<UserListRef>(null);
  const dispatch: any = useDispatch();
  const resetPasswordDialog = useRef<DialogRef>(null);
  const blockUserDialog = useRef<DialogRef>(null);
  const unblockUserDialog = useRef<DialogRef>(null);

  const [query, updateQuery] = useImmer<GetUsersQuery>({
    filtering: true,
    page: 1,
    pageSize: 10,
  });

  const debouncedLoadList = useDebounce(() => {
    if (hasPermission(PAGE_IDENTIFIER)) {
      getUsers(query)(dispatch).then(() => {
        tableRef.current?.resetSelection();
      });
    }
  }, 200);

  useEffect(() => {
    debouncedLoadList();
  }, [debouncedLoadList, query]); // eslint-disable-line

  useImperativeHandle(
    listRef,
    () => {
      return {
        reload: () => {
          debouncedLoadList();
        },
      };
    },
    [debouncedLoadList],
  );

  const userData = createSelector(
    (state: RootState) => state,
    (state) => ({
      loading: state.AdminUsers.loading,
      list: state.AdminUsers.list,
    }),
  );
  const {loading, list} = useSelector(userData);

  const columns = useMemo<ColumnDef<User, any>[]>(
    () => [
      {
        id: "actions",
        header: t("Admin.Users.TableColumn.Actions"),
        size: 120,
        cell: (cellProps) => {
          const row = cellProps.row.original as User;
          return (
            <>
              {row.userId !== userProfile?.userId && (
                <>
                  <Link
                    id={`LoginAs-${row.userId}`}
                    to=""
                    className="btn btn-ghost-secondary px-1 py-0 fs-18"
                    onClick={() => {
                      setSelectedUser(row);
                      //loginAsDialog.current?.show();
                      setIsLoginAsModalOpen(true);
                    }}
                  >
                    <i className="mdi mdi-account-key"></i>
                  </Link>
                  <DefaultUncontrolledTooltip target={`LoginAs-${row.userId}`}>{t("Admin.Users.Tooltip.LoginAs")}</DefaultUncontrolledTooltip>
                </>
              )}

              <Link
                id={`Logs-${row.userId}`}
                to=""
                className="btn btn-ghost-secondary px-1 py-0 fs-18"
                onClick={() => {
                  setSelectedUser(row);
                  setIsLogHistoryModalOpen(true);
                }}
              >
                <i className="mdi mdi-text-box"></i>
              </Link>
              <DefaultUncontrolledTooltip target={`Logs-${row.userId}`}>{t("Admin.Users.Tooltip.Logs")}</DefaultUncontrolledTooltip>

              <Link
                id={`PaymentHistory-${row.userId}`}
                to=""
                className="btn btn-ghost-success px-1 py-0 fs-18"
                onClick={() => {
                  setSelectedUser(row);
                  setIsPaymentHistoryModalOpen(true);
                }}
              >
                <i className="ri-refund-2-line"></i>
              </Link>
              <DefaultUncontrolledTooltip target={`PaymentHistory-${row.userId}`}>{t("Admin.Users.Tooltip.PaymentHistory")}</DefaultUncontrolledTooltip>

              <Link
                id={`PasswordReset-${row.userId}`}
                to=""
                className="btn btn-ghost-info px-1 py-0 fs-18"
                onClick={() => {
                  setSelectedUser(row);
                  resetPasswordDialog.current?.show();
                }}
              >
                <i className="mdi mdi-lock"></i>
              </Link>
              <DefaultUncontrolledTooltip target={`PasswordReset-${row.userId}`}>{t("Admin.Users.Tooltip.PasswordReset")}</DefaultUncontrolledTooltip>

              {row.status !== UserStatus.BANNED ? (
                <>
                  {row.userId !== userProfile?.userId && (
                    <>
                      <Link
                        id={`Block-${row.userId}`}
                        to=""
                        className="btn btn-ghost-danger px-1 py-0 fs-18"
                        onClick={() => {
                          setSelectedUser(row);
                          blockUserDialog.current?.show();
                        }}
                      >
                        <i className="mdi mdi-close-circle"></i>
                      </Link>
                      <DefaultUncontrolledTooltip target={`Block-${row.userId}`}>{t("Admin.Users.Tooltip.Block")}</DefaultUncontrolledTooltip>
                    </>
                  )}
                </>
              ) : (
                <>
                  <Link
                    id={`Unblock-${row.userId}`}
                    to=""
                    className="btn btn-ghost-success px-1 py-0 fs-18"
                    onClick={() => {
                      setSelectedUser(row);
                      unblockUserDialog.current?.show();
                    }}
                  >
                    <i className="mdi mdi-check-circle"></i>
                  </Link>
                  <DefaultUncontrolledTooltip target={`Unblock-${row.userId}`}>{t("Admin.Users.Tooltip.Unblock")}</DefaultUncontrolledTooltip>
                </>
              )}
            </>
          );
        },
      },
      {
        id: "email",
        header: t("Admin.Users.TableColumn.EmailAndName"),
        size: 100,
        accessorKey: "email",
        sortingFn: "alphanumeric",
        cell: (cellProps) => {
          const row = cellProps.row.original as User;
          return (
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0 me-2">
                <img src={row.avatar || defaultAvatar} className="rounded-circle avatar-sm img-thumbnail user-profile-image" alt="user-profile" />
              </div>
              <div className="flex-grow-1 ellipsis-two-lines">
                <div className="fw-semibold">
                  {row.fullName}
                  {row.userId === userProfile?.userId && <Badge className="ms-1">{t("You")}</Badge>}
                </div>
                <div className="text-muted" title={row.email}>
                  {row.email}
                </div>
              </div>
            </div>
          );
        },
      },
      {
        header: t("Admin.Users.TableColumn.RegisterDate"),
        size: 100,
        cell: (cellProps) => {
          const row = cellProps.row.original as User;
          return (
            <>
              <Moment locale={i18n.language} format="D MMM YYYY HH:mm" tz={userProfile?.timezone}>
                {row.registrationDate}
              </Moment>
            </>
          );
        },
      },
      {
        header: t("Admin.Users.TableColumn.Subscription"),
        size: 100,
        cell: (cellProps) => {
          const row = cellProps.row.original as User;
          const subscription = row.userSubscriptions.find((x) => x.isActive)?.subscription;
          return <>{subscription?.name ?? "-"}</>;
        },
      },
      {
        header: t("Admin.Users.TableColumn.SubscriptionRenew"),
        size: 100,
        cell: (cellProps) => {
          const row = cellProps.row.original as User;
          const userSubscription = row.userSubscriptions.find((x) => x.isActive);
          return (
            <>
              {userSubscription?.endDate ? (
                <Moment locale={i18n.language} format="D MMM YYYY HH:mm" tz={userProfile?.timezone}>
                  {userSubscription?.endDate}
                </Moment>
              ) : (
                <>-</>
              )}
            </>
          );
        },
      },
      {
        header: t("Admin.Users.TableColumn.LastLoginDate"),
        size: 100,
        accessorFn: (item) => item.lastLogin,
        sortingFn: "datetime",
        cell: (cellProps) => {
          const row = cellProps.row.original as User;
          return (
            <>
              {row.lastLogin ? (
                <Moment locale={i18n.language} format="D MMM YYYY HH:mm" tz={userProfile?.timezone}>
                  {row.lastLogin}
                </Moment>
              ) : (
                t("Admin.Users.TableColumn.NoLoginMessage")
              )}
            </>
          );
        },
      },
      {
        header: t("Admin.Users.TableColumn.CurrentLimits"),
        size: 100,
        accessorFn: (item) => item.lastLogin,
        sortingFn: "datetime",
        cell: (cellProps) => {
          const row = cellProps.row.original as User;
          const userSubscription = row.userSubscriptions.find((x) => x.isActive);
          const searchLimit = userSubscription?.limits.find((x) => x.type === UserLimitType.SEARCH);
          const scanAndSaveLimit = userSubscription?.limits.find((x) => x.type === UserLimitType.CRAWLER_SCAN);
          return (
            <>
              <Row>
                <Col xs={12} className="d-flex flex-row">
                  {searchLimit?.dailyRemaining && (
                    <>
                      <span className="me-2">{t("Analysis")}:</span>
                      <span>
                        <RenderNumber value={searchLimit?.dailyRemaining} />
                      </span>
                    </>
                  )}
                </Col>
                <Col xs={12}>
                  {scanAndSaveLimit?.dailyRemaining && (
                    <>
                      <span className="me-2">{t("Scan&Save")}:</span>
                      <span>
                        <RenderNumber value={scanAndSaveLimit?.dailyRemaining} />
                      </span>
                    </>
                  )}
                </Col>
                {!searchLimit?.dailyRemaining && !scanAndSaveLimit?.dailyRemaining && <Col>-</Col>}
              </Row>
            </>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t],
  );

  document.title = t("PageTitles.Users");

  return (
    <Restricted require={PAGE_IDENTIFIER} read fallback={() => <Unauthorized />}>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t("Admin.Users.Title")} menus={[{label: t("Admin.Users.Title")}]} />
          <>
            <Filters busy={loading.filter} page={query.page} pageSize={query.pageSize} handleFilter={(f) => updateQuery(f)} />
          </>
          <Card>
            <CardBody>
              {loading.filter ? (
                <>
                  <Loader />
                </>
              ) : list.items && list.items.length > 0 ? (
                <Restricted require={"adminUsers"} read>
                  <LoginAsModal show={isLoginAsModalOpen} toggle={() => setIsLoginAsModalOpen(!isLoginAsModalOpen)} user={selectedUser} />
                  <PaymentHistoryModal
                    show={isPaymentHistoryModalOpen}
                    toggle={() => {
                      setIsPaymentHistoryModalOpen(!isPaymentHistoryModalOpen);
                    }}
                    user={selectedUser}
                  />
                  <LogHistoryModal
                    show={isLogHistoryModalOpen}
                    toggle={() => {
                      setIsLogHistoryModalOpen(!isLogHistoryModalOpen);
                    }}
                    user={selectedUser}
                  />
                  <DataTable
                     
                    ref={tableRef}
                    busy={loading.list}
                    columns={columns}
                    data={list.items || []}
                    totalDataLength={list.totalCount}
                    showColumnSelect
                    pagination={{
                      pageIndex: query.page - 1,
                      pageSize: query.pageSize,
                    }}
                    onPaginationChanged={(pagination) => {
                      updateQuery((q) => {
                        q.page = pagination.pageIndex + 1;
                        q.pageSize = pagination.pageSize;
                        q.filtering = false;
                      });
                    }}
                    hovered
                  />
                </Restricted>
              ) : (
                <NoResult title={t("Admin.Users.NoResult.Title")} description={t("Admin.Users.NoResult.Description")} />
              )}
            </CardBody>
          </Card>
        </Container>
      </div>
      <Dialog
        ref={resetPasswordDialog}
        color="info"
        buttons={["yes", "no"]}
        busy={loading.unblock}
        iconClass="ri-alert-fill"
        message={t("Admin.Users.Dialog.ResetPassword.Description")}
        title={t("Admin.Users.Dialog.ResetPassword.Title", {value: selectedUser?.email})}
        onButtonClick={async (button, hide) => {
          if (button === "yes" && selectedUser) {
            await dispatch(resetUserPassword(selectedUser));
          }
          resetPasswordDialog.current?.hide();
        }}
      />
      <Dialog
        ref={blockUserDialog}
        color="danger"
        buttons={["yes", "no"]}
        busy={loading.block}
        iconClass="ri-alert-fill"
        message={t("Admin.Users.Dialog.BlockUser.Description")}
        title={t("Admin.Users.Dialog.BlockUser.Title", {value: selectedUser?.email})}
        onButtonClick={async (button, hide) => {
          if (button === "yes" && selectedUser) {
            await dispatch(blockUser(selectedUser));
          }
          blockUserDialog.current?.hide();
        }}
      />
      <Dialog
        ref={unblockUserDialog}
        color="success"
        buttons={["yes", "no"]}
        busy={loading.unblock}
        iconClass="ri-alert-fill"
        message={t("Admin.Users.Dialog.UnblockUser.Description")}
        title={t("Admin.Users.Dialog.UnblockUser.Title", {value: selectedUser?.email})}
        onButtonClick={async (button, hide) => {
          if (button === "yes" && selectedUser) {
            await dispatch(unblockUser(selectedUser));
          }
          unblockUserDialog.current?.hide();
        }}
      />
    </Restricted>
  );
};

export default Users;
