import {useProfile} from "Components/Hooks/UserHooks";
import {UserSearchProduct} from "models/user_search_product";
import {useTranslation} from "react-i18next";
import {Card, CardBody, CardHeader, Table, UncontrolledPopover} from "reactstrap";
import Moment from "react-moment";
import i18n from "i18n";

interface OthersProps {
  row: UserSearchProduct;
}
const Others = ({row}: OthersProps) => {
  const {t} = useTranslation();
  const {userProfile} = useProfile();
  const statusCss = (status?: boolean): string => {
    let className = "";

    if (status === true) {
      className = "bx bxs-check-circle text-success";
    } else if (status === false) {
      className = "bx bxs-x-circle text-danger";
    } else {
      className = "text-muted ri-donut-chart-line";
    }

    return className;
  };

  return (
    <Card className="border card-animate">
      <CardHeader>
        <div className="d-flex justify-content-between align-items-center">
          <h6 className="card-title mb-0">{t("Admin.SearchLogs.Box.Results.Others.Title")}</h6>
        </div>
      </CardHeader>
      <CardBody>
        <ul className="list-group">
          <li className="d-flex justify-content-between align-items-center">
            <span>Restriction Check</span>
            <span>
              <h2 className="mb-0 mt-1">
                <i id={`RestrictionCheck-${row.userSearchProductId}`} className={`${statusCss(row.spapiRestrictionCheckSucceeded)}`} role="button"></i>
              </h2>
              <UncontrolledPopover trigger="legacy" placement="right" target={`RestrictionCheck-${row.userSearchProductId}`}>
                <Table className="align-middle table table-hover mb-0">
                  <tbody>
                  <tr>
                      <td>{t("Admin.SearchLogs.Tooltip.RetryCount")}</td>
                      <td>
                        {row.spapiRestrictionCheckRetryCount}
                      </td>
                    </tr>
                    <tr>
                      <td>{t("Admin.SearchLogs.Tooltip.Request")}</td>
                      <td>
                        {row.spapiRestrictionCheckRequestDate ? (
                          <Moment locale={i18n.language} format="D MMM YYYY HH:mm" tz={userProfile?.timezone}>
                            {row.spapiRestrictionCheckRequestDate}
                          </Moment>
                        ) : (
                          <span className="badge bg-warning-subtle text-warning">{t("Waiting")}</span>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>{t("Admin.SearchLogs.Tooltip.Result")}</td>
                      <td>{row.spapiRestrictionCheckResultDate ? (
                          <Moment locale={i18n.language} format="D MMM YYYY HH:mm" tz={userProfile?.timezone}>
                            {row.spapiRestrictionCheckResultDate}
                          </Moment>
                        ) : (
                          <span className="badge bg-warning-subtle text-warning">{t("Waiting")}</span>
                        )}</td>
                    </tr>
                  </tbody>
                </Table>
              </UncontrolledPopover>
            </span>
          </li>
          <li className="d-flex justify-content-between align-items-center">
            <span>Connect Api</span>
            <span>
              <h2 className="mb-0 mt-1">
                <i id={`ConnectApi-${row.userSearchProductId}`} className={`${statusCss(row.spapiRestrictionCheckSucceeded)}`} role="button"></i>
              </h2>
              <UncontrolledPopover trigger="legacy" placement="right" target={`ConnectApi-${row.userSearchProductId}`}>
                <Table className="align-middle table table-hover mb-0">
                  <tbody>
                    <tr>
                      <td>{t("Admin.SearchLogs.Tooltip.Result")}</td>
                      <td>
                        {row.connectApiResultDate ? (
                          <Moment locale={i18n.language} format="D MMM YYYY HH:mm" tz={userProfile?.timezone}>
                            {row.connectApiResultDate}
                          </Moment>
                        ) : (
                          <span className="badge bg-warning-subtle text-warning">{t("Waiting")}</span>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </UncontrolledPopover>
            </span>
          </li>
        </ul>
      </CardBody>
    </Card>
  );
};

export default Others;
