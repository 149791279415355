import {useSelector} from "react-redux";
import {Flip, toast} from "react-toastify";
import {Col, Row} from "reactstrap";
import {createSelector} from "reselect";
import {RootState} from "slices";
import i18n from "i18n";
import {NavigateFunction} from "react-router-dom";
import {preventScrollUp} from "helpers/utilities";

const FAVORITE_TOAST_ID = "favorite";
type ToastChangeFavoriteType = "add" | "remove";
interface ToastChangeFavoriteListProps {
  type: ToastChangeFavoriteType;
  asin?: string;
  navigate?: NavigateFunction;
}

export const renderFavoriteToast = (processType: ToastChangeFavoriteType, asin?: string, navigate?: NavigateFunction) => {
  if (!toast.isActive(FAVORITE_TOAST_ID)) {
    toast(<ToastChangeFavoriteList type={processType} asin={asin} navigate={navigate} />, {
      toastId: FAVORITE_TOAST_ID,
      position: "bottom-right",
      type: processType === "add" ? "success" : "error",
      hideProgressBar: false,
      closeButton: false,
      closeOnClick: false,
      transition: Flip,
    });
  } else {
    toast.update(FAVORITE_TOAST_ID, {
      render: <ToastChangeFavoriteList type={processType} asin={asin} navigate={navigate} />,
      position: "bottom-right",
      type: processType === "add" ? "success" : "error",
      hideProgressBar: false,
      closeButton: false,
      closeOnClick: false,
      transition: Flip,
    });
  }
};

const ToastChangeFavoriteList = ({type, asin, navigate}: ToastChangeFavoriteListProps) => {
  const getSearchResultsData = createSelector(
    (state: RootState) => state,
    (state) => ({
      currentFavoriteList: state.SearchResult.currentFavoriteList,
      pageUrl: state.SearchResult.pageUrl,
    }),
  );

  const {currentFavoriteList, pageUrl} = useSelector(getSearchResultsData);

  const handleToggleModal = () => {
    if (navigate) {
      navigate(`${pageUrl}/add-to-favorites?asin=${asin}`);
      preventScrollUp();
    }
  };

  return (
    <>
      <Row>
        <Col>
          {type === "add" ? (
            <>
              {currentFavoriteList?.name ? (
                <>
                  {i18n.t("Favorites.Toast.AddedToThisList")} <span className="fw-semibold "> {currentFavoriteList.name}</span>{" "}
                </>
              ) : (
                <> {i18n.t("Favorites.Toast.AddedToTheCurrentList")}</>
              )}
            </>
          ) : (
            <>{i18n.t("Favorites.Toast.RemovedFromYourFavorites")}</>
          )}
        </Col>
        <Col xs="auto">
          {type === "add" && currentFavoriteList?.name && (
            <span role="button" className="link-secondary link-offset-2 text-decoration-underline hand" onClick={handleToggleModal}>
              {i18n.t("Change")}
            </span>
          )}
        </Col>
      </Row>
    </>
  );
};

export default ToastChangeFavoriteList;
