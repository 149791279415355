import React, {useCallback, useEffect, useState} from "react";
import {Card, CardBody, CardHeader, Col} from "reactstrap";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {DashboardSaleHistoryChart, DashboardSaleHistorySeries} from "./Charts/SaleHistoryChart";
import {createSelector} from "reselect";
import {RootState} from "slices";
import {useSelector} from "react-redux";
import {convertCurrency, getRelativeDate, getToday} from "helpers/utilities";
import {get} from "lodash";
import {OrderStatus, UserOrder} from "models/user_order";
import months from "common/month";
import i18n from "i18n";

type GraphicData = {soldItemsCount: number; revenue: number; profit: number; currencyCodeTarget: string};
const SalesHistory = () => {
  const {t} = useTranslation();
  const [saleHistorySeries, setSaleHistorySeries] = useState<DashboardSaleHistorySeries[]>([]);
  const [categories, setCategories] = useState<string[]>([]);
  const dashboardData = createSelector(
    (state: RootState) => state,
    (state) => ({
      filter: state.Dashboard.filter,
      statistics: state.Dashboard.statistics,
      currencies: state.Common.currencies,
    }),
  );
  const {filter, statistics, currencies} = useSelector(dashboardData);

  const handleFilterChange = useCallback(() => {
    const shipmentOrders = statistics.orders.filter((order: UserOrder) => order.orderStatus === OrderStatus.SHIPPED);
    const filterDay = filter?.dateRange || 1;
    const minDate = getRelativeDate(filterDay - 1);

    const graphicData = processGraphicsData(minDate, shipmentOrders, filterDay);

    let categories: string[] = [];

    if (filterDay > 0 && filterDay <= 90) {
      categories = generateDailyCategories(filterDay);
    } else if (filterDay > 90 && filterDay <= 180) {
      categories = generateWeeklyCategories();
    } else if (filterDay === 365) {
      categories = generateMonthlyCategories();
    }
    setCategories(categories);

    const array: DashboardSaleHistorySeries[] = [
      {
        name: t("Dashboard.SalesHistory.Label.SoldItemsCount"),
        type: "area",
        data: graphicData.map((item) => item.soldItemsCount),
      },
      {
        name: t("Dashboard.SalesHistory.Label.Revenue"),
        type: "bar",
        data: graphicData.map((item) => convertCurrency(currencies, item.currencyCodeTarget, filter?.currency!, item.revenue)),
      },
      {
        name: t("Dashboard.SalesHistory.Label.Profit"),
        type: "bar",
        data: graphicData.map((item) => convertCurrency(currencies, item.currencyCodeTarget, filter?.currency!, item.profit)),
      },
    ];
    setSaleHistorySeries(array);
  }, [t, filter, statistics]); // eslint-disable-line react-hooks/exhaustive-deps

  const generateDailyCategories = (filterDay: number): string[] => {
    const today: Date = getToday().toDate();
    let minDate: Date = getRelativeDate(filterDay - 1);
    const result: string[] = [];

    while (minDate <= today) {
      const month: string = get(months, i18n.language)[minDate.getMonth()];
      result.push(`${month} ${minDate.getDate()}`);
      minDate.setDate(minDate.getDate() + 1);
    }
    return result;
  };

  const generateWeeklyCategories = (): string[] => {
    const today: Date = getToday().toDate();
    let minDate: Date = getRelativeDate(180);
    const result: string[] = [];

    while (minDate <= today) {
      const month: string = get(months, i18n.language)[minDate.getMonth()];
      const weekNumber: number = Math.ceil(minDate.getDate() / 7);

      if (weekNumber <= 4 || minDate.getDate() > 28) result.push(`${month} ${t("Week")} ${weekNumber}`);

      minDate.setDate(minDate.getDate() + 7);
    }
    return result;
  };

  const generateMonthlyCategories = () => {
    const monthNames = get(months, i18n.language);
    const date = getToday().toDate();
    const currentMonth = date.getMonth();
    const categories = [];

    for (let i = 0; i < 12; i++) {
      const monthIndex = (currentMonth - i + 12) % 12;
      categories.unshift(monthNames[monthIndex]);
    }

    return categories;
  };

  const processGraphicsData = (minDate: Date, data: UserOrder[], filterDay: number): GraphicData[] => {
    let groupedData: GraphicData[] = [];
    const sortedShipmentOrders = data && data.length > 0 ? [...data] : [];
    sortedShipmentOrders.sort((a, b) => {
      if (a.purchaseDate < b.purchaseDate) return -1;
      if (a.purchaseDate > b.purchaseDate) return 1;
      return 0;
    });

    const maxDate = getToday().toDate();
    let currentDate = new Date(minDate);
    while (currentDate <= maxDate) {
      const itemsOfPeriod =
        data && data.length > 0
          ? data.filter((order) => {
              const assignmentDate = new Date(order.purchaseDate);
              if (filterDay > 0 && filterDay <= 90) {
                return assignmentDate.toDateString() === currentDate.toDateString();
              } else if (filterDay > 90 && filterDay <= 180) {
                const weekStart = new Date(currentDate);
                const weekEnd = new Date(currentDate);
                weekEnd.setDate(weekStart.getDate() + 6);
                return assignmentDate >= weekStart && assignmentDate <= weekEnd;
              } else {
                const monthStart = new Date(currentDate);
                const monthEnd = new Date(currentDate);
                monthEnd.setMonth(monthStart.getMonth() + 1);
                monthEnd.setDate(0); // Last day of the month
                return assignmentDate >= monthStart && assignmentDate <= monthEnd;
              }
            })
          : [];

      groupedData.push({
        soldItemsCount: itemsOfPeriod.reduce((a, b) => a + (b.quantityOrdered ?? 0), 0),
        revenue: itemsOfPeriod.reduce((a, b) => a + (b.totalSalesProceeds ?? 0), 0),
        profit: itemsOfPeriod.reduce((a, b) => a + (b.profit ?? 0), 0),
        currencyCodeTarget: itemsOfPeriod.length > 0 ? itemsOfPeriod[0].currencyCodeTarget! : filter?.currency!,
      });
      if (filterDay > 0 && filterDay <= 90) {
        currentDate.setDate(currentDate.getDate() + 1);
      } else if (filterDay > 90 && filterDay <= 180) {
        currentDate.setDate(currentDate.getDate() + 7);
      } else {
        currentDate.setMonth(currentDate.getMonth() + 1);
      }
    }
    return groupedData;
  };

  useEffect(() => {
    handleFilterChange();
  }, [handleFilterChange]);

  return (
    <React.Fragment>
      <Col xl={8} className="same-height">
        <Card className="card-animate">
          <CardHeader className="align-items-center d-flex card-header">
            <h4 className="card-title mb-0 flex-grow-1">{t("Dashboard.SalesHistory.Title")}</h4>
            <div className="flex-shrink-0">
              <Link className="link-body-emphasis text-decoration-underline link-underline-opacity-25 link-underline-opacity-75-hover mb-0" to={`/orders`}>
                {t("Dashboard.SalesHistory.Link.ViewOrders")}
              </Link>
            </div>
          </CardHeader>
          <CardBody className="p-0 pb-2">
            <div className="w-100">
              <div dir="ltr">
                <DashboardSaleHistoryChart series={saleHistorySeries} categories={categories} dataColors='["#13c56b", "#50c3e6", "--vz-warning"]' currency={filter?.currency} />
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default SalesHistory;
