import {useEffect, useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import {CardHeader, Row, Col, Button, Badge} from "reactstrap";
import {RootState} from "slices";
import {UserSearchProduct} from "models/user_search_product";
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "reselect";
import {useTranslation} from "react-i18next";
import {useProfile} from "Components/Hooks/UserHooks";
import {getToday, preventScrollUp} from "helpers/utilities";
import {SummaryCard} from "./Components/SummaryCard";
import {addFavoriteItem, removeFavoriteItem} from "slices/search-result2/thunk";
import CopyAsinWidget from "Components/Common/CopyAsinWidget";
import DomainToFlag from "Components/Common/DomainToFlag";
import Rating from "react-rating";
import Moment from "react-moment";
import i18n from "i18n";
import moment from "moment";
import logoDarkTransparent from "assets/images/logo-dark-transparent.png";
import DefaultUncontrolledTooltip from "Components/Common/DefaultUncontrolledTooltip";
import DisplayPrice from "Components/Common/DisplayPrice";
import classNames from "classnames";
import _ from "lodash";
interface DecisionReasonsLinkProps {
  items: string[];
  isWarning?: boolean | false;
}
const DecisionReasonsLink = ({items, isWarning}: DecisionReasonsLinkProps) => {
  const {t} = useTranslation();
  return items.length === 1 ? t("SearchResults.Summary.Reason", {count: items.length}) : t("SearchResults.Summary.ReasonPlural", {count: items.length});
};

interface SummaryProps {
  item: UserSearchProduct;
}
const Summary = ({item}: SummaryProps) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {userProfile} = useProfile();
  const {favoriteId} = useParams();
  const [addingOrRemovingFromFavorites, setAddingOrRemovingFromFavorites] = useState<boolean>(false);
  const [isInUserShipment, setIsInUserShipment] = useState<boolean>(item.inUserShipment || false);
  const searchResultsData = createSelector(
    (state: RootState) => state,
    (state) => ({
      pageUrl: state.SearchResult.pageUrl,
      showCheckList: state.SearchResult.result.showCheckList,
      currentFavoriteList: state.SearchResult.currentFavoriteList,
      shipmentInfo: state.Common.shipmentInfo,
    }),
  );
  const {pageUrl, showCheckList, currentFavoriteList, shipmentInfo} = useSelector(searchResultsData);

  useEffect(() => {
    if (!_.isEmpty(shipmentInfo)) {
      let isExistOnAnyShipment = shipmentInfo?.asinList?.find((x) => x === item.asin);
      if (isExistOnAnyShipment) {
        setIsInUserShipment(true);
      } else {
        setIsInUserShipment(false);
      }
    }
  }, [shipmentInfo]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleAddToFavoriteList = async () => {
    preventScrollUp();
    // dispatch(setSearchResultSelectedAsin(item.asin));

    const {asin, userFavoriteId, marketplaceTarget} = item;
    const favoriteListId = favoriteId || currentFavoriteList?.userFavoriteId;

    if (userFavoriteId) {
      setAddingOrRemovingFromFavorites(true);
      await removeFavoriteItem(userFavoriteId, asin, marketplaceTarget, true, navigate)(dispatch);
      setAddingOrRemovingFromFavorites(false);
    } else if (favoriteListId) {
      setAddingOrRemovingFromFavorites(true);
      await addFavoriteItem(favoriteListId, asin, marketplaceTarget, true, navigate)(dispatch);
      setAddingOrRemovingFromFavorites(false);
    } else {
      navigate(`${pageUrl}/add-to-favorites?asin=${asin}`);
    }
  };

  return (
    <>
      <CardHeader className="common-css padding-bottom-0 border-bottom-0">
        <Row className="g-4 ">
          <Col xl={1} lg={2} sm={3} className="mx-auto">
            {item.summary?.imageBig && (
              <div className="product-img-slider product-img-container">
                <img src={`https://m.media-amazon.com/images/I/${item.summary.imageBig}`} alt="" className="rounded w-100" />
              </div>
            )}

            <div className="w-auto mt-2 text-center">
              <Button
                className={"rounded-pill p-0 " + (isInUserShipment ? "btn-success" : "btn-secondary")}
                id={`SellThisButton-${item.userSearchProductId}`}
                style={{width: "80%"}}
                onClick={() => {
                  if (showCheckList) {
                    preventScrollUp();
                    navigate(`${pageUrl}/check-list?asin=${item.asin}`);
                  } else {
                    preventScrollUp();
                    navigate(`${pageUrl}/sell-this?asin=${item.asin}&marketplace=${item.marketplaceTarget}`);
                  }
                }}
              >
                <img src={logoDarkTransparent} alt="" width="100%" className="px-2 py-1" />
              </Button>
              <DefaultUncontrolledTooltip target={`SellThisButton-${item.userSearchProductId}`}>
                {isInUserShipment ? t("SearchResults.SellThisButtonReverse") : t("SearchResults.SellThisButton")}
              </DefaultUncontrolledTooltip>
            </div>
          </Col>

          <Col xl={11} lg={10} sm={9}>
            <div className="mt-xl-0">
              <div className="d-flex">
                <div className="flex-grow-1">
                  <Row>
                    <Col className="col-md">
                      <h4 className="ellipsis-single-line mb-0">{item.summary?.title}</h4>
                      <div className="text-muted mb-2 ">
                        {item.summary?.categoryPath ? item.summary.categoryPath.replaceAll("|", " > ") : item.summary?.categoryName ? item.summary.categoryName : t("Category") + ": " + t("NA")}
                      </div>
                    </Col>
                    <Col className="col-auto">
                      <div className="hstack gap-0 flex-wrap">
                        <button
                          type="button"
                          id={`AddToFavoriteButton-${item.userSearchProductId}`}
                          className={`btn p-0 ${item.userFavoriteId && "favorite-btn active"}`}
                          onClick={handleAddToFavoriteList}
                          disabled={addingOrRemovingFromFavorites}
                        >
                          <i className="ri-star-fill fs-20"></i>
                        </button>
                        <DefaultUncontrolledTooltip autohide={true} target={`AddToFavoriteButton-${item.userSearchProductId}`}>
                          {item.userFavoriteId ? t("SearchResults.Badge.RemoveFromFavorites") : t("SearchResults.Badge.AddToFavorites")}
                        </DefaultUncontrolledTooltip>
                      </div>
                    </Col>
                  </Row>

                  <div className="hstack column-gap-4 row-gap-1 flex-wrap">
                    <CopyAsinWidget Asin={item.asin} index={`CopyAsin-${item.userSearchProductId}`} bold={true} />

                    <div className="d-flex align-items-center">
                      {item.asin ? (
                        <>
                          <DomainToFlag marketplace={"us"} />
                          <Link to={`https://www.amazon.com/dp/${item.asin}?th=1&psc=1`} target="_blank" className="link-secondary link-offset-2 text-decoration-underline common-css">
                            .com
                            <i className="ri-arrow-right-up-line"></i>
                          </Link>
                          &nbsp;&nbsp;
                          {item.domainTarget ? (
                            <>
                              <DomainToFlag marketplace={item.marketplaceTarget} />
                              <Link
                                to={`https://www.amazon.${item.domainTarget}/dp/${item.asin}?th=1&psc=1`}
                                target="_blank"
                                className="link-secondary link-offset-2 text-decoration-underline common-css"
                              >
                                .{item.domainTarget}
                                <i className="ri-arrow-right-up-line"></i>
                              </Link>
                            </>
                          ) : (
                            <span className="common-css top--5">{t("NA")}</span>
                          )}
                        </>
                      ) : (
                        t("NA")
                      )}
                    </div>

                    {item.summary?.reviewCount && (
                      <>
                        <div className="text-muted">
                          <Rating
                            initialRating={item.summary?.reviewRating ?? 0}
                            fractions={2}
                            emptySymbol="mdi mdi-star-outline fs-16 text-muted "
                            fullSymbol="mdi mdi-star fs-16 text-warning "
                            readonly={true}
                          />{" "}
                          <span className="text-muted">
                            ({item.summary?.reviewCount.toLocaleString() ?? 0}{" "}
                            {item.summary?.reviewCount > 0 ? t("SearchResults.Summary.ProductReviewPlural") : t("SearchResults.Summary.ProductReview")})
                          </span>
                        </div>
                      </>
                    )}

                    <div className="d-flex align-items-center">
                      {item.summary?.isEligible != null ? (
                        <>
                          <i className={item.summary.isEligible ? "mdi mdi-check fs-20" : "bx bx-x fs-20"}></i>
                          <span className={"fw-bold " + (item.summary.isEligible ? "text-secondary" : "text-danger")}>
                            {item.summary.isEligible ? t("SearchResults.Summary.EligibleToSell") : t("SearchResults.Summary.NotEligibleToSell")}
                          </span>
                          {item.summary?.ineligibilityReasonList && !item.summary.isEligible ? (
                            <>
                              <Link
                                to={`${pageUrl}/ineligibility-reasons?asin=${item.asin}`}
                                id={`PopoverIneligibilityReasonList-${item.userSearchProductId}`}
                                className="ms-1 fs-12 link-secondary link-offset-1 text-decoration-underline common-css"
                                onClick={() => preventScrollUp()}
                              >
                                <DecisionReasonsLink items={item.summary?.ineligibilityReasonList?.split(",") ?? []} />
                              </Link>
                              <DefaultUncontrolledTooltip target={`PopoverIneligibilityReasonList-${item.userSearchProductId}`}>{t("Click for details")}</DefaultUncontrolledTooltip>
                            </>
                          ) : (
                            ""
                          )}
                        </>
                      ) : (
                        <span className="text-muted">{t("SearchResults.Summary.Eligibility") + t("NA")}</span>
                      )}
                    </div>

                    <div className="d-flex align-items-center">
                      {getToday().diff(moment.tz(item.statusDate, userProfile?.timezone!), "hours") > 48 ? (
                        <>
                          <i
                            className={`ri-error-warning-fill fs-18 ${getToday().diff(moment.tz(item.statusDate, userProfile?.timezone!), "days") > 7 ? "text-danger" : "text-warning"} me-1`}
                            id={`AnalysisWarningMessage-${item.userSearchProductId}`}
                          ></i>
                          <DefaultUncontrolledTooltip target={`AnalysisWarningMessage-${item.userSearchProductId}`}>
                            {t("SearchResults.Summary.AnalysisWarningMessage", {hours: 48})}
                          </DefaultUncontrolledTooltip>
                        </>
                      ) : (
                        <i className="bx bx-calendar-check fs-18 me-1"></i>
                      )}

                      <span
                        title={moment
                          .tz(item.statusDate, userProfile?.timezone!)
                          .locale(i18n.language)
                          .format("D MMM YYYY HH:mm")}
                      >
                        <Moment className="text-muted" locale={i18n.language} tz={userProfile?.timezone} fromNow>
                          {item.statusDate}
                        </Moment>
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <Row className="mt-4">
                <SummaryCard
                  icon="mdi mdi-chart-timeline-variant-shimmer"
                  label={t("SearchResults.Summary.Decision")}
                  content={
                    <div className="d-flex align-items-center">
                      {item.summary?.decision ? (
                        <Badge color={item.summary.decision === "Sellable" ? "success" : item.summary.decision === "Recheck" ? "warning" : "danger"} className="fs-11">
                          {t(item.summary.decision)}
                          {item.summary.decision === "Sellable" && <i className="mdi mdi-check ms-1"></i>}
                          {}
                        </Badge>
                      ) : (
                        " " + t("NA")
                      )}
                      {item.summary.decisionReasons ? (
                        <>
                          <Link
                            to={`${pageUrl}/decision-reasons?asin=${item.asin}`}
                            id={`PopoverDecisionReasons-${item.userSearchProductId}`}
                            className="ms-1 fs-12 link-secondary link-offset-1 text-decoration-underline common-css"
                            onClick={() => {
                              preventScrollUp();
                            }}
                          >
                            {item.summary?.decision === "Sellable" ? (
                              <>
                                <i className="mdi mdi-alert align-middle fs-18 text-warning"></i>
                                <DefaultUncontrolledTooltip target={`PopoverDecisionReasons-${item.userSearchProductId}`}>
                                  {t("SearchResults.Summary.DecisionSellableWarning")}
                                </DefaultUncontrolledTooltip>
                              </>
                            ) : (
                              <>
                                <DecisionReasonsLink items={item.summary?.decisionReasons.split(",")} />
                                <DefaultUncontrolledTooltip target={`PopoverDecisionReasons-${item.userSearchProductId}`}>{t("Click for details")}</DefaultUncontrolledTooltip>
                              </>
                            )}
                          </Link>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  }
                />
                <SummaryCard
                  icon="mdi mdi-finance"
                  label={t("SearchResults.Summary.SaleCount.Title")}
                  content={
                    <div className="d-flex align-items-center">
                      {item.saleAnalysis?.saleCount ? (
                        <>
                          <span>{item.saleAnalysis.saleCount.toLocaleString()}</span>
                          <small className="fs-13 text-muted">
                            <i className="mdi mdi-slash-forward"></i>
                            {t("month")}
                          </small>
                        </>
                      ) : (
                        t("NA")
                      )}{" "}
                      {item.saleAnalysis?.hasAnomalies && (
                        <>
                          <i id={`PopoverHasAnomaliesSummary-${item.userSearchProductId}`} className="ms-1 mdi mdi-alert fs-20 text-warning"></i>
                          <DefaultUncontrolledTooltip target={`PopoverHasAnomaliesSummary-${item.userSearchProductId}`}>{t("SearchResults.Summary.SaleCount.Anomalies")}</DefaultUncontrolledTooltip>
                        </>
                      )}
                      {item.saleAnalysis?.precision && !item.saleAnalysis.hasAnomalies && (
                        <>
                          <i
                            id={`PopoverPrecisionSummary-${item.userSearchProductId}`}
                            className={`ms-1 mdi ${
                              item.saleAnalysis.precision === "VeryClose"
                                ? "mdi-check-circle text-secondary"
                                : item.saleAnalysis.precision === "Medium"
                                ? "mdi-progress-check text-secondary fs-18"
                                : item.saleAnalysis.precision === "Prediction"
                                ? "mdi-progress-clock text-danger fs-18"
                                : ""
                            }`}
                          ></i>
                          <DefaultUncontrolledTooltip target={`PopoverPrecisionSummary-${item.userSearchProductId}`}>
                            [{t("SearchResults.Summary.SaleCount.Precision")}: {item.saleAnalysis.precision}] {t(`SearchResults.Summary.SaleCount.Precisions.${item.saleAnalysis.precision}`)}
                          </DefaultUncontrolledTooltip>
                        </>
                      )}
                    </div>
                  }
                />

                <SummaryCard
                  icon="mdi mdi-currency-usd"
                  label={t("SearchResults.Summary.ProfitAndROI")}
                  content={
                    <>
                      {item.profitAnalysis.profit ? (
                        <span className={classNames({"text-danger": item.profitAnalysis.profit < 0}, "float-start")}>
                          {<DisplayPrice source={item.currencyCodeTarget} value={item.profitAnalysis.profit} decimals={2} />}
                        </span>
                      ) : (
                        <span>{t("NA")}</span>
                      )}
                      {item.profitAnalysis.roi ? (
                        <span className={`badge fs-15 ms-2 ${item.profitAnalysis.roi < 0 ? "bg-danger-subtle text-danger" : "bg-success-subtle text-success"}`}> %{item.profitAnalysis.roi}</span>
                      ) : (
                        ""
                      )}
                    </>
                  }
                />
                <SummaryCard
                  icon="mdi mdi-layers-triple-outline"
                  label={t("SearchResults.Summary.Variation")}
                  content={
                    <>
                      {item.variationAnalysis?.variationCount ? (
                        <span
                          className={
                            item.variationAnalysis.variationCount < 2
                              ? "badge bg-success fs-14 common-css"
                              : item.variationAnalysis.variationCount < 5
                              ? "badge bg-warning fs-14 common-css"
                              : "badge bg-danger fs-14 common-css"
                          }
                        >
                          {item.variationAnalysis.variationCount}
                          {item.variationAnalysis.variationCount < 2 && <i className="mdi mdi-check ms-1"></i>}
                        </span>
                      ) : (
                        <span>{t("NA")}</span>
                      )}
                    </>
                  }
                />
                <SummaryCard
                  icon={
                    item.competitorAnalysis.competitionLevel === "Weak"
                      ? "mdi mdi-speedometer-slow text-success"
                      : item.competitorAnalysis.competitionLevel === "Medium"
                      ? "mdi mdi-speedometer-medium text-warning"
                      : "mdi mdi-speedometer text-danger"
                  }
                  label={t("SearchResults.Summary.Competition")}
                  content={
                    <>
                      {item.competitorAnalysis?.competitionLevel ? (
                        <Badge color={item.competitorAnalysis.competitionLevel === "Weak" ? "success" : item.competitorAnalysis.competitionLevel === "Medium" ? "warning" : "danger"} className="fs-14">
                          {t(item.competitorAnalysis.competitionLevel)}
                          {item.competitorAnalysis.competitionLevel === "Weak" && <i className="mdi mdi-check ms-1"></i>}
                        </Badge>
                      ) : (
                        t("NA")
                      )}
                    </>
                  }
                />
                <SummaryCard
                  icon="mdi mdi-trophy-outline text-secondary"
                  label={`BSR ${item.domainTarget ? " - " + item.domainTarget.replace("com.", "").toUpperCase() : ""}`}
                  content={<>{item.summary?.bsr ? item.summary.bsr.toLocaleString() : t("NA")}</>}
                />
              </Row>
            </div>
          </Col>
        </Row>
      </CardHeader>
    </>
  );
};

export default Summary;
