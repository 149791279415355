import {preventScrollUp} from "helpers/utilities";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {Modal, ModalHeader, ModalBody} from "reactstrap";
import {createSelector} from "reselect";
import {RootState} from "slices";

interface IneligibilityReasonListProps {
  isOpen: boolean;
}
const IneligibilityReasonList = ({isOpen}: IneligibilityReasonListProps) => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const [ineligibilityReasonList, setIneligibilityReasonList] = useState<string[]>();

  const searchResultsData = createSelector(
    (state: RootState) => state,
    (state) => ({
      result: state.SearchResult.result,
      pageUrl: state.SearchResult.pageUrl,
    }),
  );
  const {result, pageUrl} = useSelector(searchResultsData);

  const toggle = () => {
    navigate(pageUrl);
    preventScrollUp();
  };

  useEffect(() => {
    if (isOpen) {
      const queryParams = new URLSearchParams(window.location.search);
      const asin = queryParams.get("asin");
      if (asin) {
        const item = result.products.items?.find((x) => x.asin === asin);
        item ? setIneligibilityReasonList(item.summary.ineligibilityReasonList?.split(",")) : navigate(pageUrl);
      }
    }
  }, [isOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Modal id="showIneligibilityReasonListModal" isOpen={isOpen} toggle={toggle} fade={true} centered={true}>
      <ModalHeader className="bg-light p-3" toggle={toggle}>
        {t("SearchResults.Dialog.IneligibilityReasonsList.Title")}
      </ModalHeader>
      <ModalBody>
        {ineligibilityReasonList ? (
          ineligibilityReasonList.map((reason: string, index: number) => (
            <div key={index} className="d-flex">
              <div className="flex-shrink-0 me-2">
                <i className={`bx ${reason.endsWith("Recheck") ? "bxs-error text-warning" : "bxs-x-circle text-danger"} fs-20`}></i>
              </div>
              <div className="flex-grow-1">
                <h5>{reason}</h5>
                <p className="text-muted">{t(`IneligibilityReasons.${reason}`)}</p>
              </div>
            </div>
          ))
        ) : (
          <div>{t("No reason")}</div>
        )}
      </ModalBody>
    </Modal>
  );
};

export default IneligibilityReasonList;
