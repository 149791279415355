import {postGetUserStores} from "services/store_service";
import {ShipmentInfo, apiError, loading, reset, setShipmentInfo, setUserStores} from "./reducer";
import {ResponseModel} from "models/response_model";
import {UserStore} from "models/user_stores";
import {generateError, renderSuccessToast} from "helpers/utilities";
import {
  postAddUserShipmentItem,
  postDeleteUserShipment,
  postDeleteUserShipmentItem,
  postGetUserDraftShipments,
  postGetUserShipmentsFromSearchResults,
  postSetUserShipmentDetails,
} from "services/shipment_service";
import {IAddUserShipmentItemDto, IDeleteUserShipmentDto, IDeleteUserShipmentItemDto, IGetUserShipmentsFromSearchResultsDto, ISetUserShipmentDetailsDto} from "models/user_shipment";
import camelcaseKeys from "camelcase-keys";
import i18n from "i18n";

export const loadUserStores = () => async (dispatch: any) => {
  try {
    dispatch(loading(["userStore", true]));
    const response: ResponseModel = await postGetUserStores();
    const result = JSON.parse(response.data);
    const stores: UserStore[] = camelcaseKeys(result);
    dispatch(setUserStores(stores));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["userStore", false]));
  }
};
//deprecated
export const getUserShipmentsFromSearchResults = (getUserShipmentsFromSearchResultsRequest: IGetUserShipmentsFromSearchResultsDto) => async (dispatch: any) => {
  try {
    dispatch(loading(["userShipment", true]));
    const result: ResponseModel = await postGetUserShipmentsFromSearchResults(getUserShipmentsFromSearchResultsRequest);
    dispatch(setShipmentInfo(result.data));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["userShipment", false]));
  }
};

export const getUserDraftShipments = (asin: string, marketplace: string) => async (dispatch: any) => {
  try {
    dispatch(loading(["userShipment", true]));
    const result: ResponseModel = await postGetUserDraftShipments({asin, marketplace});
    dispatch(setShipmentInfo(result.data));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["userShipment", false]));
  }
};

export const deleteUserShipmentFromSearchResult = (deleteUserShipmentRequest: IDeleteUserShipmentDto) => async (dispatch: any) => {
  try {
    dispatch(loading(["delete", true]));
    const result: ResponseModel = await postDeleteUserShipment(deleteUserShipmentRequest);
    renderSuccessToast(i18n.t(result.message));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["delete", false]));
  }
};

export const deleteUserShipmentItemFromSearchResult = (deleteUserShipmentItemRequest: IDeleteUserShipmentItemDto) => async (dispatch: any) => {
  try {
    dispatch(loading(["delete", true]));
    const result: ResponseModel = await postDeleteUserShipmentItem(deleteUserShipmentItemRequest);
    const userShipments: ShipmentInfo = result.data;
    dispatch(setShipmentInfo(userShipments));
    renderSuccessToast(i18n.t(result.message));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["delete", false]));
  }
};

export const addUserShipmentItemFromSearchResult = (addUserShipmentItemRequest: IAddUserShipmentItemDto) => async (dispatch: any) => {
  try {
    dispatch(loading(["save", true]));
    const result: ResponseModel = await postAddUserShipmentItem(addUserShipmentItemRequest);
    const userShipments: ShipmentInfo = result.data;
    dispatch(setShipmentInfo(userShipments));
    renderSuccessToast(i18n.t(result.message));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["save", false]));
  }
};

export const setUserShipmentDetailsFromSearchResult = (newShipmentDetails: ISetUserShipmentDetailsDto) => async (dispatch: any) => {
  try {
    dispatch(loading(["update", true]));
    const result: ResponseModel = await postSetUserShipmentDetails(newShipmentDetails);
    const userShipments: ShipmentInfo = result.data;
    dispatch(setShipmentInfo(userShipments));
    renderSuccessToast(i18n.t(result.message));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["update", false]));
  }
};

export const resetCommonState = () => async (dispatch: any) => {
  dispatch(reset());
};
