import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {Card, CardBody} from "reactstrap";
import {createSelector} from "reselect";
import {RootState} from "slices";
import PlaceholderGlow from "Components/Common/PlaceholderGlow";
import CountUp from "react-countup";
import DomainToFlag from "Components/Common/DomainToFlag";

const TokenStatistics = () => {
  const {t} = useTranslation();

  const systemMonitoringData = createSelector(
    (state: RootState) => state,
    (state) => ({
      systemMonitoring: state.AdminSystemMonitoring.systemMonitoring,
      loading: state.AdminSystemMonitoring.loading,
    }),
  );

  const {systemMonitoring, loading} = useSelector(systemMonitoringData);
  return (
    <>
      <PlaceholderGlow busy={loading.list}>
        <Card className="card-animate mb-0">
          <div className="align-items-center d-flex card-header">
            <h4 className="card-title mb-0 flex-grow-1">{t("Admin.SystemMonitoring.Section.Title.TokenStatistics")}</h4>
          </div>
          <CardBody>
            <div className="table-responsive table-card">
              <table className="table-hover table table-centered table-borderless border-0 align-middle mb-0 border-top-0">
                <thead className="text-muted table-light">
                  <tr className="border">
                    <th scope="col">{t("Marketplace")}</th>
                    <th scope="col">{t("Active")}</th>
                    <th scope="col">{t("Passive")}</th>
                    <th scope="col">{t("Total")}</th>
                  </tr>
                </thead>
                <tbody>
                  {systemMonitoring.marketplacesTokensCount?.map((item, key) => {
                    return (
                      <tr key={key}>
                        <td>
                          <div className="d-flex align-items-center">
                            <DomainToFlag marketplace={item.marketplace} />
                            <span className="ellipsis-single-line">{item.marketplace}</span>
                          </div>
                        </td>
                        <td>
                          <span className="counter-value">
                            <CountUp end={item.active} duration={2} />
                          </span>
                        </td>
                        <td>
                          <span className="counter-value">
                            <CountUp end={item.passive} duration={2} />
                          </span>
                        </td>
                        <td>
                          <span className="counter-value">
                            <CountUp end={item.total} duration={2} />
                          </span>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </CardBody>
        </Card>
      </PlaceholderGlow>
    </>
  );
};

export default TokenStatistics;
