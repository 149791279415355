import BreadCrumb, {BreadcrumbMenuItem} from "Components/Common/BreadCrumb";
import {useEffect, useState} from "react";
import {Card, CardBody, Container} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import {getUserShipmentDetails, resetShipmentState} from "slices/thunks";
import {createSelector} from "reselect";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {RootState} from "slices";
import {UserShipmentStatus} from "models/user_shipment";
import {ConstantPage} from "helpers/permission_helper";
import {useProfile} from "Components/Hooks/UserHooks";
import PlaceholderGlow from "Components/Common/PlaceholderGlow";
import Restricted from "Components/Common/Restricted";
import Unauthorized from "pages/Errors/_Unauthorized";
import Draft from "./Draft";
import ShippedOrCompleted from "./ShippedOrCompleted";
import AnalyzeShipment from "./AnalyzeShipment";
import useSelectOptions, { SelectOptionsType } from "Components/Hooks/SelectOptions";
import { UserStore } from "models/user_stores";
import AmazonMarketplaceInfos from "Components/Common/AmazonMarketplaceInfos";
import { loadUserStores } from "slices/common/thunk";
import SellThis from "Components/Common/SellThis";


const PAGE_IDENTIFIER: ConstantPage = "shipmentDetails";

interface ShipmentDetailsProps {
  analyze?:boolean;
  sellThis?:boolean;
}
const ShipmentDetails = (props:ShipmentDetailsProps) => {
  const {t} = useTranslation();
  const {hasPermission} = useProfile();
  const dispatch: any = useDispatch();
  const {userShipmentId} = useParams();
  const [amazonMarketplaceInfos] = useState(AmazonMarketplaceInfos());
  const {marketplaceSelectOptions} = useSelectOptions();
  const [marketplaceSelectOptionsWithStores, setMarketplaceSelectOptionsWithStores] = useState(marketplaceSelectOptions);
  const shipmentsData = createSelector(
    (state: RootState) => state,
    (state) => ({
      loading: state.Shipment.loading,
      currentUserShipment: state.Shipment.currentUserShipment,
      userStores: state.Common.userStores
    }),
  );

  const {loading, currentUserShipment,userStores} = useSelector(shipmentsData);

  useEffect(() => {
    if (hasPermission(PAGE_IDENTIFIER)) {
      getUserShipmentDetails({userShipmentId: userShipmentId ?? ""})(dispatch);
      loadUserStores()(dispatch);
    }
    return () => {
      resetShipmentState()(dispatch);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    const userStoreOptions: SelectOptionsType[] = marketplaceSelectOptions;
    userStores.forEach((store: UserStore) => {
      const name = amazonMarketplaceInfos.find((amazonMarketplaceInfo: any) => amazonMarketplaceInfo.marketplace === store.marketplace)?.countryName + " - " + store.name;
      userStoreOptions.unshift({value: store.userStoreId, label: name});
    });
    setMarketplaceSelectOptionsWithStores(userStoreOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userStores]);


  document.title = t("PageTitles.ShipmentDetails") + " - " + currentUserShipment?.name;
  const breadcrumbMenus: BreadcrumbMenuItem[] = [
    {
      label: t("Shipments.Title"),
      url: "/shipments",
    },
    {
      label: `${t("Shipments.ShipmentDetails.Title")} - ${currentUserShipment?.name}`,
      url: "",
    },
  ];
  return (
    <Restricted require={PAGE_IDENTIFIER} read fallback={() => <Unauthorized />}>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t("Shipments.ShipmentDetails.Title")} menus={breadcrumbMenus} />
          <AnalyzeShipment isOpen={props.analyze || false} userStoresSelectOptions={marketplaceSelectOptionsWithStores} />
        <SellThis isOpen={props.sellThis || false} />
          <PlaceholderGlow busy={loading.list || loading.implementSettings}>
            <>
              {currentUserShipment && currentUserShipment.userShipmentId ? (
                <>{currentUserShipment.status === UserShipmentStatus.DRAFT ? <Draft /> : <ShippedOrCompleted />}</>
              ) : loading.list ? (
                <Card>
                  <CardBody>
                    <div>{t("Loading...")}</div>
                  </CardBody>
                </Card>
              ) : (
                <Card>
                  <CardBody>
                    <div>{t("Shipments.ShipmentDetails.NoShipmentDetails")}</div>
                  </CardBody>
                </Card>
              )}
            </>
          </PlaceholderGlow>
        </Container>
      </div>
    </Restricted>
  );
};

export default ShipmentDetails;
