import {
  ICancelUserSubscriptionDto,
  IChangeUserSubscriptionDto,
  IContinueUserSubscriptionDto,
  ICreateSubscriptionDto,
  ICreateUserSubscriptionDto,
  IDownloadInvoiceDto,
  IRetrySubscriptionPaymentDto,
  IValidateCouponDto,
} from "models/user_subscription";
import {APIClient} from "../helpers/api_helper";
import {ICreditCardDto} from "models/user_payment";
import {IDeleteUserPaymentMethodDto, ISetAsDefaultPaymentMethodDto, IUpdateUserPaymentMethodDto} from "models/user_payment_methods";
import {ResponseModel} from "models/response_model";
import {GetPaymentsQuery} from "api/query";
import * as url from "../helpers/url_helper";
import {LOCAL_STORAGE} from "helpers/local_storage";

const api = new APIClient();

export const getUserPayments = (data: GetPaymentsQuery): Promise<ResponseModel> => api.get(url.GET_USER_PAYMENTS, data);

export const postGetUserPaymentMethods = (): Promise<ResponseModel> => api.get(url.GET_USER_PAYMENT_METHODS);

export const postSaveUserSubscription = (data: ICreateSubscriptionDto) => api.create(url.POST_SAVE_USER_SUBSCRIPTION, data);

export const postGetSubscriptions = (): Promise<any> => api.create(url.GET_SUBSCRIPTIONS, []);

export const postChangeUserSubscription = (data: IChangeUserSubscriptionDto) => api.create(url.POST_CHANGE_USER_SUBSCRIPTION, data);

export const postCreateUserSubscription = (data: ICreateUserSubscriptionDto) => api.create(url.POST_CREATE_USER_SUBSCRIPTION, data);

export const postCancelUserSubscription = (data: ICancelUserSubscriptionDto) => api.create(url.POST_CANCEL_USER_SUBSCRIPTION, data);

export const postResumeUserSubscription = (data: IContinueUserSubscriptionDto) => api.create(url.POST_RESUME_USER_SUBSCRIPTION, data);

export const postDownloadInvoice = (data: IDownloadInvoiceDto) => api.create(url.POST_DOWNLOAD_INVOICE, data);

export const postRetrySubscriptionPayment = (data: IRetrySubscriptionPaymentDto) => api.create(url.POST_RETRY_SUBSCRIPTION_PAYMENT, data);

export const postSaveUserPaymentMethod = (data: ICreditCardDto) => api.create(url.POST_SAVE_USER_PAYMENT_METHOD, data);

export const postUpdateUserPaymentMethod = (data: IUpdateUserPaymentMethodDto) => api.create(url.POST_UPDATE_USER_PAYMENT_METHOD, data);

export const postDeleteUserPaymentMethod = (data: IDeleteUserPaymentMethodDto) => api.create(url.POST_DELETE_USER_PAYMENT_METHOD, data);

export const postSetAsDefaultPaymentMethod = (data: ISetAsDefaultPaymentMethodDto) => api.create(url.POST_SET_AS_DEFAULT_PAYMENT_METHOD, data);

export const postValidateCoupon = (data: IValidateCouponDto) => api.create(url.POST_VALIDATE_COUPON, data);

export const getSelectedSubscription = () => {
  const subscription = localStorage.getItem(LOCAL_STORAGE.SUBSCRIPTION);
  if (subscription) return JSON.parse(subscription);
  return null;
};
