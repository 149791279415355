import {
  IChangePasswordDto,
  IEmailVerificationDto,
  IForgotPasswordDto,
  IGetInvitationInformationDto,
  ILoginDto,
  ILoginWithTokenDto,
  IRefreshTokenDto,
  IRegisterUserDto,
  IResetPasswordDto,
  IUpdateGeneralInformationDto,
  IUpdateUIPreferencesDto,
} from "models/application_user";
import {APIClient} from "../helpers/api_helper";
import * as url from "../helpers/url_helper";
import {ResponseModel} from "models/response_model";
import {
  ICreateCostAndFeeItemDto,
  IDeleteCostAndFeeItemDto,
  IDuplicateCostAndFeeItemDto,
  IGetCostAndFeeItemDto,
  IGetCostAndFeesListQuery,
  IResetDefaultAnalyzeItemDto,
  IResetDefaultCostAndFeeItemDto,
  ISetAsDefaultCostAndFeeItemDto,
  IUpdateCostAndFeeItemDto,
} from "models/user_setting_cost_and_fee_options";
import {ICreateAnalyzeItemDto, IDeleteAnalyzeItemDto, IGetAnalyzeItemDto, ISetAsDefaultAnalyzeItemDto, IUpdateAnalyzeItemDto} from "models/user_setting_analyze_options";
import {IUpdateExchangeOptions} from "models/user_setting_exchange_options";
import {ICreateOrUpdateSubUserInvitationDto, IRefreshSubUserInvitationDto, SendReportErrorRequest} from "models/user";
import { UserLimitUsageHistoryQuery } from "api/query";

const api = new APIClient();

export const postLogin = (data: ILoginDto): Promise<ResponseModel> => api.create(url.POST_LOGIN, data);

export const postLoginWithToken = (data: ILoginWithTokenDto): Promise<ResponseModel> => api.create(url.POST_LOGIN_WITH_TOKEN, data);

export const postRegister = (data: IRegisterUserDto) => api.create(url.POST_REGISTER, data);

export const postRefreshToken = (data: IRefreshTokenDto) => api.create(url.POST_REFRESH_TOKEN, data);

export const postGetInvitationInformation = (data: IGetInvitationInformationDto) => api.create(url.POST_GET_INVITATION_INFORMATION, data);

export const postEmailVerification = (data: IEmailVerificationDto) => api.create(url.POST_EMAIL_VERIFICATION, data);

export const postSendPhoneVerificationCode = (data: any) => api.create(url.POST_SEND_PHONE_VERIFICATION_CODE, data);

export const postPhoneVerification = (data: any) => api.create(url.POST_PHONE_VERIFICATION, data);

export const postForgotPassword = (data: IForgotPasswordDto) => api.create(url.POST_FORGET_PASSWORD, data);

export const postResetPassword = (data: IResetPasswordDto) => api.create(url.POST_RESET_PASSWORD, data);

export const postUpdateGeneralInformation = (data: IUpdateGeneralInformationDto): Promise<ResponseModel> => api.create(url.POST_UPDATE_GENERAL_INFORMATION, data);

export const postUpdateUIPreferences = (data: IUpdateUIPreferencesDto): Promise<ResponseModel> => api.create(url.POST_UPDATE_UI_PREFERENCES, data);

export const postUpdateAvatar = (data: FormData): Promise<ResponseModel> => api.create(url.POST_UPDATE_AVATAR, data, { headers: { "Content-Type": "multipart/form-data" } });

export const postChangePassword = (data: IChangePasswordDto): Promise<ResponseModel> => api.create(url.POST_CHANGE_PASSWORD, data);

export const getUserSubscription = (): Promise<ResponseModel> => api.get(url.GET_USER_SUBSCRIPTION);

export const getLoggedUserFromApi = (): Promise<ResponseModel> => api.get(url.GET_LOGGED_USER);

export const postGetCostAndFees = (data: IGetCostAndFeesListQuery): Promise<ResponseModel> => api.create(url.POST_GET_COSTS_AND_FEES_LIST, data);

export const postGetCostAndFeesItem = (data: IGetCostAndFeeItemDto): Promise<ResponseModel> => api.create(url.POST_GET_COSTS_AND_FEES_ITEM, data);

export const postUpdateCostAndFeeItem = (data: IUpdateCostAndFeeItemDto): Promise<ResponseModel> => api.create(url.POST_UPDATE_COST_AND_FEE_ITEM, data);

export const postSaveCostAndFeeItem = (data: ICreateCostAndFeeItemDto): Promise<ResponseModel> => api.create(url.POST_SAVE_COST_AND_FEE_ITEM, data);

export const postSetAsDefaultCostAndFeeItem = (data: ISetAsDefaultCostAndFeeItemDto): Promise<ResponseModel> => api.create(url.POST_SET_AS_DEFAULT_COST_AND_FEE_ITEM, data);

export const postDuplicateCostAndFeeItem = (data: IDuplicateCostAndFeeItemDto): Promise<ResponseModel> => api.create(url.POST_DUPLICATE_COST_AND_FEE_ITEM, data);

export const postResetDefaultCostAndFeeItem = (data: IResetDefaultCostAndFeeItemDto): Promise<ResponseModel> => api.create(url.POST_RESET_DEFAULT_COST_AND_FEE_ITEM, data);

export const deleteCostAndFeeItem = (data: IDeleteCostAndFeeItemDto): Promise<ResponseModel> => api.delete(url.DELETE_COST_AND_FEE_ITEM, {data});

export const postGetAnalyzeOptions = (): Promise<ResponseModel> => api.create(url.POST_GET_ANALYZE_OPTIONS, []);

export const postGetAnalyzeItem = (data: IGetAnalyzeItemDto): Promise<ResponseModel> => api.create(url.POST_GET_ANALYZE_ITEM, data);

export const postSaveAnalyzeItem = (data: ICreateAnalyzeItemDto): Promise<ResponseModel> => api.create(url.POST_SAVE_ANALYZE_ITEM, data);

export const postUpdateAnalyzeItem = (data: IUpdateAnalyzeItemDto): Promise<ResponseModel> => api.create(url.POST_UPDATE_ANALYZE_ITEM, data);

export const postSetAsDefaultAnalyzeItem = (data: ISetAsDefaultAnalyzeItemDto): Promise<ResponseModel> => api.create(url.POST_SET_AS_DEFAULT_ANALYZE_ITEM, data);

export const deleteAnalyzeItem = (data: IDeleteAnalyzeItemDto): Promise<ResponseModel> => api.delete(url.DELETE_ANALYZE_ITEM, {data});

export const postResetDefaultAnalyzeItem = (data: IResetDefaultAnalyzeItemDto): Promise<ResponseModel> => api.create(url.POST_RESET_DEFAULT_ANALYZE_ITEM, data);

export const postGetExchangeRates = (): Promise<ResponseModel> => api.create(url.POST_GET_EXCHANGE_RATES, []);

export const postUpdateExchangeRates = (data: IUpdateExchangeOptions): Promise<ResponseModel> => api.create(url.POST_UPDATE_EXCHANGE_RATES, data);

export const postGetNotificationOptions = (): Promise<ResponseModel> => api.create(url.POST_GET_NOTIFICATION_OPTIONS, []);

export const postUpdateNotificationOptions = (data: any): Promise<ResponseModel> => api.create(url.POST_UPDATE_NOTIFICATION_OPTIONS, data);

export const postGetSubUsers = () => api.create(url.POST_GET_SUB_USERS, []);

export const postSaveSubUserInvitation = (data: ICreateOrUpdateSubUserInvitationDto) => api.create(url.POST_SAVE_SUB_USER_INVITATION, data);

export const postUpdateSubUserInformation = (data: ICreateOrUpdateSubUserInvitationDto) => api.create(url.POST_UPDATE_SUB_USER_INFORMATION, data);

export const postResendSubUserInvitation = (data: IRefreshSubUserInvitationDto) => api.create(url.POST_RESEND_SUB_USER_INVITATION, data);

export const deleteSubUserRequest = (data: IRefreshSubUserInvitationDto) => api.delete(url.DELETE_SUB_USER, {data});

export const postGetUserLimitUsageHistory = (data: UserLimitUsageHistoryQuery): Promise<ResponseModel> => api.create(url.POST_GET_USER_LIMIT_USAGE_HISTORY, data);

export const postGetUserRemainingLimit = (): Promise<ResponseModel> => api.create(url.POST_GET_USER_REMAINING_LIMIT, []);

export const postSendSupportRequest = (data: FormData): Promise<ResponseModel> =>
  api.create(url.POST_SEND_SUPPORT_REQUEST, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export const postSendReportError = (data: SendReportErrorRequest): Promise<ResponseModel> => api.create(url.POST_SEND_REPORT_ERROR_REQUEST, data);

export const getCaptchaInfo = (): Promise<ResponseModel> => api.create(url.GET_CAPTCHA_INFO, []);
