import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import { getSearchResultLocalData } from "helpers/local_storage";
import {ApiError, LoadingStates, PagedList} from "helpers/types";
import {SearchResultFilter, SearchResults} from "models/search_result";
import {UserFavorite} from "models/user_favorites";
import {UserSearch} from "models/user_search";
import {UserSearchResultsFilter} from "models/user_search_result_filter";

type SearchResultLoadingStates = LoadingStates<"list" | "definedFilters" | "filter" | "update" | "save" | "delete" | "listFavorite" | "renameFavoriteList">;

export type SearchResultState = {
  filter: SearchResultFilter;
  result: SearchResults;
  favoriteList: Partial<PagedList<UserFavorite>>;
  currentFavoriteList?: UserFavorite;
  showDetails: boolean;
  pageUrl: string; // /searches/search-results/:searchId OR /favorites/:favoriteId
  definedFilters: UserSearchResultsFilter[];
  loading: SearchResultLoadingStates;
  error?: ApiError;
};

const initialState: SearchResultState = {
  filter: {} as SearchResultFilter,
  result: {
    products: {items: []},
    showCheckList: false,
    favorite: {} as UserFavorite,
    search: {} as UserSearch,
    stores: [],
    categories: [],
  },
  pageUrl: "",
  favoriteList: {items: []},
  currentFavoriteList: {} as UserFavorite,
  showDetails: getSearchResultLocalData()?.showDetails ?? true,
  definedFilters: [],
  loading: {
    list: false,
    listFavorite: false,
    definedFilters: false,
    filter: false,
    update: false,
    save: false,
    delete: false,
    renameFavoriteList: false,
  },
  error: {} as ApiError,
};

const SearchResultsSlice = createSlice({
  name: "SearchResults",
  initialState,
  reducers: {
    loading(state, action: PayloadAction<[keyof SearchResultLoadingStates, boolean]>) {
      const [operation, loadingState] = action.payload;
      state.loading[operation] = loadingState;
    },
    setResult(state, action: PayloadAction<SearchResults>) {
      state.result = action.payload;
    },
    setFilter(state, action: PayloadAction<SearchResultFilter>) {
      state.filter = action.payload;
    },
    setDefinedFilters(state, action: PayloadAction<UserSearchResultsFilter[]>) {
      state.definedFilters = action.payload;
    },
    setShowDetails(state, action: PayloadAction<boolean>) {
      state.showDetails = action.payload;
    },
    setShowCheckList(state, action: PayloadAction<boolean>) {
      state.result.showCheckList = action.payload;
    },
    setPageUrl(state, action: PayloadAction<string>) {
      state.pageUrl = action.payload;
    },
    setFavoriteList(state, action: PayloadAction<Partial<PagedList<UserFavorite>>>) {
      state.favoriteList = action.payload;
    },
    setCurrentFavoriteList(state, action: PayloadAction<UserFavorite>) {
      state.currentFavoriteList = action.payload;
    },
    addFavItem(state, action) {
      const {asin, userFavoriteId} = action.payload;
      state.result = {
        ...state.result,
        products: {
          ...state.result.products,
          items: state.result.products.items?.map((item) => (item.asin === asin ? {...item, userFavoriteId} : item)),
        },
      };
    },
    removeFavItem(state, action) {
      const {asin} = action.payload;
      state.result = {
        ...state.result,
        products: {
          ...state.result.products,
          items: state.result.products.items?.map((item) => (item.asin === asin ? {...item, userFavoriteId: undefined} : item)),
        },
      };
    },
    setInUserShipment(state, action) {
      const {asin, inUserShipment} = action.payload;
      state.result = {
        ...state.result,
        products: {
          ...state.result.products,
          items: state.result.products.items?.map((item) => (item.asin === asin ? {...item, inUserShipment} : item)),
        },
      };
    },
    apiError(state, action: PayloadAction<ApiError>) {
      state.error = action.payload;
    },
    reset(state) {
      state.currentFavoriteList = initialState.currentFavoriteList;
      state.definedFilters = initialState.definedFilters;
      state.error = initialState.error;
      state.favoriteList = initialState.favoriteList;
      state.filter = initialState.filter;
      state.loading = initialState.loading;
      state.pageUrl = initialState.pageUrl;
      state.result = initialState.result;
      state.showDetails = initialState.showDetails;
    },
  },
});

export const {
  loading,
  setResult,
  setFilter,
  setDefinedFilters,
  setShowDetails,
  setShowCheckList,
  setPageUrl,
  setFavoriteList,
  setCurrentFavoriteList,
  addFavItem,
  removeFavItem,
  setInUserShipment,
  apiError,
  reset,
} = SearchResultsSlice.actions;

export default SearchResultsSlice.reducer;
