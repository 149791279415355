import {useEffect} from "react";
import {useSelector} from "react-redux";
import {createSelector} from "reselect";
import {ButtonGroup} from "reactstrap";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import i18n from "i18n";
import withRouter from "../Components/Common/withRouter";
import LanguageDropdown from "Components/Common/LanguageDropdown";

const NonAuthLayout = ({children}: any) => {
  const {t} = useTranslation();
  const nonAuthData = createSelector(
    (state) => state.Layout.layoutModeType,
    (layoutModeType) => layoutModeType,
  );
  // Inside your component
  const layoutModeType = useSelector(nonAuthData);

  useEffect(() => {
    if (layoutModeType === "dark") {
      document.body.setAttribute("data-bs-theme", "dark");
    } else {
      document.body.setAttribute("data-bs-theme", "light");
    }
    return () => {
      document.body.removeAttribute("data-bs-theme");
    };
  }, [layoutModeType]);
  const helpCenterLink = i18n.language === "en" ? "https://help.sellthis.com/en" : i18n.language === "tr" ? "https://help.sellthis.com/tr" : "";
  return (
    <div>
      <div className="language-selection d-flex">
        {window.location.pathname === "/account/subscription/new" && (
          <ButtonGroup className="ms-1 topbar-head-dropdown header-item">
            <Link to="/account/logout" className="me-2">
              {t("Logout")}
            </Link>
            <Link to={helpCenterLink} className="btn btn-light btn btn-icon btn-topbar btn-ghost-primary rounded-circle" target="_blank">
              <i className=" ri-question-line fs-22"></i>
            </Link>
          </ButtonGroup>
        )}

        <LanguageDropdown />
      </div>
      {children}
    </div>
  );
};

export default withRouter(NonAuthLayout);
