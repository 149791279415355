import {useEffect} from "react";
import {useFormik} from "formik";
import {Button, Card, CardBody, Col, Form, Label, Row, Spinner} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {RootState} from "slices";
import {createSelector} from "reselect";
import {GetUsersQuery} from "models/user";
import {UserQuery} from "api/query";
import {DateRange} from "helpers/types";
import {getAllSubscriptionData} from "slices/admin/users/thunk";
import {Subscription} from "models/subscription";
import ValidatedInput from "Components/Common/ValidatedInput";
import PlaceholderGlow from "Components/Common/PlaceholderGlow";
import DateRangeInput from "Components/Common/DateRangeInput";
import ValidatedMultiSelect from "Components/Common/ValidatedMultiSelect";
import * as Yup from "yup";

interface FiltersProps {
  busy: boolean;
  page: number;
  pageSize: number;
  handleFilter: (filter: GetUsersQuery) => void;
}
const Filters = (props: FiltersProps) => {
  const {t} = useTranslation();
  const dispatch: any = useDispatch();
  useEffect(() => {
    var payload: GetUsersQuery = {
      page: 1,
      pageSize: props.pageSize,
      filtering: true,
    };
    props.handleFilter(payload);

    dispatch(getAllSubscriptionData());
  }, []); // eslint-disable-line

  const usersData = createSelector(
    (state: RootState) => state,
    (state) => ({
      loading: state.AdminUsers.loading,
      subscriptions: state.AdminUsers.subscriptions,
    }),
  );
  const {loading, subscriptions} = useSelector(usersData);

  const validation = useFormik({
    initialValues: {} as UserQuery,
    validationSchema: Yup.object<UserQuery>({
      search: Yup.string(),
      registerDateRange: Yup.object<DateRange>(),
      lastLoginDateRange: Yup.object<DateRange>(),
      subscriptionIds: Yup.array<String>(),
    }),
    onSubmit: (values: UserQuery) => {
      const payload: UserQuery = {
        page: 1,
        pageSize: props.pageSize,
        search: values.search,
        registerDateRange: values.registerDateRange,
        lastLoginDateRange: values.lastLoginDateRange,
        subscriptionIds: values.subscriptionIds,
        filtering: true,
      };
      props.handleFilter(payload);
    },
  });

  return (
    <>
      <PlaceholderGlow busy={props.busy}>
        <Card>
          <CardBody>
            <div className="live-preview">
              <Form onSubmit={validation.handleSubmit}>
                <Row className="align-items-center">
                  <Col>
                    <div className="flex-shrink-0">
                      <Label className="form-label text-muted">{t("Admin.Users.Filters.Title")}</Label>
                    </div>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col sm={2} className="users filters-col">
                    <Label htmlFor="search">{t("Admin.Users.Filters.Label.Search")}</Label>
                    <ValidatedInput validation={validation} field="search" maxLength={100} placeholder={t("Admin.Users.Filters.Label.SearchPlaceholder")} disableValidationUI />
                  </Col>

                  <Col sm={2} className="users filters-col">
                    <Label htmlFor="search">{t("Admin.Users.Filters.Label.RegisterDate")}</Label>
                    <DateRangeInput value={validation.values.registerDateRange} onChange={(val: DateRange) => validation.setFieldValue("registerDateRange", val)} />
                  </Col>

                  <Col sm={2} className="users filters-col">
                    <Label htmlFor="search">{t("Admin.Users.Filters.Label.LastLoginDate")}</Label>
                    <DateRangeInput value={validation.values.lastLoginDateRange} onChange={(val: DateRange) => validation.setFieldValue("lastLoginDateRange", val)} />
                  </Col>

                  <Col sm={2} className="users filters-col">
                    <Label htmlFor="search">{t("Admin.Users.Filters.Label.Subscription")}</Label>
                    <ValidatedMultiSelect
                      className="common-css filter-input"
                      options={subscriptions?.map((item: Subscription) => ({label: item.name, value: item.subscriptionId}))|| []}
                      validation={validation}
                      field="subscriptionIds"
                      value={validation.values.subscriptionIds}
                    />
                  </Col>

                  <Col sm={2} className="users filters-col align-content-end">
                    <Button id="submit" type="submit" className="btn bg-primary me-0 mt-3" disabled={loading.filter}>
                      {loading.filter ? <Spinner size="sm" className="me-2"></Spinner> : <i className="ri-equalizer-fill me-1 align-bottom"></i>}
                      {t("Admin.Users.Filters.Button.Apply")}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </CardBody>
        </Card>
      </PlaceholderGlow>
    </>
  );
};

export default Filters;
