import {t} from "i18next";
import React, {useState} from "react";
import {Link} from "react-router-dom";
import DefaultUncontrolledTooltip from "./DefaultUncontrolledTooltip";

const CopyAsinWidget = ({Asin, index, marketplace, bold, asLink, hideCopyIcon}: {Asin: any; index: any; marketplace?: string; bold?: boolean; asLink?: boolean; hideCopyIcon?: boolean}) => {
  const [copied, setCopied] = useState(false);

  const handleCopyClick = () => {
    navigator.clipboard.writeText(Asin);
    setCopied(true);
    setTimeout(() => setCopied(false), 1000);
  };

  return (
    <React.Fragment>
      <div className="d-flex align-items-center">
        {asLink ? (
          <Link to={`https://www.amazon.${marketplace || "com"}/dp/${Asin}?th=1&psc=1`} target="_blank" className={`link-offset-1 text-decoration-underline me-1 ${bold ? "fw-bold" : ""}`}>
            {Asin}
            <i className="ri-arrow-right-up-line"></i>
          </Link>
        ) : (
          <span className={`me-1 ${bold ? "fw-medium" : ""}`}>{Asin}</span>
        )}

        {hideCopyIcon ? (
          <></>
        ) : (
          <>
            <i className="ri-file-copy-line cursor-pointer fs-5" id={`CopyAsinTooltip-${index}`} onClick={handleCopyClick}></i>

            <DefaultUncontrolledTooltip delay={{show: 0, hide: 0}} target={`CopyAsinTooltip-${index}`}>
              {copied ? t("Copied") : t("Copy")}
            </DefaultUncontrolledTooltip>
          </>
        )}
      </div>
    </React.Fragment>
  );
};

export default CopyAsinWidget;
