import DefaultUncontrolledTooltip from "Components/Common/DefaultUncontrolledTooltip";
import {UserSearchProduct} from "models/user_search_product";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Card, CardBody, Col, Row} from "reactstrap";

interface ProductSizeProps {
  item: UserSearchProduct;
}
const ProductSize = ({item}: ProductSizeProps) => {
  const {t} = useTranslation();
  const [dimensionInMetric, setDimensionInMetric] = useState("");
  const [weightInMetric, setWeightInMetric] = useState("");
  const [dimensionalWeightInMetric, setDimensionalWeightInMetric] = useState("");

  const LIBRE_UNIT = 0.45359237; // 1lb equals to 0.45359237kg
  const INCH_UNIT = 2.54; // 1 in equals to 0.0254m

  useEffect(() => {
    if (item.productSize) {
      const splitDimension = item.productSize.dimensions?.replaceAll(" ", "").split("*");
      if (splitDimension) {
        let length = parseFloat(splitDimension[0]);
        let width = parseFloat(splitDimension[1]);
        let height = parseFloat(splitDimension[2]);

        if (length && width && height) {
          length = length * INCH_UNIT;
          width = width * INCH_UNIT;
          height = height * INCH_UNIT;

          setDimensionInMetric(`${length.toFixed(2)} * ${width.toFixed(2)} * ${height.toFixed(2)}`);
        }
      }

      if (item.productSize.weight) {
        setWeightInMetric((item.productSize.weight * LIBRE_UNIT).toFixed(2).toString());
      }
      if (item.productSize.dimensionalWeight) {
        setDimensionalWeightInMetric((item.productSize.dimensionalWeight * LIBRE_UNIT).toFixed(2).toString());
      }
    }
  }, [item]);

  return (
    <Card className="border card-animate">
      <CardBody>
        <h5>{t("SearchResults.Analyzes.ProductSize.Title")}</h5>
        <ul className="list-group d-flex flex-group gap-1">
          <li className="d-flex justify-content-between">
            <div className="d-flex align-items-center">
              <span>{t("SearchResults.Analyzes.ProductSize.Dimensions", {value: "inch"})}</span>{" "}
              <i id={`PopoverDimensions-${item.userSearchProductId}`} className="ms-1 bx bxs-info-circle text-secondary"></i>
              <DefaultUncontrolledTooltip target={`PopoverDimensions-${item.userSearchProductId}`}>{t("SearchResults.Analyzes.ProductSize.Tooltip.Dimensions")}</DefaultUncontrolledTooltip>
            </div>

            {item.productSize?.dimensions ? (
              <Row>
                <Col className="d-flex justify-content-end pe-0">
                  {item.productSize.dimensions}
                  {" inches"}
                </Col>
                <Col xs="auto" className="ps-0">
                  {item.productSize?.dimensionsStatus ? <i className="mdi mdi-check text-success ms-1"></i> : <i className="mdi mdi-alert align-middle text-warning ms-1"></i>}
                </Col>
              </Row>
            ) : (
              <span>{t("NA")}</span>
            )}
          </li>
          <li className="d-flex justify-content-between">
            <div className="d-flex align-items-center">
              <span>{t("SearchResults.Analyzes.ProductSize.Dimensions", {value: "cm"})}</span>
              <i id={`PopoverDimensions-${item.userSearchProductId}`} className="ms-1 bx bxs-info-circle text-secondary"></i>
              <DefaultUncontrolledTooltip target={`PopoverDimensions-${item.userSearchProductId}`}>{t("SearchResults.Analyzes.ProductSize.Tooltip.Dimensions")}</DefaultUncontrolledTooltip>
            </div>

            {item.productSize?.dimensions ? (
              <Row>
                <Col className="d-flex justify-content-end pe-0">
                  {dimensionInMetric}
                  {" cm"}
                </Col>
                <Col xs="auto" className="ps-0">
                  {item.productSize?.dimensionsStatus ? <i className="mdi mdi-check text-success ms-1"></i> : <i className="mdi mdi-alert align-middle text-warning ms-1"></i>}
                </Col>
              </Row>
            ) : (
              <span>{t("NA")}</span>
            )}
          </li>
          <li className="d-flex justify-content-between">
            <div>
              <span>{t("SearchResults.Analyzes.ProductSize.Weight")}</span>
            </div>
            {item.productSize?.weight ? (
              <span className="d-flex align-items-center">
                {item.productSize.weight} {t("lb")} {`(${weightInMetric} kg)`}
                {item.productSize.weightStatus ? <i className="mdi mdi-check text-success ms-1"></i> : <i className="mdi mdi-alert align-middle text-warning ms-1"></i>}
              </span>
            ) : (
              <span>{t("NA")}</span>
            )}
          </li>
          <li className="d-flex justify-content-between">
            <div className="d-flex align-items-center">
              <span>{t("SearchResults.Analyzes.ProductSize.DimensionalWeight")}</span>{" "}
              <i id={`PopoverDimensionalWeight-${item.userSearchProductId}`} className="ms-1 bx bxs-info-circle text-secondary"></i>
              <DefaultUncontrolledTooltip target={`PopoverDimensionalWeight-${item.userSearchProductId}`} placeholder="bg-danger">
                {t("Definitions.DimensionalWeight")}
              </DefaultUncontrolledTooltip>
            </div>
            {item.productSize?.dimensionalWeight ? (
              <span className="d-flex align-items-center">
                {item.productSize.dimensionalWeight} {t("lb")} {`(${dimensionalWeightInMetric} kg)`}
                {item.productSize.dimensionalWeightStatus ? <i className="mdi mdi-check text-success ms-1"></i> : <i className="mdi mdi-alert align-middle text-warning ms-1"></i>}
              </span>
            ) : (
              <span>{t("NA")}</span>
            )}
          </li>
        </ul>
      </CardBody>
    </Card>
  );
};

export default ProductSize;
