import {APIClient} from "../helpers/api_helper";
import {ResponseModel} from "models/response_model";
import {GetUsersQuery, SearchUserQuery} from "models/user";
import {
  CancelScanCommand,
  CancelUserSearchCommand,
  ResetSearchProductItemCommand,
  RestartScanCommand,
  RestartUserSearchCommand,
  BlockUserCommand,
  UnblockUserCommand,
  ResetUserPasswordCommand,
  GenerateUserLoginTokenCommand,
  ClearSpecificTopicCommand as ClearTopicCommand,
  UndoDeleteUserSearchCommand,
  ReBalanceOrchestrationServiceCommand,
} from "api/command";
import {AdminScanAndSaveQuery, AdminUserSearchesQuery, GetUserLogHistoryQuery, GetUserPaymentHistoryQuery, SearchLogQuery, SystemMonitoringDetailsQuery} from "api/query";
import * as url from "../helpers/url_helper";

const api = new APIClient();

export const postGetUsers = (data: GetUsersQuery): Promise<ResponseModel> => api.create(url.POST_GET_USERS, data);

export const postBlockUser = (data: BlockUserCommand): Promise<ResponseModel> => api.create(url.POST_BLOCK_USER, data);

export const postUnblockUser = (data: UnblockUserCommand): Promise<ResponseModel> => api.create(url.POST_UNBLOCK_USER, data);

export const postResetUserPassword = (data: ResetUserPasswordCommand): Promise<ResponseModel> => api.create(url.POST_RESET_USER_PASSWORD, data);

export const postGenerateUserLoginToken = (data: GenerateUserLoginTokenCommand): Promise<ResponseModel> => api.create(url.POST_GENERATE_USER_LOGIN_TOKEN, data);

export const postGetUserPaymentHistory = (data: GetUserPaymentHistoryQuery): Promise<ResponseModel> => api.create(url.POST_GET_USER_PAYMENT_HISTORY, data);

export const postGetUserLogHistory = (data: GetUserLogHistoryQuery): Promise<ResponseModel> => api.create(url.POST_GET_USER_LOG_HISTORY, data);

export const getSelectUserQuery = (data: SearchUserQuery): Promise<ResponseModel> => api.get(url.GET_SEARCH_USERS, data);

export const postGetAdminUserSearches = (data: AdminUserSearchesQuery) => api.create(url.POST_GET_ADMIN_USER_SEARCHES, data);

export const postGetSystemMonitoring = () => api.create(url.POST_GET_SYSTEM_MONITORING, {});

export const postGetSystemMonitoringDetails = (data: SystemMonitoringDetailsQuery) => api.create(url.POST_GET_SYSTEM_MONITORING_DETAILS, data);

export const postClearTopics = (data: ClearTopicCommand) => api.create(url.POST_CLEAR_TOPICS, data);

export const postGetSearchLogs = (data: SearchLogQuery) => api.create(url.POST_GET_SEARCH_LOGS, data);

export const postResetSearchProductItem = (data: ResetSearchProductItemCommand) => api.create(url.POST_RESET_SEARCH_PRODUCT_ITEM, data);

export const postRestartUserSearch = (data: RestartUserSearchCommand) => api.create(url.POST_RESTART_USER_SEARCH, data);

export const postCancelUserSearch = (data: CancelUserSearchCommand) => api.create(url.POST_CANCEL_USER_SEARCH, data);

export const postUndoDeleteUserSearch = (data: UndoDeleteUserSearchCommand) => api.create(url.POST_UNDO_DELETE_USER_SEARCH, data);

export const postGetAdminScanAndSave = (data: AdminScanAndSaveQuery) => api.create(url.POST_GET_ADMIN_SCAN_AND_SAVE, data);

export const postRestartScanAndSave = (data: RestartScanCommand) => api.create(url.POST_RESTART_SCAN_AND_SAVE, data);

export const postCancelScanAndSave = (data: CancelScanCommand) => api.create(url.POST_CANCEL_SCAN_AND_SAVE, data);

export const postUndoDeletedScan = (data: CancelScanCommand) => api.create(url.POST_UNDO_DELETED_SCAN, data);

export const postRebalanceOrchestrationService = (data: ReBalanceOrchestrationServiceCommand) => api.create(url.POST_RE_BALANCE_ORCHESTRATION_SERVICE, data);
