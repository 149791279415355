import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {Card, CardBody, Col, Row} from "reactstrap";
import {createSelector} from "reselect";
import {RootState} from "slices";
import {Finance} from "models/dashboard";
import DisplayPrice from "Components/Common/DisplayPrice";

const FinanceWidget = () => {
  const {t} = useTranslation();

  const [finance, setFinance] = useState<Finance>({} as Finance);

  const dashboardData = createSelector(
    (state: RootState) => state,
    (state) => ({
      statistics: state.Dashboard.statistics,
      filter: state.Dashboard.filter,
    }),
  );

  const {filter, statistics} = useSelector(dashboardData);

  useEffect(() => {
    if (statistics) {
      setFinance(statistics.finance);
    }
  }, [statistics]);

  return (
    <React.Fragment>
      <Col xl={6} className="same-height">
        <Card className="crm-widget card-animate">
          <CardBody className="p-0">
            <Row className="row-cols-md-2 row-cols-1">
              <Col className={"col-lg"}>
                <div className="mt-3 mt-md-0 py-3 px-3">
                  <h5 className="text-muted text-uppercase fs-13">{t("Dashboard.Widgets.Label.TotalCosts")}</h5>
                  <div className="d-flex align-items-center">
                    <div className="flex-shrink-0 fs-2">
                      <i className={"text-warning mdi mdi-bank-check"}></i>
                    </div>
                    <div className="flex-grow-1 ms-2">
                      <h3 className="mb-0">
                        <span className="counter-value">
                          <DisplayPrice source={filter?.currency} value={finance.totalCosts} decimals={2} countUp />
                        </span>
                      </h3>
                    </div>
                  </div>
                </div>
              </Col>
              <Col className={"col-lg"}>
                <div className="mt-3 mt-md-0 py-3 px-3">
                  <h5 className="text-muted text-uppercase fs-13">{t("Dashboard.Widgets.Label.TotalFees")}</h5>
                  <div className="d-flex align-items-center">
                    <div className="flex-shrink-0 fs-2">
                      <i className={"text-danger mdi mdi-bank-minus"}></i>
                    </div>
                    <div className="flex-grow-1 ms-2">
                      <h3 className="mb-0">
                        <div className="counter-value">
                          <DisplayPrice source={filter?.currency} value={finance.totalFees} decimals={2} countUp />
                        </div>
                      </h3>
                    </div>
                  </div>
                </div>
              <div className="d-block d-md-none" style={{border: "1px solid #e9ebec"}}></div>

              </Col>
            </Row>
            <Row className="row-cols-md-2 row-cols-1">
              <Col className={"col-lg"}>
                <div className="mt-3 mt-md-0 py-3 px-3">
                  <h5 className="text-muted text-uppercase fs-13">{t("Dashboard.Widgets.Label.EstimatedPayouts")}</h5>
                  <div className="d-flex align-items-center">
                    <div className="flex-shrink-0 fs-2">
                      <i className={"text-success mdi mdi-cash-fast"}></i>
                    </div>
                    <div className="flex-grow-1 ms-2">
                      <h3 className="mb-0">
                        <span className="counter-value">
                          <DisplayPrice source={filter?.currency} value={finance.estimatedPayouts} decimals={2} countUp />
                        </span>
                      </h3>
                    </div>
                  </div>
                  <div className="text-muted">
                    <i className="mdi mdi-information align-bottom"></i> {t("Dashboard.Widgets.Label.EstimatedPayoutsDescription")}
                  </div>
                </div>
              </Col>
              <Col className={"col-lg"}>
                <div className="mt-3 mt-md-0 py-3 px-3">
                  <h5 className="text-muted text-uppercase fs-13">{t("Dashboard.Widgets.Label.EstimatedStockValue")}</h5>
                  <div className="d-flex align-items-center">
                    <div className="flex-shrink-0 fs-2">
                      <i className={"text-info mdi mdi-package-variant-closed"}></i>
                    </div>
                    <div className="flex-grow-1 ms-2">
                      <h3 className="mb-0">
                        <div className="counter-value">
                          <DisplayPrice source={filter?.currency} value={finance.estimatedStockValue} decimals={2} countUp />
                        </div>
                      </h3>
                    </div>
                  </div>
                  <div className="text-muted">
                    <i className="mdi mdi-information align-bottom"></i> {t("Dashboard.Widgets.Label.EstimatedStockValueDescription")}
                  </div>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default FinanceWidget;
