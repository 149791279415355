import React, { useEffect } from "react";
import Route from "./Routes";
import "./assets/scss/themes.scss";
import {BroadcastChannels} from "helpers/broadcast_channels";
import { useNavigate } from "react-router-dom";
import { ApplicationUser } from "models/application_user";
import {LOCAL_STORAGE} from "helpers/local_storage";

function App() {
  const navigate = useNavigate();
  const channel = new BroadcastChannel(BroadcastChannels.AUTH_CHANNEL);
  useEffect(() => {
    const user: any = localStorage.getItem(LOCAL_STORAGE.LOGGED_USER);
    if (user) {
      const parsedUser: ApplicationUser = JSON.parse(user);

      if (!parsedUser.hasSubscription && !parsedUser.isSystemUser) {
        navigate("/account/subscription/new");
      }
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    channel.onmessage = ()=> {
      window.location.reload();
    };
    return () => {
      channel.close();
    };
  }, []); // eslint-disable-line

  return (
    <React.Fragment>
      <Route />
    </React.Fragment>
  );
}

export default App;
