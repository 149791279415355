import {LOCAL_STORAGE} from "helpers/local_storage";
import {LAYOUT_MODE_TYPES} from "../../models/layout";
import {useDispatch} from "react-redux";
import {updateUIPreferences} from "slices/account/thunk";

interface LightDarkProps {
  layoutMode: string;
  onChangeLayoutMode: (mode: string) => void;
}
const LightDark = ({layoutMode, onChangeLayoutMode}: LightDarkProps) => {
  const dispatch: any = useDispatch();

  const changeLayoutAction = (mode: LAYOUT_MODE_TYPES) => {
    const currentLang = localStorage.getItem(LOCAL_STORAGE.I18N_LANGUAGE) as string;
    const updatePromise = updateUIPreferences(currentLang, mode)(dispatch);
    updatePromise.then((isSuccess) => {
      if (isSuccess) {
        localStorage.setItem(LOCAL_STORAGE.THEME, mode);
      }
    });
  };

  const mode = layoutMode === LAYOUT_MODE_TYPES["DARKMODE"] ? LAYOUT_MODE_TYPES["LIGHTMODE"] : LAYOUT_MODE_TYPES["DARKMODE"];

  return (
    <div className="ms-1 header-item d-none d-sm-flex">
      <button
        onClick={() => {
          onChangeLayoutMode(mode);
          changeLayoutAction(mode);
        }}
        type="button"
        className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle light-dark-mode"
      >
        <i className="bx bx-moon fs-22"></i>
      </button>
    </div>
  );
};

export default LightDark;
