import BreadCrumb, {BreadcrumbMenuItem} from "Components/Common/BreadCrumb";
import {useEffect, useState} from "react";
import {Alert, Badge, Button, Card, CardBody, CardHeader, Col, Container, Input, Row, Table, UncontrolledAlert, UncontrolledPopover} from "reactstrap";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import PlaceholderGlow from "Components/Common/PlaceholderGlow";
import AmazonMarketplaceInfos, {AmazonMarketplaceInfosType} from "Components/Common/AmazonMarketplaceInfos";
import logoDarkTransparent from "assets/images/logo-dark-transparent.png";
import DefaultUncontrolledTooltip from "Components/Common/DefaultUncontrolledTooltip";
import CopyAsinWidget from "Components/Common/CopyAsinWidget";
import DomainToFlag from "Components/Common/DomainToFlag";
import Rating from "react-rating";
import {SummaryCard} from "pages/Searches/SearchResults/Item/Components/SummaryCard";
import SimpleBar from "simplebar-react";
import {PriceHistoryChart, SellerHistoryChart, BSRHistoryChart} from "Components/Common/Charts/_TestCharts";

const ProductDetails = () => {
  const {t} = useTranslation();

  const [amazonMarketplaceInfos] = useState(AmazonMarketplaceInfos());
  const [sourceMarketplace, setSourceMarketplace] = useState<AmazonMarketplaceInfosType>();
  const [destinationMarketplace, setDestinationMarketplace] = useState<AmazonMarketplaceInfosType>();

  const CheckboxItem = ({status}: any) => {
    return status === false ? (
      <>
        <i className="ri-close-circle-fill fs-18 text-danger"></i>
        <span className="ms-1">{t("No")}</span>
      </>
    ) : status === null || status === undefined ? (
      <i className="ri-donut-chart-line fs-18 text-muted"></i>
    ) : (
      <>
        <i className="ri-checkbox-circle-fill fs-18 text-success"></i>
        <span className="ms-1">{t("Yes")}</span>
      </>
    );
  };

  useEffect(() => {
    let source = amazonMarketplaceInfos.find((amazonMarketplaceInfo: any) => amazonMarketplaceInfo.marketplace === "US");
    let destination = amazonMarketplaceInfos.find((amazonMarketplaceInfo: any) => amazonMarketplaceInfo.marketplace === "CA");
    setSourceMarketplace(source);
    setDestinationMarketplace(destination);
  }, [amazonMarketplaceInfos]);

  document.title = t("PageTitles.ProductDetails") + " - B0C2Z44JLK";
  const breadcrumbMenus: BreadcrumbMenuItem[] = [
    {
      label: `${t("ProductDetails.Title")} - B0C2Z44JLK`,
    },
  ];
  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title={`${t("ProductDetails.Title")} - ${"B0C2Z44JLK"}`} menus={breadcrumbMenus} />
        <PlaceholderGlow busy={false}>
          <Row>
            <Col>
              <div className="h-100">
                <Row>
                  <Col>
                    <Card className="card-animate">
                      <CardBody className="common-css">
                        <Row className="g-4 ">
                          <Col className="mx-auto">
                            <div className="product-img-slider product-img-container">
                              <img src={`https://m.media-amazon.com/images/I/31XaF8pj9iL.jpg`} alt="" className="rounded w-100" />
                            </div>
                          </Col>

                          <Col xl={11} lg={10} sm={9}>
                            <div className="mt-xl-0">
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <Row>
                                    <Col className="col-md">
                                      <h4 className="ellipsis-single-line">
                                        MAJESTIC PURE Vitamin E Oil - 100% Pure & Natural, 20,000 IU | Premium Grade, Cold-Pressed and Tocopherol to Repair Dry, Damaged Skin| Moisturizing Skin, Hair
                                        and Scalp
                                      </h4>
                                    </Col>
                                    <Col className="col-1" style={{minWidth: 100}}>
                                      <div className="d-flex justify-content-end">
                                        <Button className={"rounded-pill p-0 btn-secondary"} id={`SellThisButton`}>
                                          <img src={logoDarkTransparent} alt="" width="100%" className="px-2 py-1" />
                                        </Button>
                                        <DefaultUncontrolledTooltip target={`SellThisButton`}>{t("SearchResults.SellThisButton")}</DefaultUncontrolledTooltip>
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col xxl={3} xl={6} sm={12} className="my-1">
                                      <div className="d-flex align-items-center">
                                        <span className="me-3 text-muted">ASIN</span>
                                        <div className="hstack gap-3 flex-wrap">
                                          <CopyAsinWidget Asin={"B0C2Z44JLK"} index={`CopyAsin`} bold={true} />

                                          <div className="d-flex align-items-center">
                                            &nbsp;&nbsp;
                                            <DomainToFlag marketplace={sourceMarketplace?.marketplace || ""} />
                                            <Link
                                              to={`https://www.amazon.com/dp/${"B0C2Z44JLK"}?th=1&psc=1`}
                                              target="_blank"
                                              rel="noreferrer"
                                              className="link-secondary link-offset-2 text-decoration-underline"
                                            >
                                              .com
                                              <i className="ri-arrow-right-up-line"></i>
                                            </Link>
                                            &nbsp;&nbsp;
                                            <DomainToFlag marketplace={destinationMarketplace?.marketplace || ""} />
                                            <Link
                                              to={`https://www.amazon.${sourceMarketplace?.domain}/dp/${"B0C2Z44JLK"}?th=1&psc=1`}
                                              target="_blank"
                                              rel="noreferrer"
                                              className="link-secondary link-offset-2 text-decoration-underline"
                                            >
                                              .ca
                                              <i className="ri-arrow-right-up-line"></i>
                                            </Link>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="d-flex align-items-center">
                                        <span className="me-3 text-muted">SKU</span>
                                        <div className="hstack gap-3 flex-wrap">
                                          <CopyAsinWidget Asin={"AU_051"} index={"AU_051"} bold={true} />

                                          <span className="ms-3 text-muted">Stock</span>
                                          <div className="d-flex align-items-center">
                                            <Link id="Stock" className="link-body-emphasis link-opacity-75-hover fw-medium d-flex align-items-center" to={""}>
                                              <span className="fw-medium">5</span>
                                              <i className="ri-arrow-down-s-line fs-16 ms-1 text-info"></i>
                                            </Link>
                                            <UncontrolledPopover trigger="legacy" placement="right" target="Stock">
                                              <Table className="align-middle table table-hover mb-0">
                                                <thead className="table-light">
                                                  <tr>
                                                    <th>{t("Inventory.TableColumn.Stock.Tooltip.QuantityType")}</th>
                                                    <th>{t("Inventory.TableColumn.Stock.Tooltip.Units")}</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  <tr>
                                                    <td>{t("Inventory.TableColumn.Stock.Tooltip.Available")}</td>
                                                    <td>5</td>
                                                  </tr>
                                                  <tr>
                                                    <td>{t("Inventory.TableColumn.Stock.Tooltip.Inbound")}</td>
                                                    <td>0</td>
                                                  </tr>
                                                  <tr>
                                                    <td>{t("Inventory.TableColumn.Stock.Tooltip.Reserved")}</td>
                                                    <td>0</td>
                                                  </tr>
                                                  <tr>
                                                    <td>{t("Inventory.TableColumn.Stock.Tooltip.Unfulfillable")}</td>
                                                    <td>0</td>
                                                  </tr>
                                                  <tr>
                                                    <td>{t("Inventory.TableColumn.Stock.Tooltip.Researching")}</td>
                                                    <td>0</td>
                                                  </tr>
                                                  <tr>
                                                    <th scope="row">{t("Inventory.TableColumn.Stock.Tooltip.Total")}</th>
                                                    <th scope="row">5</th>
                                                  </tr>
                                                </tbody>
                                              </Table>
                                            </UncontrolledPopover>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="d-flex align-items-center">
                                        <span className="me-3 text-muted d-flex align-items-center">
                                          Dimensions
                                          <i id="PopoverDimensions" className="ms-1 bx bxs-info-circle text-secondary"></i>
                                          <DefaultUncontrolledTooltip target="PopoverDimensions">{t("SearchResults.Analyzes.ProductSize.Tooltip.Dimensions")}</DefaultUncontrolledTooltip>
                                        </span>
                                        <span className="d-flex align-items-center fw-medium">
                                          5.51 * 0.98 * 8.46 inches
                                          <i className="bx bx-check fs-18 text-success"></i>
                                        </span>
                                      </div>
                                    </Col>
                                    <Col xxl={3} xl={6} sm={12} className="my-1">
                                      <div className="d-flex align-items-center mb-1">
                                        <span className="me-3 text-muted">Variations</span>
                                        <span className="fw-medium">
                                          <i className="mdi mdi-layers-triple-outline text-secondary me-1 align-middle"></i>20
                                        </span>
                                      </div>
                                      <div className="d-flex align-items-center mb-1">
                                        <span className="me-3 text-muted">FBA Eligibility</span>
                                        <span className="d-flex align-items-center fw-medium">
                                          FBA Eligible
                                          <i className="bx bx-check fs-18 text-success"></i>
                                        </span>
                                      </div>
                                      <div className="d-flex align-items-center">
                                        <span className="me-3 text-muted">Weight</span>
                                        <span className="d-flex align-items-center fw-medium">
                                          4.61 lb
                                          <i className="bx bx-check fs-18 text-success"></i>
                                        </span>
                                      </div>
                                    </Col>
                                    <Col xxl={3} xl={6} sm={12} className="my-1">
                                      <div className="d-flex align-items-center mb-1">
                                        <span className="me-3 text-muted">Category</span>
                                        <span className="fw-medium ellipsis-single-line">Home & Kitchen - 304.084 (BSR - CA)</span>
                                      </div>
                                      <div className="d-flex align-items-center mb-1">
                                        <span className="me-3 text-muted">Review</span>
                                        <span className="fw-medium ellipsis-single-line">
                                          <Rating initialRating={123} fractions={2} emptySymbol="mdi mdi-star-outline fs-16 text-muted " fullSymbol="mdi mdi-star fs-16 text-warning" readonly={true} />
                                          <span className="text-muted">(123 product reviews)</span>
                                        </span>
                                      </div>
                                      <div className="d-flex align-items-center">
                                        <span className="me-3 text-muted">
                                          Dimensional Weight
                                          <i id="PopoverDimensionalWeight" className="ms-1 bx bxs-info-circle text-secondary"></i>
                                          <DefaultUncontrolledTooltip target="PopoverDimensionalWeight" placement={"bottom"}>
                                            {t("Definitions.DimensionalWeight")}
                                          </DefaultUncontrolledTooltip>
                                        </span>
                                        <span className="d-flex align-items-center fw-medium">
                                          4.61 lb
                                          <i className="bx bx-check fs-18 text-success"></i>
                                        </span>
                                      </div>
                                    </Col>
                                    <Col xxl={3} xl={6} sm={12} className="my-1">
                                      <div className="d-flex align-items-center mb-1">
                                        <span className="me-3 text-muted">BSR</span>
                                        <span className="d-flex align-items-center fw-medium">
                                          <DomainToFlag marketplace={"ca"} />
                                          304.084
                                        </span>
                                      </div>
                                      <div className="d-flex align-items-center">
                                        <span className="me-3 text-muted">Listing Launch Date</span>
                                        <span className="text-success fw-medium">5 years 3 months ago</span>
                                      </div>
                                    </Col>
                                  </Row>
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Card className="card-animate">
                      <CardBody>
                        <h5 className="fs-15">{t("ProductDetails.AnalysisSummary.Title")}</h5>

                        <div className="mt-2">
                          <div className="d-flex justify-content-center align-items-center fw-medium fs-16">
                            <i className="bx bxs-location-plus text-success fs-20 me-1"></i>
                            <span>
                              <span className="text-nowrap">{sourceMarketplace?.countryName} </span>
                              <img src={sourceMarketplace?.flag} alt={`${sourceMarketplace?.countryName} Flag`} className="rounded-circle" height="20" />
                            </span>
                            <span className="mx-2">
                              <i className="ri-arrow-right-line fs-20 align-bottom"></i>
                            </span>
                            <span className="text-nowrap">
                              <img src={destinationMarketplace?.flag} alt={`${t(destinationMarketplace?.countryName ?? "")} Flag`} className="rounded-circle" height="20" />{" "}
                              {t(destinationMarketplace?.countryName ?? "")}
                              {" - "}
                              <span className="text-nowrap">My Store Name</span>
                            </span>
                          </div>
                          <div className="d-flex justify-content-center align-items-center fs-14 mt-1">
                            <span className="me-1 text-muted">Analysis Date:</span>
                            <span className="d-flex align-items-center fw-medium text-success">
                              3 hours ago
                              <i className="bx bx-calendar-check fs-18 ms-1"></i>
                            </span>
                            <div className="d-flex align-items-center ms-2">
                              <Button id={`AnalyzeShipmentItems`} className="btn btn-ghost-secondary px-2 py-0 d-flex align-items-center">
                                <i className="mdi mdi-chart-timeline-variant-shimmer fs-18 me-1"></i>
                                {t("Reanalyze")}
                              </Button>
                              <DefaultUncontrolledTooltip target={`AnalyzeShipmentItems`}>{t("Reanalyze")}</DefaultUncontrolledTooltip>
                            </div>
                          </div>
                          <div className="mt-4 d-flex justify-content-center">
                            <Row>
                              <SummaryCard
                                icon="mdi mdi-chart-timeline-variant-shimmer"
                                label={t("Decision")}
                                content={
                                  <div className="d-flex align-items-center">
                                    <Badge color={"danger"}>Not Sellable</Badge>
                                    <span className="ms-1 fs-12 text-secondary">2 reasons</span>
                                  </div>
                                }
                              />

                              <SummaryCard
                                icon="mdi mdi-finance"
                                label={t("Sale Count")}
                                content={
                                  <div className="d-flex align-items-center">
                                    <>
                                      <span>5</span>
                                      <small className="fs-13 text-muted">/{t("month")}</small>
                                      <i id="PopoverPrecisionSummary" className="ms-1 mdi mdi-check-circle text-secondary"></i>
                                      <DefaultUncontrolledTooltip target={"PopoverPrecisionSummary"}>
                                        [{t("SearchResults.Summary.SaleCount.Precision")}: {"VeryClose"}] {t(`SearchResults.Summary.SaleCount.Precisions.${"VeryClose"}`)}
                                      </DefaultUncontrolledTooltip>
                                    </>
                                  </div>
                                }
                              />
                              <SummaryCard
                                icon="mdi mdi-currency-usd"
                                label={t("Profit / ROI")}
                                content={
                                  <>
                                    <span className="">$11.35 CAD</span>
                                    <span className="badge fs-15 ms-2 bg-success-subtle text-success"> %61.22</span>
                                  </>
                                }
                              />
                              <SummaryCard
                                icon="mdi mdi-layers-triple-outline"
                                label={t("Variation")}
                                content={
                                  <>
                                    <span className="badge bg-success fs-14">
                                      1<i className="bx bx-check ms-1"></i>
                                    </span>
                                  </>
                                }
                              />
                              <SummaryCard
                                icon="mdi mdi-speedometer-slow text-success"
                                label={t("Competition")}
                                content={
                                  <>
                                    <span className="fs-14 badge bg-success">
                                      Weak<i className="bx bx-check ms-1"></i>
                                    </span>
                                  </>
                                }
                              />
                            </Row>
                          </div>

                          <div className="mt-3 d-flex justify-content-center">
                            <Col xxl={8} xl={10} sm={12}>
                              <div className="d-flex">
                                <div className="flex-shrink-0 me-2">
                                  <i className="bx bxs-x-circle text-danger fs-20"></i>
                                </div>
                                <div className="flex-grow-1">
                                  <h5>Not Profitable</h5>

                                  <p className="text-muted fs-14">
                                    This product has a low profit margin or the profit margin cannot be calculated. This indicates that the cost of the product is high relative to its selling price or
                                    that the selling price is not sufficiently profitable.
                                  </p>
                                </div>
                              </div>
                              <div className="d-flex">
                                <div className="flex-shrink-0 me-2">
                                  <i className="bx bxs-x-circle text-danger fs-20"></i>
                                </div>
                                <div className="flex-grow-1">
                                  <h5>Potential Brand Owner</h5>
                                  <p className="text-muted fs-14">The seller of this product could be the official representative or owner of the brand in the product's source and target markets.</p>
                                </div>
                              </div>
                            </Col>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>

                <Row>
                  <Col xl={9} className="same-height">
                    <Card className="card-animate">
                      <CardBody>
                        <h5 className="fs-15">{t("ProductDetails.GeneralChecksAlerts.Title")}</h5>

                        <div className="mt-3">
                          <div className="row d-flex mt-4">
                            <Col xxl={3} xl={4} sm={12}>
                              <span className="h5 d-flex align-items-center">
                                <i className="ri-error-warning-fill fs-18 text-warning me-1"></i>
                                Aynı Ürün Kontrolü
                              </span>
                            </Col>
                            <Col xxl={9} xl={8} sm={12}>
                              <h5 className="mb-0">Kaynak mağazadaki ürünler ile hedef mağazadaki ürünler aynı olmayabilir.</h5>
                              <p className="text-muted mb-0 mt-1">
                                Kaynak Mağazada Ürün Başlığı Birden Fazla Adet İçerdiğini Belirtiyor: Kaynak pazaryerindeki ürün başlığı "pack" ("pack of 1" hariç) ifadesi içeriyor ve hedef
                                pazaryerindeki ürün başlığında böyle bir terim yok.
                              </p>
                            </Col>
                          </div>

                          <div className="row d-flex mt-4">
                            <Col xxl={3} xl={4} sm={12}>
                              <span className="h5 d-flex align-items-center">
                                <i className="ri-error-warning-fill fs-18 text-warning me-1"></i>
                                Gümrük Kontrolü
                              </span>
                            </Col>
                            <Col xxl={9} xl={8} sm={12}>
                              <h5 className="mb-0">Kaynak mağazadaki ürünler ile hedef mağazadaki ürünler aynı olmayabilir.</h5>
                              <p className="text-muted mb-0 mt-1">
                                Ürün, başlık ve detaylarında Seeds kelimesini içerdiği için gümrükten geçemeyebilir. Kesin bilgi için aradeponuzla iletişime geçip ürünü sorabilirsiniz.
                              </p>
                            </Col>
                          </div>

                          <div className="row d-flex mt-4">
                            <Col xxl={3} xl={4} sm={12}>
                              <span className="h5 d-flex align-items-center">
                                <i className="ri-checkbox-circle-fill fs-18 text-success me-1"></i>
                                Ürün Boyut Kontrolü
                              </span>
                            </Col>
                            <Col xxl={9} xl={8} sm={12}>
                              <h5 className="mb-0">Ürün boyutu FBA için uygun.</h5>
                            </Col>
                          </div>

                          <div className="row d-flex mt-4">
                            <Col xxl={3} xl={4} sm={12}>
                              <span className="h5 d-flex align-items-center">
                                <i className="ri-checkbox-circle-fill fs-18 text-success me-1"></i>
                                Yeni Ürün Kontrolü
                              </span>
                            </Col>
                            <Col xxl={9} xl={8} sm={12}>
                              <h5 className="mb-0">Ürün 90 günden daha az bir süre önce listelenmiştir. Yeni bir üründür.</h5>
                            </Col>
                          </div>

                          <div className="row d-flex mt-4">
                            <Col xxl={3} xl={4} sm={12}>
                              <span className="h5 d-flex align-items-center">
                                <i className="ri-error-warning-fill fs-18 text-warning me-1"></i>
                                Ürün Rating Kontrolü
                              </span>
                            </Col>
                            <Col xxl={9} xl={8} sm={12}>
                              <h5 className="mb-0">Ürün rating’i 3'ün altında. İade oranı yüksek olabilir.</h5>
                            </Col>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col xl={3} className="same-height">
                    <Card className="card-animate bg-warning-subtle">
                      <CardBody>
                        <div className="d-flex h-100 align-items-center pb-3">
                          <div className="d-flex flex-column gap-2 justify-content-center text-center p-3 w-100">
                            <div className="mx-auto mb-2">
                              <i className="mdi mdi-alert-remove text-warning h1"></i>
                            </div>
                            <h5>
                              Kısıtlama Kontrolü (Product Restriction) <i>AU_Nish_Store</i> mağazanız için yapıldı.
                            </h5>
                            <span className="badge rounded-pill fs-14 badge bg-danger mx-auto my-1">APPROVAL_REQUIRED</span>
                            <div className="mt-2">
                              <p className="text-muted mb-1">Bu ASIN'i mağazanıza eklemek için onay gereklidir.</p>
                              <a className="fw-medium link-secondary" href="https://sellercentral.amazon.ca/hz/approvalrequest/restrictions/approve?asin=B0047MAPFM" target="_blank" rel="noreferrer">
                                Seller Central'dan Onay İsteyin: B0047MAPFM
                                <i className="ri-arrow-right-s-line align-bottom"></i>
                              </a>
                            </div>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>

                <Row>
                  <Col xl={4} className="same-height">
                    <Card className="card-animate">
                      <CardBody>
                        <h5 className="fs-15">{t("ProductDetails.ProfitCalculation.Title")}</h5>

                        <div className="mt-3 fs-14">
                          <ul className="list-group d-flex flex-group gap-2">
                            <li className="d-flex justify-content-between fw-semibold">
                              <span>Costs</span>
                              <span>($17.57 USD) $23.90 CAD </span>
                            </li>
                            <li className="d-flex align-items-center justify-content-between">
                              <span className="ms-3">Purchase Price</span>
                              <span className="d-flex align-items-center">
                                <span>$21.69 CAD</span>
                                <div className="input-group input-group-sm shipment-details input-group-size ms-2">
                                  <Input type="text" className="form-control form-control-sm border-input-group fw-medium" value={15.95} />
                                  <span className="input-group-text">{sourceMarketplace?.currency}</span>
                                </div>
                              </span>
                            </li>
                            <li className="d-flex align-items-center justify-content-between">
                              <span className="ms-3">Tax</span>
                              <span className="d-flex align-items-center">
                                <span>$0.03 CAD</span>
                                <div className="input-group input-group-sm shipment-details input-group-size ms-2">
                                  <Input type="text" className="form-control form-control-sm border-input-group fw-medium" value={0.02} />
                                  <span className="input-group-text">{sourceMarketplace?.currency}</span>
                                </div>
                              </span>
                            </li>
                            <li className="d-flex align-items-center justify-content-between">
                              <div className="d-flex align-items-center">
                                <span className="ms-3">Prep &amp; Ship</span>
                                <i id="PopoverShippingPrice" className="ms-1 bx bxs-info-circle text-secondary"></i>
                                <DefaultUncontrolledTooltip target="PopoverShippingPrice">{t("Definitions.ShippingPrice")}</DefaultUncontrolledTooltip>
                              </div>
                              <span className="d-flex align-items-center">
                                <span>$2.18 CAD</span>
                                <div className="input-group input-group-sm shipment-details input-group-size ms-2">
                                  <Input type="text" className="form-control form-control-sm border-input-group fw-medium" value={1.6} />
                                  <span className="input-group-text">{sourceMarketplace?.currency}</span>
                                </div>
                              </span>
                            </li>
                          </ul>
                          <li className="d-flex justify-content-between fw-semibold mt-3">
                            <span>Fees</span>
                          </li>
                          <ul className="list-group d-flex flex-group gap-2">
                            <li className="d-flex align-items-center justify-content-between">
                              <span className="ms-3">FBA Fee</span>
                              <div className="input-group input-group-sm shipment-details input-group-size ms-2">
                                <Input type="text" className="form-control form-control-sm border-input-group fw-medium" value={6.36} />
                                <span className="input-group-text">{destinationMarketplace?.currency}</span>
                              </div>
                            </li>
                            <li className="d-flex align-items-center justify-content-between">
                              <span className="ms-3">Referral Fee</span>
                              <div className="input-group input-group-sm shipment-details input-group-size ms-2">
                                <Input type="text" className="form-control form-control-sm border-input-group fw-medium" value={3.0} />
                                <span className="input-group-text">{destinationMarketplace?.currency}</span>
                              </div>
                            </li>
                            <li className="d-flex align-items-center justify-content-between">
                              <div className="d-flex align-items-center">
                                <span className="ms-3">Other Fees</span>
                                <i id="PopoverOtherFees" className="ms-1 bx bxs-info-circle text-secondary"></i>
                                <DefaultUncontrolledTooltip target="PopoverOtherFees">{t("Definitions.OtherFees")}</DefaultUncontrolledTooltip>
                              </div>
                              <div className="input-group input-group-sm shipment-details input-group-size ms-2">
                                <Input type="text" className="form-control form-control-sm border-input-group fw-medium" value={0.11} />
                                <span className="input-group-text">{destinationMarketplace?.currency}</span>
                              </div>
                            </li>
                          </ul>
                          <li className="d-flex justify-content-between fw-semibold mt-3">
                            <span>Overall Cost</span>
                            <span>$9.47 CAD</span>
                          </li>
                          <li className="d-flex justify-content-between mt-2">
                            <span>Sell Price</span>
                            <div className="input-group input-group-sm shipment-details input-group-size ms-2">
                              <Input type="text" className="form-control form-control-sm border-input-group fw-medium" value={19.99} />
                              <span className="input-group-text">{destinationMarketplace?.currency}</span>
                            </div>
                          </li>
                          <hr />
                          <ul className="list-group d-flex flex-group gap-2">
                            <li className="d-flex justify-content-between fw-semibold">
                              <span>Profit</span>
                              <span className="text-danger">-$13.38 CAD</span>
                            </li>
                            <li className="d-flex justify-content-between fw-semibold">
                              <span>Profit (USD)</span>
                              <span className="text-danger">-$9.84 USD</span>
                            </li>
                            <li className="d-flex justify-content-between fw-semibold">
                              <span>Margin</span>
                              <span className="text-danger">-66.93%</span>
                            </li>
                            <li className="d-flex justify-content-between fw-semibold">
                              <div>
                                <span>ROI</span>
                              </div>
                              <span className="text-danger">-55.98%</span>
                            </li>
                          </ul>
                          <div className="d-flex justify-content-between mt-2 fs-11">
                            <span></span>
                            <span className="text-muted">
                              <em>Exchange Rate: 1.36</em>
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col xl={8} className="same-height">
                    <Card className="card-animate">
                      <CardBody>
                        <h5 className="fs-15">{t("ProductDetails.ProfitAnalysis.Title")}</h5>
                        <Row className="mt-3">
                          <Col xl={3} lg={5} className="same-height">
                            <div className="h-100 d-flex flex-column justify-content-between">
                              <SummaryCard
                                icon="mdi mdi-cash-fast"
                                label={t("Price Change")}
                                cardClassName="mb-2"
                                content={
                                  <span className="fs-14 badge bg-success-subtle text-success">
                                    Stable<i className="bx bx-check ms-1"></i>
                                  </span>
                                }
                              />

                              <SummaryCard
                                icon="ri-exchange-dollar-line"
                                label={t("Change Rate")}
                                cardClassName="mb-2"
                                content={<span className="fs-14 badge bg-success-subtle text-success">2.55%</span>}
                              />

                              <SummaryCard
                                icon="mdi mdi-tag-plus-outline"
                                label={t("Max. Price Scenario")}
                                cardClassName="mb-2"
                                content={
                                  <>
                                    <span className="badge fs-15 bg-success-subtle text-success">%61</span>
                                    <span className="ms-1 small">ROI</span>
                                  </>
                                }
                              />

                              <SummaryCard
                                icon="mdi mdi-tag-minus-outline"
                                iconColorClassName="text-danger"
                                label={t("Min. Price Scenario")}
                                content={
                                  <>
                                    <span className="badge fs-15 bg-success-subtle text-success">%12</span>
                                    <span className="ms-1 small">ROI</span>
                                  </>
                                }
                              />
                            </div>
                          </Col>
                          <Col xl={9} lg={7} className="same-height">
                            <div className="w-100 h-100">
                              <div className="table-responsive mb-2 shadow">
                                <Table className="align-middle table-hover table-nowrap table-borderless mb-0 h-100">
                                  <thead className="bg-light">
                                    <tr>
                                      <th scope="col">Scenarios</th>
                                      <th scope="col">Price</th>
                                      <th scope="col">Profit</th>
                                      <th scope="col">ROI</th>
                                      <th scope="col">Margin</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>
                                        <span className="d-flex align-items-center text-danger">
                                          <i className="mdi mdi-trending-down align-middle  me-1"></i> Min. Price
                                        </span>
                                      </td>
                                      <td className="fw-medium">$45 CAD</td>
                                      <td>$45 CAD</td>
                                      <td>30%</td>
                                      <td>35%</td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <span className="d-flex align-items-center text-success">
                                          <i className="mdi mdi-trending-up align-middle  me-1"></i> Max. Price
                                        </span>
                                      </td>
                                      <td className="fw-medium">$45 CAD</td>
                                      <td>$45 CAD</td>
                                      <td>30%</td>
                                      <td>35%</td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <span className="d-flex align-items-center text-primary">
                                          <i className="mdi mdi-swap-vertical align-middle  me-1"></i> Average Price
                                        </span>
                                      </td>
                                      <td className="fw-medium">$45 CAD</td>
                                      <td>$45 CAD</td>
                                      <td>30%</td>
                                      <td>35%</td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <span className="d-flex align-items-center text-secondary">
                                          <i className="mdi mdi-currency-usd align-middle  me-1"></i> Current Price
                                        </span>
                                      </td>
                                      <td className="fw-medium">$45 CAD</td>
                                      <td>$45 CAD</td>
                                      <td>30%</td>
                                      <td>35%</td>
                                    </tr>
                                  </tbody>
                                </Table>
                              </div>

                              <Alert color="light" className="bg-light border-light text-body mb-4">
                                <i className="mdi mdi-information"></i> Displays potential profit, ROI, and margin for minimum, maximum, average, and current price scenarios to assess financial
                                outcomes.
                              </Alert>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Card className="mb-0">
                              <CardHeader className="align-items-center d-flex">
                                <h5 className="fs-15 card-title mb-0 flex-grow-1">Price History Chart</h5>
                                <div className="flex-shrink-0">
                                  <div className="d-flex justify-content-end gap-1">
                                    <div role="group" className="gap-2 btn-group">
                                      <button type="button" className="link-body-emphasis text-decoration-underline link-underline-opacity-75-hover align-items-center d-flex nowrap p-0 btn btn-link">
                                        All Time
                                      </button>
                                      <button type="button" className="link-body-emphasis link-underline-opacity-75-hover align-items-center d-flex nowrap p-0 btn btn-link">
                                        7 Days
                                      </button>
                                      <button type="button" className="link-body-emphasis link-underline-opacity-75-hover align-items-center d-flex nowrap p-0 btn btn-link">
                                        30 days
                                      </button>
                                      <button type="button" className="link-body-emphasis link-underline-opacity-75-hover align-items-center d-flex nowrap p-0 btn btn-link">
                                        90 days
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </CardHeader>
                              <div className="card-body">
                                <PriceHistoryChart dataColors='["--vz-secondary"]' />
                              </div>
                            </Card>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>

                <Row>
                  <Col xl={12} className="same-height">
                    <Card className="card-animate">
                      <CardBody>
                        <h5 className="fs-15">{t("ProductDetails.CompetitorAnalysis.Title")}</h5>

                        <Row className="mt-3">
                          <Col xl={4} lg={6} className="same-height">
                            <div className="d-flex flex-column gap-0 h-100">
                              <div className="p-2 border card rounded d-flex justify-content-center mb-3">
                                <div className="d-flex align-items-center">
                                  <div className="avatar-sm me-2">
                                    <div className="avatar-title rounded bg-transparent text-secondary h1">
                                      <i className="mdi mdi-speedometer text-danger"></i>
                                    </div>
                                  </div>
                                  <div className="me-3">
                                    <p className="text-muted mb-1 fs-18">{t("Competition")}</p>
                                    <h4 className="fs-22 fw-semibold ff-secondary">
                                      <span className="fs-14 badge bg-danger">Strong</span>
                                    </h4>
                                  </div>
                                </div>
                              </div>
                              <Alert className="alert alert-warning border-0 rounded-0 d-flex align-items-center mb-4">
                                <div className="fw-semibold">Bu ürünü şu anda Amazon satıyor, bu nedenle rekabet güçlü olarak belirlenmiştir.</div>
                              </Alert>
                            </div>
                          </Col>
                          <Col xl={4} lg={6} className="same-height">
                            <Card>
                              <CardBody>
                                <div className="fs-15">
                                  <div className="d-flex pb-2">
                                    <div className="flex-grow-1">
                                      <p className="text-truncate mb-0">
                                        <i className="mdi mdi-store align-middle text-primary me-2"></i>FBA Seller Count
                                      </p>
                                    </div>
                                    <div className="flex-shrink-0">
                                      <p className="mb-0">6</p>
                                    </div>
                                  </div>
                                  <div className="d-flex py-2">
                                    <div className="flex-grow-1">
                                      <p className="text-truncate mb-0">
                                        <i className="mdi mdi-store align-middle text-primary me-2"></i>Remote FBA Seller Count
                                      </p>
                                    </div>
                                    <div className="flex-shrink-0">
                                      <p className="mb-0">5</p>
                                    </div>
                                  </div>
                                  <div className="d-flex py-2">
                                    <div className="flex-grow-1">
                                      <p className="text-truncate mb-0">
                                        <i className="mdi mdi-store align-middle text-primary me-2"></i>FBM Seller Count
                                      </p>
                                    </div>
                                    <div className="flex-shrink-0">
                                      <p className="mb-0">4</p>
                                    </div>
                                  </div>
                                  <div className="d-flex pt-2">
                                    <div className="flex-grow-1">
                                      <p className="text-truncate mb-0">
                                        <i className="mdi mdi-link-variant align-middle text-primary me-2"></i>Total Seller Count
                                      </p>
                                    </div>
                                    <div className="flex-shrink-0">
                                      <p className="mb-0 d-flex align-items-center">
                                        <i className="ri-bar-chart-fill text-success me-1"></i> 25
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </CardBody>
                            </Card>
                          </Col>
                          <Col xl={4} lg={6} className="same-height">
                            <Card>
                              <CardBody>
                                <div className="fs-15">
                                  <div className="d-flex pb-2">
                                    <div className="flex-grow-1">
                                      <p className="text-truncate mb-0">
                                        <i className="mdi mdi-store align-middle text-primary me-2"></i>Average FBA Seller Count <i className="small text-muted">(Last 30 days)</i>
                                      </p>
                                    </div>
                                    <div className="flex-shrink-0">
                                      <p className="mb-0">6</p>
                                    </div>
                                  </div>
                                  <div className="d-flex py-2">
                                    <div className="flex-grow-1">
                                      <p className="text-truncate mb-0">
                                        <i className="ri-amazon-fill align-middle text-primary me-2"></i>Is Amazon Exist?
                                      </p>
                                    </div>
                                    <div className="flex-shrink-0">
                                      <p className="mb-0">
                                        <i className="ri-close-circle-fill text-danger"></i>
                                      </p>
                                    </div>
                                  </div>
                                  <div className="d-flex pt-2">
                                    <div className="flex-grow-1">
                                      <p className="text-truncate mb-0">
                                        <i className="ri-amazon-fill align-middle text-primary me-2"></i>Amazon Last Seen
                                      </p>
                                    </div>
                                    <div className="flex-shrink-0">
                                      <p className="mb-0">Just now</p>
                                    </div>
                                  </div>
                                </div>
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>

                        <Row className="mt-4">
                          <Col xl={6} sm={12} className="same-height">
                            <Card className="mb-0">
                              <CardHeader className="align-items-center d-flex">
                                <h5 className="fs-15 card-title mb-0 flex-grow-1">Seller History Chart</h5>
                                <div className="flex-shrink-0">
                                  <div className="d-flex justify-content-end gap-1">
                                    <div role="group" className="gap-2 btn-group">
                                      <button type="button" className="link-body-emphasis text-decoration-underline link-underline-opacity-75-hover align-items-center d-flex nowrap p-0 btn btn-link">
                                        All Time
                                      </button>
                                      <button type="button" className="link-body-emphasis link-underline-opacity-75-hover align-items-center d-flex nowrap p-0 btn btn-link">
                                        7 Days
                                      </button>
                                      <button type="button" className="link-body-emphasis link-underline-opacity-75-hover align-items-center d-flex nowrap p-0 btn btn-link">
                                        30 days
                                      </button>
                                      <button type="button" className="link-body-emphasis link-underline-opacity-75-hover align-items-center d-flex nowrap p-0 btn btn-link">
                                        90 days
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </CardHeader>
                              <div className="card-body">
                                <SellerHistoryChart dataColors='["--vz-secondary", "--vz-success", "--vz-danger"]' />
                              </div>
                            </Card>
                          </Col>
                          <Col xl={6} sm={12} className="same-height">
                            <Card>
                              <CardHeader className="bg-light py-2">
                                <div className="d-flex align-items-center">
                                  <h5 className="fs-15 flex-grow-1 mb-0">
                                    Sellers who are selling this product <span className="text-muted small">B0C2Z44JLK</span>
                                  </h5>
                                  <div className="d-flex gap-1">
                                    <Button color="secondary" size="sm" className="btn-ghost-secondary">
                                      FBA <span className="badge bg-secondary-subtle text-secondary align-middle rounded-pill">1</span>
                                    </Button>
                                    <Button color="secondary" size="sm" className="btn-ghost-secondary">
                                      FBM <span className="badge bg-secondary-subtle text-secondary align-middle rounded-pill">13</span>
                                    </Button>
                                    <Button color="secondary" size="sm" className="btn-ghost-secondary">
                                      Amazon <span className="badge bg-secondary-subtle text-secondary align-middle rounded-pill">1</span>
                                    </Button>
                                    <Button color="secondary" size="sm" className="btn-soft-secondary">
                                      All <span className="badge bg-secondary-subtle text-secondary align-middle rounded-pill">15</span>
                                    </Button>
                                  </div>
                                </div>
                              </CardHeader>
                              <CardBody>
                                <div className="table-responsive table-card">
                                  <SimpleBar style={{height: "490px"}}>
                                    <table className="align-middle table-hover table-nowrap mb-0 h-100 table border-top-0">
                                      <thead className="sticky-top bg-light">
                                        <tr>
                                          <th>{t("Seller Id")}</th>
                                          <th>{t("Seller Type")}</th>
                                          <th>{t("Rating")}</th>
                                          <th>{t("Price")}</th>
                                          <th>
                                            <div className="d-flex align-items-center">
                                              {t("Is Remote FBA")}
                                              <i id="RemoteFBADefinition" className="ms-1 bx bxs-info-circle text-secondary"></i>
                                              <DefaultUncontrolledTooltip target="RemoteFBADefinition">{t("Definitions.RemoteFBA")}</DefaultUncontrolledTooltip>
                                            </div>
                                          </th>
                                          <th>{t("Is Amazon")}</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td>
                                            <span className="hstack gap-2">
                                              <div className="avatar-xs img-thumbnail rounded-circle flex-shrink-0">
                                                <img src={destinationMarketplace?.flag /*sellerMarketplace?.flag*/} alt="Country Flag" className="rounded-circle" />
                                              </div>
                                              <div>
                                                <div className="hstack">
                                                  <Link
                                                    to={`https://www.amazon.${"com"}/sp?seller=${"AAW6CJIKK3PL3"}`}
                                                    target="_blank"
                                                    className="link-secondary link-offset-2 text-decoration-underline"
                                                  >
                                                    AAW6CJIKK3PL3
                                                    <i className="ri-arrow-right-up-line"></i>
                                                  </Link>
                                                  <div>
                                                    <i id="PopoverSellerStrength-1" className="text-warning align-middle ri-flashlight-fill ms-1 fs-14"></i>
                                                    <DefaultUncontrolledTooltip target="PopoverSellerStrength-1">
                                                      {"Moderate seller: This seller has an average number of feedback and ratings, presenting moderate competition."}
                                                    </DefaultUncontrolledTooltip>
                                                  </div>
                                                </div>
                                              </div>
                                            </span>
                                          </td>
                                          <td>
                                            <span className="badge bg-warning-subtle text-warning fs-12">FBA</span>
                                          </td>
                                          <td>
                                            <span className="d-flex align-items-center">
                                              <i className="ri-star-fill text-warning fs-16 me-1"></i> 17
                                            </span>
                                          </td>
                                          <td>
                                            <span className="d-flex">$88.06 CAD</span>
                                            <span className="text-nowrap d-flex align-items-center">
                                              <i className="text-warning mdi mdi-trending-down me-1"></i>
                                              <span className="text-secondary text-nowrap small">Lowest</span>
                                            </span>
                                          </td>
                                          <td>
                                            <span className="d-flex align-items-center">
                                              <CheckboxItem status={true} />
                                            </span>
                                          </td>
                                          <td>
                                            <span className="d-flex align-items-center">
                                              <CheckboxItem status={false} />
                                            </span>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <span className="hstack gap-2">
                                              <div className="avatar-xs img-thumbnail rounded-circle flex-shrink-0">
                                                <img src={destinationMarketplace?.flag /*sellerMarketplace?.flag*/} alt="Country Flag" className="rounded-circle" />
                                              </div>
                                              <div>
                                                <div className="hstack">
                                                  <span>Amazon CA</span>
                                                  <div>
                                                    <i id="PopoverSellerStrength-2" className="text-danger align-middle ri-flashlight-fill ms-1 fs-14"></i>
                                                    <DefaultUncontrolledTooltip target="PopoverSellerStrength-2">
                                                      {"Strong seller: This seller has a high number of feedback and ratings, indicating strong competition."}
                                                    </DefaultUncontrolledTooltip>
                                                  </div>
                                                </div>
                                                <span>
                                                  <i className="text-secondary mdi mdi-basket me-1 align-middle"></i>
                                                  <span className="text-secondary text-nowrap small fw-medium">Buybox</span>
                                                </span>
                                              </div>
                                            </span>
                                          </td>
                                          <td>
                                            <span className="badge bg-danger-subtle text-danger fs-12">Amazon</span>
                                          </td>
                                          <td>
                                            <span className="d-flex align-items-center">
                                              <i className="ri-star-fill text-warning fs-16 me-1"></i> 12
                                            </span>
                                          </td>
                                          <td>$88.06 CAD</td>
                                          <td>
                                            <span className="d-flex align-items-center">
                                              <CheckboxItem status={false} />
                                            </span>
                                          </td>
                                          <td>
                                            <span className="d-flex align-items-center">
                                              <CheckboxItem status={true} />
                                            </span>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <span className="hstack gap-2">
                                              <div className="avatar-xs img-thumbnail rounded-circle flex-shrink-0">
                                                <img src={destinationMarketplace?.flag /*sellerMarketplace?.flag*/} alt="Country Flag" className="rounded-circle" />
                                              </div>
                                              <div>
                                                <div className="hstack">
                                                  <Link
                                                    to={`https://www.amazon.${"com"}/sp?seller=${"A1ISKZNHY8JGSW"}`}
                                                    target="_blank"
                                                    className="link-secondary link-offset-2 text-decoration-underline"
                                                  >
                                                    A1ISKZNHY8JGSW
                                                    <i className="ri-arrow-right-up-line"></i>
                                                  </Link>
                                                  <div>
                                                    <i id="PopoverSellerStrength-3" className="text-success align-middle ri-flashlight-fill ms-1 fs-14"></i>
                                                    <DefaultUncontrolledTooltip target="PopoverSellerStrength-3">
                                                      {"Weak seller: This seller has a low number of feedback and ratings, making them easier to compete with."}
                                                    </DefaultUncontrolledTooltip>
                                                  </div>
                                                </div>
                                              </div>
                                            </span>
                                          </td>
                                          <td>
                                            <span className="badge bg-primary-subtle text-primary fs-12">FBM</span>
                                          </td>
                                          <td>
                                            <span className="d-flex align-items-center">
                                              <i className="ri-star-fill text-warning fs-16 me-1"></i> 133
                                            </span>
                                          </td>
                                          <td>$88.06 CAD</td>
                                          <td>
                                            <span className="d-flex align-items-center">
                                              <CheckboxItem status={true} />
                                            </span>
                                          </td>
                                          <td>
                                            <span className="d-flex align-items-center">
                                              <CheckboxItem status={false} />
                                            </span>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <span className="hstack gap-2">
                                              <div className="avatar-xs img-thumbnail rounded-circle flex-shrink-0">
                                                <img src={destinationMarketplace?.flag /*sellerMarketplace?.flag*/} alt="Country Flag" className="rounded-circle" />
                                              </div>
                                              <div>
                                                <div className="hstack">
                                                  <Link
                                                    to={`https://www.amazon.${"com"}/sp?seller=${"AAW6CJIKK3PL3"}`}
                                                    target="_blank"
                                                    className="link-secondary link-offset-2 text-decoration-underline"
                                                  >
                                                    AAW6CJIKK3PL3
                                                    <i className="ri-arrow-right-up-line"></i>
                                                  </Link>
                                                  <div>
                                                    <i id="PopoverSellerStrength-1" className="text-warning align-middle ri-flashlight-fill ms-1 fs-14"></i>
                                                    <DefaultUncontrolledTooltip target="PopoverSellerStrength-1">
                                                      {"Moderate seller: This seller has an average number of feedback and ratings, presenting moderate competition."}
                                                    </DefaultUncontrolledTooltip>
                                                  </div>
                                                </div>
                                              </div>
                                            </span>
                                          </td>
                                          <td>
                                            <span className="badge bg-warning-subtle text-warning fs-12">FBA</span>
                                          </td>
                                          <td>
                                            <span className="d-flex align-items-center">
                                              <i className="ri-star-fill text-warning fs-16 me-1"></i> 17
                                            </span>
                                          </td>
                                          <td>
                                            <span className="d-flex">$88.06 CAD</span>
                                            <span className="text-nowrap d-flex align-items-center">
                                              <i className="text-warning mdi mdi-trending-down me-1"></i>
                                              <span className="text-secondary text-nowrap small">Lowest</span>
                                            </span>
                                          </td>
                                          <td>
                                            <span className="d-flex align-items-center">
                                              <CheckboxItem status={true} />
                                            </span>
                                          </td>
                                          <td>
                                            <span className="d-flex align-items-center">
                                              <CheckboxItem status={false} />
                                            </span>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <span className="hstack gap-2">
                                              <div className="avatar-xs img-thumbnail rounded-circle flex-shrink-0">
                                                <img src={destinationMarketplace?.flag /*sellerMarketplace?.flag*/} alt="Country Flag" className="rounded-circle" />
                                              </div>
                                              <div>
                                                <div className="hstack">
                                                  <Link
                                                    to={`https://www.amazon.${"com"}/sp?seller=${"AAW6CJIKK3PL3"}`}
                                                    target="_blank"
                                                    className="link-secondary link-offset-2 text-decoration-underline"
                                                  >
                                                    AAW6CJIKK3PL3
                                                    <i className="ri-arrow-right-up-line"></i>
                                                  </Link>
                                                  <div>
                                                    <i id="PopoverSellerStrength-1" className="text-warning align-middle ri-flashlight-fill ms-1 fs-14"></i>
                                                    <DefaultUncontrolledTooltip target="PopoverSellerStrength-1">
                                                      {"Moderate seller: This seller has an average number of feedback and ratings, presenting moderate competition."}
                                                    </DefaultUncontrolledTooltip>
                                                  </div>
                                                </div>
                                              </div>
                                            </span>
                                          </td>
                                          <td>
                                            <span className="badge bg-warning-subtle text-warning fs-12">FBA</span>
                                          </td>
                                          <td>
                                            <span className="d-flex align-items-center">
                                              <i className="ri-star-fill text-warning fs-16 me-1"></i> 17
                                            </span>
                                          </td>
                                          <td>
                                            <span className="d-flex">$88.06 CAD</span>
                                            <span className="text-nowrap d-flex align-items-center">
                                              <i className="text-warning mdi mdi-trending-down me-1"></i>
                                              <span className="text-secondary text-nowrap small">Lowest</span>
                                            </span>
                                          </td>
                                          <td>
                                            <span className="d-flex align-items-center">
                                              <CheckboxItem status={true} />
                                            </span>
                                          </td>
                                          <td>
                                            <span className="d-flex align-items-center">
                                              <CheckboxItem status={false} />
                                            </span>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <span className="hstack gap-2">
                                              <div className="avatar-xs img-thumbnail rounded-circle flex-shrink-0">
                                                <img src={destinationMarketplace?.flag /*sellerMarketplace?.flag*/} alt="Country Flag" className="rounded-circle" />
                                              </div>
                                              <div>
                                                <div className="hstack">
                                                  <Link
                                                    to={`https://www.amazon.${"com"}/sp?seller=${"AAW6CJIKK3PL3"}`}
                                                    target="_blank"
                                                    className="link-secondary link-offset-2 text-decoration-underline"
                                                  >
                                                    AAW6CJIKK3PL3
                                                    <i className="ri-arrow-right-up-line"></i>
                                                  </Link>
                                                  <div>
                                                    <i id="PopoverSellerStrength-1" className="text-warning align-middle ri-flashlight-fill ms-1 fs-14"></i>
                                                    <DefaultUncontrolledTooltip target="PopoverSellerStrength-1">
                                                      {"Moderate seller: This seller has an average number of feedback and ratings, presenting moderate competition."}
                                                    </DefaultUncontrolledTooltip>
                                                  </div>
                                                </div>
                                              </div>
                                            </span>
                                          </td>
                                          <td>
                                            <span className="badge bg-warning-subtle text-warning fs-12">FBA</span>
                                          </td>
                                          <td>
                                            <span className="d-flex align-items-center">
                                              <i className="ri-star-fill text-warning fs-16 me-1"></i> 17
                                            </span>
                                          </td>
                                          <td>
                                            <span className="d-flex">$88.06 CAD</span>
                                            <span className="text-nowrap d-flex align-items-center">
                                              <i className="text-warning mdi mdi-trending-down me-1"></i>
                                              <span className="text-secondary text-nowrap small">Lowest</span>
                                            </span>
                                          </td>
                                          <td>
                                            <span className="d-flex align-items-center">
                                              <CheckboxItem status={true} />
                                            </span>
                                          </td>
                                          <td>
                                            <span className="d-flex align-items-center">
                                              <CheckboxItem status={false} />
                                            </span>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <span className="hstack gap-2">
                                              <div className="avatar-xs img-thumbnail rounded-circle flex-shrink-0">
                                                <img src={destinationMarketplace?.flag /*sellerMarketplace?.flag*/} alt="Country Flag" className="rounded-circle" />
                                              </div>
                                              <div>
                                                <div className="hstack">
                                                  <Link
                                                    to={`https://www.amazon.${"com"}/sp?seller=${"AAW6CJIKK3PL3"}`}
                                                    target="_blank"
                                                    className="link-secondary link-offset-2 text-decoration-underline"
                                                  >
                                                    AAW6CJIKK3PL3
                                                    <i className="ri-arrow-right-up-line"></i>
                                                  </Link>
                                                  <div>
                                                    <i id="PopoverSellerStrength-1" className="text-warning align-middle ri-flashlight-fill ms-1 fs-14"></i>
                                                    <DefaultUncontrolledTooltip target="PopoverSellerStrength-1">
                                                      {"Moderate seller: This seller has an average number of feedback and ratings, presenting moderate competition."}
                                                    </DefaultUncontrolledTooltip>
                                                  </div>
                                                </div>
                                              </div>
                                            </span>
                                          </td>
                                          <td>
                                            <span className="badge bg-warning-subtle text-warning fs-12">FBA</span>
                                          </td>
                                          <td>
                                            <span className="d-flex align-items-center">
                                              <i className="ri-star-fill text-warning fs-16 me-1"></i> 17
                                            </span>
                                          </td>
                                          <td>
                                            <span className="d-flex">$88.06 CAD</span>
                                            <span className="text-nowrap d-flex align-items-center">
                                              <i className="text-warning mdi mdi-trending-down me-1"></i>
                                              <span className="text-secondary text-nowrap small">Lowest</span>
                                            </span>
                                          </td>
                                          <td>
                                            <span className="d-flex align-items-center">
                                              <CheckboxItem status={true} />
                                            </span>
                                          </td>
                                          <td>
                                            <span className="d-flex align-items-center">
                                              <CheckboxItem status={false} />
                                            </span>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <span className="hstack gap-2">
                                              <div className="avatar-xs img-thumbnail rounded-circle flex-shrink-0">
                                                <img src={destinationMarketplace?.flag /*sellerMarketplace?.flag*/} alt="Country Flag" className="rounded-circle" />
                                              </div>
                                              <div>
                                                <div className="hstack">
                                                  <Link
                                                    to={`https://www.amazon.${"com"}/sp?seller=${"AAW6CJIKK3PL3"}`}
                                                    target="_blank"
                                                    className="link-secondary link-offset-2 text-decoration-underline"
                                                  >
                                                    AAW6CJIKK3PL3
                                                    <i className="ri-arrow-right-up-line"></i>
                                                  </Link>
                                                  <div>
                                                    <i id="PopoverSellerStrength-1" className="text-warning align-middle ri-flashlight-fill ms-1 fs-14"></i>
                                                    <DefaultUncontrolledTooltip target="PopoverSellerStrength-1">
                                                      {"Moderate seller: This seller has an average number of feedback and ratings, presenting moderate competition."}
                                                    </DefaultUncontrolledTooltip>
                                                  </div>
                                                </div>
                                              </div>
                                            </span>
                                          </td>
                                          <td>
                                            <span className="badge bg-warning-subtle text-warning fs-12">FBA</span>
                                          </td>
                                          <td>
                                            <span className="d-flex align-items-center">
                                              <i className="ri-star-fill text-warning fs-16 me-1"></i> 17
                                            </span>
                                          </td>
                                          <td>
                                            <span className="d-flex">$88.06 CAD</span>
                                            <span className="text-nowrap d-flex align-items-center">
                                              <i className="text-warning mdi mdi-trending-down me-1"></i>
                                              <span className="text-secondary text-nowrap small">Lowest</span>
                                            </span>
                                          </td>
                                          <td>
                                            <span className="d-flex align-items-center">
                                              <CheckboxItem status={true} />
                                            </span>
                                          </td>
                                          <td>
                                            <span className="d-flex align-items-center">
                                              <CheckboxItem status={false} />
                                            </span>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <span className="hstack gap-2">
                                              <div className="avatar-xs img-thumbnail rounded-circle flex-shrink-0">
                                                <img src={destinationMarketplace?.flag /*sellerMarketplace?.flag*/} alt="Country Flag" className="rounded-circle" />
                                              </div>
                                              <div>
                                                <div className="hstack">
                                                  <Link
                                                    to={`https://www.amazon.${"com"}/sp?seller=${"AAW6CJIKK3PL3"}`}
                                                    target="_blank"
                                                    className="link-secondary link-offset-2 text-decoration-underline"
                                                  >
                                                    AAW6CJIKK3PL3
                                                    <i className="ri-arrow-right-up-line"></i>
                                                  </Link>
                                                  <div>
                                                    <i id="PopoverSellerStrength-1" className="text-warning align-middle ri-flashlight-fill ms-1 fs-14"></i>
                                                    <DefaultUncontrolledTooltip target="PopoverSellerStrength-1">
                                                      {"Moderate seller: This seller has an average number of feedback and ratings, presenting moderate competition."}
                                                    </DefaultUncontrolledTooltip>
                                                  </div>
                                                </div>
                                              </div>
                                            </span>
                                          </td>
                                          <td>
                                            <span className="badge bg-warning-subtle text-warning fs-12">FBA</span>
                                          </td>
                                          <td>
                                            <span className="d-flex align-items-center">
                                              <i className="ri-star-fill text-warning fs-16 me-1"></i> 17
                                            </span>
                                          </td>
                                          <td>
                                            <span className="d-flex">$88.06 CAD</span>
                                            <span className="text-nowrap d-flex align-items-center">
                                              <i className="text-warning mdi mdi-trending-down me-1"></i>
                                              <span className="text-secondary text-nowrap small">Lowest</span>
                                            </span>
                                          </td>
                                          <td>
                                            <span className="d-flex align-items-center">
                                              <CheckboxItem status={true} />
                                            </span>
                                          </td>
                                          <td>
                                            <span className="d-flex align-items-center">
                                              <CheckboxItem status={false} />
                                            </span>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <span className="hstack gap-2">
                                              <div className="avatar-xs img-thumbnail rounded-circle flex-shrink-0">
                                                <img src={destinationMarketplace?.flag /*sellerMarketplace?.flag*/} alt="Country Flag" className="rounded-circle" />
                                              </div>
                                              <div>
                                                <div className="hstack">
                                                  <Link
                                                    to={`https://www.amazon.${"com"}/sp?seller=${"AAW6CJIKK3PL3"}`}
                                                    target="_blank"
                                                    className="link-secondary link-offset-2 text-decoration-underline"
                                                  >
                                                    AAW6CJIKK3PL3
                                                    <i className="ri-arrow-right-up-line"></i>
                                                  </Link>
                                                  <div>
                                                    <i id="PopoverSellerStrength-1" className="text-warning align-middle ri-flashlight-fill ms-1 fs-14"></i>
                                                    <DefaultUncontrolledTooltip target="PopoverSellerStrength-1">
                                                      {"Moderate seller: This seller has an average number of feedback and ratings, presenting moderate competition."}
                                                    </DefaultUncontrolledTooltip>
                                                  </div>
                                                </div>
                                              </div>
                                            </span>
                                          </td>
                                          <td>
                                            <span className="badge bg-warning-subtle text-warning fs-12">FBA</span>
                                          </td>
                                          <td>
                                            <span className="d-flex align-items-center">
                                              <i className="ri-star-fill text-warning fs-16 me-1"></i> 17
                                            </span>
                                          </td>
                                          <td>
                                            <span className="d-flex">$88.06 CAD</span>
                                            <span className="text-nowrap d-flex align-items-center">
                                              <i className="text-warning mdi mdi-trending-down me-1"></i>
                                              <span className="text-secondary text-nowrap small">Lowest</span>
                                            </span>
                                          </td>
                                          <td>
                                            <span className="d-flex align-items-center">
                                              <CheckboxItem status={true} />
                                            </span>
                                          </td>
                                          <td>
                                            <span className="d-flex align-items-center">
                                              <CheckboxItem status={false} />
                                            </span>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <span className="hstack gap-2">
                                              <div className="avatar-xs img-thumbnail rounded-circle flex-shrink-0">
                                                <img src={destinationMarketplace?.flag /*sellerMarketplace?.flag*/} alt="Country Flag" className="rounded-circle" />
                                              </div>
                                              <div>
                                                <div className="hstack">
                                                  <Link
                                                    to={`https://www.amazon.${"com"}/sp?seller=${"AAW6CJIKK3PL3"}`}
                                                    target="_blank"
                                                    className="link-secondary link-offset-2 text-decoration-underline"
                                                  >
                                                    AAW6CJIKK3PL3
                                                    <i className="ri-arrow-right-up-line"></i>
                                                  </Link>
                                                  <div>
                                                    <i id="PopoverSellerStrength-1" className="text-warning align-middle ri-flashlight-fill ms-1 fs-14"></i>
                                                    <DefaultUncontrolledTooltip target="PopoverSellerStrength-1">
                                                      {"Moderate seller: This seller has an average number of feedback and ratings, presenting moderate competition."}
                                                    </DefaultUncontrolledTooltip>
                                                  </div>
                                                </div>
                                              </div>
                                            </span>
                                          </td>
                                          <td>
                                            <span className="badge bg-warning-subtle text-warning fs-12">FBA</span>
                                          </td>
                                          <td>
                                            <span className="d-flex align-items-center">
                                              <i className="ri-star-fill text-warning fs-16 me-1"></i> 17
                                            </span>
                                          </td>
                                          <td>
                                            <span className="d-flex">$88.06 CAD</span>
                                            <span className="text-nowrap d-flex align-items-center">
                                              <i className="text-warning mdi mdi-trending-down me-1"></i>
                                              <span className="text-secondary text-nowrap small">Lowest</span>
                                            </span>
                                          </td>
                                          <td>
                                            <span className="d-flex align-items-center">
                                              <CheckboxItem status={true} />
                                            </span>
                                          </td>
                                          <td>
                                            <span className="d-flex align-items-center">
                                              <CheckboxItem status={false} />
                                            </span>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <span className="hstack gap-2">
                                              <div className="avatar-xs img-thumbnail rounded-circle flex-shrink-0">
                                                <img src={destinationMarketplace?.flag /*sellerMarketplace?.flag*/} alt="Country Flag" className="rounded-circle" />
                                              </div>
                                              <div>
                                                <div className="hstack">
                                                  <Link
                                                    to={`https://www.amazon.${"com"}/sp?seller=${"AAW6CJIKK3PL3"}`}
                                                    target="_blank"
                                                    className="link-secondary link-offset-2 text-decoration-underline"
                                                  >
                                                    AAW6CJIKK3PL3
                                                    <i className="ri-arrow-right-up-line"></i>
                                                  </Link>
                                                  <div>
                                                    <i id="PopoverSellerStrength-1" className="text-warning align-middle ri-flashlight-fill ms-1 fs-14"></i>
                                                    <DefaultUncontrolledTooltip target="PopoverSellerStrength-1">
                                                      {"Moderate seller: This seller has an average number of feedback and ratings, presenting moderate competition."}
                                                    </DefaultUncontrolledTooltip>
                                                  </div>
                                                </div>
                                              </div>
                                            </span>
                                          </td>
                                          <td>
                                            <span className="badge bg-warning-subtle text-warning fs-12">FBA</span>
                                          </td>
                                          <td>
                                            <span className="d-flex align-items-center">
                                              <i className="ri-star-fill text-warning fs-16 me-1"></i> 17
                                            </span>
                                          </td>
                                          <td>
                                            <span className="d-flex">$88.06 CAD</span>
                                            <span className="text-nowrap d-flex align-items-center">
                                              <i className="text-warning mdi mdi-trending-down me-1"></i>
                                              <span className="text-secondary text-nowrap small">Lowest</span>
                                            </span>
                                          </td>
                                          <td>
                                            <span className="d-flex align-items-center">
                                              <CheckboxItem status={true} />
                                            </span>
                                          </td>
                                          <td>
                                            <span className="d-flex align-items-center">
                                              <CheckboxItem status={false} />
                                            </span>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </SimpleBar>
                                </div>
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>

                <Row>
                  <Col xl={12} className="same-height">
                    <Card className="card-animate">
                      <CardBody>
                        <h5 className="fs-15">{t("ProductDetails.VariationAnalysis.Title")}</h5>

                        <Row className="mt-4">
                          <Col xl={8} sm={12}>
                            <Card>
                              <CardBody>
                                <div className="table-responsive table-card">
                                  <SimpleBar style={{height: "480px"}}>
                                    <table className="table-centered align-middle table-hover mb-0 h-100 table border-top-0">
                                      <thead className="sticky-top bg-light">
                                        <tr>
                                          <th>ASIN</th>
                                          <th>{t("Product")}</th>
                                          <th>{t("Dimensions")}</th>
                                          <th>{t("Price")}</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td className="ribbon-box ribbon-fill ribbon-sm">
                                            <div id="PopoverCurrentVariation" className="ribbon ribbon-info" style={{zIndex: 9}}>
                                              <i className="ri-flashlight-fill"></i>
                                            </div>
                                            <DefaultUncontrolledTooltip target="PopoverCurrentVariation">{"This is the selected variation."}</DefaultUncontrolledTooltip>
                                            <div className="overflow-hidden">
                                              <Row className="align-items-center" style={{minWidth: "280px"}}>
                                                <Col xs="auto" className="pe-1">
                                                  <div className="avatar-sm rounded flex-shrink-0 overflow-hidden">
                                                    <img className="rounded w-100" src={"https://m.media-amazon.com/images/I/51bu+fH8w8L._SL75_.jpg"} alt="" />
                                                  </div>
                                                </Col>
                                                <Col xs="auto">
                                                  <div className="d-flex align-items-center">
                                                    <CopyAsinWidget Asin={"B01A5STDAO"} index={"B01A5STDAO"} asLink={true} marketplace={destinationMarketplace?.marketplace} />
                                                  </div>
                                                  <div className="d-flex gap-1 mt-1">
                                                    <span className="badge bg-danger-subtle text-danger fs-12">2 FBA</span>
                                                    <span className="badge bg-success-subtle text-success fs-12">5 FBM</span>
                                                  </div>
                                                </Col>
                                              </Row>
                                            </div>
                                          </td>
                                          <td>
                                            <p className="ellipsis-two-lines mb-0" style={{maxWidth: "350px"}}>
                                              EarPlanes EP2 Ultra-Reusable Earplugs, Airplane Pressure Prevention Earplugs for Adults (? Pairs)
                                            </p>
                                          </td>
                                          <td>
                                            <span className="text-nowrap">
                                              <span className="fs-14 fw-medium">1.55 * 1.2 * 1.3 inch</span>
                                              <h5 className="fs-14 my-1 text-muted">2.5 lb</h5>
                                            </span>
                                          </td>
                                          <td>
                                            <span className="text-nowrap">
                                              <span className="fs-14 fw-medium">$72.55 CAD</span>
                                              <span className="text-nowrap d-flex align-items-center fw-medium">
                                                <i className="fs-13 align-middle text-success ri-arrow-right-up-line me-1"></i>
                                                <span className="text-success">2.3%</span>
                                              </span>
                                            </span>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <div className="overflow-hidden">
                                              <Row className="align-items-center" style={{minWidth: "280px"}}>
                                                <Col xs="auto" className="pe-1">
                                                  <div className="avatar-sm rounded flex-shrink-0 overflow-hidden">
                                                    <img className="rounded w-100" src={"https://m.media-amazon.com/images/I/41nHe5sKBEL._SL75_.jpg"} alt="" />
                                                  </div>
                                                </Col>
                                                <Col xs="auto">
                                                  <div className="d-flex align-items-center">
                                                    <CopyAsinWidget Asin={"B01A5STDAO"} index={"B01A5STDAO"} asLink={true} marketplace={destinationMarketplace?.marketplace} />
                                                  </div>
                                                  <div className="d-flex gap-1 mt-1">
                                                    <span className="badge bg-danger-subtle text-danger fs-12">2 FBA</span>
                                                    <span className="badge bg-success-subtle text-success fs-12">5 FBM</span>
                                                  </div>
                                                </Col>
                                              </Row>
                                            </div>
                                          </td>
                                          <td>
                                            <p className="ellipsis-two-lines mb-0" style={{maxWidth: "350px"}}>
                                              (1 Pack) Pokemon Card Game Japanese 151 SV2a Booster Pack (7 Cards Per Pack)
                                            </p>
                                          </td>
                                          <td>
                                            <span className="text-nowrap">
                                              <span className="fs-14 fw-medium">1.55 * 1.2 * 1.3 inch</span>
                                              <h5 className="fs-14 my-1 text-muted">2.5 lb</h5>
                                            </span>
                                          </td>
                                          <td>
                                            <span className="text-nowrap">
                                              <span className="fs-14 fw-medium">$72.55 CAD</span>
                                              <span className="text-nowrap d-flex align-items-center fw-medium">
                                                <i className="fs-13 align-middle text-success ri-arrow-right-up-line me-1"></i>
                                                <span className="text-success">2.3%</span>
                                              </span>
                                            </span>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <div className="overflow-hidden">
                                              <Row className="align-items-center" style={{minWidth: "280px"}}>
                                                <Col xs="auto" className="pe-1">
                                                  <div className="avatar-sm rounded flex-shrink-0 overflow-hidden">
                                                    <img className="rounded w-100" src={"https://m.media-amazon.com/images/I/51j5+LgSWaL._SL75_.jpg"} alt="" />
                                                  </div>
                                                </Col>
                                                <Col xs="auto">
                                                  <div className="d-flex align-items-center">
                                                    <CopyAsinWidget Asin={"B01A5STDAO"} index={"B01A5STDAO"} asLink={true} marketplace={destinationMarketplace?.marketplace} />
                                                  </div>
                                                  <div className="d-flex gap-1 mt-1">
                                                    <span className="badge bg-danger-subtle text-danger fs-12">2 FBA</span>
                                                    <span className="badge bg-success-subtle text-success fs-12">5 FBM</span>
                                                  </div>
                                                </Col>
                                              </Row>
                                            </div>
                                          </td>
                                          <td>
                                            <p className="ellipsis-two-lines mb-0" style={{maxWidth: "350px"}}>
                                              RAK Multi-Tool 2Pc Pen Gift Set - LED Light, Touchscreen Stylus, Ruler, Level, Bottle Opener, Phillips Screwdriver, Flathead, and Ballpoint Pen
                                            </p>
                                          </td>
                                          <td>
                                            <span className="text-nowrap">
                                              <span className="fs-14 fw-medium">1.55 * 1.2 * 1.3 inch</span>
                                              <h5 className="fs-14 my-1 text-muted">2.5 lb</h5>
                                            </span>
                                          </td>
                                          <td>
                                            <span className="text-nowrap">
                                              <span className="fs-14 fw-medium">$72.55 CAD</span>
                                              <span className="text-nowrap d-flex align-items-center fw-medium">
                                                <i className="fs-13 align-middle text-success ri-arrow-right-up-line me-1"></i>
                                                <span className="text-success">2.3%</span>
                                              </span>
                                            </span>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <div className="overflow-hidden">
                                              <Row className="align-items-center" style={{minWidth: "280px"}}>
                                                <Col xs="auto" className="pe-1">
                                                  <div className="avatar-sm rounded flex-shrink-0 overflow-hidden">
                                                    <img className="rounded w-100" src={"https://m.media-amazon.com/images/I/41Wr1TUEChL._SL75_.jpg"} alt="" />
                                                  </div>
                                                </Col>
                                                <Col xs="auto">
                                                  <div className="d-flex align-items-center">
                                                    <CopyAsinWidget Asin={"B01A5STDAO"} index={"B01A5STDAO"} asLink={true} marketplace={destinationMarketplace?.marketplace} />
                                                  </div>
                                                  <div className="d-flex gap-1 mt-1">
                                                    <span className="badge bg-danger-subtle text-danger fs-12">2 FBA</span>
                                                    <span className="badge bg-success-subtle text-success fs-12">5 FBM</span>
                                                  </div>
                                                </Col>
                                              </Row>
                                            </div>
                                          </td>
                                          <td>
                                            <p className="ellipsis-two-lines mb-0" style={{maxWidth: "350px"}}>
                                              Thermacell Refills for Rechargeable Mosquito Repellers, Radius and E-Series, 3-Pack of 40-Hour Refills for 120-Hours Total; Improved Repellent Formula
                                              Providing 20-Foot Zone
                                            </p>
                                          </td>
                                          <td>
                                            <span className="text-nowrap">
                                              <span className="fs-14 fw-medium">1.55 * 1.2 * 1.3 inch</span>
                                              <h5 className="fs-14 my-1 text-muted">2.5 lb</h5>
                                            </span>
                                          </td>
                                          <td>
                                            <span className="text-nowrap">
                                              <span className="fs-14 fw-medium">$72.55 CAD</span>
                                              <span className="text-nowrap d-flex align-items-center fw-medium">
                                                <i className="fs-13 align-middle text-success ri-arrow-right-up-line me-1"></i>
                                                <span className="text-success">2.3%</span>
                                              </span>
                                            </span>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <div className="overflow-hidden">
                                              <Row className="align-items-center" style={{minWidth: "280px"}}>
                                                <Col xs="auto" className="pe-1">
                                                  <div className="avatar-sm rounded flex-shrink-0 overflow-hidden">
                                                    <img className="rounded w-100" src={"https://m.media-amazon.com/images/I/41aKIDlFGeL._SL75_.jpg"} alt="" />
                                                  </div>
                                                </Col>
                                                <Col xs="auto">
                                                  <div className="d-flex align-items-center">
                                                    <CopyAsinWidget Asin={"B01A5STDAO"} index={"B01A5STDAO"} asLink={true} marketplace={destinationMarketplace?.marketplace} />
                                                  </div>
                                                  <div className="d-flex gap-1 mt-1">
                                                    <span className="badge bg-danger-subtle text-danger fs-12">2 FBA</span>
                                                    <span className="badge bg-success-subtle text-success fs-12">5 FBM</span>
                                                  </div>
                                                </Col>
                                              </Row>
                                            </div>
                                          </td>
                                          <td>
                                            <p className="ellipsis-two-lines mb-0" style={{maxWidth: "350px"}}>
                                              (1 Pack) Pokemon Card Game Japanese 151 SV2a Booster Pack (7 Cards Per Pack)
                                            </p>
                                          </td>
                                          <td>
                                            <span className="text-nowrap">
                                              <span className="fs-14 fw-medium">1.55 * 1.2 * 1.3 inch</span>
                                              <h5 className="fs-14 my-1 text-muted">2.5 lb</h5>
                                            </span>
                                          </td>
                                          <td>
                                            <span className="text-nowrap">
                                              <span className="fs-14 fw-medium">$72.55 CAD</span>
                                              <span className="text-nowrap d-flex align-items-center fw-medium">
                                                <i className="fs-13 align-middle text-success ri-arrow-right-up-line me-1"></i>
                                                <span className="text-success">2.3%</span>
                                              </span>
                                            </span>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <div className="overflow-hidden">
                                              <Row className="align-items-center" style={{minWidth: "280px"}}>
                                                <Col xs="auto" className="pe-1">
                                                  <div className="avatar-sm rounded flex-shrink-0 overflow-hidden">
                                                    <img className="rounded w-100" src={"https://m.media-amazon.com/images/I/51AUWXKJprL._SL75_.jpg"} alt="" />
                                                  </div>
                                                </Col>
                                                <Col xs="auto">
                                                  <div className="d-flex align-items-center">
                                                    <CopyAsinWidget Asin={"B01A5STDAO"} index={"B01A5STDAO"} asLink={true} marketplace={destinationMarketplace?.marketplace} />
                                                  </div>
                                                  <div className="d-flex gap-1 mt-1">
                                                    <span className="badge bg-danger-subtle text-danger fs-12">2 FBA</span>
                                                    <span className="badge bg-success-subtle text-success fs-12">5 FBM</span>
                                                  </div>
                                                </Col>
                                              </Row>
                                            </div>
                                          </td>
                                          <td>
                                            <p className="ellipsis-two-lines mb-0" style={{maxWidth: "350px"}}>
                                              (1 Pack) Pokemon Card Game Japanese 151 SV2a Booster Pack (7 Cards Per Pack)
                                            </p>
                                          </td>
                                          <td>
                                            <span className="text-nowrap">
                                              <span className="fs-14 fw-medium">1.55 * 1.2 * 1.3 inch</span>
                                              <h5 className="fs-14 my-1 text-muted">2.5 lb</h5>
                                            </span>
                                          </td>
                                          <td>
                                            <span className="text-nowrap">
                                              <span className="fs-14 fw-medium">$72.55 CAD</span>
                                              <span className="text-nowrap d-flex align-items-center fw-medium">
                                                <i className="fs-13 align-middle text-success ri-arrow-right-up-line me-1"></i>
                                                <span className="text-success">2.3%</span>
                                              </span>
                                            </span>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <div className="overflow-hidden">
                                              <Row className="align-items-center" style={{minWidth: "280px"}}>
                                                <Col xs="auto" className="pe-1">
                                                  <div className="avatar-sm rounded flex-shrink-0 overflow-hidden">
                                                    <img className="rounded w-100" src={"https://m.media-amazon.com/images/I/51bu+fH8w8L._SL75_.jpg"} alt="" />
                                                  </div>
                                                </Col>
                                                <Col xs="auto">
                                                  <div className="d-flex align-items-center">
                                                    <CopyAsinWidget Asin={"B01A5STDAO"} index={"B01A5STDAO"} asLink={true} marketplace={destinationMarketplace?.marketplace} />
                                                  </div>
                                                  <div className="d-flex gap-1 mt-1">
                                                    <span className="badge bg-danger-subtle text-danger fs-12">2 FBA</span>
                                                    <span className="badge bg-success-subtle text-success fs-12">5 FBM</span>
                                                  </div>
                                                </Col>
                                              </Row>
                                            </div>
                                          </td>
                                          <td>
                                            <p className="ellipsis-two-lines mb-0" style={{maxWidth: "350px"}}>
                                              (1 Pack) Pokemon Card Game Japanese 151 SV2a Booster Pack (7 Cards Per Pack)
                                            </p>
                                          </td>
                                          <td>
                                            <span className="text-nowrap">
                                              <span className="fs-14 fw-medium">1.55 * 1.2 * 1.3 inch</span>
                                              <h5 className="fs-14 my-1 text-muted">2.5 lb</h5>
                                            </span>
                                          </td>
                                          <td>
                                            <span className="text-nowrap">
                                              <span className="fs-14 fw-medium">$72.55 CAD</span>
                                              <span className="text-nowrap d-flex align-items-center fw-medium">
                                                <i className="fs-13 align-middle text-success ri-arrow-right-up-line me-1"></i>
                                                <span className="text-success">2.3%</span>
                                              </span>
                                            </span>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <div className="overflow-hidden">
                                              <Row className="align-items-center" style={{minWidth: "280px"}}>
                                                <Col xs="auto" className="pe-1">
                                                  <div className="avatar-sm rounded flex-shrink-0 overflow-hidden">
                                                    <img className="rounded w-100" src={"https://m.media-amazon.com/images/I/51bu+fH8w8L._SL75_.jpg"} alt="" />
                                                  </div>
                                                </Col>
                                                <Col xs="auto">
                                                  <div className="d-flex align-items-center">
                                                    <CopyAsinWidget Asin={"B01A5STDAO"} index={"B01A5STDAO"} asLink={true} marketplace={destinationMarketplace?.marketplace} />
                                                  </div>
                                                  <div className="d-flex gap-1 mt-1">
                                                    <span className="badge bg-danger-subtle text-danger fs-12">2 FBA</span>
                                                    <span className="badge bg-success-subtle text-success fs-12">5 FBM</span>
                                                  </div>
                                                </Col>
                                              </Row>
                                            </div>
                                          </td>
                                          <td>
                                            <p className="ellipsis-two-lines mb-0" style={{maxWidth: "350px"}}>
                                              (1 Pack) Pokemon Card Game Japanese 151 SV2a Booster Pack (7 Cards Per Pack)
                                            </p>
                                          </td>
                                          <td>
                                            <span className="text-nowrap">
                                              <span className="fs-14 fw-medium">1.55 * 1.2 * 1.3 inch</span>
                                              <h5 className="fs-14 my-1 text-muted">2.5 lb</h5>
                                            </span>
                                          </td>
                                          <td>
                                            <span className="text-nowrap">
                                              <span className="fs-14 fw-medium">$72.55 CAD</span>
                                              <span className="text-nowrap d-flex align-items-center fw-medium">
                                                <i className="fs-13 align-middle text-success ri-arrow-right-up-line me-1"></i>
                                                <span className="text-success">2.3%</span>
                                              </span>
                                            </span>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <div className="overflow-hidden">
                                              <Row className="align-items-center" style={{minWidth: "280px"}}>
                                                <Col xs="auto" className="pe-1">
                                                  <div className="avatar-sm rounded flex-shrink-0 overflow-hidden">
                                                    <img className="rounded w-100" src={"https://m.media-amazon.com/images/I/51bu+fH8w8L._SL75_.jpg"} alt="" />
                                                  </div>
                                                </Col>
                                                <Col xs="auto">
                                                  <div className="d-flex align-items-center">
                                                    <CopyAsinWidget Asin={"B01A5STDAO"} index={"B01A5STDAO"} asLink={true} marketplace={destinationMarketplace?.marketplace} />
                                                  </div>
                                                  <div className="d-flex gap-1 mt-1">
                                                    <span className="badge bg-danger-subtle text-danger fs-12">2 FBA</span>
                                                    <span className="badge bg-success-subtle text-success fs-12">5 FBM</span>
                                                  </div>
                                                </Col>
                                              </Row>
                                            </div>
                                          </td>
                                          <td>
                                            <p className="ellipsis-two-lines mb-0" style={{maxWidth: "350px"}}>
                                              (1 Pack) Pokemon Card Game Japanese 151 SV2a Booster Pack (7 Cards Per Pack)
                                            </p>
                                          </td>
                                          <td>
                                            <span className="text-nowrap">
                                              <span className="fs-14 fw-medium">1.55 * 1.2 * 1.3 inch</span>
                                              <h5 className="fs-14 my-1 text-muted">2.5 lb</h5>
                                            </span>
                                          </td>
                                          <td>
                                            <span className="text-nowrap">
                                              <span className="fs-14 fw-medium">$72.55 CAD</span>
                                              <span className="text-nowrap d-flex align-items-center fw-medium">
                                                <i className="fs-13 align-middle text-success ri-arrow-right-up-line me-1"></i>
                                                <span className="text-success">2.3%</span>
                                              </span>
                                            </span>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </SimpleBar>
                                </div>
                              </CardBody>
                            </Card>
                          </Col>
                          <Col xl={4} sm={12}>
                            <UncontrolledAlert color="warning" className="alert-additional mb-4" toggle={null}>
                              <div className="alert-body">
                                <div className="d-flex">
                                  <div className="flex-shrink-0 me-3">
                                    <i className="ri-alert-line fs-16 align-middle"></i>
                                  </div>
                                  <div className="flex-grow-1">
                                    <h5 className="alert-heading">Too Many Variations!</h5>
                                    <p className="mb-0">
                                      We would like to remind you that this product has 435 variations, and 29 sales correspond to the total sales across all these variations. Therefore, a detailed
                                      analysis of the variations is necessary.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </UncontrolledAlert>

                            <Card>
                              <CardBody>
                                <div className="fs-15">
                                  <div className="d-flex pb-2">
                                    <div className="flex-grow-1">
                                      <p className="text-truncate mb-0">
                                        <i className="mdi mdi-layers-triple-outline align-middle text-primary me-2"></i>Variation count
                                      </p>
                                    </div>
                                    <div className="flex-shrink-0">
                                      <p className="mb-0">12</p>
                                    </div>
                                  </div>
                                  <div className="d-flex py-2">
                                    <div className="flex-grow-1">
                                      <p className="text-truncate mb-0">
                                        <i className="bx bx-dollar-circle align-middle text-private me-2"></i>Price difference with other variations
                                      </p>
                                    </div>
                                    <div className="flex-shrink-0">
                                      <p className="mb-0">%2.3</p>
                                    </div>
                                  </div>
                                  <div className="d-flex py-2">
                                    <div className="flex-grow-1">
                                      <p className="text-truncate mb-0">
                                        <i className="mdi mdi-store align-middle text-private me-2"></i>Are there FBA sellers in this variation?
                                      </p>
                                    </div>
                                    <div className="flex-shrink-0">
                                      <p className="mb-0">Evet</p>
                                    </div>
                                  </div>
                                  <div className="d-flex pt-2">
                                    <div className="flex-grow-1">
                                      <p className="text-truncate mb-0">
                                        <i className="mdi mdi-store align-middle text-primary me-2"></i>Are there FBA sellers in the other variations?
                                      </p>
                                    </div>
                                    <div className="flex-shrink-0">
                                      <p className="mb-0">Evet</p>
                                    </div>
                                  </div>
                                </div>
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>

                <Row>
                  <Col xl={12} className="same-height">
                    <Card className="card-animate">
                      <CardBody>
                        <h5 className="fs-15">{t("ProductDetails.SellersAndListingAnalysis.Title")}</h5>
                        <Row className="mt-3">
                          <Col className="col-12 mb-4">
                            <UncontrolledAlert color="danger" className="alert-additional mb-xl-0" toggle={null}>
                              <div className="alert-body">
                                <div className="d-flex">
                                  <div className="flex-shrink-0 me-3">
                                    <i className="ri-alert-line fs-16 align-middle"></i>
                                  </div>
                                  <div className="flex-grow-1">
                                    <h5 className="alert-heading">Potential Brand Owner Alert!</h5>
                                    <p className="mb-0">The product is sold by the potential brand owner. It is recommended to avoid this product due to the risk of suspension.</p>
                                  </div>
                                </div>
                              </div>
                            </UncontrolledAlert>
                          </Col>

                          <Col xxl={4} xl={6} sm={12}>
                            <Card>
                              <CardHeader className="bg-light py-2">
                                <span className="hstack gap-2">
                                  <div className="avatar-xs img-thumbnail rounded-circle flex-shrink-0">
                                    <img src={sourceMarketplace?.flag} alt="Country Flag" className="rounded-circle" />
                                  </div>
                                  <h5 className="mb-0">{sourceMarketplace?.countryName}</h5>
                                </span>
                              </CardHeader>
                              <CardBody>
                                <div className="fs-15">
                                  <div className="d-flex  pb-2">
                                    <div className="flex-grow-1">
                                      <p className="text-truncate mb-0">
                                        <i className="ri-amazon-fill align-middle me-2"></i>Listed on Amazon?
                                      </p>
                                    </div>
                                    <div className="flex-shrink-0">
                                      <span className="d-flex align-items-center">
                                        <CheckboxItem status={true} />
                                      </span>
                                    </div>
                                  </div>
                                  <div className="d-flex py-2">
                                    <div className="flex-grow-1">
                                      <p className="text-truncate mb-0">
                                        <i className="mdi mdi-store align-middle text-primary me-2"></i>Current Seller
                                      </p>
                                    </div>
                                    <div className="flex-shrink-0">
                                      <span className="badge bg-warning-subtle text-warning fs-12">FBA</span>
                                    </div>
                                  </div>
                                  <div className="d-flex pt-2">
                                    <div className="flex-grow-1">
                                      <p className="text-truncate mb-0">
                                        <i className="mdi mdi-store-edit align-middle text-primary me-2"></i>Seller Count
                                      </p>
                                    </div>
                                    <div className="flex-shrink-0">
                                      <p className="mb-0 d-flex align-items-center">
                                        <i className="ri-bar-chart-fill text-success me-1"></i> 25
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </CardBody>
                            </Card>
                            <Card>
                              <CardHeader className="bg-light py-2">
                                <span className="hstack gap-2">
                                  <div className="avatar-xs img-thumbnail rounded-circle flex-shrink-0">
                                    <img src={destinationMarketplace?.flag} alt="Country Flag" className="rounded-circle" />
                                  </div>
                                  <h5 className="mb-0">{destinationMarketplace?.countryName}</h5>
                                </span>
                              </CardHeader>
                              <CardBody>
                                <div className="fs-15">
                                  <div className="d-flex pb-2">
                                    <div className="flex-grow-1">
                                      <p className="text-truncate mb-0">
                                        <i className="ri-amazon-fill align-middle me-2"></i>Listed on Amazon?
                                      </p>
                                    </div>
                                    <div className="flex-shrink-0">
                                      <span className="d-flex align-items-center">
                                        <CheckboxItem status={true} />
                                      </span>
                                    </div>
                                  </div>
                                  <div className="d-flex py-2">
                                    <div className="flex-grow-1">
                                      <p className="text-truncate mb-0">
                                        <i className="mdi mdi-store-check align-middle text-primary me-2"></i>Current Seller
                                      </p>
                                    </div>
                                    <div className="flex-shrink-0">
                                      <span className="badge bg-danger-subtle text-primary fs-12">Amazon</span>
                                    </div>
                                  </div>
                                  <div className="d-flex pt-2">
                                    <div className="flex-grow-1">
                                      <p className="text-truncate mb-0">
                                        <i className="mdi mdi-store-edit align-middle text-primary me-2"></i>Seller Count
                                      </p>
                                    </div>
                                    <div className="flex-shrink-0">
                                      <p className="mb-0 d-flex align-items-center">
                                        <i className="ri-bar-chart-fill text-success me-1"></i> 12
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </CardBody>
                            </Card>
                            <Card>
                              <CardHeader className="bg-light">
                                <span className="hstack gap-2">
                                  <h5 className="mb-0">Cross-Market Sellers Statistics</h5>
                                </span>
                              </CardHeader>
                              <CardBody>
                                <div className="fs-15">
                                  <div className="d-flex pb-2">
                                    <div className="flex-grow-1">
                                      <p className="text-truncate mb-0">
                                        <i className="mdi mdi-store-edit align-middle text-primary me-2"></i>Common FBA Seller Count
                                      </p>
                                    </div>
                                    <div className="flex-shrink-0">
                                      <p className="mb-0 d-flex align-items-center">
                                        <i className="ri-bar-chart-fill text-success me-1"></i> 12
                                      </p>
                                    </div>
                                  </div>
                                  <div className="d-flex py-2">
                                    <div className="flex-grow-1">
                                      <p className="text-truncate mb-0">
                                        <i className="mdi mdi-store-edit align-middle text-primary me-2"></i>Common FBM Seller Count
                                      </p>
                                    </div>
                                    <div className="flex-shrink-0">
                                      <p className="mb-0 d-flex align-items-center">
                                        <i className="ri-bar-chart-fill text-success me-1"></i> 4
                                      </p>
                                    </div>
                                  </div>
                                  <div className="d-flex py-2">
                                    <div className="flex-grow-1">
                                      <p className="text-truncate mb-0">
                                        <i className="mdi mdi-store-edit align-middle text-primary me-2"></i>Common Remote FBA Seller Count
                                      </p>
                                    </div>
                                    <div className="flex-shrink-0">
                                      <p className="mb-0 d-flex align-items-center">
                                        <i className="ri-bar-chart-fill text-success me-1"></i> 1
                                      </p>
                                    </div>
                                  </div>
                                  <div className="d-flex pt-2">
                                    <div className="flex-grow-1">
                                      <p className="mb-0">
                                        <i className="mdi mdi-trademark align-middle text-primary me-2"></i>Potential Brand Owner
                                        <i className="mdi mdi-alert align-middle text-warning fs-18 ms-1"></i>
                                      </p>
                                    </div>
                                    <div className="flex-shrink-0">
                                      <span className="badge bg-danger-subtle text-danger fs-12">Yes</span>
                                    </div>
                                  </div>
                                </div>
                              </CardBody>
                            </Card>
                          </Col>
                          <Col xxl={4} xl={6} sm={12} className="same-height">
                            <Card>
                              <CardHeader className="bg-light py-2">
                                <div className="d-flex align-items-center">
                                  <span className="flex-grow-1 hstack gap-2">
                                    <div className="avatar-xs img-thumbnail rounded-circle flex-shrink-0">
                                      <img src={sourceMarketplace?.flag} alt="Country Flag" className="rounded-circle" />
                                    </div>
                                    <h5 className="fs-15 mb-0">Seller List</h5>
                                  </span>
                                  <div className="d-flex gap-1">
                                    <Button color="secondary" size="sm" className="btn-ghost-secondary">
                                      FBA <span className="badge bg-secondary-subtle text-secondary align-middle rounded-pill">1</span>
                                    </Button>
                                    <Button color="secondary" size="sm" className="btn-ghost-secondary">
                                      FBM <span className="badge bg-secondary-subtle text-secondary align-middle rounded-pill">13</span>
                                    </Button>
                                    <Button color="secondary" size="sm" className="btn-ghost-secondary">
                                      Amazon <span className="badge bg-secondary-subtle text-secondary align-middle rounded-pill">1</span>
                                    </Button>
                                    <Button color="secondary" size="sm" className="btn-soft-secondary">
                                      All <span className="badge bg-secondary-subtle text-secondary align-middle rounded-pill">15</span>
                                    </Button>
                                  </div>
                                </div>
                              </CardHeader>
                              <CardBody>
                                <div className="table-responsive table-card">
                                  <SimpleBar style={{height: "600px"}}>
                                    <table className="align-middle table-hover table-nowrap mb-0 h-100 table">
                                      <tbody>
                                        <tr>
                                          <td>
                                            <div className="align-items-center d-flex">
                                              <span className="flex-grow-1 hstack gap-2">
                                                <div className="avatar-xs img-thumbnail rounded-circle flex-shrink-0">
                                                  <img src={sourceMarketplace?.flag} alt="Country Flag" className="rounded-circle" />
                                                </div>
                                                <div>
                                                  <div className="hstack">
                                                    <Link
                                                      to={`https://www.amazon.${"com"}/sp?seller=${"A1ISKZNHY8JGSW"}`}
                                                      target="_blank"
                                                      className="link-dark link-offset-2 text-decoration-underline"
                                                    >
                                                      A1ISKZNHY8JGSW
                                                      <i className="ri-arrow-right-up-line"></i>
                                                    </Link>
                                                    <div>
                                                      <i id="PopoverSellerStrength-1" className="text-warning align-middle ri-flashlight-fill ms-1 fs-14"></i>
                                                      <DefaultUncontrolledTooltip target="PopoverSellerStrength-1">
                                                        {"Moderate seller: This seller has an average number of feedback and ratings, presenting moderate competition."}
                                                      </DefaultUncontrolledTooltip>
                                                    </div>
                                                    <span className="badge bg-warning-subtle text-warning fs-12 ms-2">FBA</span>
                                                    <span className="ms-2">
                                                      <i className="text-secondary mdi mdi-basket me-1 align-middle "></i>
                                                      <span className="text-secondary text-nowrap small fw-medium">Buybox</span>
                                                    </span>
                                                  </div>
                                                </div>
                                              </span>
                                              <span className="d-flex align-items-center flex-shrink-0">
                                                <i className="ri-star-fill text-warning fs-16 me-1"></i> Rating: 167
                                              </span>
                                            </div>
                                            <div className="hstack gap-2 flex-wrap mt-1 ms-1">
                                              <div className="d-flex align-items-center">
                                                <DomainToFlag marketplace={sourceMarketplace?.marketplace || ""} />
                                                <Link to={`https://www.amazon.${"com"}/sp?seller=${"A1ISKZNHY8JGSW"}`} target="_blank" className="link-dark text-muted">
                                                  {t("Store")}
                                                  <i className="ri-arrow-right-up-line align-middle"></i>
                                                </Link>
                                              </div>
                                              <div className="d-flex align-items-center">
                                                <DomainToFlag marketplace={sourceMarketplace?.marketplace || ""} />
                                                <Link to={`https://www.amazon.${"com"}/s?me=${"A1ISKZNHY8JGSW"}`} target="_blank" className="link-dark text-muted">
                                                  {t("Products")}
                                                  <i className="ri-arrow-right-up-line align-middle"></i>
                                                </Link>
                                              </div>
                                              <div className="vr mx-1"></div>
                                              <div className="d-flex align-items-center">
                                                <DomainToFlag marketplace={destinationMarketplace?.marketplace || ""} />
                                                <Link to={`https://www.amazon.${"com"}/sp?seller=${"A1ISKZNHY8JGSW"}`} target="_blank" className="link-dark text-muted">
                                                  {t("Store")}
                                                  <i className="ri-arrow-right-up-line align-middle"></i>
                                                </Link>
                                              </div>
                                              <div className="d-flex align-items-center">
                                                <DomainToFlag marketplace={destinationMarketplace?.marketplace || ""} />
                                                <Link to={`https://www.amazon.${"com"}/s?me=${"A1ISKZNHY8JGSW"}`} target="_blank" className="link-dark text-muted">
                                                  {t("Products")}
                                                  <i className="ri-arrow-right-up-line align-middle"></i>
                                                </Link>
                                              </div>
                                            </div>
                                            <div className="hstack gap-3 flex-wrap mt-1 ms-1">
                                              <span>
                                                <i className="text-warning mdi mdi-account-multiple me-1 align-middle"></i>
                                                <span className="text-warning text-nowrap small fw-medium">Common FBA Seller</span>
                                              </span>
                                              <span>
                                                <i className="text-warning mdi mdi-web me-1 align-middle"></i>
                                                <span className="text-warning text-nowrap small fw-medium">Remote FBA</span>
                                              </span>
                                              <span>
                                                <i className="text-danger mdi mdi-trademark me-1 align-middle"></i>
                                                <span className="text-danger text-nowrap small fw-medium">Potential Brand Owner</span>
                                              </span>
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <div className="align-items-center d-flex">
                                              <span className="flex-grow-1 hstack gap-2">
                                                <div className="avatar-xs img-thumbnail rounded-circle flex-shrink-0">
                                                  <img src={sourceMarketplace?.flag} alt="Country Flag" className="rounded-circle" />
                                                </div>
                                                <div>
                                                  <div className="hstack">
                                                    <Link to={`https://www.amazon.${"com"}/sp?seller=${"AAW6CJIKK3PL3"}`} target="_blank" className="link-dark link-offset-2 text-decoration-underline">
                                                      AAW6CJIKK3PL3
                                                      <i className="ri-arrow-right-up-line"></i>
                                                    </Link>
                                                    <div>
                                                      <i id="PopoverSellerStrength-1" className="text-warning align-middle ri-flashlight-fill ms-1 fs-14"></i>
                                                      <DefaultUncontrolledTooltip target="PopoverSellerStrength-1">
                                                        {"Moderate seller: This seller has an average number of feedback and ratings, presenting moderate competition."}
                                                      </DefaultUncontrolledTooltip>
                                                    </div>
                                                    <span className="badge bg-warning-subtle text-warning fs-12 ms-2">FBA</span>
                                                  </div>
                                                </div>
                                              </span>
                                              <span className="d-flex align-items-center flex-shrink-0">
                                                <i className="ri-star-fill text-warning fs-16 me-1"></i> Rating: 133
                                              </span>
                                            </div>
                                            <div className="hstack gap-2 flex-wrap mt-1 ms-1">
                                              <div className="d-flex align-items-center">
                                                <DomainToFlag marketplace={sourceMarketplace?.marketplace || ""} />
                                                <Link to={`https://www.amazon.${"com"}/sp?seller=${"AAW6CJIKK3PL3"}`} target="_blank" className="link-dark text-muted">
                                                  {t("Store")}
                                                  <i className="ri-arrow-right-up-line align-middle"></i>
                                                </Link>
                                              </div>
                                              <div className="d-flex align-items-center">
                                                <DomainToFlag marketplace={sourceMarketplace?.marketplace || ""} />
                                                <Link to={`https://www.amazon.${"com"}/s?me=${"AAW6CJIKK3PL3"}`} target="_blank" className="link-dark text-muted">
                                                  {t("Products")}
                                                  <i className="ri-arrow-right-up-line align-middle"></i>
                                                </Link>
                                              </div>
                                              <div className="vr mx-1"></div>
                                              <div className="d-flex align-items-center">
                                                <DomainToFlag marketplace={destinationMarketplace?.marketplace || ""} />
                                                <Link to={`https://www.amazon.${"com"}/sp?seller=${"AAW6CJIKK3PL3"}`} target="_blank" className="link-dark text-muted">
                                                  {t("Store")}
                                                  <i className="ri-arrow-right-up-line align-middle"></i>
                                                </Link>
                                              </div>
                                              <div className="d-flex align-items-center">
                                                <DomainToFlag marketplace={destinationMarketplace?.marketplace || ""} />
                                                <Link to={`https://www.amazon.${"com"}/s?me=${"AAW6CJIKK3PL3"}`} target="_blank" className="link-dark text-muted">
                                                  {t("Products")}
                                                  <i className="ri-arrow-right-up-line align-middle"></i>
                                                </Link>
                                              </div>
                                            </div>
                                            <div className="hstack gap-3 flex-wrap mt-1 ms-1">
                                              <span>
                                                <i className="text-warning mdi mdi-account-multiple me-1 align-middle"></i>
                                                <span className="text-warning text-nowrap small fw-medium">Common FBA Seller</span>
                                              </span>
                                              <span>
                                                <i className="text-warning mdi mdi-web me-1 align-middle"></i>
                                                <span className="text-warning text-nowrap small fw-medium">Remote FBA</span>
                                              </span>
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <div className="align-items-center d-flex">
                                              <span className="flex-grow-1 hstack gap-2">
                                                <div className="avatar-xs img-thumbnail rounded-circle flex-shrink-0">
                                                  <img src={sourceMarketplace?.flag} alt="Country Flag" className="rounded-circle" />
                                                </div>
                                                <div>
                                                  <div className="hstack">
                                                    <span>Amazon.com</span>
                                                    <div>
                                                      <i id="PopoverSellerStrength-1" className="text-danger align-middle ri-flashlight-fill ms-1 fs-14"></i>
                                                      <DefaultUncontrolledTooltip target="PopoverSellerStrength-1">
                                                        {"Strong seller: This seller has a high number of feedback and ratings, indicating strong competition."}
                                                      </DefaultUncontrolledTooltip>
                                                    </div>
                                                    <span className="badge bg-danger-subtle text-danger fs-12 ms-2">Amazon</span>
                                                  </div>
                                                </div>
                                              </span>
                                              <span className="d-flex align-items-center flex-shrink-0">
                                                <i className="ri-star-fill text-warning fs-16 me-1"></i> Rating: 23
                                              </span>
                                            </div>
                                            <div className="hstack gap-2 flex-wrap mt-1 ms-1">
                                              <div className="d-flex align-items-center">
                                                <DomainToFlag marketplace={sourceMarketplace?.marketplace || ""} />
                                                <Link to={`https://www.amazon.${"com"}/sp?seller=${"AAW6CJIKK3PL3"}`} target="_blank" className="link-dark text-muted">
                                                  {t("Store")}
                                                  <i className="ri-arrow-right-up-line align-middle"></i>
                                                </Link>
                                              </div>
                                              <div className="d-flex align-items-center">
                                                <DomainToFlag marketplace={sourceMarketplace?.marketplace || ""} />
                                                <Link to={`https://www.amazon.${"com"}/s?me=${"AAW6CJIKK3PL3"}`} target="_blank" className="link-dark text-muted">
                                                  {t("Products")}
                                                  <i className="ri-arrow-right-up-line align-middle"></i>
                                                </Link>
                                              </div>
                                              <div className="vr mx-1"></div>
                                              <div className="d-flex align-items-center">
                                                <DomainToFlag marketplace={destinationMarketplace?.marketplace || ""} />
                                                <Link to={`https://www.amazon.${"com"}/sp?seller=${"AAW6CJIKK3PL3"}`} target="_blank" className="link-dark text-muted">
                                                  {t("Store")}
                                                  <i className="ri-arrow-right-up-line align-middle"></i>
                                                </Link>
                                              </div>
                                              <div className="d-flex align-items-center">
                                                <DomainToFlag marketplace={destinationMarketplace?.marketplace || ""} />
                                                <Link to={`https://www.amazon.${"com"}/s?me=${"AAW6CJIKK3PL3"}`} target="_blank" className="link-dark text-muted">
                                                  {t("Products")}
                                                  <i className="ri-arrow-right-up-line align-middle"></i>
                                                </Link>
                                              </div>
                                            </div>
                                            <div className="hstack gap-3 flex-wrap mt-1 ms-1">
                                              <span>
                                                <i className="text-warning mdi mdi-account-multiple me-1 align-middle"></i>
                                                <span className="text-warning text-nowrap small fw-medium">Common FBA Seller</span>
                                              </span>
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <div className="align-items-center d-flex">
                                              <span className="flex-grow-1 hstack gap-2">
                                                <div className="avatar-xs img-thumbnail rounded-circle flex-shrink-0">
                                                  <img src={sourceMarketplace?.flag} alt="Country Flag" className="rounded-circle" />
                                                </div>
                                                <div>
                                                  <div className="hstack">
                                                    <Link to={`https://www.amazon.${"com"}/sp?seller=${"AAW6CJIKK3PL3"}`} target="_blank" className="link-dark link-offset-2 text-decoration-underline">
                                                      AAW6CJIKK3PL3
                                                      <i className="ri-arrow-right-up-line"></i>
                                                    </Link>
                                                    <div>
                                                      <i id="PopoverSellerStrength-1" className="text-warning align-middle ri-flashlight-fill ms-1 fs-14"></i>
                                                      <DefaultUncontrolledTooltip target="PopoverSellerStrength-1">
                                                        {"Moderate seller: This seller has an average number of feedback and ratings, presenting moderate competition."}
                                                      </DefaultUncontrolledTooltip>
                                                    </div>
                                                    <span className="badge bg-primary-subtle text-primary fs-12 ms-2">FBM</span>
                                                  </div>
                                                </div>
                                              </span>
                                              <span className="d-flex align-items-center flex-shrink-0">
                                                <i className="ri-star-fill text-warning fs-16 me-1"></i> Rating: 123
                                              </span>
                                            </div>
                                            <div className="hstack gap-2 flex-wrap mt-1 ms-1">
                                              <div className="d-flex align-items-center">
                                                <DomainToFlag marketplace={sourceMarketplace?.marketplace || ""} />
                                                <Link to={`https://www.amazon.${"com"}/sp?seller=${"AAW6CJIKK3PL3"}`} target="_blank" className="link-dark text-muted">
                                                  {t("Store")}
                                                  <i className="ri-arrow-right-up-line align-middle"></i>
                                                </Link>
                                              </div>
                                              <div className="d-flex align-items-center">
                                                <DomainToFlag marketplace={sourceMarketplace?.marketplace || ""} />
                                                <Link to={`https://www.amazon.${"com"}/s?me=${"AAW6CJIKK3PL3"}`} target="_blank" className="link-dark text-muted">
                                                  {t("Products")}
                                                  <i className="ri-arrow-right-up-line align-middle"></i>
                                                </Link>
                                              </div>
                                              <div className="vr mx-1"></div>
                                              <div className="d-flex align-items-center">
                                                <DomainToFlag marketplace={destinationMarketplace?.marketplace || ""} />
                                                <Link to={`https://www.amazon.${"com"}/sp?seller=${"AAW6CJIKK3PL3"}`} target="_blank" className="link-dark text-muted">
                                                  {t("Store")}
                                                  <i className="ri-arrow-right-up-line align-middle"></i>
                                                </Link>
                                              </div>
                                              <div className="d-flex align-items-center">
                                                <DomainToFlag marketplace={destinationMarketplace?.marketplace || ""} />
                                                <Link to={`https://www.amazon.${"com"}/s?me=${"AAW6CJIKK3PL3"}`} target="_blank" className="link-dark text-muted">
                                                  {t("Products")}
                                                  <i className="ri-arrow-right-up-line align-middle"></i>
                                                </Link>
                                              </div>
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <div className="align-items-center d-flex">
                                              <span className="flex-grow-1 hstack gap-2">
                                                <div className="avatar-xs img-thumbnail rounded-circle flex-shrink-0">
                                                  <img src={sourceMarketplace?.flag} alt="Country Flag" className="rounded-circle" />
                                                </div>
                                                <div>
                                                  <div className="hstack">
                                                    <Link to={`https://www.amazon.${"com"}/sp?seller=${"AAW6CJIKK3PL3"}`} target="_blank" className="link-dark link-offset-2 text-decoration-underline">
                                                      AAW6CJIKK3PL3
                                                      <i className="ri-arrow-right-up-line"></i>
                                                    </Link>
                                                    <div>
                                                      <i id="PopoverSellerStrength-1" className="text-warning align-middle ri-flashlight-fill ms-1 fs-14"></i>
                                                      <DefaultUncontrolledTooltip target="PopoverSellerStrength-1">
                                                        {"Moderate seller: This seller has an average number of feedback and ratings, presenting moderate competition."}
                                                      </DefaultUncontrolledTooltip>
                                                    </div>
                                                    <span className="badge bg-primary-subtle text-primary fs-12 ms-2">FBM</span>
                                                  </div>
                                                </div>
                                              </span>
                                              <span className="d-flex align-items-center flex-shrink-0">
                                                <i className="ri-star-fill text-warning fs-16 me-1"></i> Rating: 32
                                              </span>
                                            </div>
                                            <div className="hstack gap-2 flex-wrap mt-1 ms-1">
                                              <div className="d-flex align-items-center">
                                                <DomainToFlag marketplace={sourceMarketplace?.marketplace || ""} />
                                                <Link to={`https://www.amazon.${"com"}/sp?seller=${"AAW6CJIKK3PL3"}`} target="_blank" className="link-dark text-muted">
                                                  {t("Store")}
                                                  <i className="ri-arrow-right-up-line align-middle"></i>
                                                </Link>
                                              </div>
                                              <div className="d-flex align-items-center">
                                                <DomainToFlag marketplace={sourceMarketplace?.marketplace || ""} />
                                                <Link to={`https://www.amazon.${"com"}/s?me=${"AAW6CJIKK3PL3"}`} target="_blank" className="link-dark text-muted">
                                                  {t("Products")}
                                                  <i className="ri-arrow-right-up-line align-middle"></i>
                                                </Link>
                                              </div>
                                              <div className="vr mx-1"></div>
                                              <div className="d-flex align-items-center">
                                                <DomainToFlag marketplace={destinationMarketplace?.marketplace || ""} />
                                                <Link to={`https://www.amazon.${"com"}/sp?seller=${"AAW6CJIKK3PL3"}`} target="_blank" className="link-dark text-muted">
                                                  {t("Store")}
                                                  <i className="ri-arrow-right-up-line align-middle"></i>
                                                </Link>
                                              </div>
                                              <div className="d-flex align-items-center">
                                                <DomainToFlag marketplace={destinationMarketplace?.marketplace || ""} />
                                                <Link to={`https://www.amazon.${"com"}/s?me=${"AAW6CJIKK3PL3"}`} target="_blank" className="link-dark text-muted">
                                                  {t("Products")}
                                                  <i className="ri-arrow-right-up-line align-middle"></i>
                                                </Link>
                                              </div>
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <div className="align-items-center d-flex">
                                              <span className="flex-grow-1 hstack gap-2">
                                                <div className="avatar-xs img-thumbnail rounded-circle flex-shrink-0">
                                                  <img src={sourceMarketplace?.flag} alt="Country Flag" className="rounded-circle" />
                                                </div>
                                                <div>
                                                  <div className="hstack">
                                                    <Link to={`https://www.amazon.${"com"}/sp?seller=${"AAW6CJIKK3PL3"}`} target="_blank" className="link-dark link-offset-2 text-decoration-underline">
                                                      AAW6CJIKK3PL3
                                                      <i className="ri-arrow-right-up-line"></i>
                                                    </Link>
                                                    <div>
                                                      <i id="PopoverSellerStrength-1" className="text-warning align-middle ri-flashlight-fill ms-1 fs-14"></i>
                                                      <DefaultUncontrolledTooltip target="PopoverSellerStrength-1">
                                                        {"Moderate seller: This seller has an average number of feedback and ratings, presenting moderate competition."}
                                                      </DefaultUncontrolledTooltip>
                                                    </div>
                                                    <span className="badge bg-primary-subtle text-primary fs-12 ms-2">FBM</span>
                                                  </div>
                                                </div>
                                              </span>
                                              <span className="d-flex align-items-center flex-shrink-0">
                                                <i className="ri-star-fill text-warning fs-16 me-1"></i> Rating: 12
                                              </span>
                                            </div>
                                            <div className="hstack gap-2 flex-wrap mt-1 ms-1">
                                              <div className="d-flex align-items-center">
                                                <DomainToFlag marketplace={sourceMarketplace?.marketplace || ""} />
                                                <Link to={`https://www.amazon.${"com"}/sp?seller=${"AAW6CJIKK3PL3"}`} target="_blank" className="link-dark text-muted">
                                                  {t("Store")}
                                                  <i className="ri-arrow-right-up-line align-middle"></i>
                                                </Link>
                                              </div>
                                              <div className="d-flex align-items-center">
                                                <DomainToFlag marketplace={sourceMarketplace?.marketplace || ""} />
                                                <Link to={`https://www.amazon.${"com"}/s?me=${"AAW6CJIKK3PL3"}`} target="_blank" className="link-dark text-muted">
                                                  {t("Products")}
                                                  <i className="ri-arrow-right-up-line align-middle"></i>
                                                </Link>
                                              </div>
                                              <div className="vr mx-1"></div>
                                              <div className="d-flex align-items-center">
                                                <DomainToFlag marketplace={destinationMarketplace?.marketplace || ""} />
                                                <Link to={`https://www.amazon.${"com"}/sp?seller=${"AAW6CJIKK3PL3"}`} target="_blank" className="link-dark text-muted">
                                                  {t("Store")}
                                                  <i className="ri-arrow-right-up-line align-middle"></i>
                                                </Link>
                                              </div>
                                              <div className="d-flex align-items-center">
                                                <DomainToFlag marketplace={destinationMarketplace?.marketplace || ""} />
                                                <Link to={`https://www.amazon.${"com"}/s?me=${"AAW6CJIKK3PL3"}`} target="_blank" className="link-dark text-muted">
                                                  {t("Products")}
                                                  <i className="ri-arrow-right-up-line align-middle"></i>
                                                </Link>
                                              </div>
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <div className="align-items-center d-flex">
                                              <span className="flex-grow-1 hstack gap-2">
                                                <div className="avatar-xs img-thumbnail rounded-circle flex-shrink-0">
                                                  <img src={sourceMarketplace?.flag} alt="Country Flag" className="rounded-circle" />
                                                </div>
                                                <div>
                                                  <div className="hstack">
                                                    <Link to={`https://www.amazon.${"com"}/sp?seller=${"AAW6CJIKK3PL3"}`} target="_blank" className="link-dark link-offset-2 text-decoration-underline">
                                                      AAW6CJIKK3PL3
                                                      <i className="ri-arrow-right-up-line"></i>
                                                    </Link>
                                                    <div>
                                                      <i id="PopoverSellerStrength-1" className="text-warning align-middle ri-flashlight-fill ms-1 fs-14"></i>
                                                      <DefaultUncontrolledTooltip target="PopoverSellerStrength-1">
                                                        {"Moderate seller: This seller has an average number of feedback and ratings, presenting moderate competition."}
                                                      </DefaultUncontrolledTooltip>
                                                    </div>
                                                    <span className="badge bg-primary-subtle text-primary fs-12 ms-2">FBM</span>
                                                  </div>
                                                </div>
                                              </span>
                                              <span className="d-flex align-items-center flex-shrink-0">
                                                <i className="ri-star-fill text-warning fs-16 me-1"></i> Rating: 12
                                              </span>
                                            </div>
                                            <div className="hstack gap-2 flex-wrap mt-1 ms-1">
                                              <div className="d-flex align-items-center">
                                                <DomainToFlag marketplace={sourceMarketplace?.marketplace || ""} />
                                                <Link to={`https://www.amazon.${"com"}/sp?seller=${"AAW6CJIKK3PL3"}`} target="_blank" className="link-dark text-muted">
                                                  {t("Store")}
                                                  <i className="ri-arrow-right-up-line align-middle"></i>
                                                </Link>
                                              </div>
                                              <div className="d-flex align-items-center">
                                                <DomainToFlag marketplace={sourceMarketplace?.marketplace || ""} />
                                                <Link to={`https://www.amazon.${"com"}/s?me=${"AAW6CJIKK3PL3"}`} target="_blank" className="link-dark text-muted">
                                                  {t("Products")}
                                                  <i className="ri-arrow-right-up-line align-middle"></i>
                                                </Link>
                                              </div>
                                              <div className="vr mx-1"></div>
                                              <div className="d-flex align-items-center">
                                                <DomainToFlag marketplace={destinationMarketplace?.marketplace || ""} />
                                                <Link to={`https://www.amazon.${"com"}/sp?seller=${"AAW6CJIKK3PL3"}`} target="_blank" className="link-dark text-muted">
                                                  {t("Store")}
                                                  <i className="ri-arrow-right-up-line align-middle"></i>
                                                </Link>
                                              </div>
                                              <div className="d-flex align-items-center">
                                                <DomainToFlag marketplace={destinationMarketplace?.marketplace || ""} />
                                                <Link to={`https://www.amazon.${"com"}/s?me=${"AAW6CJIKK3PL3"}`} target="_blank" className="link-dark text-muted">
                                                  {t("Products")}
                                                  <i className="ri-arrow-right-up-line align-middle"></i>
                                                </Link>
                                              </div>
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <div className="align-items-center d-flex">
                                              <span className="flex-grow-1 hstack gap-2">
                                                <div className="avatar-xs img-thumbnail rounded-circle flex-shrink-0">
                                                  <img src={sourceMarketplace?.flag} alt="Country Flag" className="rounded-circle" />{" "}
                                                </div>
                                                <div>
                                                  <div className="hstack">
                                                    <Link to={`https://www.amazon.${"com"}/sp?seller=${"AAW6CJIKK3PL3"}`} target="_blank" className="link-dark link-offset-2 text-decoration-underline">
                                                      AAW6CJIKK3PL3
                                                      <i className="ri-arrow-right-up-line"></i>
                                                    </Link>
                                                    <div>
                                                      <i id="PopoverSellerStrength-1" className="text-warning align-middle ri-flashlight-fill ms-1 fs-14"></i>
                                                      <DefaultUncontrolledTooltip target="PopoverSellerStrength-1">
                                                        {"Moderate seller: This seller has an average number of feedback and ratings, presenting moderate competition."}
                                                      </DefaultUncontrolledTooltip>
                                                    </div>
                                                    <span className="badge bg-primary-subtle text-primary fs-12 ms-2">FBM</span>
                                                  </div>
                                                </div>
                                              </span>
                                              <span className="d-flex align-items-center flex-shrink-0">
                                                <i className="ri-star-fill text-warning fs-16 me-1"></i> Rating: 12
                                              </span>
                                            </div>
                                            <div className="hstack gap-2 flex-wrap mt-1 ms-1">
                                              <div className="d-flex align-items-center">
                                                <DomainToFlag marketplace={sourceMarketplace?.marketplace || ""} />
                                                <Link to={`https://www.amazon.${"com"}/sp?seller=${"AAW6CJIKK3PL3"}`} target="_blank" className="link-dark text-muted">
                                                  {t("Store")}
                                                  <i className="ri-arrow-right-up-line align-middle"></i>
                                                </Link>
                                              </div>
                                              <div className="d-flex align-items-center">
                                                <DomainToFlag marketplace={sourceMarketplace?.marketplace || ""} />
                                                <Link to={`https://www.amazon.${"com"}/s?me=${"AAW6CJIKK3PL3"}`} target="_blank" className="link-dark text-muted">
                                                  {t("Products")}
                                                  <i className="ri-arrow-right-up-line align-middle"></i>
                                                </Link>
                                              </div>
                                              <div className="vr mx-1"></div>
                                              <div className="d-flex align-items-center">
                                                <DomainToFlag marketplace={destinationMarketplace?.marketplace || ""} />
                                                <Link to={`https://www.amazon.${"com"}/sp?seller=${"AAW6CJIKK3PL3"}`} target="_blank" className="link-dark text-muted">
                                                  {t("Store")}
                                                  <i className="ri-arrow-right-up-line align-middle"></i>
                                                </Link>
                                              </div>
                                              <div className="d-flex align-items-center">
                                                <DomainToFlag marketplace={destinationMarketplace?.marketplace || ""} />
                                                <Link to={`https://www.amazon.${"com"}/s?me=${"AAW6CJIKK3PL3"}`} target="_blank" className="link-dark text-muted">
                                                  {t("Products")}
                                                  <i className="ri-arrow-right-up-line align-middle"></i>
                                                </Link>
                                              </div>
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <div className="align-items-center d-flex">
                                              <span className="flex-grow-1 hstack gap-2">
                                                <div className="avatar-xs img-thumbnail rounded-circle flex-shrink-0">
                                                  <img src={sourceMarketplace?.flag} alt="Country Flag" className="rounded-circle" />
                                                </div>
                                                <div>
                                                  <div className="hstack">
                                                    <Link to={`https://www.amazon.${"com"}/sp?seller=${"AAW6CJIKK3PL3"}`} target="_blank" className="link-dark link-offset-2 text-decoration-underline">
                                                      AAW6CJIKK3PL3
                                                      <i className="ri-arrow-right-up-line"></i>
                                                    </Link>
                                                    <div>
                                                      <i id="PopoverSellerStrength-1" className="text-warning align-middle ri-flashlight-fill ms-1 fs-14"></i>
                                                      <DefaultUncontrolledTooltip target="PopoverSellerStrength-1">
                                                        {"Moderate seller: This seller has an average number of feedback and ratings, presenting moderate competition."}
                                                      </DefaultUncontrolledTooltip>
                                                    </div>
                                                    <span className="badge bg-primary-subtle text-primary fs-12 ms-2">FBM</span>
                                                  </div>
                                                </div>
                                              </span>
                                              <span className="d-flex align-items-center flex-shrink-0">
                                                <i className="ri-star-fill text-warning fs-16 me-1"></i> Rating: 12
                                              </span>
                                            </div>
                                            <div className="hstack gap-2 flex-wrap mt-1 ms-1">
                                              <div className="d-flex align-items-center">
                                                <DomainToFlag marketplace={sourceMarketplace?.marketplace || ""} />
                                                <Link to={`https://www.amazon.${"com"}/sp?seller=${"AAW6CJIKK3PL3"}`} target="_blank" className="link-dark text-muted">
                                                  {t("Store")}
                                                  <i className="ri-arrow-right-up-line align-middle"></i>
                                                </Link>
                                              </div>
                                              <div className="d-flex align-items-center">
                                                <DomainToFlag marketplace={sourceMarketplace?.marketplace || ""} />
                                                <Link to={`https://www.amazon.${"com"}/s?me=${"AAW6CJIKK3PL3"}`} target="_blank" className="link-dark text-muted">
                                                  {t("Products")}
                                                  <i className="ri-arrow-right-up-line align-middle"></i>
                                                </Link>
                                              </div>
                                              <div className="vr mx-1"></div>
                                              <div className="d-flex align-items-center">
                                                <DomainToFlag marketplace={destinationMarketplace?.marketplace || ""} />
                                                <Link to={`https://www.amazon.${"com"}/sp?seller=${"AAW6CJIKK3PL3"}`} target="_blank" className="link-dark text-muted">
                                                  {t("Store")}
                                                  <i className="ri-arrow-right-up-line align-middle"></i>
                                                </Link>
                                              </div>
                                              <div className="d-flex align-items-center">
                                                <DomainToFlag marketplace={destinationMarketplace?.marketplace || ""} />
                                                <Link to={`https://www.amazon.${"com"}/s?me=${"AAW6CJIKK3PL3"}`} target="_blank" className="link-dark text-muted">
                                                  {t("Products")}
                                                  <i className="ri-arrow-right-up-line align-middle"></i>
                                                </Link>
                                              </div>
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <div className="align-items-center d-flex">
                                              <span className="flex-grow-1 hstack gap-2">
                                                <div className="avatar-xs img-thumbnail rounded-circle flex-shrink-0">
                                                  <img src={sourceMarketplace?.flag} alt="Country Flag" className="rounded-circle" />
                                                </div>
                                                <div>
                                                  <div className="hstack">
                                                    <Link to={`https://www.amazon.${"com"}/sp?seller=${"AAW6CJIKK3PL3"}`} target="_blank" className="link-dark link-offset-2 text-decoration-underline">
                                                      AAW6CJIKK3PL3
                                                      <i className="ri-arrow-right-up-line"></i>
                                                    </Link>
                                                    <div>
                                                      <i id="PopoverSellerStrength-1" className="text-warning align-middle ri-flashlight-fill ms-1 fs-14"></i>
                                                      <DefaultUncontrolledTooltip target="PopoverSellerStrength-1">
                                                        {"Moderate seller: This seller has an average number of feedback and ratings, presenting moderate competition."}
                                                      </DefaultUncontrolledTooltip>
                                                    </div>
                                                    <span className="badge bg-primary-subtle text-primary fs-12 ms-2">FBM</span>
                                                  </div>
                                                </div>
                                              </span>
                                              <span className="d-flex align-items-center flex-shrink-0">
                                                <i className="ri-star-fill text-warning fs-16 me-1"></i> Rating: 12
                                              </span>
                                            </div>
                                            <div className="hstack gap-2 flex-wrap mt-1 ms-1">
                                              <div className="d-flex align-items-center">
                                                <DomainToFlag marketplace={sourceMarketplace?.marketplace || ""} />
                                                <Link to={`https://www.amazon.${"com"}/sp?seller=${"AAW6CJIKK3PL3"}`} target="_blank" className="link-dark text-muted">
                                                  {t("Store")}
                                                  <i className="ri-arrow-right-up-line align-middle"></i>
                                                </Link>
                                              </div>
                                              <div className="d-flex align-items-center">
                                                <DomainToFlag marketplace={sourceMarketplace?.marketplace || ""} />
                                                <Link to={`https://www.amazon.${"com"}/s?me=${"AAW6CJIKK3PL3"}`} target="_blank" className="link-dark text-muted">
                                                  {t("Products")}
                                                  <i className="ri-arrow-right-up-line align-middle"></i>
                                                </Link>
                                              </div>
                                              <div className="vr mx-1"></div>
                                              <div className="d-flex align-items-center">
                                                <DomainToFlag marketplace={destinationMarketplace?.marketplace || ""} />
                                                <Link to={`https://www.amazon.${"com"}/sp?seller=${"AAW6CJIKK3PL3"}`} target="_blank" className="link-dark text-muted">
                                                  {t("Store")}
                                                  <i className="ri-arrow-right-up-line align-middle"></i>
                                                </Link>
                                              </div>
                                              <div className="d-flex align-items-center">
                                                <DomainToFlag marketplace={destinationMarketplace?.marketplace || ""} />
                                                <Link to={`https://www.amazon.${"com"}/s?me=${"AAW6CJIKK3PL3"}`} target="_blank" className="link-dark text-muted">
                                                  {t("Products")}
                                                  <i className="ri-arrow-right-up-line align-middle"></i>
                                                </Link>
                                              </div>
                                            </div>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </SimpleBar>
                                </div>
                              </CardBody>
                            </Card>
                          </Col>
                          <Col xxl={4} xl={6} sm={12} className="same-height">
                            <Card>
                              <CardHeader className="bg-light py-2">
                                <div className="d-flex align-items-center">
                                  <span className="flex-grow-1 hstack gap-2">
                                    <div className="avatar-xs img-thumbnail rounded-circle flex-shrink-0">
                                      <img src={destinationMarketplace?.flag} alt="Country Flag" className="rounded-circle" />
                                    </div>
                                    <h5 className="fs-15 mb-0">Seller List</h5>
                                  </span>
                                  <div className="d-flex gap-1">
                                    <Button color="secondary" size="sm" className="btn-ghost-secondary">
                                      FBA <span className="badge bg-secondary-subtle text-secondary align-middle rounded-pill">1</span>
                                    </Button>
                                    <Button color="secondary" size="sm" className="btn-ghost-secondary">
                                      FBM <span className="badge bg-secondary-subtle text-secondary align-middle rounded-pill">13</span>
                                    </Button>
                                    <Button color="secondary" size="sm" className="btn-ghost-secondary">
                                      Amazon <span className="badge bg-secondary-subtle text-secondary align-middle rounded-pill">1</span>
                                    </Button>
                                    <Button color="secondary" size="sm" className="btn-soft-secondary">
                                      All <span className="badge bg-secondary-subtle text-secondary align-middle rounded-pill">15</span>
                                    </Button>
                                  </div>
                                </div>
                              </CardHeader>
                              <CardBody>
                                <div className="table-responsive table-card">
                                  <SimpleBar style={{height: "600px"}}>
                                    <table className="align-middle table-hover table-nowrap  mb-0 h-100 table">
                                      <tbody>
                                        <tr>
                                          <td>
                                            <div className="align-items-center d-flex">
                                              <span className="flex-grow-1 hstack gap-2">
                                                <div className="avatar-xs img-thumbnail rounded-circle flex-shrink-0">
                                                  <img src={destinationMarketplace?.flag /*sellerMarketplace?.flag*/} alt="Country Flag" className="rounded-circle" />
                                                </div>
                                                <div>
                                                  <div className="hstack">
                                                    <Link
                                                      to={`https://www.amazon.${"com"}/sp?seller=${"A1ISKZNHY8JGSW"}`}
                                                      target="_blank"
                                                      className="link-dark link-offset-2 text-decoration-underline"
                                                    >
                                                      A1ISKZNHY8JGSW
                                                      <i className="ri-arrow-right-up-line"></i>
                                                    </Link>
                                                    <div>
                                                      <i id="PopoverSellerStrength-1" className="text-warning align-middle ri-flashlight-fill ms-1 fs-14"></i>
                                                      <DefaultUncontrolledTooltip target="PopoverSellerStrength-1">
                                                        {"Moderate seller: This seller has an average number of feedback and ratings, presenting moderate competition."}
                                                      </DefaultUncontrolledTooltip>
                                                    </div>
                                                    <span className="badge bg-warning-subtle text-warning fs-12 ms-2">FBA</span>
                                                    <span className="ms-2">
                                                      <i className="text-secondary mdi mdi-basket me-1 align-middle "></i>
                                                      <span className="text-secondary text-nowrap small fw-medium">Buybox</span>
                                                    </span>
                                                  </div>
                                                </div>
                                              </span>
                                              <span className="d-flex align-items-center flex-shrink-0">
                                                <i className="ri-star-fill text-warning fs-16 me-1"></i> Rating: 167
                                              </span>
                                            </div>
                                            <div className="hstack gap-2 flex-wrap mt-1 ms-1">
                                              <div className="d-flex align-items-center">
                                                <Link to={`https://www.amazon.${"com"}/sp?seller=${"A1ISKZNHY8JGSW"}`} target="_blank" className="link-dark text-muted">
                                                  {t("Store")}
                                                  <i className="ri-arrow-right-up-line align-middle"></i>
                                                </Link>
                                              </div>
                                              <div className="d-flex align-items-center">
                                                <Link to={`https://www.amazon.${"com"}/s?me=${"A1ISKZNHY8JGSW"}`} target="_blank" className="link-dark text-muted">
                                                  {t("Products")}
                                                  <i className="ri-arrow-right-up-line align-middle"></i>
                                                </Link>
                                              </div>
                                            </div>
                                            <div className="hstack gap-3 flex-wrap mt-1 ms-1">
                                              <span>
                                                <i className="text-warning mdi mdi-account-multiple me-1 align-middle"></i>
                                                <span className="text-warning text-nowrap small fw-medium">Common FBA Seller</span>
                                              </span>
                                              <span>
                                                <i className="text-warning mdi mdi-web me-1 align-middle"></i>
                                                <span className="text-warning text-nowrap small fw-medium">Remote FBA</span>
                                              </span>
                                              <span>
                                                <i className="text-danger mdi mdi-trademark me-1 align-middle"></i>
                                                <span className="text-danger text-nowrap small fw-medium">Potential Brand Owner</span>
                                              </span>
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <div className="align-items-center d-flex">
                                              <span className="flex-grow-1 hstack gap-2">
                                                <div className="avatar-xs img-thumbnail rounded-circle flex-shrink-0">
                                                  <img src={destinationMarketplace?.flag /*sellerMarketplace?.flag*/} alt="Country Flag" className="rounded-circle" />
                                                </div>
                                                <div>
                                                  <div className="hstack">
                                                    <Link to={`https://www.amazon.${"com"}/sp?seller=${"AAW6CJIKK3PL3"}`} target="_blank" className="link-dark link-offset-2 text-decoration-underline">
                                                      AAW6CJIKK3PL3
                                                      <i className="ri-arrow-right-up-line"></i>
                                                    </Link>
                                                    <div>
                                                      <i id="PopoverSellerStrength-1" className="text-warning align-middle ri-flashlight-fill ms-1 fs-14"></i>
                                                      <DefaultUncontrolledTooltip target="PopoverSellerStrength-1">
                                                        {"Moderate seller: This seller has an average number of feedback and ratings, presenting moderate competition."}
                                                      </DefaultUncontrolledTooltip>
                                                    </div>
                                                    <span className="badge bg-warning-subtle text-warning fs-12 ms-2">FBA</span>
                                                  </div>
                                                </div>
                                              </span>
                                              <span className="d-flex align-items-center flex-shrink-0">
                                                <i className="ri-star-fill text-warning fs-16 me-1"></i> Rating: 133
                                              </span>
                                            </div>
                                            <div className="hstack gap-2 flex-wrap mt-1 ms-1">
                                              <div className="d-flex align-items-center">
                                                <Link to={`https://www.amazon.${"com"}/sp?seller=${"A1ISKZNHY8JGSW"}`} target="_blank" className="link-dark text-muted">
                                                  {t("Store")}
                                                  <i className="ri-arrow-right-up-line align-middle"></i>
                                                </Link>
                                              </div>
                                              <div className="d-flex align-items-center">
                                                <Link to={`https://www.amazon.${"com"}/s?me=${"A1ISKZNHY8JGSW"}`} target="_blank" className="link-dark text-muted">
                                                  {t("Products")}
                                                  <i className="ri-arrow-right-up-line align-middle"></i>
                                                </Link>
                                              </div>
                                            </div>
                                            <div className="hstack gap-3 flex-wrap mt-1 ms-1">
                                              <span>
                                                <i className="text-warning mdi mdi-account-multiple me-1 align-middle"></i>
                                                <span className="text-warning text-nowrap small fw-medium">Common FBA Seller</span>
                                              </span>
                                              <span>
                                                <i className="text-warning mdi mdi-web me-1 align-middle"></i>
                                                <span className="text-warning text-nowrap small fw-medium">Remote FBA</span>
                                              </span>
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <div className="align-items-center d-flex">
                                              <span className="flex-grow-1 hstack gap-2">
                                                <div className="avatar-xs img-thumbnail rounded-circle flex-shrink-0">
                                                  <img src={destinationMarketplace?.flag /*sellerMarketplace?.flag*/} alt="Country Flag" className="rounded-circle" />
                                                </div>
                                                <div>
                                                  <div className="hstack">
                                                    <span>Amazon.com</span>
                                                    <div>
                                                      <i id="PopoverSellerStrength-1" className="text-danger align-middle ri-flashlight-fill ms-1 fs-14"></i>
                                                      <DefaultUncontrolledTooltip target="PopoverSellerStrength-1">
                                                        {"Strong seller: This seller has a high number of feedback and ratings, indicating strong competition."}
                                                      </DefaultUncontrolledTooltip>
                                                    </div>
                                                    <span className="badge bg-danger-subtle text-danger fs-12 ms-2">Amazon</span>
                                                  </div>
                                                </div>
                                              </span>
                                              <span className="d-flex align-items-center flex-shrink-0">
                                                <i className="ri-star-fill text-warning fs-16 me-1"></i> Rating: 23
                                              </span>
                                            </div>
                                            <div className="hstack gap-2 flex-wrap mt-1 ms-1">
                                              <div className="d-flex align-items-center">
                                                <Link to={`https://www.amazon.${"com"}/sp?seller=${"A1ISKZNHY8JGSW"}`} target="_blank" className="link-dark text-muted">
                                                  {t("Store")}
                                                  <i className="ri-arrow-right-up-line align-middle"></i>
                                                </Link>
                                              </div>
                                              <div className="d-flex align-items-center">
                                                <Link to={`https://www.amazon.${"com"}/s?me=${"A1ISKZNHY8JGSW"}`} target="_blank" className="link-dark text-muted">
                                                  {t("Products")}
                                                  <i className="ri-arrow-right-up-line align-middle"></i>
                                                </Link>
                                              </div>
                                            </div>
                                            <div className="hstack gap-3 flex-wrap mt-1 ms-1">
                                              <span>
                                                <i className="text-warning mdi mdi-account-multiple me-1 align-middle"></i>
                                                <span className="text-warning text-nowrap small fw-medium">Common FBA Seller</span>
                                              </span>
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <div className="align-items-center d-flex">
                                              <span className="flex-grow-1 hstack gap-2">
                                                <div className="avatar-xs img-thumbnail rounded-circle flex-shrink-0">
                                                  <img src={destinationMarketplace?.flag /*sellerMarketplace?.flag*/} alt="Country Flag" className="rounded-circle" />
                                                </div>
                                                <div>
                                                  <div className="hstack">
                                                    <Link to={`https://www.amazon.${"com"}/sp?seller=${"AAW6CJIKK3PL3"}`} target="_blank" className="link-dark link-offset-2 text-decoration-underline">
                                                      AAW6CJIKK3PL3
                                                      <i className="ri-arrow-right-up-line"></i>
                                                    </Link>
                                                    <div>
                                                      <i id="PopoverSellerStrength-1" className="text-warning align-middle ri-flashlight-fill ms-1 fs-14"></i>
                                                      <DefaultUncontrolledTooltip target="PopoverSellerStrength-1">
                                                        {"Moderate seller: This seller has an average number of feedback and ratings, presenting moderate competition."}
                                                      </DefaultUncontrolledTooltip>
                                                    </div>
                                                    <span className="badge bg-primary-subtle text-primary fs-12 ms-2">FBM</span>
                                                  </div>
                                                </div>
                                              </span>
                                              <span className="d-flex align-items-center flex-shrink-0">
                                                <i className="ri-star-fill text-warning fs-16 me-1"></i> Rating: 123
                                              </span>
                                            </div>
                                            <div className="hstack gap-2 flex-wrap mt-1 ms-1">
                                              <div className="d-flex align-items-center">
                                                <Link to={`https://www.amazon.${"com"}/sp?seller=${"A1ISKZNHY8JGSW"}`} target="_blank" className="link-dark text-muted">
                                                  {t("Store")}
                                                  <i className="ri-arrow-right-up-line align-middle"></i>
                                                </Link>
                                              </div>
                                              <div className="d-flex align-items-center">
                                                <Link to={`https://www.amazon.${"com"}/s?me=${"A1ISKZNHY8JGSW"}`} target="_blank" className="link-dark text-muted">
                                                  {t("Products")}
                                                  <i className="ri-arrow-right-up-line align-middle"></i>
                                                </Link>
                                              </div>
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <div className="align-items-center d-flex">
                                              <span className="flex-grow-1 hstack gap-2">
                                                <div className="avatar-xs img-thumbnail rounded-circle flex-shrink-0">
                                                  <img src={destinationMarketplace?.flag /*sellerMarketplace?.flag*/} alt="Country Flag" className="rounded-circle" />
                                                </div>
                                                <div>
                                                  <div className="hstack">
                                                    <Link to={`https://www.amazon.${"com"}/sp?seller=${"AAW6CJIKK3PL3"}`} target="_blank" className="link-dark link-offset-2 text-decoration-underline">
                                                      AAW6CJIKK3PL3
                                                      <i className="ri-arrow-right-up-line"></i>
                                                    </Link>
                                                    <div>
                                                      <i id="PopoverSellerStrength-1" className="text-warning align-middle ri-flashlight-fill ms-1 fs-14"></i>
                                                      <DefaultUncontrolledTooltip target="PopoverSellerStrength-1">
                                                        {"Moderate seller: This seller has an average number of feedback and ratings, presenting moderate competition."}
                                                      </DefaultUncontrolledTooltip>
                                                    </div>
                                                    <span className="badge bg-primary-subtle text-primary fs-12 ms-2">FBM</span>
                                                  </div>
                                                </div>
                                              </span>
                                              <span className="d-flex align-items-center flex-shrink-0">
                                                <i className="ri-star-fill text-warning fs-16 me-1"></i> Rating: 32
                                              </span>
                                            </div>
                                            <div className="hstack gap-2 flex-wrap mt-1 ms-1">
                                              <div className="d-flex align-items-center">
                                                <Link to={`https://www.amazon.${"com"}/sp?seller=${"A1ISKZNHY8JGSW"}`} target="_blank" className="link-dark text-muted">
                                                  {t("Store")}
                                                  <i className="ri-arrow-right-up-line align-middle"></i>
                                                </Link>
                                              </div>
                                              <div className="d-flex align-items-center">
                                                <Link to={`https://www.amazon.${"com"}/s?me=${"A1ISKZNHY8JGSW"}`} target="_blank" className="link-dark text-muted">
                                                  {t("Products")}
                                                  <i className="ri-arrow-right-up-line align-middle"></i>
                                                </Link>
                                              </div>
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <div className="align-items-center d-flex">
                                              <span className="flex-grow-1 hstack gap-2">
                                                <div className="avatar-xs img-thumbnail rounded-circle flex-shrink-0">
                                                  <img src={destinationMarketplace?.flag /*sellerMarketplace?.flag*/} alt="Country Flag" className="rounded-circle" />
                                                </div>
                                                <div>
                                                  <div className="hstack">
                                                    <Link to={`https://www.amazon.${"com"}/sp?seller=${"AAW6CJIKK3PL3"}`} target="_blank" className="link-dark link-offset-2 text-decoration-underline">
                                                      AAW6CJIKK3PL3
                                                      <i className="ri-arrow-right-up-line"></i>
                                                    </Link>
                                                    <div>
                                                      <i id="PopoverSellerStrength-1" className="text-warning align-middle ri-flashlight-fill ms-1 fs-14"></i>
                                                      <DefaultUncontrolledTooltip target="PopoverSellerStrength-1">
                                                        {"Moderate seller: This seller has an average number of feedback and ratings, presenting moderate competition."}
                                                      </DefaultUncontrolledTooltip>
                                                    </div>
                                                    <span className="badge bg-primary-subtle text-primary fs-12 ms-2">FBM</span>
                                                  </div>
                                                </div>
                                              </span>
                                              <span className="d-flex align-items-center flex-shrink-0">
                                                <i className="ri-star-fill text-warning fs-16 me-1"></i> Rating: 12
                                              </span>
                                            </div>
                                            <div className="hstack gap-2 flex-wrap mt-1 ms-1">
                                              <div className="d-flex align-items-center">
                                                <Link to={`https://www.amazon.${"com"}/sp?seller=${"A1ISKZNHY8JGSW"}`} target="_blank" className="link-dark text-muted">
                                                  {t("Store")}
                                                  <i className="ri-arrow-right-up-line align-middle"></i>
                                                </Link>
                                              </div>
                                              <div className="d-flex align-items-center">
                                                <Link to={`https://www.amazon.${"com"}/s?me=${"A1ISKZNHY8JGSW"}`} target="_blank" className="link-dark text-muted">
                                                  {t("Products")}
                                                  <i className="ri-arrow-right-up-line align-middle"></i>
                                                </Link>
                                              </div>
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <div className="align-items-center d-flex">
                                              <span className="flex-grow-1 hstack gap-2">
                                                <div className="avatar-xs img-thumbnail rounded-circle flex-shrink-0">
                                                  <img src={destinationMarketplace?.flag /*sellerMarketplace?.flag*/} alt="Country Flag" className="rounded-circle" />
                                                </div>
                                                <div>
                                                  <div className="hstack">
                                                    <Link to={`https://www.amazon.${"com"}/sp?seller=${"AAW6CJIKK3PL3"}`} target="_blank" className="link-dark link-offset-2 text-decoration-underline">
                                                      AAW6CJIKK3PL3
                                                      <i className="ri-arrow-right-up-line"></i>
                                                    </Link>
                                                    <div>
                                                      <i id="PopoverSellerStrength-1" className="text-warning align-middle ri-flashlight-fill ms-1 fs-14"></i>
                                                      <DefaultUncontrolledTooltip target="PopoverSellerStrength-1">
                                                        {"Moderate seller: This seller has an average number of feedback and ratings, presenting moderate competition."}
                                                      </DefaultUncontrolledTooltip>
                                                    </div>
                                                    <span className="badge bg-primary-subtle text-primary fs-12 ms-2">FBM</span>
                                                  </div>
                                                </div>
                                              </span>
                                              <span className="d-flex align-items-center flex-shrink-0">
                                                <i className="ri-star-fill text-warning fs-16 me-1"></i> Rating: 12
                                              </span>
                                            </div>
                                            <div className="hstack gap-2 flex-wrap mt-1 ms-1">
                                              <div className="d-flex align-items-center">
                                                <Link to={`https://www.amazon.${"com"}/sp?seller=${"A1ISKZNHY8JGSW"}`} target="_blank" className="link-dark text-muted">
                                                  {t("Store")}
                                                  <i className="ri-arrow-right-up-line align-middle"></i>
                                                </Link>
                                              </div>
                                              <div className="d-flex align-items-center">
                                                <Link to={`https://www.amazon.${"com"}/s?me=${"A1ISKZNHY8JGSW"}`} target="_blank" className="link-dark text-muted">
                                                  {t("Products")}
                                                  <i className="ri-arrow-right-up-line align-middle"></i>
                                                </Link>
                                              </div>
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <div className="align-items-center d-flex">
                                              <span className="flex-grow-1 hstack gap-2">
                                                <div className="avatar-xs img-thumbnail rounded-circle flex-shrink-0">
                                                  <img src={destinationMarketplace?.flag /*sellerMarketplace?.flag*/} alt="Country Flag" className="rounded-circle" />
                                                </div>
                                                <div>
                                                  <div className="hstack">
                                                    <Link to={`https://www.amazon.${"com"}/sp?seller=${"AAW6CJIKK3PL3"}`} target="_blank" className="link-dark link-offset-2 text-decoration-underline">
                                                      AAW6CJIKK3PL3
                                                      <i className="ri-arrow-right-up-line"></i>
                                                    </Link>
                                                    <div>
                                                      <i id="PopoverSellerStrength-1" className="text-warning align-middle ri-flashlight-fill ms-1 fs-14"></i>
                                                      <DefaultUncontrolledTooltip target="PopoverSellerStrength-1">
                                                        {"Moderate seller: This seller has an average number of feedback and ratings, presenting moderate competition."}
                                                      </DefaultUncontrolledTooltip>
                                                    </div>
                                                    <span className="badge bg-primary-subtle text-primary fs-12 ms-2">FBM</span>
                                                  </div>
                                                </div>
                                              </span>
                                              <span className="d-flex align-items-center flex-shrink-0">
                                                <i className="ri-star-fill text-warning fs-16 me-1"></i> Rating: 12
                                              </span>
                                            </div>
                                            <div className="hstack gap-2 flex-wrap mt-1 ms-1">
                                              <div className="d-flex align-items-center">
                                                <Link to={`https://www.amazon.${"com"}/sp?seller=${"A1ISKZNHY8JGSW"}`} target="_blank" className="link-dark text-muted">
                                                  {t("Store")}
                                                  <i className="ri-arrow-right-up-line align-middle"></i>
                                                </Link>
                                              </div>
                                              <div className="d-flex align-items-center">
                                                <Link to={`https://www.amazon.${"com"}/s?me=${"A1ISKZNHY8JGSW"}`} target="_blank" className="link-dark text-muted">
                                                  {t("Products")}
                                                  <i className="ri-arrow-right-up-line align-middle"></i>
                                                </Link>
                                              </div>
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <div className="align-items-center d-flex">
                                              <span className="flex-grow-1 hstack gap-2">
                                                <div className="avatar-xs img-thumbnail rounded-circle flex-shrink-0">
                                                  <img src={destinationMarketplace?.flag /*sellerMarketplace?.flag*/} alt="Country Flag" className="rounded-circle" />
                                                </div>
                                                <div>
                                                  <div className="hstack">
                                                    <Link to={`https://www.amazon.${"com"}/sp?seller=${"AAW6CJIKK3PL3"}`} target="_blank" className="link-dark link-offset-2 text-decoration-underline">
                                                      AAW6CJIKK3PL3
                                                      <i className="ri-arrow-right-up-line"></i>
                                                    </Link>
                                                    <div>
                                                      <i id="PopoverSellerStrength-1" className="text-warning align-middle ri-flashlight-fill ms-1 fs-14"></i>
                                                      <DefaultUncontrolledTooltip target="PopoverSellerStrength-1">
                                                        {"Moderate seller: This seller has an average number of feedback and ratings, presenting moderate competition."}
                                                      </DefaultUncontrolledTooltip>
                                                    </div>
                                                    <span className="badge bg-primary-subtle text-primary fs-12 ms-2">FBM</span>
                                                  </div>
                                                </div>
                                              </span>
                                              <span className="d-flex align-items-center flex-shrink-0">
                                                <i className="ri-star-fill text-warning fs-16 me-1"></i> Rating: 12
                                              </span>
                                            </div>
                                            <div className="hstack gap-2 flex-wrap mt-1 ms-1">
                                              <div className="d-flex align-items-center">
                                                <Link to={`https://www.amazon.${"com"}/sp?seller=${"A1ISKZNHY8JGSW"}`} target="_blank" className="link-dark text-muted">
                                                  {t("Store")}
                                                  <i className="ri-arrow-right-up-line align-middle"></i>
                                                </Link>
                                              </div>
                                              <div className="d-flex align-items-center">
                                                <Link to={`https://www.amazon.${"com"}/s?me=${"A1ISKZNHY8JGSW"}`} target="_blank" className="link-dark text-muted">
                                                  {t("Products")}
                                                  <i className="ri-arrow-right-up-line align-middle"></i>
                                                </Link>
                                              </div>
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <div className="align-items-center d-flex">
                                              <span className="flex-grow-1 hstack gap-2">
                                                <div className="avatar-xs img-thumbnail rounded-circle flex-shrink-0">
                                                  <img src={destinationMarketplace?.flag /*sellerMarketplace?.flag*/} alt="Country Flag" className="rounded-circle" />
                                                </div>
                                                <div>
                                                  <div className="hstack">
                                                    <Link to={`https://www.amazon.${"com"}/sp?seller=${"AAW6CJIKK3PL3"}`} target="_blank" className="link-dark link-offset-2 text-decoration-underline">
                                                      AAW6CJIKK3PL3
                                                      <i className="ri-arrow-right-up-line"></i>
                                                    </Link>
                                                    <div>
                                                      <i id="PopoverSellerStrength-1" className="text-warning align-middle ri-flashlight-fill ms-1 fs-14"></i>
                                                      <DefaultUncontrolledTooltip target="PopoverSellerStrength-1">
                                                        {"Moderate seller: This seller has an average number of feedback and ratings, presenting moderate competition."}
                                                      </DefaultUncontrolledTooltip>
                                                    </div>
                                                    <span className="badge bg-primary-subtle text-primary fs-12 ms-2">FBM</span>
                                                  </div>
                                                </div>
                                              </span>
                                              <span className="d-flex align-items-center flex-shrink-0">
                                                <i className="ri-star-fill text-warning fs-16 me-1"></i> Rating: 12
                                              </span>
                                            </div>
                                            <div className="hstack gap-2 flex-wrap mt-1 ms-1">
                                              <div className="d-flex align-items-center">
                                                <Link to={`https://www.amazon.${"com"}/sp?seller=${"A1ISKZNHY8JGSW"}`} target="_blank" className="link-dark text-muted">
                                                  {t("Store")}
                                                  <i className="ri-arrow-right-up-line align-middle"></i>
                                                </Link>
                                              </div>
                                              <div className="d-flex align-items-center">
                                                <Link to={`https://www.amazon.${"com"}/s?me=${"A1ISKZNHY8JGSW"}`} target="_blank" className="link-dark text-muted">
                                                  {t("Products")}
                                                  <i className="ri-arrow-right-up-line align-middle"></i>
                                                </Link>
                                              </div>
                                            </div>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </SimpleBar>
                                </div>
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>

                <Row>
                  <Col xl={12} className="same-height">
                    <Card className="card-animate">
                      <CardBody>
                        <h5 className="fs-15">{t("ProductDetails.SalesAnalysis.Title")}</h5>

                        <Row className="mt-4">
                          <Col xl={8} sm={12}>
                            <Card className="mb-0">
                              <CardHeader className="align-items-center d-flex">
                                <h5 className="fs-15 card-title mb-0 flex-grow-1">BSR History Chart</h5>
                                <div className="flex-shrink-0">
                                  <div className="d-flex justify-content-end gap-1">
                                    <div role="group" className="gap-2 btn-group">
                                      <button type="button" className="link-body-emphasis text-decoration-underline link-underline-opacity-75-hover align-items-center d-flex nowrap p-0 btn btn-link">
                                        All Time
                                      </button>
                                      <button type="button" className="link-body-emphasis link-underline-opacity-75-hover align-items-center d-flex nowrap p-0 btn btn-link">
                                        7 Days
                                      </button>
                                      <button type="button" className="link-body-emphasis link-underline-opacity-75-hover align-items-center d-flex nowrap p-0 btn btn-link">
                                        30 days
                                      </button>
                                      <button type="button" className="link-body-emphasis link-underline-opacity-75-hover align-items-center d-flex nowrap p-0 btn btn-link">
                                        90 days
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </CardHeader>
                              <div className="card-body">
                                <BSRHistoryChart dataColors='["--vz-secondary"]' />
                              </div>
                            </Card>
                          </Col>
                          <Col xl={4} sm={12}>
                            <UncontrolledAlert color="success" className="alert-additional mb-4" toggle={null}>
                              <div className="alert-body">
                                <div className="d-flex">
                                  <div className="flex-shrink-0 me-3">
                                    <i className="ri-error-warning-line fs-16 align-middle"></i>
                                  </div>
                                  <div className="flex-grow-1">
                                    <h5 className="alert-heading">
                                      Precision: <span className="fw-medium">Medium</span>
                                    </h5>
                                    <p className="mb-0">
                                      It provides a moderately reliable estimate of sales numbers and reflects overall trends, but it may not fully capture fluctuations on specific days.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </UncontrolledAlert>

                            <Card>
                              <CardBody>
                                <div className="fs-15">
                                  <div className="d-flex pb-2">
                                    <div className="flex-grow-1">
                                      <p className="text-truncate mb-0">
                                        <i className="mdi mdi-package-variant align-middle text-primary me-2"></i>Sale Count <i className="text-muted small">(30 days)</i>
                                      </p>
                                    </div>
                                    <div className="flex-shrink-0">
                                      <p className="mb-0 fw-medium">29</p>
                                    </div>
                                  </div>
                                  <div className="d-flex py-2">
                                    <div className="flex-grow-1">
                                      <p className="text-truncate mb-0">
                                        <i className="mdi mdi-chart-line align-middle text-primary me-2"></i>Sale Trend
                                      </p>
                                    </div>
                                    <div className="flex-shrink-0">
                                      <p className="mb-0 fw-medium">%2.3</p>
                                    </div>
                                  </div>
                                  <div className="d-flex py-2">
                                    <div className="flex-grow-1">
                                      <p className="text-truncate mb-0">
                                        <i className="bx bxs-category-alt align-middle text-primary me-2"></i>Category
                                      </p>
                                    </div>
                                    <div className="flex-shrink-0">
                                      <p className="mb-0">Home & Kitchen</p>
                                    </div>
                                  </div>
                                  <div className="d-flex pt-2">
                                    <div className="flex-grow-1">
                                      <p className="text-truncate mb-0">
                                        <i className="mdi mdi-trophy align-middle text-primary me-2"></i>Average BSR <i className="text-muted small">(30 days)</i>
                                      </p>
                                    </div>
                                    <div className="flex-shrink-0">
                                      <p className="mb-0">131.194</p>
                                    </div>
                                  </div>
                                </div>
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </PlaceholderGlow>
      </Container>
    </div>
  );
};

export default ProductDetails;
