import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {getDemoModeInfo, getLoggedUser, removeLoggedUserLocalData} from "helpers/local_storage";
import {ApiError, LoadingStates} from "helpers/types";
import {ApplicationUser} from "models/application_user";
import {UserRemainingLimitViewModel} from "models/user_limit";

type LoginLoadingStates = LoadingStates<"login" | "logout" | "load" | "limit" | "loginWithToken">;
type LoginStatus = "empty" | "success" | "failed";

export type DemoMode = {
  active: boolean;
  date: Date;
}
export type LoginState = {
  loading: LoginLoadingStates;
  status: LoginStatus;
  error?: ApiError;
  user?: ApplicationUser;
  demoMode?: DemoMode;
};

const initialState: LoginState = {
  loading: {
    login: false,
    logout: false,
    load: false,
    limit: false,
    loginWithToken:false
  },
  status: "empty",
  error: {} as ApiError,
  user: getLoggedUser(),
  demoMode: getDemoModeInfo()
};

const loginSlice = createSlice({
  name: "Login",
  initialState,
  reducers: {
    setStatus(state, action: PayloadAction<LoginStatus>) {
      state.status = action.payload;
    },
    setLoginUser(state, action: PayloadAction<ApplicationUser | undefined>) {
      state.user = action.payload;
      state.error = undefined;
    },
    setLoggedUserRemainingLimits(state, action: PayloadAction<UserRemainingLimitViewModel>) {
      state.user!.remainingLimits = action.payload;
    },
    loading(state, action: PayloadAction<[keyof LoginLoadingStates, boolean]>) {
      const [operation, loadingState] = action.payload;
      state.loading[operation] = loadingState;
    },
    apiError(state, action: PayloadAction<ApiError>) {
      state.error = action.payload;
    },
    reset(state) {
      state.loading = initialState.loading;
      state.status = initialState.status;
      state.error = initialState.error;
      state.user = undefined;
      state.demoMode = initialState.demoMode;
      removeLoggedUserLocalData();
    },
  },
});

export const {setStatus, setLoginUser, setLoggedUserRemainingLimits, loading, apiError, reset} = loginSlice.actions;

export default loginSlice.reducer;
