import {Card, CardBody} from "reactstrap";
import {useTranslation} from "react-i18next";
import CheckListItem from "./Components/CheckListItem";
import {UserSearchProduct} from "models/user_search_product";

interface CheckListProps {
  item: UserSearchProduct;
}
const CheckList = ({item}: CheckListProps) => {
  const {t} = useTranslation();

  return (
    <Card className="border card-animate">
      <CardBody>
        <ul className="list-unstyled vstack gap-1 mb-0">
          <h5>{t("SearchResults.Analyzes.CheckList.Title")}</h5>
          <CheckListItem labelName={t("SearchResults.Analyzes.CheckList.SameProduct")} status={item.checkList?.sameProductCheckResult === 1 ? undefined : null}></CheckListItem>
          <CheckListItem
            labelName={t("SearchResults.Analyzes.CheckList.CustomsCheck")}
            status={item.checkList?.customsCheckCategoryCheckResult === 1 || item.checkList?.customsCheckKeywordCheckResult === 1 ? undefined : null}
          ></CheckListItem>
          <CheckListItem labelName={t("SearchResults.Analyzes.CheckList.ProductSizeEligibleToFba")} status={item.checkList?.productSizeEligibleToFBA ?? null}></CheckListItem>
          <CheckListItem
            labelName={
              t("SearchResults.Analyzes.CheckList.IsOldProduct") +
              (item.checkList?.productExistSince
                ? "(" +
                  item.checkList.productExistSince
                    .replace("years", t("years"))
                    .replace("year", t("year"))
                    .replace("months", t("months"))
                    .replace("month", t("month"))
                    .replace("days", t("days"))
                    .replace("day", t("day"))
                    .replace("and", t("and"))
                    .replace("ago", t("ago")) +
                  ")"
                : "")
            }
            status={item.checkList?.isOldProduct ?? null}
          ></CheckListItem>
          <CheckListItem labelName={t("SearchResults.Analyzes.CheckList.IsTheRatingAverageGood")} status={item.checkList?.isTheRatingAverageGood ?? null}></CheckListItem>
        </ul>
      </CardBody>
    </Card>
  );
};

export default CheckList;
