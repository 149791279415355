import {multiValueContainer, optionContainer, singleSelectStyles} from "./Select/SelectStyle";
import {FormikValidation} from "helpers/types";
import {useTranslation} from "react-i18next";
import {FormFeedback} from "reactstrap";
import {SelectOptionsType} from "../Hooks/SelectOptions";
import Select from "react-select";

interface ValidatedSelectProps<T> {
  value: any;
  field: keyof T;
  options: SelectOptionsType[];
  isClearable?: boolean;
  validation: FormikValidation<T>;
  className?: string;
  placeholder?: string;
  errorStyle?: "container" | "solid";
}
const ValidatedSelect = <T,>(componentProps: ValidatedSelectProps<T>) => {
  const {t} = useTranslation();
  const fieldName = componentProps.field.toString();
  const {error, touched} = componentProps.validation.getFieldMeta(fieldName);

  const handleChange = (selectedOption: any) => {
    const newValue = selectedOption ? selectedOption.value : undefined;

    componentProps.validation.setFieldValue(fieldName, newValue);
  };

  return (
    <>
      <Select
        className={`${componentProps.className} ${touched && error && componentProps.errorStyle === "container" && "form-control is-invalid form-select-invalid"}`}
        name={fieldName}
        options={componentProps.options}
        isMulti={false}
        isClearable={componentProps.isClearable}
        onBlur={componentProps.validation.handleBlur}
        value={componentProps.options.filter((option: any) => {
          return componentProps.value && componentProps.value.includes(option.value);
        })}
        onChange={handleChange}
        components={{MultiValueContainer: multiValueContainer, Option: optionContainer}}
        placeholder={componentProps.placeholder}
        noOptionsMessage={() => t("NoOptions")}
        hideSelectedOptions={false}
        backspaceRemovesValue={false}
        closeMenuOnSelect={true}
        styles={singleSelectStyles}
        menuPortalTarget={document.body}
      />
      {touched && error ? (
        <FormFeedback type="invalid" className="searches validation-width">
          {error}
        </FormFeedback>
      ) : null}
    </>
  );
};

export default ValidatedSelect;
