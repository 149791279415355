import {useFormik} from "formik";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "reselect";
import {changeSendToAnalyzeModalVisibility, sendToAnalyze} from "slices/thunks";
import {Modal, ModalBody, Col, Row, Button, Form, Spinner} from "reactstrap";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {postGetUserStores} from "services/store_service";
import {ResponseModel} from "models/response_model";
import {UserStore} from "models/user_stores";
import {RootState} from "slices";
import SelectOptions, {SelectOptionsType} from "Components/Hooks/SelectOptions";
import AmazonMarketplaceInfos from "Components/Common/AmazonMarketplaceInfos";
import classNames from "classnames";
import ValidatedSelect from "Components/Common/ValidatedSelect";
import ValidatedInput from "Components/Common/ValidatedInput";
import * as Yup from "yup";

const ReAnalyze = () => {
  const {t} = useTranslation();
  const [marketplaceOptions, setMarketplaceOptions] = useState<any>();
  const {marketplaceSelectOptions} = SelectOptions();
  const [amazonMarketplaceInfos] = useState(AmazonMarketplaceInfos());
  const navigate = useNavigate();
  useEffect(() => {
    loadMarketplaceOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dispatch: any = useDispatch();
  const favoritesData = createSelector(
    (state: RootState) => state,
    (state) => ({
      loading: state.Favorites.loading,
      selectedFavoriteList: state.Favorites.selectedFavoriteList,
      analyzeModalVisibility: state.Favorites.analyzeModalVisibility,
    }),
  );
  const {loading, analyzeModalVisibility, selectedFavoriteList} = useSelector(favoritesData);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {searchName: "", marketplace: null},
    validationSchema: Yup.object({
      searchName: Yup.string()
        .required(t("Favorites.Validation.SearchName"))
        .max(100, t("Favorites.Validation.SearchNameMaxLength")),
      marketplace: Yup.string().required(t("Favorites.Validation.Marketplace")),
    }),
    onSubmit: async (values: any) => {
      await dispatch(sendToAnalyze(selectedFavoriteList.userFavoriteId, values.searchName, values.marketplace, navigate));
    },
  });

  const loadMarketplaceOptions = async () => {
    const result: ResponseModel = await postGetUserStores();
    const userStores = JSON.parse(result.data) as UserStore[];

    const userStoreMarketplaces = userStores.map((store: any) => store.Marketplace);
    const filteredOptions: SelectOptionsType[] = marketplaceSelectOptions.filter((option) => !userStoreMarketplaces.includes(option.value));
    userStores.forEach((store: any) => {
      if (store.Marketplace !== "US" && store.Status) {
        const label = amazonMarketplaceInfos.find((amazonMarketplaceInfo: any) => amazonMarketplaceInfo.marketplace === store.Marketplace)?.countryName + " - " + store.Name;
        filteredOptions.unshift({value: store.UserStoreId, label: label});
      }
    });
    setMarketplaceOptions(filteredOptions);
  };

  return (
    <>
      <Modal backdrop="static" isOpen={analyzeModalVisibility} centered={true}>
        <ModalBody className="py-3 px-5">
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <div className="mt-2 text-center">
              <i className={classNames("mdi", "mdi-chart-timeline-variant-shimmer", "display-5", "text-info")}></i>
              <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-4">
                <h4>{t("Favorites.Dialog.Reanalyze.Title")}</h4>
                <p className="text-muted mx-4 mb-0 mt-3">{t("Favorites.Dialog.Reanalyze.Description")}</p>
              </div>
            </div>
            <div className="mt-4">
              <Row className="g-3">
                <Col xs={12} md={6}>
                  <ValidatedInput validation={validation} type="text" field="searchName" maxLength={100} placeholder={t("Searches.Name")} disableValidationUI />
                </Col>
                <Col xs={12} md={6}>
                  <ValidatedSelect
                    className="new-shipment filter-input"
                    options={marketplaceOptions}
                    validation={validation}
                    field="marketplace"
                    value={validation.values.marketplace}
                    errorStyle="container"
                  />
                </Col>
              </Row>
            </div>

            <div className="mt-4 mb-2">
                <div className="d-flex gap-2 justify-content-center">
                  <Button type="submit" color={"success"} disabled={loading.analyze}>
                    {loading.analyze ? <Spinner size="sm" className="me-2"/> : null}
                    {t("Favorites.Dialog.Reanalyze.SubmitButton")}
                  </Button>
                  <Button
                    type="button"
                    color={"light"}
                    onClick={() => {
                      changeSendToAnalyzeModalVisibility(false)(dispatch);
                      validation.resetForm();
                    }}
                    disabled={loading.analyze}
                  >
                    {t("Favorites.Dialog.Reanalyze.CancelButton")}
                  </Button>
                </div>
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};

export default ReAnalyze;
