import AmazonMarketplaceInfos from "./AmazonMarketplaceInfos";
import CountUp from "react-countup";
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {createSelector} from "reselect";
import {RootState} from "slices";
import {Currencies} from "models/currencies";
import { formatCurrency } from "helpers/utilities";

interface CurrencyProps {
  source: string;
  target?: string;
  value: number;
  decimals: number;
  countUp?: boolean;
}

const Currency = (props: CurrencyProps) => {
  const [amazonMarketplaceInfos] = useState(AmazonMarketplaceInfos());
  const [convertedValue, setConvertedValue] = useState<number>(0);

  const commonData = createSelector(
    (state: RootState) => state,
    (state) => ({
      currencies: state.Common.currencies,
    }),
  );
  const {currencies} = useSelector(commonData);

  useEffect(() => {
    if (currencies) {
      if (props.source === props.target) {
        setConvertedValue(props.value);
      } else {
        const sourceCurrency = currencies.find((cur: Currencies) => cur.currencyCode === props.source);
        const targetCurrency = currencies.find((cur: Currencies) => cur.currencyCode === props.target);

        if (props.source === "USD" && targetCurrency) {
          setConvertedValue(targetCurrency?.rate * props.value);
        } else if (sourceCurrency && targetCurrency) {
          const converted = (props.value / sourceCurrency.rate) * targetCurrency.rate;
          setConvertedValue(converted);
        } else if (props.target === "USD" && sourceCurrency) {
          setConvertedValue(props.value / sourceCurrency.rate);
        }
      }
    }
  }, [currencies, props.source, props.target, props.value]);

  const getCurrencySymbol = (currency?: string) => {
    return amazonMarketplaceInfos.find((amazonMarketplaceInfo: any) => amazonMarketplaceInfo.currency === currency)?.priceSign || "";
  };

  return (
    <>
      {props.countUp ? (
        <CountUp
          start={0}
          prefix={`${getCurrencySymbol(props.target)}`}
          suffix={` ${props.target}`}
          decimals={props.decimals}
          duration={2}
          end={convertedValue}
          formattingFn={(value: number) => formatCurrency(getCurrencySymbol(props.target), convertedValue, props.decimals, props.target)}
        />
      ) : (
        `${formatCurrency(getCurrencySymbol(props.target), convertedValue, props.decimals, props.target)}`
      )}
    </>
  );
};

export default Currency;
