import BreadCrumb, {BreadcrumbMenuItem} from "Components/Common/BreadCrumb";
import DataTable, {DataTableRef} from "Components/Common/DataTable";
import useSelectOptions, {SelectOptionsType} from "Components/Hooks/SelectOptions";
import {ButtonGroup, Card, CardBody, Container, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown} from "reactstrap";
import {useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useImmer} from "use-immer";
import {SearchResultQuery, SortParameters} from "models/search_result";
import {ColumnDef} from "@tanstack/react-table";
import {UserSearchProduct} from "models/user_search_product";
import {createSelector} from "reselect";
import {RootState} from "slices";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useDebounce} from "Components/Hooks/HelperHooks";
import {useProfile} from "Components/Hooks/UserHooks";
import {ConstantPage} from "helpers/permission_helper";
import {getUserSearchResults, getUserSearchResultsFilters, resetSearchResultState, setSearchResultPageUrl} from "slices/search-result2/thunk";
import {UserSearchProductStatus} from "models/enums/user_search_product_status";
import {UserStore} from "models/user_stores";
import Restricted from "Components/Common/Restricted";
import Unauthorized from "pages/Errors/_Unauthorized";
import Filters from "./Filter";
import Loader from "Components/Common/Loader";
import NoResult from "Components/Common/NoResult";
import InitialFilter from "./Filter/_InitialFilter";
import SearchResultItem from "./Item";
import ReportError from "./Modals/ReportError";
import IneligibilityReasonList from "./Modals/IneligibilityReasonList";
import DecisionReasons from "./Modals/DecisionReasons";
import DefinedFilters from "./Modals/DefinedFilter";
import CheckList from "./Modals/CheckList";
import FBASellers from "./Modals/FBASellers";
import ProductNotExist from "./Item/Components/ProductNotExist";
import RestrictionReasonCodes from "./Modals/RestrictionReason";
import InProgress from "./Item/Components/InProgress";
import AddToFavorites from "./Modals/AddToFavorites";
import SellThis from "Components/Common/SellThis";
import ReAnalyzeSearch from "../Searches/Modals/ReAnalyzeSearch";
import AmazonMarketplaceInfos from "Components/Common/AmazonMarketplaceInfos";
import {loadUserStores} from "slices/common/thunk";

const PAGE_IDENTIFIER: ConstantPage = "searchResult";

export type SearchResultListRef = {
  reload: VoidFunction;
};
interface SearchResultsProps {
  reportError?: boolean;
  ineligibilityReasons?: boolean;
  decisionReasons?: boolean;
  definedFilters?: boolean;
  checkList?: boolean;
  fbaSellers?: boolean;
  addToFavorites?: boolean;
  sellThis?: boolean;
  reAnalyze?: boolean;
  restrictionCheck?: boolean;
}
const SearchResults = (props: SearchResultsProps) => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const {searchId, favoriteId} = useParams();
  const {hasPermission} = useProfile();
  const {marketplaceSelectOptions} = useSelectOptions();
  const [marketplaceSelectOptionsWithStores, setMarketplaceSelectOptionsWithStores] = useState(marketplaceSelectOptions);
  const [amazonMarketplaceInfos] = useState(AmazonMarketplaceInfos());
  const dispatch = useDispatch();
  const tableRef = useRef<DataTableRef>(null);
  const listRef = useRef<SearchResultListRef>(null);
  const searchResultsData = createSelector(
    (state: RootState) => state,
    (state) => ({
      loading: state.SearchResult.loading,
      result: state.SearchResult.result,
    }),
  );
  const {loading, result} = useSelector(searchResultsData);

  const [query, updateQuery] = useImmer<SearchResultQuery>({
    filtering: true,
    page: 1,
    pageSize: 10,
    searchId,
    favoriteId,
    filter: InitialFilter(),
  });

  const debouncedLoadList = useDebounce(() => {
    if (hasPermission(PAGE_IDENTIFIER)) {
      getUserSearchResultsFilters()(dispatch);
      getUserSearchResults(query)(dispatch).then(() => {
        tableRef.current?.resetSelection();
      });
    }
  }, 200);

  useEffect(() => {
    if (hasPermission(PAGE_IDENTIFIER)) {
      loadUserStores()(dispatch);
    }
  }, []); //eslint-disable-line

  useEffect(() => {
    debouncedLoadList();
  }, [debouncedLoadList, query]);

  useImperativeHandle(
    listRef,
    () => {
      return {
        reload: () => {
          debouncedLoadList();
        },
      };
    },
    [debouncedLoadList],
  );

  const findPageUrl = useCallback(() => {
    if (searchId) {
      setSearchResultPageUrl(`/searches/search-results/${searchId}`)(dispatch);
    } else if (favoriteId) {
      setSearchResultPageUrl(`/favorites/${favoriteId}`)(dispatch);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    findPageUrl();
  }, [findPageUrl]);

  useEffect(() => {
    return () => {
      resetSearchResultState()(dispatch);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const commonData = createSelector(
    (state: RootState) => state,
    (state) => ({
      userStores: state.Common.userStores,
    }),
  );
  const {userStores} = useSelector(commonData);

  useEffect(() => {
    const userStoreOptions: SelectOptionsType[] = marketplaceSelectOptions;
    userStores.forEach((store: UserStore) => {
      const name = amazonMarketplaceInfos.find((amazonMarketplaceInfo: any) => amazonMarketplaceInfo.marketplace === store.marketplace)?.countryName + " - " + store.name;
      userStoreOptions.unshift({value: store.userStoreId, label: name});
    });
    setMarketplaceSelectOptionsWithStores(userStoreOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userStores]);

  const columns = useMemo<ColumnDef<UserSearchProduct, any>[]>(
    () => [
      {
        header: " ",
        cell: (cellProps) => {
          const row = cellProps.row.original as UserSearchProduct;
          return (
            <>
              {row.existAtSource === undefined || row.existAtSource === false || row.existAtTarget === undefined || row.existAtTarget === false ? (
                <ProductNotExist item={row} />
              ) : row.status > UserSearchProductStatus.IN_PROGRESS ? (
                <SearchResultItem key={row.userSearchProductId} item={row} />
              ) : (
                <InProgress item={row} />
              )}
            </>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t],
  );

  const [sortParameterLabel, setSortParameterLabel] = useState("");
  const sortBy = new URLSearchParams(window.location.search).get("sortBy");

  useEffect(() => {
    if (sortBy) {
      if (sortBy === "profit_margin_asc") setSortParameterLabel(t("SearchResults.SortBy.ProfitMarginAsc"));
      else if (sortBy === "profit_margin_desc") setSortParameterLabel(t("SearchResults.SortBy.ProfitMarginDesc"));
      else if (sortBy === "roi_asc") setSortParameterLabel(t("SearchResults.SortBy.ROIAsc"));
      else if (sortBy === "roi_desc") setSortParameterLabel(t("SearchResults.SortBy.ROIDesc"));
      else if (sortBy === "bsr_asc") setSortParameterLabel(t("SearchResults.SortBy.BSRAsc"));
      else if (sortBy === "bsr_desc") setSortParameterLabel(t("SearchResults.SortBy.BSRDesc"));
      else if (sortBy === "sale_count_asc") setSortParameterLabel(t("SearchResults.SortBy.SaleCountAsc"));
      else if (sortBy === "sale_count_desc") setSortParameterLabel(t("SearchResults.SortBy.SaleCountDesc"));
      else if (sortBy === "variation_count_asc") setSortParameterLabel(t("SearchResults.SortBy.VariationCountAsc"));
      else if (sortBy === "variation_count_desc") setSortParameterLabel(t("SearchResults.SortBy.VariationCountDesc"));
      else if (sortBy === "purchase_price_asc") setSortParameterLabel(t("SearchResults.SortBy.PurchasePriceAsc"));
      else if (sortBy === "purchase_price_desc") setSortParameterLabel(t("SearchResults.SortBy.PurchasePriceDesc"));
      else if (sortBy === "sell_price_asc") setSortParameterLabel(t("SearchResults.SortBy.SellPriceAsc"));
      else if (sortBy === "sell_price_desc") setSortParameterLabel(t("SearchResults.SortBy.SellPriceDesc"));
    }
  }, [sortBy, t]);

  const handleSortBy = (filterValue: SortParameters) => {
    const changedUrl = `${location.pathname}?sortBy=${filterValue}`;
    navigate(changedUrl);
  };

  const render_Sorting = (): JSX.Element => {
    return (
      <ButtonGroup className="d-grid">
        <UncontrolledDropdown direction="down">
          <DropdownToggle tag="button" className="btn btn-light w-100">
            {t("Sort by")}: {sortParameterLabel} <i className="mdi mdi-chevron-down"></i>
          </DropdownToggle>
          <DropdownMenu style={{zIndex: 5}}>
            <DropdownItem onClick={() => handleSortBy("profit_margin_desc")}>{t("SearchResults.SortBy.ProfitMarginDesc")}</DropdownItem>
            <DropdownItem onClick={() => handleSortBy("profit_margin_asc")}>{t("SearchResults.SortBy.ProfitMarginAsc")}</DropdownItem>
            <DropdownItem onClick={() => handleSortBy("roi_desc")}>{t("SearchResults.SortBy.ROIDesc")}</DropdownItem>
            <DropdownItem onClick={() => handleSortBy("roi_asc")}>{t("SearchResults.SortBy.ROIAsc")}</DropdownItem>
            <DropdownItem onClick={() => handleSortBy("bsr_desc")}>{t("SearchResults.SortBy.BSRDesc")}</DropdownItem>
            <DropdownItem onClick={() => handleSortBy("bsr_asc")}>{t("SearchResults.SortBy.BSRAsc")}</DropdownItem>
            <DropdownItem onClick={() => handleSortBy("sale_count_desc")}>{t("SearchResults.SortBy.SaleCountDesc")}</DropdownItem>
            <DropdownItem onClick={() => handleSortBy("sale_count_asc")}>{t("SearchResults.SortBy.SaleCountAsc")}</DropdownItem>
            <DropdownItem onClick={() => handleSortBy("variation_count_desc")}>{t("SearchResults.SortBy.VariationCountDesc")}</DropdownItem>
            <DropdownItem onClick={() => handleSortBy("variation_count_asc")}>{t("SearchResults.SortBy.VariationCountAsc")}</DropdownItem>
            <DropdownItem onClick={() => handleSortBy("purchase_price_desc")}>{t("SearchResults.SortBy.PurchasePriceDesc")}</DropdownItem>
            <DropdownItem onClick={() => handleSortBy("purchase_price_asc")}>{t("SearchResults.SortBy.PurchasePriceAsc")}</DropdownItem>
            <DropdownItem onClick={() => handleSortBy("sell_price_desc")}>{t("SearchResults.SortBy.SellPriceDesc")}</DropdownItem>
            <DropdownItem onClick={() => handleSortBy("sell_price_asc")}>{t("SearchResults.SortBy.SellPriceAsc")}</DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </ButtonGroup>
    );
  };

  const breadcrumbTitle = result.search ? `${t("SearchResults.Title")} - ${result.search.name}` : favoriteId ? `${t("Favorites.Title")} - ${result.favorite?.name}` : "";
  const breadcrumbMenus: BreadcrumbMenuItem[] = searchId
    ? [
        {
          label: t("Searches.Title"),
          url: "/searches",
        },
        {
          label: `${t("SearchResults.Title")} - ${result.search?.name}`,
          url: "",
        },
      ]
    : favoriteId
    ? [
        {
          label: t("Favorites.Title"),
          url: "/favorites",
        },
        {
          label: `${result.favorite?.name}`,
          url: "",
        },
      ]
    : [];
  document.title = t("PageTitles.SearchResults");
  return (
    <Restricted require="searchResult" read fallback={() => <Unauthorized />}>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={breadcrumbTitle} menus={breadcrumbMenus} />
          <>
            <Filters page={query.page} pageSize={query.pageSize} handleFilter={(f) => updateQuery(f)} />
          </>
          <Card>
            <CardBody>
              {loading.filter ? (
                <Loader />
              ) : result?.products?.items && result?.products?.items?.length > 0 ? (
                <>
                  <DataTable
                    theadClass="d-none border-0"
                    tableClass="border-0"
                    tbodyClass="border-0"
                    tdClass="border-0"
                    ref={tableRef}
                    busy={loading.list}
                    columns={columns}
                    data={result.products.items || []}
                    totalDataLength={result.products.totalCount}
                    renderSortingSelect={render_Sorting}
                    disableStickyHeader
                    pagination={{
                      pageIndex: query.page - 1,
                      pageSize: query.pageSize,
                    }}
                    onPaginationChanged={(pagination) =>
                      updateQuery((q) => {
                        q.page = pagination.pageIndex + 1;
                        q.pageSize = pagination.pageSize;
                      })
                    }
                  />
                </>
              ) : (
                <NoResult title={t("SearchResults.NoResult.Title")} description={t("SearchResults.NoResult.Description")} />
              )}
            </CardBody>
          </Card>
        </Container>
      </div>
      <FBASellers isOpen={props.fbaSellers || false} />
      <CheckList isOpen={props.checkList || false} />
      <DefinedFilters isOpen={props.definedFilters || false} />
      <DecisionReasons isOpen={props.decisionReasons || false} />
      <ReportError isOpen={props.reportError || false} />
      <IneligibilityReasonList isOpen={props.ineligibilityReasons || false} />
      <AddToFavorites isOpen={props.addToFavorites || false} />
      <SellThis isOpen={props.sellThis || false} />
      <ReAnalyzeSearch isOpen={props.reAnalyze || false} userStoresSelectOptions={marketplaceSelectOptionsWithStores} />
      <RestrictionReasonCodes isOpen={props.restrictionCheck || false} />
    </Restricted>
  );
};

export default SearchResults;
