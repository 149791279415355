import { Input, InputProps } from "reactstrap";
import { IMaskInput } from 'react-imask';
import classNames from "classnames";
import { ValidatableInputProps } from "helpers/types";

export type TextInputProps = Pick<InputProps, "value" | "onKeyUp" | "id" | "disabled" | "onBlur" | "className" | "style" | "placeholder" | "onInput"> & ValidatableInputProps<string> & {
    type?: "text" | "email" | "url" | "textarea" | "password",
    mask?: string
}

const TextInput = ({ mask, isValid, className, value, onChange, disabled, ...rest }: TextInputProps) =>  {
    return <>
        {mask ? 
            <IMaskInput
                className={classNames("form-control", className, {
                    "is-invalid": isValid === false,
                    "is-valid": isValid === true
                })}
                lazy={false}
                mask={mask}
                onAccept={(val:any) => onChange?.(val)}
                value={value || ""}
                {...rest} /> 
            : 
            <Input
                valid={isValid === true}
                invalid={isValid === false}
                onChange={e => onChange?.(e.target.value)}
                value={value || ""}
                disabled={disabled} 
                type={rest.type}
                {...rest} />
            }

            {
                isValid === false && <div className="invalid-feedback">{rest.errorMessage}</div>
            }
    </>;
}

export default TextInput;