import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ApiError, LoadingStates} from "helpers/types";
import { SystemMonitoring } from "models/system_monitoring";
import { SystemMonitoringDetails } from "models/system_monitoring_details";

type AdminSystemMonitoringLoadingStates = LoadingStates<"list" | "details" |"clearTopic" |"rebalance">;

export type AdminSystemMonitoringState = {
  loading: AdminSystemMonitoringLoadingStates;
  systemMonitoring: SystemMonitoring;
  details: SystemMonitoringDetails;
  error?: ApiError;
};

const initialState: AdminSystemMonitoringState = {
  loading: {
    list: false,
    clearTopic: false,
    details: false,
    rebalance: false
  },
  systemMonitoring: {} as SystemMonitoring,
  details: {} as SystemMonitoringDetails,
  error: {} as ApiError,
};

const AdminSystemMonitoringSlice = createSlice({
  name: "AdminSystemMonitoring",
  initialState,
  reducers: {
    setSystemMonitoring(state, action: PayloadAction<SystemMonitoring>) {
      state.systemMonitoring = action.payload;
    },
    setDetails(state, action: PayloadAction<SystemMonitoringDetails>) {
      state.details = action.payload;
    },
    loading(state, action: PayloadAction<[keyof AdminSystemMonitoringLoadingStates, boolean]>) {
      const [operation, loadingState] = action.payload;
      state.loading[operation] = loadingState;
    },
    apiError(state, action: PayloadAction<ApiError>) {
      state.error = action.payload;
    },
    reset(state) {
      state.loading = initialState.loading;
      state.error = initialState.error;
    },
  },
});

export const {setSystemMonitoring, setDetails, loading, apiError, reset} = AdminSystemMonitoringSlice.actions;

export default AdminSystemMonitoringSlice.reducer;
