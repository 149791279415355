import {Subscription} from "./subscription";
import {UserLimit} from "./user_limit";
import {ICreditCardDto} from "./user_payment";

export enum UserSubscriptionStatus {
  Trial = 0,
  Active = 1,
  Canceled = 2,
  Terminated = 3,
  PaymentFailed = 4,
}

export interface UserSubscription {
  userSubscriptionId: string;
  subscriptionId: string;
  userId: string;
  paymentId: string;
  startDate: Date;
  endDate: Date;
  stripeSubscriptionId: string;
  billingDay: number;
  billingPeriodMonth: number;
  status: UserSubscriptionStatus;
  isActive: boolean;
  subscription: Subscription;
  limits: UserLimit[];
}

export interface ICreateSubscriptionDto extends ICreditCardDto {
  billingPeriodMonth: number;
  subscriptionId: string;
  subscriptionPriceId: string;
  couponCode: string;
}

export interface IChangeUserSubscriptionDto {
  userId: string;
  newSubscriptionId: string;
  newSubscriptionPriceId: string;
}

export interface ICreateUserSubscriptionDto {
  userId: string;
  newSubscriptionId: string;
  newSubscriptionPriceId: string;
}

export interface IDownloadInvoiceDto {
  userPaymentId: string;
}

export interface ICancelUserSubscriptionDto {
  subscriptionId: string;
  cancelAtPeriodEnd: boolean;
}
export interface IContinueUserSubscriptionDto {
  subscriptionId: string;
}

export interface IRetrySubscriptionPaymentDto {
  userSubscriptionId: string;
}

export interface IValidateCouponDto {
  couponCode: string;
}
