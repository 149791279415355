import {ForwardedRef, PropsWithChildren, forwardRef, useCallback, useImperativeHandle, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {Button, Col, Input, Modal, ModalBody, Row, Spinner} from "reactstrap";
import classNames from "classnames";
import _ from "lodash";

type ButtonType = "yes" | "no" | "cancel" | "ok" | "retry";
type ButtonConfigs = Record<ButtonType, {label: string; color: string}>;

export interface DialogRef {
  show: VoidFunction;
  hide: VoidFunction;
}

export interface DialogProps extends PropsWithChildren {
  title?: string;
  message?: string;
  color?: string;
  busy?: boolean;
  show?: boolean;
  showField?: boolean;
  iconClass?: string;
  buttons?: ButtonType[];
  buttonConfig?: (config: ButtonConfigs) => ButtonConfigs;
  onButtonClick?: (button: ButtonType, textField: string, hide: VoidFunction) => void;
  onToggle?: () => void;
}

const defaultButtonConfigs: ButtonConfigs = {
  yes: {
    label: "Yes",
    color: "default",
  },
  no: {
    label: "No",
    color: "light",
  },
  cancel: {
    label: "Cancel",
    color: "light",
  },
  ok: {
    label: "Ok",
    color: "default",
  },
  retry: {
    label: "Retry",
    color: "secondary",
  },
};

const Dialog = (props: DialogProps, ref: ForwardedRef<DialogRef>) => {
  const {t} = useTranslation();
  const [isOpen, setIsOpen] = useState(props.show || false);
  const [textField, setTextField] = useState<string>("");
  const buttonConfigs = useMemo(() => {
    return props.buttonConfig ? props.buttonConfig(_.cloneDeep(defaultButtonConfigs)) : defaultButtonConfigs;
  }, [props]);

  const buttons = useMemo(
    () =>
      _.chain<ButtonType>(props.buttons || ["ok"])
        .uniq()
        .map((button) => ({type: button, ...buttonConfigs[button]}))
        .value(),
    [buttonConfigs, props.buttons],
  );

  const onButtonClick = (button: ButtonType) => {
    props.onButtonClick?.(button, textField, hide);
  };
  const toggle = useCallback(() => {
    if (props.onToggle) {
      props.onToggle();
    }
    setIsOpen((prev) => !prev);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setIsOpen]);
  const show = useCallback(() => setIsOpen(true), [setIsOpen]);
  const hide = useCallback(() => setIsOpen(false), [setIsOpen]);

  const color = props.color || "info";

  useImperativeHandle(
    ref,
    () => {
      return {
        show,
        hide,
      };
    },
    [show, hide],
  );

  return (
    <>
      <Modal isOpen={isOpen} toggle={toggle} backdrop="static" centered>
        <ModalBody className="py-3 px-5">
          {props.children || (
            <div className="mt-2 text-center">
              {props.iconClass && <i className={classNames(props.iconClass, "display-5", `text-${color}`)}></i>}
              <Row>
                <Col>
                  <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-4">
                    <h4>{props.title}</h4>
                    <p className="text-muted mx-4 mb-0">{props.message}</p>
                  </div>
                </Col>
              </Row>
              <br />
              {props.showField && <Input type="text" className="form-control mb-3" onChange={(e) => setTextField(e.target.value)} />}
            </div>
          )}
          <div className="mt-4 mb-2">
            <div className="d-flex gap-2 justify-content-center">
              {buttons.map((b) => (
                <Button type="button" key={b.type} color={b.color === "default" ? color : b.color} onClick={() => onButtonClick(b.type)} disabled={props.busy}>
                  {(b.color === "default") && props.busy && <Spinner size="sm" className="me-2"></Spinner>}
                  {t(b.label)}
                </Button>
              ))}
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default forwardRef(Dialog);
