import {ResponseModel} from "models/response_model";
import {CouponDetails, apiError, loading, setCouponDetails, setCouponStatus, setList} from "./reducer";
import {postGetSubscriptions, postSaveUserSubscription, postValidateCoupon} from "services/payment_service";
import {ICreateSubscriptionDto} from "models/user_subscription";
import {generateError, renderSuccessToast} from "helpers/utilities";
import {getLoggedUser, LOCAL_STORAGE, removeSubscriptionLocalData} from "helpers/local_storage";
import i18n from "i18n";

export const getAllSubscriptionList = () => async (dispatch: any) => {
  try {
    dispatch(loading(["list", true]));
    const result: ResponseModel = await postGetSubscriptions();
    dispatch(setList({data: result.data}));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["list", false]));
  }
};

export const validateCoupon = (couponCode: string) => async (dispatch: any) => {
  try {
    dispatch(loading(["coupon", true]));
    const result: ResponseModel = await postValidateCoupon({couponCode});
    dispatch(apiError(null));
    dispatch(setCouponStatus("applied"));
    const responseData: CouponDetails = result.data;
    dispatch(setCouponDetails(responseData));
    renderSuccessToast(i18n.t("Account.Subscription.Toast.CouponAppliedSuccessfully"));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["coupon", false]));
  }
};

export const removeCoupon = () => async (dispatch: any) => {
  try {
    dispatch(loading(["coupon", true]));
    dispatch(setCouponStatus("empty"));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["coupon", false]));
  }
};

export const createNewSubscription = (subscriptionDto: ICreateSubscriptionDto) => async (dispatch: any) => {
  try {
    dispatch(loading(["create", true]));
    await postSaveUserSubscription(subscriptionDto);
    const user = getLoggedUser();
    if (user) {
      user.hasSubscription = true;
      await localStorage.setItem(LOCAL_STORAGE.LOGGED_USER, JSON.stringify(user));

      // Redirect to getting started page after subscription creation.
      window.location.href = "/getting-started";
    }
    removeSubscriptionLocalData();
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
  } finally {
    dispatch(loading(["create", false]));
  }
};
