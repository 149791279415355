import Moment from "react-moment";
import {useTranslation} from "react-i18next";
import {Row, Col, Card, CardHeader, CardBody, Progress} from "reactstrap";
import "moment/locale/tr";
import CopyAsinWidget from "Components/Common/CopyAsinWidget";
import {UserSearchProduct} from "models/user_search_product";
import i18n from "i18n";
import {useEffect, useState} from "react";

interface InProgressProps {
  item: UserSearchProduct;
}
const InProgress = ({item}: InProgressProps) => {
  const {t} = useTranslation();
  const [progress, setProgress] = useState<number>(0);
  useEffect(() => {
    if (item) {
      setProgress((item.completedServiceCount / 13) * 100);
    }
  }, [item]);

  return (
    <Row>
      <Col xxl={12}>
        <Card className="border">
          <CardHeader className="common-css padding-bottom-0">
            <Row className="align-items-center g-4">
              <Col sm>
                {item.asin ? (
                  <>
                    <div className="card-title mb-0">
                      <CopyAsinWidget Asin={item.asin} index={item.userSearchProductId} bold={true} />
                    </div>
                  </>
                ) : (
                  "Asin: " + t("NA")
                )}
              </Col>
            </Row>
            <Row>
              <Col sm>
                <Card className="bg-light overflow-hidden shadow-none my-3 card mt-2">
                  <CardBody>
                    <div className="d-flex align-items-center">
                      <div className="flex-shrink-0">
                        <i className="mdi mdi-spin mdi-cog-outline fs-22 me-1"></i>
                      </div>
                      <div className="flex-grow-1 ms-2">
                        <h5 className="mb-0 align-items-center">{t("SearchResults.Analyzes.InProgress.AnalysisContinues")}</h5>
                        <p className="text-muted mb-0">
                          {t("SearchResults.Analyzes.InProgress.AnalysisStarted")}
                          {
                            <Moment className="text-primary" locale={i18n.language} fromNow>
                              {item.statusDate}
                            </Moment>
                          }
                          {"."}
                        </p>
                      </div>
                    </div>
                  </CardBody>
                  <div>
                    <Progress
                      value={progress}
                      color={progress < 3 ? "danger" : progress < 6 ? "warning" : progress < 9 ? "info" : progress < 12 ? "primary" : "success"}
                      className="bg-success-subtle rounded-0"
                    />
                  </div>
                </Card>
              </Col>
            </Row>
          </CardHeader>
        </Card>
      </Col>
    </Row>
  );
};

export default InProgress;
